import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* updatePasswordRequest({ data, form }) {
  try {
    yield call(api.updatePassword, data);
    Alerts.alertSuccess("Senha alterada com sucesso!");
    form.clearAllValues();
    yield put(Creators.updatePasswordSuccess());
  } catch (response) {
    Alerts.alertError("Não foi possível atualizar a senha. ");
    yield put(Creators.updatePasswordFailure());
  }
}
