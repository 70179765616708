import React, { useState } from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
import { Creators } from "../reduxSagas";
import { Creators as PlansCreators } from "../../premium/reduxSagas";
import ResetPassword from "../components/ResetPassword";
import BasicData from "../components/BasicData";
import CompleteData from "../components/CompleteData";
import { ButtonContained } from "../../../components";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import { Creators as GlobalCreators } from "../../globalReduxSagas";

import { customModal } from "../../modals/utils";
import { Filters } from "../../../lib";

function Profile({
  updatePasswordRequest,
  isFetching,
  getCompanyData,
  companyData,
  putCompleteRegister,
  getVerticals,
  getBusinessModel,
  getSectors,
  getSizes,
  getTypeOfSolution,
  getOffice,
  inputs,
  putBasicRegister,
  getUserInfosRequest,
  userData,
  postNewPlan,
  getPlanTec,
  getPlanNotTec,
  plan,
  getIncubators,
  incubators,
}) {
  const [formPassword, setFormRef] = useState(null);
  const [formBasic, setFormBasic] = useState(null);
  const [completeData, setCompleteData] = useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    ref.current = formPassword;
  }, [formPassword]);

  React.useEffect(() => {
    getUserInfosRequest();
    getVerticals();
    getBusinessModel();
    getSectors();
    getSizes();
    getTypeOfSolution();
    getOffice();
    getIncubators();
  }, [
    getUserInfosRequest,
    getVerticals,
    getBusinessModel,
    getSectors,
    getSizes,
    getTypeOfSolution,
    getOffice,
    getIncubators,
  ]);
  React.useEffect(() => {
    if (userData?.role === "ROLE_ASSOCIATE_MANAGER") {
      getCompanyData();
    } else if (userData?.role === "ROLE_NOT_ASSOCIATE_MANAGER") {
      getPlanNotTec();
    } else if (userData?.role === "ROLE_NOT_ASSOCIATE_TECHNOLOGY_MANAGER") {
      getPlanTec();
    }
  }, [
    userData,
    getCompanyData,
    getUserInfosRequest,
    getPlanNotTec,
    getPlanTec,
  ]);

  const submitNewPassword = (data) => {
    updatePasswordRequest(data, ref.current);
  };

  const submitCompleteData = (data) => {
    putCompleteRegister(data);
  };

  const submitBasicData = (data) => {
    putBasicRegister(data);
  };

  const changePlan = (id) => {
    customModal.setInfos(
      "ALTERAÇÃO DE PLANO",
      [
        "Ao confirmar sua solicitação de alteração de plano, a ACATE fará uma análise de perfil para a proposta de upgrade do plano atual. Deseja continuar?",
      ],
      {
        label: "SIM, CONTINUAR",
        onClick: () => {
          postNewPlan(id);
          customModal.close();
        },
      },
      {
        label: "CANCELAR",
        onClick: () => {
          customModal.close();
        },
      }
    );
  };

  const render = (data, Complete) => {
    if (data) {
      if (Complete) {
        return (
          <Grid item xs={12}>
            <CompleteData
              setForm={setFormBasic}
              setCompleteData={setCompleteData}
              onSubmit={submitCompleteData}
              inputs={inputs}
              companyData={data}
              formBasic={formBasic}
            />
          </Grid>
        );
      } else {
        return (
          <Grid item xs={12}>
            <BasicData
              setForm={setFormBasic}
              setCompleteData={setCompleteData}
              companyData={data}
              onSubmit={submitBasicData}
              incubators={incubators}
              formBasic={formBasic}
            />
          </Grid>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <Styles.Container>
      <Grid container spacing={1}>
        {isFetching ? (
          <CircularProgress />
        ) : !completeData ? (
          <>
            {plan && (
              <Grid item xs={12}>
                <Styles.CardContainer>
                  <Styles.CardHeader>
                    <Styles.CardTitle>PLANO ATUAL</Styles.CardTitle>
                  </Styles.CardHeader>
                  <Styles.Line />
                  <Styles.ActiveContainer>
                    <Styles.ActivePlan>
                      {userData?.planName
                        ? userData?.planName
                        : "Sem plano ativo"}
                    </Styles.ActivePlan>
                    <Styles.ActivePlanDate>
                      {userData?.planBeginDate
                        ? Filters.formatDate(userData?.planBeginDate)
                        : "--/--/-----"}
                    </Styles.ActivePlanDate>
                  </Styles.ActiveContainer>
                  <Styles.ButtonContainer>
                    <ButtonContained
                      loading={isFetching}
                      disabled={isFetching}
                      onClick={() => changePlan(plan?.response?.id)}
                      fullWidth={false}
                    >
                      Alterar Plano
                    </ButtonContained>
                  </Styles.ButtonContainer>
                </Styles.CardContainer>
              </Grid>
            )}

            {companyData && render(companyData, false)}
            <Grid item xs={12}>
              <ResetPassword
                onSubmit={submitNewPassword}
                setForm={setFormRef}
              />
            </Grid>
          </>
        ) : (
          companyData && (
            <>
              {plan && (
                <Grid item xs={12}>
                  <Styles.CardContainer>
                    <Styles.CardHeader>
                      <Styles.CardTitle>PLANO ATUAL</Styles.CardTitle>
                    </Styles.CardHeader>
                    <Styles.Line />
                    <Styles.ActiveContainer>
                      <Styles.ActivePlan>
                        {userData?.planName
                          ? userData?.planName
                          : "Sem plano ativo"}
                      </Styles.ActivePlan>
                      <Styles.ActivePlanDate>
                        {userData?.planBeginDate
                          ? Filters.formatDate(userData?.planBeginDate)
                          : "--/--/-----"}
                      </Styles.ActivePlanDate>
                    </Styles.ActiveContainer>
                    <Styles.ButtonContainer>
                      <ButtonContained
                        loading={isFetching}
                        disabled={isFetching}
                        onClick={() => changePlan(plan?.response?.id)}
                        fullWidth={false}
                      >
                        Alterar Plano
                      </ButtonContained>
                    </Styles.ButtonContainer>
                  </Styles.CardContainer>
                </Grid>
              )}

              {render(companyData, true)}
            </>
          )
        )}
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const {
    isFetching,
    companyData,
    businessModel,
    sectors,
    sizes,
    typeOfSolution,
    verticals,
    office,
    plan,
    incubators,
  } = state.profile;
  const { userData } = state.global;
  return {
    userData,
    isFetching,
    companyData,
    inputs: {
      businessModel: businessModel,
      sectors: sectors,
      sizes: sizes,
      typeOfSolution: typeOfSolution,
      office: office,
      verticals: verticals,
    },
    plan,
    incubators,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    updatePasswordRequest,
    getCompanyData,
    putCompleteRegister,
    getVerticals,
    getBusinessModel,
    getSectors,
    getSizes,
    getTypeOfSolution,
    putBasicRegister,
    getOffice,
    getPlanTec,
    getPlanNotTec,
    getIncubators,
  } = Creators;
  const { getUserInfosRequest } = GlobalCreators;
  const { postNewPlan } = PlansCreators;
  return {
    getUserInfosRequest: function () {
      return dispatch(getUserInfosRequest());
    },
    updatePasswordRequest: function (data, form) {
      return dispatch(updatePasswordRequest(data, form));
    },
    getCompanyData: function () {
      return dispatch(getCompanyData());
    },
    putCompleteRegister: function (data) {
      return dispatch(putCompleteRegister(data));
    },
    getVerticals: function (data) {
      return dispatch(getVerticals(data));
    },
    getBusinessModel: function (data) {
      return dispatch(getBusinessModel(data));
    },
    getSectors: function (data) {
      return dispatch(getSectors(data));
    },
    getOffice: function (data) {
      return dispatch(getOffice(data));
    },
    getSizes: function (data) {
      return dispatch(getSizes(data));
    },
    getTypeOfSolution: function (data) {
      return dispatch(getTypeOfSolution(data));
    },

    putBasicRegister: function (data) {
      return dispatch(putBasicRegister(data));
    },
    postNewPlan: function (data) {
      return dispatch(postNewPlan(data));
    },
    getPlanTec: function () {
      return dispatch(getPlanTec());
    },
    getPlanNotTec: function () {
      return dispatch(getPlanNotTec());
    },
    getIncubators: function () {
      return dispatch(getIncubators());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Profile));
