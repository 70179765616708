const colors = {
  Acate: {
    main: "#000000",
    backgroundColor: "#ECECEC",
    chartFill: "#5AB6E5",
    positive: "#05D3D3",
    negative: "#F0166D",
    neutral: "#FFD11A",
    text: "#000000",
    whiteBackground: "#ffffff",

    primary: "#ACCA13",
    backgroundPrimary: "#CBE255",
    primaryTransparent: "#E9F1C473",
    secondary: "#21B3C4",
    woman: "#21B3C4",
    man: "#E95F69",
    others: "#B96BD4",
    backgroundSecondary: "#64DAE8",
    secondaryTransparent: "#C8F9FF70",
  },
};

export default colors;
