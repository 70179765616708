import styled from "styled-components";
import { FontStyles, Text } from "../../../components";
import { MediaQueries } from "../../../config";
import Button from "@material-ui/core/Button";
const PersonInfo = styled(Button)((props) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: 0,
  [MediaQueries.smDown]: {
    display: "none",
  },
}));
const InfoRow = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: 0,
  [MediaQueries.smDown]: {
    display: "none",
  },
}));

const Email = styled(Text)((props) => ({
  color: props.theme.palette.text.secondary,
  ...FontStyles.roman12,
  marginBottom: 0,
  marginTop: 0,
  textAlign: "left",
}));

const Name = styled(Text)((props) => ({
  color: props.theme.palette.text.secondary,
  ...FontStyles.bold14,
  marginBottom: 0,
  marginTop: -5,
  textAlign: "left",
  // textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
  // overflow: "hidden",
}));

const Styles = {
  PersonInfo,
  Name,
  Email,
  InfoRow,
};

export default Styles;
