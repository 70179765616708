import React from "react";
import { EvaluatedReleases } from "../features";

function EvaluatedReleasesPage() {
  return (
    <>
      <EvaluatedReleases />
    </>
  );
}

export default EvaluatedReleasesPage;
