import { Filters } from "../../lib";
export default function exportUsers(response) {
  const data = response.data.response.map((item) => {
    return {
      id: item.id,
      companyName: item.companyName,
      username: item.username,
      email: item.email,
      releasedDate: Filters.formatDate(item.releasedDate),
      status: item.status ? "Aprovado" : "Desaprovado",
      plan: item.plan,
      isTech: item.isTech ? "Sim" : "Não",
      employeeNumber: item.employeeNumber
        ? item.employeeNumber
        : "Não registrado",
      office: item.office ? item.office?.name : "Não Registrado",
      cnpj: item.cnpj ? Filters.cnpjMask(item.cnpj) : "-",
    };
  });

  return { ...response, usersList: data };
}
