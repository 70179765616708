import React from "react";

import {
  FormControl,
  Input,
  FormHelperText,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { formConnector } from "../../FormConfig";
import Text from "../strings/Text";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IconButton } from "@material-ui/core";
import { FontStyles, Tolltip } from "../index";
import { Spacing } from "../../config";

const Label = styled(Text)(({ theme, $withValue, $withError }) => {
  const { palette: colors, typography } = theme;

  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withValue
      ? $withError
        ? colors.error.main
        : colors.text.secondary
      : typography.body2.color,
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
  };
});

const Background = styled.div(({ theme, $minHeight, $error, disabled }) => {
  const { spacing, palette: colors } = theme;

  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: colors.background.input,
    boxShadow: $error
      ? `inset 0 0 0 2px ${colors.text.tertiary}`
      : `inset 0 0 0 ${colors.text.tertiary}`,

    opacity: disabled ? 0.6 : 1,
    borderRadius: 5,
    minHeight: $minHeight && spacing(12),
  };
});
const StyledInput = styled(Input)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $alternativeColors,
    ...rest
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      padding: $small ? spacing(0, 1) : spacing(1),
      multiline: true,
      textOverflow: "ellipsis",
      color: colors.text.secondary,
      backgroundColor:
        $alternativeColors === 2
          ? colors.gray
          : $alternativeColors
          ? colors.text.primary
          : "transparent",
      borderRadius: 5,

      "& .MuiInput-input": {
        color: $alternativeColors ? colors.text.primary : colors.text.secondary,
        width: spacing(5),
        "&::placeholder": {
          opacity: 0.8,
        },
        "&input:-internal-autofill-selected": {
          color: "red",
          background: "red",
        },
        "& .MuiInputBase-input": {
          color: "#6605B8",
        },
      },
      "& .MuiInputBase-input": {
        color: "#6605B8",
      },
      ...$inputStyle,
    };
  }
);
const Count = styled(Text)(({ theme, $withValue }) => {
  const { spacing, palette: colors } = theme;

  return {
    width: "100%",
    color: colors.text.tertiary,
    opacity: 0.6,
    textAlign: "end",
    marginBlock: spacing(0.5),
    paddingRight: spacing(1.5),
    ...FontStyles.medium12,
  };
});
const LabelView = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
  };
});
const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.tertiary,
  };
});
const inputTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        width: "4.8rem",
      },
    },
  },
});
function InputComponent(props) {
  const {
    value,
    error,
    handleKeyPress,
    handleKeyDown,
    onBlur,
    setConfigs,
    ref,
    formHandler,
    fileInputRef,
    usedProps,
  } = formConnector.useStringValue(props);

  const {
    inputLabel,
    icon,
    theme,
    required,
    color,
    disableError,
    hideVisualError,
    margin = "none",
    variant = "outlined",
    inputStyle,
    type,
    downloadButtonLabel,
    uploadButtonLabel,
    withDownload,
    hideButtonLabel,
    readOnly,
    downloadHref,
    openOnNewTab,
    autoComplete = false,
    accept,
    small,
    alternativeColors,
    withHide,
    placeholder,
    percentage,
    count,
    minHeight,
    maxHeight,
    tooltip,
    ...rest
  } = usedProps;

  return (
    <FormControl fullWidth error={hideVisualError ? null : Boolean(error)}>
      <LabelView>
        <Label
          $withValue={Boolean(value) || value === 0 || Boolean(placeholder)}
          $withError={Boolean(error)}
        >
          {inputLabel}
        </Label>
        {tooltip && (
          <Tolltip
            title={tooltip?.title}
            tip={tooltip?.content}
            disableFocusListener
          />
        )}
      </LabelView>

      <MuiThemeProvider theme={percentage && inputTheme}>
        <Background
          $error={hideVisualError ? null : Boolean(error)}
          disabled={props.disabled || type === "file"}
          $minHeight={minHeight}
        >
          <StyledInput
            {...rest}
            $alternativeColors={alternativeColors}
            $small={small}
            autoComplete={autoComplete ? autoComplete : null}
            readOnly={readOnly}
            disabled={props.disabled || type === "file"}
            $error={hideVisualError ? null : Boolean(error)}
            multiline={count ? true : false}
            inputProps={{
              maxLength: count,
              style: {
                maxHeight: maxHeight && Spacing(maxHeight - 1.5),
                overflow: maxHeight && "auto",
              },
            }}
            count={count}
            onClick={
              type === "file" ? () => fileInputRef.current.click() : null
            }
            disableUnderline
            endAdornment={percentage && <span>%</span>}
            type={type === "file" ? null : type}
            value={type === "file" ? (value ? value.name : "") : value}
            required={Boolean(required)}
            variant={variant}
            color={color}
            label={value ? inputLabel : null}
            fullWidth
            margin={margin}
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
            inputRef={ref}
            onChange={
              type !== "file" ? (event) => setConfigs(event.target.value) : null
            }
            onBlur={(event) => {
              setTimeout(() => {
                onBlur(event);
                formHandler.testInputError(props.name);
              }, 10);
            }}
            placeholder={placeholder}
          />
          {count ? (
            <Count>
              {ref.current ? ref.current.value.length : 0} / {count} caracteres
            </Count>
          ) : null}
        </Background>
      </MuiThemeProvider>

      {disableError ? null : (
        <ErrorMessage error={Boolean(error)}>
          {Boolean(error) ? error : " "}
        </ErrorMessage>
      )}

      {withHide ? (
        <IconButton
          onClick={withHide}
          component="span"
          style={{
            position: "absolute",
            right: 0,
            top: "24px",
            color: "gray",
          }}
        >
          <VisibilityIcon />
        </IconButton>
      ) : null}
    </FormControl>
  );
}

export default withTheme(InputComponent);
