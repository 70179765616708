import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { api, ResponseError } from "../../services";
import { SessionStorage } from "../../lib";

export default function* getUserInfos() {
  try {
    const response = yield call(api.getCurrentUser, null);
    const data = SessionStorage.getItem("userData");
    yield put(Creators.getUserInfosSuccess(data));
    yield put(Creators.getUserDataSuccess(response.userInfo));
  } catch (response) {
    const errorResponse = new ResponseError(response);
    yield put(Creators.globalLogout());
    errorResponse.alertMessage();
    yield put(Creators.getUserInfosFailure());
  }
}
