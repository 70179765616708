import React from "react";
import { ContactUs } from "../features";

function ContactUsAcate() {
  return (
    <>
      <ContactUs/>
    </>
  );
}

export default ContactUsAcate;
