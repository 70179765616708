import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";
import { navigateTo } from "../../../navigation/navigate";

export default function* putCompleteRegister({ data }) {
  try {
    const response = yield call(api.putCompleteRegister, data);
    yield put(Creators.putCompleteRegisterSuccess(response));
    navigateTo.FinancialData();
    Alerts.alertSuccess("Dados do usuário salvos com sucesso.");
  } catch (response) {
    Alerts.alertError("Não foi possível salvar os dados do usuário");
    yield put(Creators.putCompleteRegisterFailure());
  }
}
