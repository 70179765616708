import React from "react";
import { ChartDescription } from "../../../../components";
import { Grid } from "@material-ui/core";
import { ChartsColors } from "../../../../config";
import { connect } from "react-redux";
function FooterChart({ data, $alternative, gender, userData }) {
  return (
    <>
      {gender ? (
        userData && userData?.role.includes("ACATE") ? (
          <Grid container>
            <Grid item>
              <ChartDescription color={ChartsColors.Acate.primary}>
                Total de Funcionários
              </ChartDescription>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item>
              <ChartDescription color={ChartsColors.Acate.primary}>
                Total de Funcionários
              </ChartDescription>
            </Grid>
            <Grid item>
              <ChartDescription color={ChartsColors.Acate.woman}>
                % de Mulheres
              </ChartDescription>
            </Grid>
            <Grid item>
              <ChartDescription color={ChartsColors.Acate.man}>
                % de Homens
              </ChartDescription>
            </Grid>
            <Grid item>
              <ChartDescription color={ChartsColors.Acate.others}>
                % de Não Binário
              </ChartDescription>
            </Grid>
          </Grid>
        )
      ) : userData && userData?.role.includes("ACATE") ? (
        <Grid container>
          <Grid item>
            <ChartDescription
              color={ChartsColors.Acate.secondary}
              box={!$alternative}
              dotLine={$alternative}
            >
              Média das Empresas
            </ChartDescription>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item>
            <ChartDescription color={ChartsColors.Acate.primary}>
              Minha Empresa
            </ChartDescription>
          </Grid>
          <Grid item>
            <ChartDescription
              color={ChartsColors.Acate.secondary}
              box={!$alternative}
              dotLine={$alternative}
            >
              Média das Empresas
            </ChartDescription>
          </Grid>
        </Grid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { userData } = state.global;
  return {
    userData,
  };
}

export default connect(mapStateToProps)(React.memo(FooterChart));
