import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* postNewPlan({ data }) {
  try {
    yield call(api.postNewPlan, data);

    yield put(Creators.postNewPlanSuccess());
    Alerts.setCustomModal("ALTERAÇÃO DE PLANO CONFIRMADA!", [
      "Sua solicitação de alteração de plano já foi enviada para a ACATE e agora é só aguardar!",
    ]);
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível solicitar um novo plano"
    );
    yield put(Creators.postNewPlanFailure());
  }
}
