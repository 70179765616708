import styled from "styled-components";
import { FontStyles } from "../../../components";

const Container = styled.div(() => {
  return {
    display: "flex",
    alignItems: "center",
    flex: 1,
  };
});
const ImageIcon = styled.img(({ theme, $Acate }) => {
  const { spacing } = theme;
  return {
    height: spacing(2.625),
    marginInline: spacing(0.625),
    filter: $Acate
      ? "invert(27%) sepia(0%) saturate(0%) hue-rotate(230deg) brightness(101%) contrast(99%)"
      : "invert(64%) sepia(100%) saturate(363%) hue-rotate(25deg) brightness(94%) contrast(102%)",
  };
});
const Content = styled.div(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold18,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "uppercase",
    color: colors.text.secondary,
  };
});

const Style = {
  Container,
  Content,
  ImageIcon,
};

export default Style;
