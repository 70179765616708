import React from "react";
import Styles from "../styles/Styles";
import { Texts } from "../../../config";
import { ButtonOutlined, Checkbox, Select } from "../../../components";

import { FormHolder } from "../../../FormConfig";
import RegistryAssociated from "./RegistryAssociated";
import RegistryUser from "./registryUser";
import RegistryNotAssociated from "./RegistryNotAssociated";
function StepTwo({
  goBack,
  onSubmit,
  RenderSwiperDots,
  cities,
  cnaes,
  UFList,
  cityListRequest,
  isFetching,
}) {
  const texts = Texts["pt-BR"];
  const [format, setFormate] = React.useState(1);
  const [ref, setRef] = React.useState(1);
  const [disabled, setDisabled] = React.useState(false);
  function renderItem(a) {
    let render = [];
    if (format === 1) {
      render.push(
        <RegistryAssociated
          cities={cities}
          cityListRequest={cityListRequest}
          UFList={UFList}
          cnaes={cnaes}
        />
      );
      if (ref !== 1) {
        ref.findInput("cpf") && ref.removeInput("cpf");
      }
    } else if (format === 2) {
      render.push(
        <RegistryNotAssociated
          formRef={ref}
          cnaes={cnaes}
          cities={cities}
          cityListRequest={cityListRequest}
          UFList={UFList}
        />
      );
      if (ref !== 1) {
        ref.findInput("cpf") && ref.removeInput("cpf");
        ref.findInput("employeeNumber") && ref.removeInput("employeeNumber");
      }
    } else {
      render.push(
        <RegistryUser
          cities={cities}
          cityListRequest={cityListRequest}
          UFList={UFList}
        />
      );
      if (ref !== 1) {
        ref.removeInput("companyName");
        ref.removeInput("cnpj");
        ref.removeInput("cnaeId");
        ref.findInput("employeeNumber") && ref.removeInput("employeeNumber");
        ref.findInput("foundationYear") && ref.removeInput("foundationYear");
      }
    }
    return render;
  }

  const checkCity = (data) => {
    data?.cityId === (-1 || "-1" || null || undefined)
      ? setDisabled(true)
      : onSubmit(data);
  };

  return (
    <Styles.Content $padding>
      <FormHolder onSubmit={checkCity} formRef={setRef}>
        <Select
          onChange={(data) => setFormate(data)}
          inputLabel={"Tipo de Usuário"}
          name="userType"
          required={texts.login.userMessage}
          defaultValue={1}
          options={[
            { value: 1, label: "Empresa Associada" },

            //TO-DO Removidos temporariamente
            { value: 2, label: "Empresa Não Associada" },
            { value: 3, label: "Pesquisador" },
            { value: 4, label: "Pessoa Física" },
          ]}
        />
        <Styles.ScrollFrom>{renderItem()}</Styles.ScrollFrom>
        {RenderSwiperDots(format)}
        <Styles.Check>
          <Checkbox
            required={texts.login.passwordMessage}
            label={
              <p style={{ margin: 0, padding: 0 }}>
                {texts.login.modal.agree}
                <a
                  href="https://www.acate.com.br/termo-de-uso-plataforma-acate-data-tech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {texts.login.modal.terms}
                </a>
              </p>
            }
          />
        </Styles.Check>

        <Styles.ConfirmButton
          type="submit"
          name="button"
          loading={isFetching}
          disabled={isFetching || disabled}
        >
          {texts.registry.confirm}
        </Styles.ConfirmButton>
        <ButtonOutlined
          $upper
          onClick={goBack}
          loading={isFetching}
          disabled={isFetching || disabled}
        >
          {texts.registry.back}
        </ButtonOutlined>
      </FormHolder>
    </Styles.Content>
  );
}

export default React.memo(StepTwo);
