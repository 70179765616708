const ELEMENTS = {
  IDS: {
    reactContainer: "root",
    applicationDefaultScroll: "scroll-container",
    headerFilterModal: "header-filters-modal",
  },
  CLASSES: {
    headerFilterModalVisible: "show-header-filters",
  },
};

const HML = false; // True = Dev , False = Produção

const Constants = {
  ELEMENTS,
  HML,
};

export default Constants;
