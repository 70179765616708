import styled from "styled-components";
import {
  FontStyles,
  Text,
  ButtonContained,
  ButtonOutlined,
} from "../../../components";
const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    height: `calc(100vh - ${spacing(9)}px)`,
    width: "100%",
    paddingInline: spacing(3),
    paddingBlock: spacing(2.5),
    overflow: "auto",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
  };
});

const HeaderCard = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: spacing(0.5),
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    height: spacing(0.1),
    marginBottom: spacing(1),
    backgroundColor: colors.carbon,
    opacity: 0.7,
  };
});

const TitleCard = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.semibold14,
  };
});

const NewUser = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.semibold12,
    padding: spacing(0.9, 1.9),
  };
});

const DeleteUser = styled(ButtonContained)(({ theme, $secondColor, off }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    minWidth: $secondColor ? 0 : null,
    backgroundColor: $secondColor ? colors.carbon : !off ? "gray" : null,
    padding: $secondColor ? spacing(0.975, 1.5) : spacing(0.9, 2),
    margin: 0,
    "& .MuiButton-startIcon": {
      marginRight: $secondColor ? 0 : 0,
    },
    ":hover": {
      backgroundColor: $secondColor ? colors.carbon + "CC" : null,
    },
  };
});

const ManagerButton = styled(ButtonOutlined)(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.semibold12,
    padding: spacing(0.6, 2),
  };
});
const ModalPadding = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(3.5),
  };
});
const ModalInfo = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.background.input,
    padding: spacing(3.75),
  };
});
const ModalUserTitle = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold14,
    color: colors.text.secondary,
    marginBottom: spacing(1),
  };
});
const ModalUserInfo = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
  };
});
const Styles = {
  Container,
  Content,
  HeaderCard,
  TitleCard,
  NewUser,
  DeleteUser,
  Line,
  ModalPadding,
  ManagerButton,
  ModalInfo,
  ModalUserInfo,
  ModalUserTitle,
};

export default Styles;
