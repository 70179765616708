import React from "react";
import Styles from "../styles/Styles";
import Filter from "../components/Filter";
import { connect } from "react-redux";
import { Creators } from "../reduxSagas";
import { CircularProgress } from "@material-ui/core";
import DisplayIndicators from "../components/DisplayIndicators";

function Container({
  getManagementData,
  isFetching,
  managementData,
  getManageriaTooltips,
  tooltips,
}) {
  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(null);

  React.useEffect(() => {
    if (ref !== null) {
      setWidth(ref?.current?.offsetWidth);
      getManagementData(null);
      getManageriaTooltips();
    }
  }, [getManagementData, getManageriaTooltips]);

  return (
    <Styles.Container ref={ref}>
      <Styles.FilterBackground>
        <Filter widthMax={width} onSubmit={(data) => getManagementData(data)} />
      </Styles.FilterBackground>
      {managementData !== null && !isFetching ? (
        <DisplayIndicators
          data={managementData}
          isFetching={isFetching}
          tooltips={tooltips}
        />
      ) : isFetching ? (
        <Styles.Background>
          <Styles.LoadingContainer>
            <CircularProgress size={32} />
          </Styles.LoadingContainer>
        </Styles.Background>
      ) : null}
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching, managementData, tooltips } = state.managementGeral;
  return {
    isFetching,
    managementData,
    tooltips,
  };
}

function mapDispatchToProps(dispatch) {
  const { getManagementData, getManageriaTooltips } = Creators;

  return {
    getManagementData: function (filter) {
      return dispatch(getManagementData(filter));
    },
    getManageriaTooltips: function () {
      return dispatch(getManageriaTooltips());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Container));
