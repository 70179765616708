import createReducers from "../../../store/helpers/createPageReducer";
import contactUsDetailedRequest from "./contactUsDetailedRequest";
import contactUsInfoRequest from "./contactUsInfoRequest";
import sendContactUsRequest from "./sendContactUsRequest";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getContactUsDetailed",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: contactUsDetailedRequest,
    },
    {
      name: "contactUsDetailedSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        contactUsDetailed: data,
      }),
    },
    {
        name: "contactUsDetailedFailure",
        function: (state) => ({ ...state, isFetching: false, contactUsDetailed: [] }),
    },
    {
        name: "getContactUsInformation",
        params: ["data"],
        function: (state) => ({
          ...state,
          isFetching: true,
        }),
        sagaFunction: contactUsInfoRequest,
    },
    {
        name: "contactUsInfoSuccess",
        params: ["data"],
        function: (state, { data }) => ({
          ...state,
          isFetching: false,
          contactUsInformation: data,
        }),
    },
    {
        name: "contactUsInfoFailure",
        function: (state) => ({ ...state, isFetching: false, contactUsInformation: [] }),
    },
    {
        name: "sendContactUsRequest",
        params: ["data"],
        function: (state) => ({
          ...state,
          isFetching: true,
        }),
        sagaFunction: sendContactUsRequest,
      },
      {
        name: "sendContactUsSuccess",
        params: ["data"],
        function: (state) => ({
          ...state,
          isFetching: false,
        }),
      },
      {
        name: "sendContactUsFailure",
        function: (state) => ({ ...state, isFetching: false }),
      },
  ],
  {
    isFetching: false,
    contactUsDetailed: null,
    contactUsInformation: null,
  }
);
export { Creators, reducers, sagas };
