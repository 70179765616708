import React from "react";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import ReorderIcon from "@material-ui/icons/Reorder";
import { Draggable } from "react-beautiful-dnd";

const ItemContainer = styled.div(({ $last }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: $last && "flex-end",
}));
const SortImage = styled(ReorderIcon)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2.5),
    height: spacing(2.5),
    margin: spacing(0, 0.625),
    fill: colors.text.secondary,
    verticalAlign: "text-bottom",
  };
});
const GridData = styled.tr(({ $drag }) => {
  return {
    width: "100%",
    zIndex: 9999,
  };
});

const ColumnItem = styled.td(({ theme, $first, $last, $drag, nColumn }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
    padding: $first ? spacing(0, 1, 0, 3) : spacing(1),
    marginLeft: $first ? spacing(2) : spacing(0),
    marginBlock: spacing(2),
    borderTopLeftRadius: $first ? spacing(0.6) : null,
    borderBottomLeftRadius: $first ? spacing(0.6) : null,
    backgroundColor: "#EEEEEE",
    borderTopRightRadius: $last ? spacing(0.6) : null,
    borderBottomRightRadius: $last ? spacing(0.6) : null,
    verticalAlign: "middle",
    textAlign: $last && "right",
    paddingRight: $first ? spacing(2) : spacing(0),
  };
});

function GridItemTable({
  provided,
  snapshot,
  renderItemColumns,
  item,
  index,
  isFetching,
}) {
  return (
    <Draggable
      key={item.id}
      draggableId={item.id.toString()}
      index={index}
      isDragDisabled={isFetching}
    >
      {(provided, snapshot) => (
        <>
          <GridData
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            $drag={snapshot.isDragging}
          >
            <ColumnItem
              $drag={snapshot.isDragging}
              key={0}
              $first={true}
              $last={false}
              nColumn={renderItemColumns(item).length}
            >
              <SortImage />
              {index + 1 < 10 ? "0" + (index + 1) : index + 1}
            </ColumnItem>
            {renderItemColumns(item).map((itemToRender, key) => (
              <ColumnItem
                $drag={snapshot.isDragging}
                key={key}
                $first={false}
                $last={
                  key === renderItemColumns(item).length - 1 ? true : false
                }
                nColumn={renderItemColumns(item).length}
              >
                <ItemContainer
                  $last={
                    key === renderItemColumns(item).length - 1 ? true : false
                  }
                >
                  {itemToRender}
                </ItemContainer>
              </ColumnItem>
            ))}
          </GridData>
        </>
      )}
    </Draggable>
  );
}

export default React.memo(GridItemTable);
