import React from "react";
import { AccessHistoryClients } from "../features";

function AccessHistoryPage() {
  return (
    <>
      <AccessHistoryClients />
    </>
  );
}

export default AccessHistoryPage;
