import React from "react";
import { connect } from "react-redux";

import { Styles } from "../styles";
import { Creators } from "../reduxSagas";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchUsers from "../components/SearchUsers";
import UsersList from "../components/UsersList";
import { IntegratedComponentHolder } from "../../../components";
import { SessionStorage } from "../../../lib";

function AccessHistory({
  isFetching,
  accessHistoryClients,
  getAccessHistoryClients,
}) {
  const [page, setPage] = React.useState(1);

  const Mount = React.useCallback(() => {
    const requestBody = {
      username: null,
      company: null,
      lastAccess: null,
      releasedDate: null,
      page: 1,
    };

    getAccessHistoryClients(requestBody);
  }, [getAccessHistoryClients]);

  React.useEffect(Mount, [Mount]);

  const getUsers = (data) => {
    setPage(1);
    const requestBody = {
      username: data.username ? data.username : null,
      company: data.company ? data.company : null,
      lastAccess: data.lastAccess ? data.lastAccess : null,
      releasedDate: data.releasedDate ? data.releasedDate : null,
      page: 1,
    };

    getAccessHistoryClients(requestBody);
  };

  const setPageList = (value) => {
    let filter = SessionStorage.getItem("accessHistoryClientsFilter");

    filter = { ...filter, page: value };
    setPage(value);
    getAccessHistoryClients(filter);
  };

  return (
    <Styles.Container>
      <Grid container direction="row" alignContent="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Styles.Content>
            <Grid container spacing={1}>
              <SearchUsers onSubmit={getUsers} />
            </Grid>
          </Styles.Content>
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && !accessHistoryClients}
            reloadMessage={"Não encontrado"}
            showEmpty={
              !isFetching && accessHistoryClients?.usersList?.length < 1
            }
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <UsersList
                usersList={accessHistoryClients?.usersList}
                page={page}
                setPage={setPageList}
                totalPages={accessHistoryClients?.data?.response?.pageTotal}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching, accessHistoryClients } = state.accessHistoryClients;
  return {
    isFetching,
    accessHistoryClients,
  };
}

function mapDispatchToProps(dispatch) {
  const { getAccessHistoryClients } = Creators;
  return {
    getAccessHistoryClients: function (data) {
      return dispatch(getAccessHistoryClients(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AccessHistory));
