import styled from "styled-components";
import { FontStyles, Text, ButtonContained } from "../../../components";
import ErrorIcon from "@material-ui/icons/Error";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { Link } from "@material-ui/core";
import { MediaQueries } from "../../../config";
const Container = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    height: "100%",
    maxHeight: "100vh",
    backgroundColor: colors.background.paper,
    flexDirection: "column",
    padding: spacing(3, 3, 1, 3),
    justifyContent: "center",
    overflow: "auto",
  };
});

const Content = styled.div(({ theme, $padding }) => {
  const { spacing, palette: colors } = theme;
  return {
    paddingTop: $padding ? spacing(1.5) : null,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    width: "32rem",
    maxWidth: `calc(100% - ${spacing(1)}px)`,
    maxHeight: `calc(100% - ${spacing(3)}px)`,
    backgroundColor: colors.ba,
    borderRadius: 5,
    marginInline: "auto",
  };
});

const LoginSubtitle = styled(Text)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginBottom: spacing(1),
  };
});

const LogoContainer = styled.div(({ theme }) => {
  return {
    width: "100%",
    height: "fit-content",
    alignSelf: "center",
    marginBottom: "23px",
  };
});

const Header = styled.div(({ theme }) => {
  return {
    width: "100%",
    height: "fit-content",
    marginBottom: "3vh",
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  };
});

const AcateLogo = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(30.25),
    height: "auto",
  };
});

const Subtitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.primary,
    ...FontStyles.roman14,
    opacity: 0.88,
    margin: 0,
  };
});

const Version = styled.p(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    alignSelf: "center",
    marginTop: spacing(4),
    color: colors.text.primary,
    ...FontStyles.italic14,
  };
});

const ImageBG = styled.div(({ theme, $Image }) => {
  return {
    width: "100%",
    height: "100%",
    backgroundImage: $Image,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    boxShadow: "inset 0 0 0 1000px #acca1359",
  };
});

const DivBG = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: spacing(9.5),
    flexDirection: "column",
  };
});

const TextBG = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.bold22,
    fontSize: spacing(6.75),
    margin: 0,
    textAlign: "end",
    color: "white",
    [MediaQueries.mdDown]: {
      fontSize: spacing(4.25),
    },
  };
});

const TextCopyright = styled.p(({ theme }) => {
  return {
    ...FontStyles.bold16,
    margin: 0,
    color: "white",
    textAlign: "end",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  };
});

const TextSelect = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium14,
    color: colors.text.secondary,
    textTransform: "uppercase",
    padding: 0,
    margin: 0,
  };
});

const SelectBox = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
});

const Copyright = styled(CopyrightIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.text.primary,
    width: spacing(2),
    marginLeft: spacing(1.5),
  };
});

const ConfirmButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    textTransform: "uppercase",
    marginBlock: spacing(2, 1),
  };
});

const HyperLink = styled(Link)(({ theme }) => {
  return {
    ...FontStyles.italic14,
    alignSelf: "flex-end",
  };
});

const ScrollFrom = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    width: "100%",
    maxHeight: "28.75rem",
    minHeight: "13.5rem",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingRight: spacing(1),
    "::-webkit-scrollbar-thumb": {
      background: colors.background.secondary,
      borderRadius: "15px",
      height: "2px",
    },
  };
});
const Description = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.italic14,
    color: colors.carbon,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: spacing(1),
  };
});
const WarningDescription = styled(ErrorIcon)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(1.5),
    height: "auto",
    color: colors.carbon,
    opacity: 1,
    marginRight: spacing(0.75),
  };
});
const FLogo = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(16.125),
    height: "auto",
  };
});

const Check = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    marginTop: spacing(1),
  };
});
const Styles = {
  Container,
  Content,
  LogoContainer,
  Version,
  Subtitle,
  Link,
  ImageBG,
  TextBG,
  DivBG,
  TextCopyright,
  Copyright,
  Header,
  AcateLogo,
  LoginSubtitle,
  ConfirmButton,
  TextSelect,
  SelectBox,
  HyperLink,
  ScrollFrom,
  Description,
  WarningDescription,
  FLogo,
  Check,
};

export default Styles;
