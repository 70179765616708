import React from "react";
import { connect } from "react-redux";
import Title from "../../../components/strings/Title";
import { Texts, Images } from "../../../config";
import { Swiper } from "../../../components";
import { Styles } from "../styles";
import { Grid } from "@material-ui/core";
import StepOne from "../components/StepOne";
import StepTwo from "../components/StepTwo";

import { navigateTo } from "../../../navigation/navigate";
import { Creators } from "../reduxSagas";
import useMediaQuery from "@material-ui/core/useMediaQuery";
function FirstAccessPage({
  cnaListRequest,
  cityListRequest,
  postRegistration,
  CNAEList,
  CityList,
  UFList,
  ufListRequest,
  isFetching,
  emailOnUse,
  isValid,
}) {
  const texts = Texts["pt-BR"];
  const [formData, setFormData] = React.useState({});

  const matches = useMediaQuery("(max-width:599px)");

  React.useEffect(() => {
    ufListRequest();
    cnaListRequest();
  }, [cnaListRequest, ufListRequest]);
  const saveData = (data, callback) => {
    setFormData({ ...formData, ...data });
    callback();
  };
  const types = [
    "ASSOCIATE",
    "NOT_ASSOCIETE",
    "RESEARCHER",
    "PHYSICAL_PERSON",
    "OTHER",
  ];

  const submitData = (data) => {
    let request = {
      username: data.username,
      email: formData.email,
      password: formData.password,
      userType: types[parseInt(data.userType) - 1],
      companyName: data.companyName ? data.companyName : null,
      docNumber: data.cnpj ? data.cnpj : data.cpf,
      cityId: parseInt(data.cityId),
      telephone: data.telephone,
      cnaeId:
        data.cnaeId && data.cnaeId !== "-1" ? parseInt(data.cnaeId) : null,
      docType: data.cnpj ? "CNPJ" : "CPF",
      employeeNumber: data.employeeNumber ? data.employeeNumber : null,
      foundationYear: data.foundationYear ? data.foundationYear : null,
    };
    postRegistration(request);
  };

  const checkEmail = (string, callback) => {
    emailOnUse(string, callback);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction={matches ? "column-reverse" : "unset"}
        wrap={matches ? "nowrap" : "unset"}
        style={{ overflow: matches ? "auto" : null, height: "100vh" }}
      >
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <Styles.Container>
            <Styles.Header>
              <Styles.AcateLogo src={Images.LogoLogin} />
            </Styles.Header>
            <Styles.Content>
              <Title>{texts.registry.title}</Title>
              <Styles.LoginSubtitle>
                {texts.registry.subtitle}
              </Styles.LoginSubtitle>

              <Swiper
                screens={[
                  ({ goNext, RenderSwiperDots }) => (
                    <StepOne
                      checkEmail={checkEmail}
                      isValidEmail={isValid}
                      onSubmit={(data) => {
                        saveData(data, goNext);
                      }}
                      goBack={() => navigateTo.Landing()}
                      RenderSwiperDots={RenderSwiperDots}
                      isFetching={isFetching}
                    />
                  ),
                  ({ goPrevious, RenderSwiperDots }) => (
                    <StepTwo
                      cityListRequest={cityListRequest}
                      UFList={UFList}
                      cities={CityList}
                      cnaes={CNAEList}
                      onSubmit={(data) => {
                        saveData(data, () => submitData(data));
                      }}
                      goBack={() => {
                        goPrevious();
                      }}
                      RenderSwiperDots={RenderSwiperDots}
                      isFetching={isFetching}
                    />
                  ),
                ]}
              />
            </Styles.Content>
          </Styles.Container>
        </Grid>

        <Grid item xs={12} sm={6} md={7} lg={8}>
          <Styles.ImageBG $Image={`url(${Images.BackgroundImage})`}>
            <Styles.DivBG>
              <Styles.FLogo src={Images.Finep} />
              <div>
                <Styles.TextBG>
                  {texts.login.bgText.map((item) => {
                    return item;
                  })}
                </Styles.TextBG>
                <Styles.TextCopyright>
                  {texts.login.copyright} <Styles.Copyright />
                </Styles.TextCopyright>
              </div>
            </Styles.DivBG>
          </Styles.ImageBG>
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { isFetching, CNAEList, CityList, UFList, isValid } = state.registry;
  return {
    isFetching,
    CNAEList,
    CityList,
    UFList,
    isValid,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    cnaListRequest,
    cityListRequest,
    postRegistration,
    ufListRequest,
    emailOnUse,
  } = Creators;
  return {
    cnaListRequest: function () {
      return dispatch(cnaListRequest());
    },
    cityListRequest: function (uf) {
      return dispatch(cityListRequest(uf));
    },
    ufListRequest: function () {
      return dispatch(ufListRequest());
    },
    postRegistration: function (data) {
      return dispatch(postRegistration(data));
    },
    emailOnUse: function (data, callback) {
      return dispatch(emailOnUse(data, callback));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(FirstAccessPage));
