export default function getManagerIndicator(response) {
  const data = response.data.response.groups.map((item) => {
    return {
      id: item.id,
      label: item.name,
      position: item.position,
      tip: item.tip,
      inputs: item.indicators.map((input) => {
        return {
          id: input.id,
          label: input.name,
          tip: input.tip,
          notApplicable: input.notApplicable,
          required: input.required,
          value:
            input.valueType === "INTEGER" || input.valueType === "LIST"
              ? input.value?.value
              : parseFloat(input.value?.value).toFixed(2),
          valueLabel: input.valueLabel,
          valueType: input.valueType,
        };
      }),
    };
  });

  data.sort(function (a, b) {
    return a.position - b.position;
  });
  return { ...response, inputs: data };
}
