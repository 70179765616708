import React from "react";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Text from "../strings/Text";
import { Spacing, Colors } from "../../config";
import dateFns from "@date-io/date-fns";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import { createMuiTheme, FormControl, ThemeProvider } from "@material-ui/core";
const InputDate = styled(KeyboardDatePicker)(
  ({ theme, $alternativeColors, error }) => {
    const { palette: colors, spacing } = theme;
    return {
      ".MuiInputBase-root": {
        margin: 0,
        marginTop: spacing(3.125),
        paddingBlock: spacing(1),
        border: 0,
        borderRadius: 5,
        color: $alternativeColors ? colors.text.primary : colors.text.secondary,
        backgroundColor: $alternativeColors
          ? colors.text.secondary
          : colors.background.input,
        "& input": {
          padding: "6px 8px 7px 8px",
          "&::placeholder": {
            opacity: 0.8,
          },
        },
        "& fieldset": {
          border: error ? null : 0,
        },
      },
    };
  }
);

const Label = styled(Text)(({ theme, $withError }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: $withError ? colors.error.main : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

function InputDateComponent(props) {
  const {
    value,
    error,
    handleKeyPress,
    handleKeyDown,
    onBlur,
    setConfigs,
    ref,
    formHandler,
    usedProps,
  } = formConnector.useStringValue(props);

  const {
    inputLabel,
    icon,
    theme,
    required,
    color,
    disableError,
    borderRadius,
    variant = "outlined",
    inputStyle,
    views = ["year", "month", "date"],
    autoOk = true,
    format,
    readOnly,
    minDate,
    maxDate,
    alternativeColors,
    autoComplete,
    ...restProps
  } = usedProps;
  const materialTheme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        adornedEnd: {
          borderRadius: Spacing(0.625),
          padding: 0,
          backgroundColor: Colors.gray,
        },
        inputAdornedEnd: {
          paddingBlock: Spacing(1.75),
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: Colors.green,
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: Colors.green,
        },
      },
      MuiButton: {
        textPrimary: { color: Colors.green },
      },
    },
  });
  return (
    <FormControl fullWidth error={Boolean(error)}>
      <MuiPickersUtilsProvider utils={dateFns}>
        <ThemeProvider theme={materialTheme}>
          <Label $withError={Boolean(error)}>{inputLabel}</Label>
          <InputDate
            {...restProps}
            $alternativeColors={alternativeColors}
            {...(readOnly ? { open: !readOnly } : {})}
            autoComplete={autoComplete === false ? "new-password" : null}
            inputVariant="filled"
            format={format ? format : "dd/MM/yyyy"}
            minDate={minDate}
            maxDate={maxDate}
            InputProps={{
              disableUnderline: true,
            }}
            value={value ? value : null}
            required={Boolean(required)}
            variant={variant}
            color={color}
            fullWidth
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
            views={views}
            inputRef={ref}
            autoOk={autoOk}
            onChange={(date) => setConfigs(date)}
            onBlur={(event) => {
              setTimeout(() => {
                onBlur(event);
                formHandler.testInputError(props.name);
              }, 10);
            }}
            error={Boolean(error)}
            helperText={disableError ? null : error ? error : " "}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

export default InputDateComponent;
