import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* cnaListRequest() {
  try {
    const response = yield call(api.getCNAE);

    yield put(Creators.cnaListRequestSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao encontra UF.");
    yield put(Creators.cnaListRequestFailure());
  }
}
