import styled from "styled-components";
import { FontStyles, Text, ButtonContained } from "../../../components";
import ErrorIcon from "@material-ui/icons/Error";
import { IconButton, Button } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    height: `calc(100vh - ${spacing(10)}px)`,
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
    paddingBottom: spacing(1),
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.carbon,
    width: "100%",
    height: 1,
    marginBottom: spacing(2.5),
  };
});

const CardContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",
    height: "100%",
    padding: spacing(2),
    borderRadius: spacing(2),
  };
});

const CardHeader = styled.div(({ theme, $info }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: $info ? "flex-start" : "space-between",
    alignItems: "center",
    marginBottom: spacing(0.5),
  };
});

const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    color: colors.text.secondary,
    textTransform: "uppercase",
    margin: 0,
  };
});
const ButtonContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
  };
});

const Dots = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.carbon,
    margin: 0,
    padding: 0,
  };
});
const Back = styled(Button)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold14,
    color: colors.text.secondary,
    textTransform: "uppercase",
    margin: 0,
    marginBottom: spacing(2),
  };
});
const Description = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.medium12,
    color: colors.carbon,
    opacity: 0.6,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: spacing(1),
  };
});
const WarningDescription = styled(ErrorIcon)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(1.5),
    height: "auto",
    color: colors.carbon,
    opacity: 0.6,
    marginRight: spacing(0.5),
  };
});

const InfosButton = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(2),
    height: "auto",
    margin: 0,
    padding: 0,
  };
});

const InfoIcon = styled(HelpIcon)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    marginLeft: spacing(1),
    height: "auto",
    color: colors.text.tertiary,
  };
});
const DeleteUser = styled(ButtonContained)(({ theme, $secondColor, off }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    minWidth: $secondColor ? 0 : null,
    marginRight: spacing(1),
    backgroundColor: $secondColor ? colors.carbon : !off ? "gray" : null,
    padding: $secondColor ? spacing(0.9, 1.5) : spacing(0.9, 1.125),
    "& .MuiButton-startIcon": {
      marginRight: $secondColor ? 0 : spacing(1),
    },
    ":hover": {
      backgroundColor: $secondColor ? colors.carbon + "CC" : null,
    },
  };
});
const NewIndicator = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.semibold12,
    padding: spacing(0.9, 1.9),
    marginBottom: spacing(1),
  };
});
const ModalPadding = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(3.5),
    overflow: "auto",
    height: "100%",
  };
});

const Label = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: colors.text.secondary,
  };
});

const Styles = {
  Container,
  Line,
  CardContainer,
  CardHeader,
  CardTitle,
  Dots,
  ButtonContainer,
  Description,
  WarningDescription,
  InfosButton,
  InfoIcon,
  DeleteUser,
  Back,
  NewIndicator,
  ModalPadding,
  Label,
};

export default Styles;
