import React from "react";
import { Images } from "../../../config";
import Styles from "../styles/Styles";

function HomePage() {
  return (
    <Styles.Container $Image={`url(${Images.WaterMarkAcate})`}>
      <Styles.BackgroundMark />
    </Styles.Container>
  );
}

export default HomePage;
