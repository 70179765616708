import styled from "styled-components";
import { Container as DefaultContainer } from "@material-ui/core";

const Container = styled.div(({ theme }) => {
  const { palette: colors } = theme;
  return {
    position: "relative",
    display: "flex",
    width: "80vw",
    backgroundColor: colors.white,
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.3)",
    zIndex: 1,
    height: "min-content",
    justifyContent: "space-between",
  };
});

const Content = styled(DefaultContainer)(({ theme, $maxWidth }) => {
  const { spacing } = theme;
  return {
    maxWidth: "100vw",
    display: "flex",
    width: "100%",
    padding: spacing(0.5),
    paddingLeft: spacing(0),
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 2,
  };
});

const FirstContentGroup = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
}));

const UserContentGroup = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    alignItems: "center",
    padding: spacing(1.7),
    width: 250,
    backgroundColor: colors.background.input,
  };
});

const Rectangle = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    display: "inline-block",
    width: spacing(3),
    height: spacing(2.625),
    backgroundColor: colors.background.secondary,
  };
});
const Styles = {
  Container,
  Content,
  FirstContentGroup,
  UserContentGroup,
  Rectangle,
};

export default Styles;
