import createReducers from "../../../../store/helpers/createPageReducer";
import getFinantialData from "./getFinantialData";
import postExportIndicators from "./postExportIndicators";
import getFinantialTooltips from "./getFinantialTooltips";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getFinantialData",
      params: ["filter"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getFinantialData,
    },
    {
      name: "getFinantialDataSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        finantialData: data,
      }),
    },
    {
      name: "getFinantialDataFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        finantialData: null,
      }),
    },

    {
      name: "getFinantialTooltips",
      params: ["filter"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getFinantialTooltips,
    },
    {
      name: "getFinantialTooltipsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        tooltips: data,
      }),
    },
    {
      name: "getFinantialTooltipsFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        tooltips: null,
      }),
    },

    {
      name: "postExportIndicators",
      params: ["filter"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: postExportIndicators,
    },
    {
      name: "postExportIndicatorsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        exportData: data,
      }),
    },
    {
      name: "postExportIndicatorsFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    finantialData: null,
    exportData: null,
    tooltips: null,
  }
);

export { Creators, reducers, sagas };
