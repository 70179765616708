import React from "react";
import { ButtonContained, InputEmail, Switch } from "../../../components";
import Styles from "../styles/Styles";
import Grid from "@material-ui/core/Grid";
import { FormHolder } from "../../../FormConfig";

import { Texts } from "../../../config";
import { Creators as CreatorsRegistry } from "../../firstAccess/reduxSagas";
import { Input, InputAutoComplete } from "../../../components";
import { Filters, Validations } from "../../../lib";
import { connect } from "react-redux";

function BasicData({
  isFetching,
  setForm,
  cityListRequest,
  CityList,
  setCompleteData,
  companyData,
  onSubmit,
  UFList,
  ufListRequest,
  incubators,
  formBasic,
}) {
  const texts = Texts["pt-BR"];

  const [isIncubating, setIsIncubating] = React.useState(
    Boolean(companyData?.incubator?.value)
  );
  React.useEffect(() => {
    ufListRequest();
    if (companyData?.uf) {
      return cityListRequest(companyData?.uf.ufName);
    }
    return null;
  }, [ufListRequest, companyData, cityListRequest]);

  return (
    <Styles.CardContainer>
      <Grid container>
        <Grid item xs={6}>
          <Styles.ButtonsNavigation onClick={() => setCompleteData(false)}>
            Dados Básicos
          </Styles.ButtonsNavigation>
        </Grid>
        <Grid item xs={6}>
          <Styles.ButtonsNavigation
            Second
            onClick={() => setCompleteData(true)}
          >
            Dados Completos
          </Styles.ButtonsNavigation>
        </Grid>
      </Grid>
      <Styles.CardHeader>
        <Styles.CardTitle>Completar Cadastro</Styles.CardTitle>
      </Styles.CardHeader>
      <Styles.Line />

      <FormHolder onSubmit={onSubmit} formRef={setForm}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputEmail
              variant="standard"
              name="email"
              nextInput="companyName"
              inputLabel={"E-mail"}
              defaultValue={companyData?.responsableEmail}
              readOnly
            />
          </Grid>

          <Grid item xs={3}>
            <Input
              variant="standard"
              name="companyName"
              nextInput="cnpj"
              defaultValue={companyData?.name}
              inputLabel={texts.login.stepTwo.companyName}
            />
          </Grid>

          <Grid item xs={3}>
            <Input
              variant="standard"
              name="cnpj"
              nextInput="cityId"
              inputLabel={texts.login.stepTwo.cnpj}
              mask={(data) => Filters.cnpjMask(data)}
              validation={Validations.isCNPJ}
              maskToSubmit={Filters.cnpjToRequest}
              defaultValue={companyData?.cnpj}
              readOnly={companyData?.cnpj ? true : false}
            />
          </Grid>
          <Grid item xs={3}>
            <InputAutoComplete
              inputLabel={texts.login.stepTwo.uf}
              name="cityId"
              autoComplete="off"
              value={undefined}
              options={UFList ? UFList : []}
              defaultValue={companyData?.uf}
              onChange={(value, index) => {
                if (!isFetching) {
                  UFList.find((element) => {
                    if (element.value === index) {
                      return cityListRequest(element.ufName);
                    }
                    return null;
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <InputAutoComplete
              inputLabel={texts.login.stepTwo.city}
              name="cityId"
              autoComplete="off"
              value={undefined}
              options={CityList ? CityList : []}
              defaultValue={companyData?.city}
            />
          </Grid>
          <Grid item xs={3}>
            <Switch
              defaultValue={Boolean(companyData?.incubator?.value)}
              label="EMPRESA INCUBADA NO MIDITEC"
              name="isIncubating"
              onChangeValue={(a) => {
                setIsIncubating(a);
                if (a === false) {
                  formBasic.clearValue("incubatorId");
                  const button = formBasic
                    .getInputRef("incubatorId")
                    .getElementsByClassName(
                      "MuiAutocomplete-clearIndicator"
                    )[0];
                  if (button) {
                    button.click();
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <InputAutoComplete
              disabled={!isIncubating}
              inputLabel={"INCUBADORA"}
              name="incubatorId"
              autoComplete="off"
              defaultValue={companyData?.incubator}
              options={
                incubators
                  ? incubators?.map((item) => {
                      return { value: item?.id, label: item?.name };
                    })
                  : []
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              defaultValue={companyData?.description}
              placeholder="Adicionar descrição da empresa..."
              name="description"
              inputLabel={"DESCRIÇÃO DA EMPRESA"}
              count={200}
            />
          </Grid>
        </Grid>

        <Styles.ButtonContainer>
          <ButtonContained
            loading={isFetching}
            disabled={isFetching}
            type="submit"
            name="button"
            fullWidth={false}
          >
            Confirmar Alterações
          </ButtonContained>
        </Styles.ButtonContainer>
      </FormHolder>
    </Styles.CardContainer>
  );
}

function mapStateToProps(state) {
  const { isFetching, CityList, UFList } = state.registry;
  return {
    isFetching,
    CityList,
    UFList,
  };
}

function mapDispatchToProps(dispatch) {
  const { cityListRequest, postRegistration, ufListRequest } = CreatorsRegistry;

  return {
    cityListRequest: function (uf) {
      return dispatch(cityListRequest(uf));
    },
    ufListRequest: function () {
      return dispatch(ufListRequest());
    },
    postRegistration: function (data) {
      return dispatch(postRegistration(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BasicData);
