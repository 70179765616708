import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* emailOnUse({ data, callback }) {
  try {
    const response = yield call(api.emailOnUse, { email: data ? data : "" });

    yield put(Creators.emailOnUseSuccess(response.data));
    if (response.data.response === true) {
      callback();
    }
  } catch (response) {
    Alerts.alertError("Erro ao encontra email.");
    yield put(Creators.emailOnUseFailure());
  }
}
