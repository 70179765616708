import React from "react";
import { Switch, Route } from "react-router-dom";
import { PagesConfig, Themes } from "../config";
import Pages from "../pages";
import { Creators as globalCreators } from "../features/globalReduxSagas";
import { connect } from "react-redux";
import { Styles } from "./styles";
import { Menu, Header } from "../features";
import { ThemeProvider } from "../components";

function Routes({ isLoggedIn, isFetching, getStatus, pathname, isAcateMode }) {
  const mount = React.useCallback(() => {
    getStatus();
  }, [getStatus]);
  React.useEffect(mount, [mount]);
  return isLoggedIn ? (
    <ThemeProvider theme={isAcateMode ? Themes.Acate : Themes.Light}>
      <Styles.InitialBackground $didMount={!isFetching}>
        <Menu />
        <Styles.LoggedContainer id="scroll-container">
          <Header />
          <Switch>
            {PagesConfig.logged.map((item, index) => {
              if (item.pages) {
                return item.pages.map((page, index) => {
                  const Component = Pages[page.name];
                  return (
                    <Route
                      key={page.navigationId}
                      exact={true}
                      path={page.path}
                      component={() => <Component />}
                    />
                  );
                });
              }
              return null;
            })}
          </Switch>
        </Styles.LoggedContainer>
      </Styles.InitialBackground>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={Themes.Light}>
      <Styles.InitialBackground $didMount={!isFetching}>
        <Switch>
          {PagesConfig.notLogged.map((item, index) => (
            <Route
              key={item.path + index}
              exact={true}
              path={item.path}
              component={Pages[item.name]}
            />
          ))}
        </Switch>
      </Styles.InitialBackground>
    </ThemeProvider>
  );
}

function mapStateToProps({ global, router }) {
  const { isLoggedIn, isFetching, isAcateMode } = global;
  const { pathname } = router.location;
  return {
    isLoggedIn,
    pathname,
    isFetching,
    isAcateMode,
  };
}

function mapDispatchToProps(dispatch) {
  const { getGlobalStatus } = globalCreators;
  return {
    getStatus: () => dispatch(getGlobalStatus()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Routes));
