import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { api } from "../../../services";
import { Alerts, SessionStorage } from "../../../lib";

export default function* resetPasswordCodeRequest({ data }) {
  try {
    const dataRequest = {
      email: data.email,
      code: data.code.toUpperCase(),
    };
    const response = yield call(api.recoveryPasswordCode, dataRequest);
    SessionStorage.setItem("token", response.data.response);
    yield put(Creators.resetPasswordCodeRequestSuccess(response));
  } catch (response) {
    Alerts.alertError("Código de autenticação incorreto");
    yield put(Creators.resetPasswordRequestFailure());
    yield put(Creators.resetPasswordCodeFailure());
  }
}
