import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { Creators } from "../index";
import { Alerts } from "../../../../lib";

export default function* getOffice() {
  try {
    const sectors = yield call(api.getOffice, null);

    yield put(Creators.getOfficeSuccess(sectors));
  } catch (response) {
    Alerts.alertError("Não foi encontrar os cargos de atuação");
    yield put(Creators.getInputsFailure());
  }
}
