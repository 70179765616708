import styled from "styled-components";
import {
  FontStyles,
  Text,
  ButtonContained,
  InputDate,
} from "../../../components";
import { IconButton } from "@material-ui/core";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    height: `calc(100vh - ${spacing(11)}px)`,
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
    [`@media only screen and (max-width: 1600px)`]: {
      width: "80vw",
    },
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: spacing(2),
    overflow: "hidden",
  };
});
const ScrollContainer = styled.div(({ theme }) => {
  return {
    width: "100%",
    overflow: "auto",
  };
});

const HeaderCard = styled.div(({ theme, $info }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: $info ? "flex-start" : "space-between",
    alignItems: "center",
    marginBottom: spacing(0.5),
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    height: spacing(0.1),
    marginBottom: spacing(1),
    backgroundColor: colors.carbon,
    opacity: 0.7,
  };
});

const TitleCard = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.semibold14,
  };
});

const ActionButton = styled(ButtonContained)(({ theme, $secondColor }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    width: 175,
    backgroundColor: $secondColor ? colors.green : colors.carbon,
    padding: spacing(0.9, 1.5),
    marginLeft: spacing(1),
    ":hover": {
      backgroundColor: $secondColor ? colors.green : colors.carbon,
    },
  };
});

const Dots = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.carbon,
    margin: 0,
    padding: 0,
  };
});

const InputWithoutLabel = styled(InputDate)(({ theme }) => {
  const { spacing } = theme;
  return {
    top: spacing(2.5),
  };
});
const InputLabel = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});
const BetweenDate = styled(Text)(({ theme }) => {
  const { palette: colors, spacing } = theme;

  return {
    ...FontStyles.roman14,
    color: colors.text.secondary,
    textAlign: "center",
    paddingTop: spacing(1.5),
  };
});
const CompactButton = styled(ButtonContained)(({ theme, $export }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    fontSize: $export ? FontStyles.semibold12.size : FontStyles.semibold14.size,
    paddingBlock: spacing(0.75),
    paddingInline: spacing(2),
    marginBottom: spacing(1.25),
    color: colors.text.primary,
    backgroundColor: colors.text.secondary,
    textTransform: "capitalize",
    letterSpacing: 0,
  };
});
const Styles = {
  Container,
  Content,
  HeaderCard,
  TitleCard,
  ActionButton,
  Line,
  Dots,
  InputWithoutLabel,
  InputLabel,
  BetweenDate,
  CompactButton,
  ScrollContainer,
};

export default Styles;
