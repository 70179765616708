import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
import { MenuGroupWithPages } from "../components";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { customModal } from "../../modals/utils";
import FirstAcessNotification from "../components/FirstAcessNotification";
import CompleteRegister from "../components/CompleteRegister";
import { Images, Texts } from "../../../config";
import { SessionStorage } from "../../../lib";
import { CircularProgress } from "@material-ui/core";
function Menu({
  pathname,
  globalLogout,
  userData,
  getUserInfosRequest,
  clearUserData,
}) {
  const texts = Texts["pt-BR"].notification;

  React.useEffect(() => {
    const modalNotification = () => {
      customModal.setInfos(
        texts.title,
        texts.modalText,
        null,
        null,
        <CompleteRegister
          email={userData?.email}
          profile={userData?.needToCompleteRegister}
        />
      );
    };

    const modalFirstNotification = () => {
      customModal.setInfos(
        texts.firstTitle,
        texts.firstModalText,
        null,
        null,
        <FirstAcessNotification
          email={userData?.email}
          profile={userData?.needToCompleteRegister}
        />,
        true
      );
    };

    const firstNotification = window.localStorage.getItem(
      `${userData?.email}-firstNotificationDisable`
    );
    const notification = window.localStorage.getItem(
      `${userData?.email}-notificationDisable`
    );
    const show = SessionStorage.getItem("MenuNotification");

    if (
      !firstNotification &&
      (userData?.needToCompleteRegister || userData?.needToInputIndicators) &&
      !userData?.role?.includes("ACATE") &&
      userData?.role === "ROLE_ASSOCIATE_MANAGER"
    ) {
      modalFirstNotification();
      SessionStorage.setItem("MenuNotification", true);
    } else if (
      notification !== `"true"` &&
      (userData?.needToCompleteRegister || userData?.needToInputIndicators) &&
      !userData?.role?.includes("ACATE") &&
      userData?.role === "ROLE_ASSOCIATE_MANAGER" &&
      show === null
    ) {
      modalNotification();
      SessionStorage.setItem("MenuNotification", true);
    }
  }, [userData, texts]);

  let firstOne = true;

  return (
    <Styles.Container>
      <Styles.AcateLogo src={Images.Logo} />
      <Styles.Line />
      <Styles.ScrollContainer>
        {userData ? (
          userData?.pages.map((item, index) => {
            const first = firstOne;
            firstOne = false;
            return (
              <MenuGroupWithPages
                key={item.session + index}
                headerName={item.session}
                item={item.pages}
                index={index}
                firstItem={first}
                globalLogout={globalLogout}
                clearUserData={clearUserData}
                pathname={pathname}
                user={userData}
              />
            );
          })
        ) : (
          <div
            style={{
              width: "96%",
              marginTop: "10%",
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
      </Styles.ScrollContainer>
    </Styles.Container>
  );
}

function mapStateToProps({ router, global }) {
  const { userData } = global;
  return {
    pathname: router.location.pathname,
    userData,
  };
}

function mapDispatchToProps(dispatch) {
  const { globalLogout, getUserInfosRequest, clearUserData } = GlobalCreators;
  return {
    globalLogout: function () {
      return dispatch(globalLogout());
    },
    getUserInfosRequest: function () {
      return dispatch(getUserInfosRequest());
    },
    clearUserData: function () {
      return dispatch(clearUserData());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Menu));
