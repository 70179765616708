import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
//import { Creators } from "../reduxSagas";
import PlanList from "../components/PlanList";
import Grid from "@material-ui/core/Grid";
import { Filters } from "../../../lib";
//import { Creators as GlobalCreators } from "../../globalReduxSagas";
function Premium({ userData }) {
  return (
    <Styles.Container>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Styles.CardContainer>
            <Styles.CardHeader>
              <Styles.CardTitle>PLANOS ATIVOS</Styles.CardTitle>
            </Styles.CardHeader>

            <Styles.Line />
            <Styles.PlanCard>
              <Styles.ActiveContainer>
                <Styles.ActivePlan>
                  {userData?.planName ? userData?.planName : "Sem plano ativo"}
                </Styles.ActivePlan>
                <Styles.ActivePlanDate>
                  {userData?.planBeginDate
                    ? Filters.formatDate(userData?.planBeginDate)
                    : "--/--/-----"}
                </Styles.ActivePlanDate>
              </Styles.ActiveContainer>
            </Styles.PlanCard>
          </Styles.CardContainer>
        </Grid>
        <Grid item xs={6}>
          <PlanList userData={userData} />
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.premium;
  const { userData } = state.global;
  return {
    isFetching,
    userData,
  };
}

export default connect(mapStateToProps, null)(React.memo(Premium));
