import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import { FontStyles } from "../../../components";
import { MediaQueries } from "../../../config";
import { Logos } from "../../../config/logos";

const useStyles = makeStyles((theme) => {
  const { palette: colors, spacing } = theme;
  return {
    icon: {
      paddingRight: spacing(0.625),
      width: spacing(3),
      height: spacing(3),
      marginRight: spacing(0.5),
      fill: colors.white,
      filter: "invert(1)",
      minWidth: 24,
    },
    subicon: {
      paddingRight: spacing(0.625),
      width: "auto",
      height: "0.8rem",
      marginRight: spacing(0.5),
    },
    iconFill: {
      paddingRight: spacing(0.625),
      width: "1.5rem",
      height: "1.5rem",
      marginRight: spacing(0.5),
      fill: colors.background.secondary,
    },
  };
});

const Container = styled.div(({ theme }) => {
  const { palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100vh",
    width: "min-content",
    backgroundColor: colors.background.secondary,
    position: "relative",
    boxShadow: "0px 0px 4px #00000014",
    maxWidth: "20vw",
    minWidth: "20vw",
    zIndex: 90,
  };
});

const Background = styled.div((props) => {
  return {
    marginLeft: "auto",
    height: "100%",
  };
});

const Logo = styled(Logos.acateLogo)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginLeft: spacing(3),
    marginTop: spacing(1),
    width: 110,
  };
});

const SubLogo = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold16,
    marginInline: spacing(3),
    letterSpacing: 1,
    fontWeight: "600",
    color: colors.text.primary,
    marginTop: spacing(3),
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",
    height: 1,
    marginTop: spacing(1),
  };
});

const ScrollContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    overflow: "auto",
    width: "100%",
    marginRight: spacing(3.75),
  };
});

const Group = styled.div(({ theme, $withoutPadding }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    paddingBottom: $withoutPadding ? 0 : spacing(1),
    marginTop: spacing(2),
    [MediaQueries.mdDown]: {
      padding: spacing(0, 2, $withoutPadding ? 0 : 1, 2),
    },
    [MediaQueries.smDown]: {},
  };
});

const GroupTitle = styled.p((props) => ({
  margin: 0,
  color: props.theme.palette.text.primary,
  ...FontStyles.semibold12,
}));

const GroupNameContainer = styled.div({
  display: "flex",
  alignItems: "center",
});

const GroupTitleContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(0, 5),
    [MediaQueries.mdDown]: {
      padding: spacing(0, 2),
    },
  };
});

const Page = styled.a(
  ({ theme, $disabled, $buttonContained, $currentPage }) => {
    const { spacing, palette: colors } = theme;
    return {
      justifyContent: " space-between",
      color: $buttonContained
        ? colors.background.secondary
        : theme.palette.text.primary,
      cursor: $disabled ? "default" : "pointer",
      display: "flex",
      alignItems: "center",
      opacity: $disabled ? 0.5 : 1,
      margin: $buttonContained ? "auto" : null,
      marginLeft: spacing(5),
      ...FontStyles.medium12,
      "&:hover": {
        backgroundColor: $disabled ? null : colors.text.primary + 30,
      },
      borderRight: $currentPage ? `${spacing(0.7)}px solid white` : null,
      [MediaQueries.mdDown]: {
        padding: spacing(0, 2),
        marginLeft: 0,
      },
    };
  }
);
const PageContent = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: spacing(3),
    width: "max-content",
    marginBlock: spacing(0.75),
  };
});
const Premium = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    background: "white",
    color: colors.text.tertiary,
    borderRadius: spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing(0, 2),
  };
});
const SubGroup = styled.div(({ props }) => ({
  transition: "1s",
  overflow: "hidden",
  height: "unset",
}));

const ContactContent = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    padding: spacing(3.5),
    backgroundColor: colors.background.input,
  };
});

const TitleModal = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: colors.text.secondary,
  };
});

const InfoModal = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
  };
});
const ModalPadding = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(3.5),
    overflow: "auto",
    height: "100%",
  };
});
const Notification = styled.p(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.bold10,
    color: colors.text.tertiary,
    backgroundColor: colors.white,
    borderRadius: spacing(1.25),
    letterSpacing: spacing(0.041),
    padding: spacing(0.25, 1.5),

    marginRight: spacing(1.25),
  };
});

const GreenBullet = styled.li(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.green,
    fontSize: 20,
  };
});
const AcateLogo = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    marginLeft: spacing(3),
    marginTop: spacing(1),
    width: spacing(19.375),
  };
});
const Styles = {
  AcateLogo,
  Container,
  Background,
  SubLogo,
  Line,
  GroupTitle,
  Group,
  GroupNameContainer,
  useStyles,
  Page,
  GroupTitleContainer,
  SubGroup,
  ScrollContainer,
  Logo,
  Premium,
  ContactContent,
  InfoModal,
  TitleModal,
  ModalPadding,
  Notification,
  PageContent,
  GreenBullet,
};

export default Styles;
