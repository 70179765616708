import createReducers from "../../../store/helpers/createPageReducer";
import getPlans from "./getPlans";
import postNewPlan from "./postNewPlan";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getPlans",
      params: ["data", "form"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getPlans,
    },
    {
      name: "getPlansSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        plansList: data,
      }),
    },
    {
      name: "getPlansFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "postNewPlan",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: postNewPlan,
    },
    {
      name: "postNewPlanSuccess",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "postNewPlanFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    plansList: null,
  }
);

export { Creators, reducers, sagas };
