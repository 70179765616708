export default function getPlansApproval(response) {
  const data = response.data.response.elements.map((item) => {
    return {
      plan: item.plan,
      requestDate: item.requestDate,
      enterprise: item.requester,
      id: item.id,
    };
  });
  return { ...response, ApprovalList: data };
}
