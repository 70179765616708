import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import { ButtonContained, FontStyles, Input, Text } from "../../../components";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    height: `calc(100vh - ${spacing(11)}px)`,
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
  };
});

const ContactContent = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    padding: spacing(3.5),
    backgroundColor: colors.background.input,
  };
});

const TitleModal = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: colors.text.secondary,
  };
});

const InfoModal = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
  };
});

const HeaderCard = styled.div(({ theme, $info }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: $info ? "flex-start" : "space-between",
    alignItems: "center",
    marginBottom: spacing(0.5),
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    height: spacing(0.1),
    marginBottom: spacing(1),
    backgroundColor: colors.carbon,
    opacity: 0.7,
  };
});

const TitleCard = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.semibold14,
  };
});

const ActionButton = styled(ButtonContained)(({ theme, $secondColor }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    width: 100,
    backgroundColor: $secondColor ? colors.green : colors.carbon,
    padding: spacing(0.9, 1.5),
    marginLeft: spacing(1),
    ":hover": {
      backgroundColor: $secondColor ? colors.green : colors.carbon,
    },
  };
});

const Dots = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.carbon,
    margin: 0,
    padding: 0,
  };
});

const LargeInput = styled(Input)(({ theme }) => {
  return {
    minWidth: 250,
    // backgroundColor: "red"
  };
});

const InputWithoutLabel = styled(Input)(({ theme }) => {
  const { spacing } = theme;
  return {
    top: spacing(2.5),
  };
});

const Styles = {
  Container,
  Content,
  ContactContent,
  TitleModal,
  InfoModal,
  HeaderCard,
  TitleCard,
  ActionButton,
  Line,
  Dots,
  InputWithoutLabel,
  LargeInput,
};

export default Styles;
