import React from "react";
import { connect } from "react-redux";
import { Texts } from "../../../config";

import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { Creators } from "../reduxSagas";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchUsers from "../components/SearchUsers";
import UsersList from "../components/UsersList";
import ModalRegistry from "../components/ModalRegistry";
import ModalNewManager from "../components/ModalNewManager";
import { customModal } from "../../modals/utils";
import { IntegratedComponentHolder } from "../../../components";
import { SessionStorage } from "../../../lib";

function Users({
  isFetching,
  usersList,
  getUserList,
  newUserRequest,
  updateUserRequest,
  patchStatusUser,
  newManagerRequest,
  clearUserData,
  globalLogout,
  userData,
}) {
  const texts = Texts["pt-BR"].Users;
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    getUserList({
      fullName: null,
      email: null,
      status: null,

      page: 1,
    });
  }, [getUserList]);

  const getUsers = (data) => {
    setPage(1);
    const requestBody = {
      fullName: data.name ? data.name : null,
      email: data.email ? data.email : null,

      status:
        data.status !== "-1" ? (data.status === "0" ? true : false) : null,
      page: 1,
    };

    getUserList(requestBody);
    setPage(1);
  };

  const modalNewUser = () => {
    customModal.setInfos(
      texts.modalNew.title,
      [texts.modalNew.subTitle],
      null,
      null,
      <ModalRegistry
        newUser={(data) => {
          const fullName = data.name.split(" ");
          const name = fullName[0];
          fullName.shift();
          const lastName = fullName.join(" ");

          const requestBody = {
            firstName: name,
            lastName: lastName,
            email: data.email,
            role: "Colaborador",
          };
          newUserRequest(requestBody, getUserList);

          customModal.close();
        }}
      />
    );
  };

  const modalUpdateUser = (item) => {
    customModal.setInfos(
      texts.modalUpdate.title,
      [texts.modalUpdate.subTitle],
      null,
      null,
      <ModalRegistry
        isEditing
        updateUser={(data) => {
          const fullName = data.name.split(" ");
          const name = fullName[0];
          fullName.shift();
          const lastName = fullName.join(" ");

          const requestBody = {
            firstName: name,
            lastName: lastName,
            role: data.role,
            id: item.id,
            email: data.email,
          };
          updateUserRequest(requestBody, getUserList);
          customModal.close();
        }}
        userData={item}
      />
    );
  };

  const modalUpdateManager = (item) => {
    customModal.setInfos(
      texts.modalManager.title,
      [texts.modalManager.subTitle],
      null,
      null,
      <ModalNewManager
        updateManager={() => {
          newManagerRequest(item.id, () => {
            clearUserData();
            globalLogout();
          });
          customModal.close();
        }}
        userData={item}
      />
    );
  };
  const modalStatus = (item) => {
    customModal.setInfos(
      item.status ? texts.modalDelete.title[0] : texts.modalDelete.title[1],
      [
        (item.status
          ? texts.modalDelete.subTitle[0]
          : texts.modalDelete.subTitle[1]) +
          item?.name +
          "?",
      ],
      {
        label: texts.modalDelete.confirm,
        onClick: () => {
          customModal.close();
          patchStatusUser(item, getUserList);
        },
      },
      {
        label: texts.modalDelete.exit,
        onClick: () => {
          customModal.close();
        },
      }
    );
  };

  const setPageList = (value) => {
    let filter = SessionStorage.getItem("userListFilter");

    filter = { ...filter, page: value };
    setPage(value);
    getUserList(filter);
  };

  return (
    <Styles.Container>
      <Grid container direction="row" alignContent="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Styles.Content>
            <Grid container spacing={1}>
              <FormHolder onSubmit={getUsers}>
                <SearchUsers modal={modalNewUser} />
              </FormHolder>
            </Grid>
          </Styles.Content>
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && usersList.length < 1}
            reloadMessage={"Não encontrado"}
            showEmpty={!isFetching && usersList.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}>
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <CircularProgress color="primary" />{" "}
              </div>
            ) : (
              <UsersList
                usersList={usersList.usersList}
                updateUser={modalUpdateUser}
                deleteUser={modalStatus}
                changeManager={modalUpdateManager}
                page={page}
                setPage={setPageList}
                totalPages={usersList?.data?.response?.pageTotal}
                userData={userData}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching, usersList } = state.users;
  const { userData } = state.global;
  return {
    isFetching,
    usersList,
    userData,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getUserList,
    newUserRequest,
    updateUserRequest,
    patchStatusUser,
    newManagerRequest,
  } = Creators;
  const { globalLogout, clearUserData } = GlobalCreators;
  return {
    getUserList: function (data) {
      return dispatch(getUserList(data));
    },
    newUserRequest: function (data, update) {
      return dispatch(newUserRequest(data, update));
    },
    updateUserRequest: function (data, update) {
      return dispatch(updateUserRequest(data, update));
    },
    patchStatusUser: function (data, update) {
      return dispatch(patchStatusUser(data, update));
    },
    newManagerRequest: function (data, update) {
      return dispatch(newManagerRequest(data, update));
    },
    globalLogout: function () {
      return dispatch(globalLogout());
    },

    clearUserData: function () {
      return dispatch(clearUserData());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Users));
