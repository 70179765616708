import createReducers from "../../../store/helpers/createPageReducer";
import getManagementIndicators from "./getManagementIndicators";
import getIndicatorsYear from "./getIndicatorsYear";
import sendManagementIndicatorsRequest from "./sendManagementIndicatorsRequest";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getManagementIndicatorsRequest",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getManagementIndicators,
    },
    {
      name: "getManagementIndicatorsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataIndicators: data,
      }),
    },
    {
      name: "getManagementIndicatorsFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getIndicatorsYear",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getIndicatorsYear,
    },
    {
      name: "getIndicatorsYearSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        years: data,
      }),
    },
    {
      name: "getIndicatorsYearFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "sendManagementIndicatorsRequest",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: sendManagementIndicatorsRequest,
    },
    {
      name: "sendManagementIndicatorsSuccess",
      params: ["data", "getIndicator"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "sendManagementIndicatorsFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    dataIndicators: null,
    years: null,
  }
);

export { Creators, reducers, sagas };
