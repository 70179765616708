import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Creators as globalCreators } from "../../globalReduxSagas";
import { SessionStorage, Alerts } from "../../../lib";

export default function* loginRequest({ data, captcha }) {
  try {
    delete data.cd;
    const response = yield call(api.getToken, data, captcha);

    let userData = {
      email: response.data.response.email,
      name: response.data.response.username,
      role: response.data.response.roles,
      isAcate: response.data.response.roles[0].includes("ACATE"),
    };

    yield put(Creators.loginSuccess());
    SessionStorage.setItem("isLoggedIn", true);

    SessionStorage.setItem(
      "isAcateMode",
      response.data.response.roles[0].includes("ACATE")
    );

    SessionStorage.setItem("userData", userData);
    SessionStorage.setItem("token", response.data.response.token);
    yield put(globalCreators.getGlobalStatus());
  } catch (response) {
    Alerts.alertError(
      "E-mail e/ou senha informados estão incorretos. Tente novamente ou clique em 'Esqueci minha senha'. "
    );
    yield put(Creators.loginFailure());
  }
}
