import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* exportUserList({ data }) {
  try {
    const requestBody = {
      searchParameters: {
        companyName: data.companyName,
        accessTypeRequested: data.accessTypeRequested,
        username: data.username,
        email: data.email,
        beginRequestDate: data.beginRequestDate,
        endRequestDate: data.endRequestDate,
        planId: data.planId,
        isTech: data.isTech,
        officeId: data.officeId,
        incubatorId: data.incubatorId,
        cnpj: data.cnpj,
      },
      page: {
        number: data.page - 1,
        size: 999999999,
      },
    };
    const response = yield call(api.exportUserList, requestBody);
    yield put(Creators.exportUserListSuccess(response.usersList));
  } catch (response) {
    Alerts.alertError("Erro ao realizar pesquisa de liberações. ");
    yield put(Creators.exportUserListFailure());
  }
}
