import React from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Input,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import { FontStyles } from "../index";
import Text from "../strings/Text";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FontStyle from "../styles/fontsStyles";
const StyledInput = styled(Input)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $alternativeColors,
    ...rest
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      padding: $small ? spacing(0, 1) : spacing(1),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor: $alternativeColors
        ? colors.text.secondary
        : colors.text.primary,
      borderRadius: spacing(1),
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.error.main}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.3 : 1,
      "& .MuiInput-input": {
        color: $alternativeColors ? colors.text.primary : colors.text.primary,

        "&::placeholder": {
          opacity: 0.8,
        },
      },

      ...$inputStyle,
    };
  }
);
const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {};
});

const StyledMenuItem = styled(MenuItem)(({ theme, filled }) => {
  const { palette: colors } = theme;
  return {
    opacity: filled ? 0.7 : 1,
    backgroundColor: colors.background.paper,
    ...FontStyle.bold12,
    "&.MuiListItem-button:hover": {
      backgroundColor: colors.text.tertiary + 20,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      backgroundColor: colors.text.primary + 50,
    },

    "& .MuiPaper-root": {
      backgroundColor: "red",
    },
  };
});

const StyledSelect = styled(Select)(({ theme, $withError, $maxHeight }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyle.medium16,
    color: colors.text.secondary,
    padding: spacing(1),
    backgroundColor: colors.background.input,
    borderRadius: spacing(0.7),

    border: $withError
      ? `1px solid ${colors.background.secondary}`
      : "0px solid red",

    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: spacing(1),
    },
  };
});

const Label = styled(Text)(({ theme, $withValue, $withError, $maxHeight }) => {
  const { palette: colors, typography } = theme;

  return {
    ...FontStyles.bold14,
    zIndex: $maxHeight ? 1000 : 10,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withValue
      ? $withError
        ? colors.error.main
        : colors.text.secondary
      : typography.body2.color,
    transition: ".2s",
    pointerEvents: "none",
  };
});
const Dot = styled(FiberManualRecordIcon)(({ theme, $color }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(1.5),
    height: spacing(1.5),
    color: $color ? colors.text.secondary : colors.text.tertiary,
    marginRight: spacing(1),
  };
});
const SelectedValue = styled.p(({ theme, $color }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
    margin: 0,
  };
});
function SelectComponent(props) {
  const { value, error, setConfigs, onBlur, ref, formHandler, usedProps } =
    formConnector.useStringValue(props);
  const { onChange, name } = props;
  const onSelectValue = React.useCallback(
    (event) => {
      setConfigs(event.target.value, event);
      try {
        onChange(event.target.value);
      } catch (err) {}
      setTimeout(() => {
        onBlur();
        formHandler.testInputError(name);
      }, 10);
    },
    [setConfigs, onBlur, formHandler, name, onChange]
  );

  const {
    containerStyles,
    required,
    inputLabel,
    options,
    disableError,
    customInput,
    $maxHeight,
    alternativeColors = false,
  } = usedProps;

  return (
    <StyledFormControl
      style={containerStyles}
      fullWidth
      error={Boolean(error)}
      required={Boolean(required)}
      $maxHeight={$maxHeight}
      onMouseUp={(event) => event.stopPropagation()}>
      <Label
        $withValue={Boolean(value) || value === 0}
        $withError={Boolean(error)}
        $maxHeight={$maxHeight}>
        {inputLabel}
      </Label>
      <StyledSelect
        $withError={Boolean(error)}
        $alternativeColors={alternativeColors}
        disableUnderline
        ref={ref}
        value={value}
        $maxHeight={$maxHeight}
        onChange={onSelectValue}
        onMouseUp={(event) => event.stopPropagation()}
        renderValue={(value) => {
          return <SelectedValue>{value}</SelectedValue>;
        }}
        MenuProps={
          <>
            <Dot />
          </>
        }
        input={customInput ? <StyledInput $maxHeight={$maxHeight} /> : null}>
        {options.map((option) => (
          <StyledMenuItem value={option.value} filled={option.filled}>
            <Dot $color={option.filled} />{" "}
            {option.label +
              (option.filled
                ? " REGISTRADO"
                : option.cache
                ? " EM PREENCHIMENTO"
                : " AGUARDANDO PREENCHIMENTO")}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </StyledFormControl>
  );
}

export default SelectComponent;
