import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* patchApproveAccess({ data, update }) {
  try {
    yield call(api.patchApproveAccess, data);
    let filter = SessionStorage.getItem("companyAccessFilter");
    yield put(Creators.patchApproveAccessSuccess());
    Alerts.alertSuccess(
      "Acesso da empresa foi aprovado com sucesso!"
    );
    update(filter);
  } catch (response) {
    Alerts.alertError(
      "Erro ao aprovar acesso da empresa"
    );
    yield put(Creators.patchApproveAccessFailure());
  }
}
