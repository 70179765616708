import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* sendManagementIndicatorsRequest({ data }) {
  try {
    const response = yield call(api.sendFinancialIndicators, data);
    yield put(Creators.sendManagementIndicatorsSuccess());

    Alerts.alertSuccess(response?.data?.message);
    yield put(Creators.getManagementIndicatorsRequest(data.year));
    yield put(Creators.getIndicatorsYear());
  } catch (response) {
    Alerts.alertError("Não foi possível salvar os indicadores.");
    yield put(Creators.sendManagementIndicatorsFailure());
  }
}
