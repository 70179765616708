import React from "react";
import {

  FormControl,
  FormHelperText,
  Checkbox,
  withTheme,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import Text from "../strings/Text";
import { FontStyles } from "..";

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    icon: {
      width: spacing(3),
      height: spacing(3),
    },
    border: {
      fill: "transparent",
      strokeWidth: spacing(0.2),
      x: `calc(50% - ${spacing(2) / 2}px)`,
      y: `calc(50% - ${spacing(2) / 2}px)`,
      stroke: "black",
      width: spacing(2),
      height: spacing(2),
      rx: spacing(0.2),
    },
    iconCenter: {
      fill: "black",
      width: spacing(1),
      height: spacing(1),
      rx: spacing(0.2),
      x: `calc(50% - ${spacing(1) / 2}px)`,
      y: `calc(50% - ${spacing(1) / 2}px)`,
    },
  };
});

const Label = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium14,
    color: colors.text.secondary,
  };
});
function CheckboxComponent(props) {
  const { label, required, disableError } = props;
  const classes = useStyles();

  const { value, error, setConfigs, ref } =
    formConnector.useBooleanValue(props);

  return (
    <FormControl required={required} error={error}>
      <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
        <Checkbox  
          onChange={() => setConfigs(!value)}
          checked={value}
          inputRef={ref}
          color="primary"
          icon={
            <svg className={classes.icon}>
              <rect className={classes.border} />
            </svg>
          }
          checkedIcon={
            <svg className={classes.icon}>
              <rect className={classes.border} />
              <rect className={classes.iconCenter} />
            </svg>
          }
          style={{ margin: 0, padding: 0 }}
        />
        <Label>{label}</Label>

      </div>
    
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(CheckboxComponent);
