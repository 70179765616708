import React from "react";
import { connect } from "react-redux";
import { ButtonText, Checkbox, FontStyles } from "../../../components";
import { Grid } from "@material-ui/core";
import Styles from "../styles/Styles";
import { FormHolder } from "../../../FormConfig";
import { customModal } from "../../modals/utils";
import { navigateTo } from "../../../navigation/navigate";
import { Texts } from "../../../config";
function CompleteRegister({ email, noCheck, profile }) {
  const texts = Texts["pt-BR"].notification;
  const [check, setCheck] = React.useState(false);
  const NotificationPopup = (data) => {
    window.localStorage.setItem(
      `${email}-notificationDisable`,
      JSON.stringify(data.disable)
    );
    profile ? navigateTo.Profile() : navigateTo.FinancialData();
    customModal.close();
  };
  const NotificationMenu = () => {
    profile ? navigateTo.Profile() : navigateTo.FinancialData();

    customModal.close();
  };
  React.useEffect(() => {
    const notification = window.localStorage.getItem(
      `${email}-notificationDisable`
    );
    if (notification !== `"true"`) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [email]);

  return (
    <Styles.ModalPadding>
      <FormHolder
        onSubmit={(data) => {
          noCheck ? NotificationMenu() : NotificationPopup(data);
        }}>
        <Grid
          style={{ marginBlock: "1em" }}
          container
          direction="row"
          justify="flex-end">
          {noCheck ? null : (
            <Grid xs={12} item style={{ width: "fit-content" }}>
              <Checkbox
                actualValue={check}
                name="disable"
                label={texts.checkbox}
                onChange={(data, value) => setCheck(value)}
              />
            </Grid>
          )}

          <Grid item style={{ width: "fit-content" }}>
            <ButtonText
              onClick={() => {
                customModal.close();
                window.localStorage.setItem(
                  `${email}-notificationDisable`,
                  JSON.stringify(check.toString())
                );
              }}
              fullWidth={false}
              style={{ textTransform: "uppercase", ...FontStyles.medium16 }}>
              {texts.back}
            </ButtonText>
          </Grid>

          <Grid item style={{ width: "fit-content" }}>
            <ButtonText
              $defaultColor
              type="submit"
              fullWidth={false}
              style={{ textTransform: "uppercase", ...FontStyles.semibold16 }}>
              {texts.complete}
            </ButtonText>
          </Grid>
        </Grid>
      </FormHolder>
    </Styles.ModalPadding>
  );
}

export default connect()(React.memo(CompleteRegister));
