import styled from "styled-components";
import { FontStyles, Text } from "../../../components";

import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core/";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    height: `calc(100vh - ${spacing(9)}px)`,
    width: "100%",
    paddingInline: spacing(3),
    paddingBlock: spacing(2.5),
    overflow: "auto",
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.carbon,
    width: "100%",
    height: 1,
    marginBottom: spacing(2),
  };
});

const CardContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",
    height: "100%",
    padding: spacing(2),
    borderRadius: spacing(2),
  };
});

const CardHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };
});

const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    color: colors.text.secondary,
    textTransform: "uppercase",
    margin: 0,
  };
});
const ButtonContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
    margin: 0,
    padding: 0,
  };
});

const Dots = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.carbon,
  };
});

const ButtonsNavigation = styled(Button)(({ theme, Second }) => {
  const { palette: colors } = theme;
  return {
    width: "100%",
    backgroundColor: Second
      ? colors.action.disabledBackground
      : colors.primary.main,
    borderRadius: 0,
    borderBottom: Second
      ? `4px solid ${colors.carbon}`
      : `4px solid ${colors.background.defaultHover}`,
    color: Second ? colors.carbon : colors.text.primary,
    "&:hover": {
      backgroundColor: colors.background.defaultHover,
    },
  };
});
const ActiveContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    padding: spacing(2),
    backgroundColor: colors.background.input,
    borderRadius: spacing(1),
    marginBottom: spacing(2),
  };
});

const ActivePlan = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.roman16,
    color: colors.text.secondary,
    opacity: 0.88,
  };
});
const ActivePlanDate = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold14,
    color: colors.text.secondary,
    opacity: 0.44,
  };
});
const Styles = {
  Container,
  Line,
  CardContainer,
  CardHeader,
  CardTitle,
  Dots,
  ButtonContainer,
  ButtonsNavigation,
  ActivePlan,
  ActiveContainer,
  ActivePlanDate,
};

export default Styles;
