import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* getApprovalList({ data }) {
  try {
    SessionStorage.setItem("plansApprovalFilter", data);
    const requestBody = {
      searchParameters: {
        requester: data.enterprise,
        planId: data.plan,
        requestDateBegin: data.startDate,
        requestDateEnd: data.endDate,
      },
      page: {
        number: data.page - 1,
        size: 6,
      },
    };
    const response = yield call(api.getApprovalList, requestBody);

    yield put(Creators.getApprovalListSuccess(response));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao realizar pesquisa de acessos. "
    );
    yield put(Creators.getApprovalListFailure());
  }
}
