import React from "react";
import { connect } from "react-redux";
import {
  Select,
  Input,
  ButtonOutlined,
  ButtonContained,
  InputEmail,
} from "../../../components";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import { Styles } from "../styles";
import AddIcon from "@material-ui/icons/Add";
function SearchUsers({ isFetching, modal }) {
  return (
    <>
      <Grid item xs={12}>
        <Styles.HeaderCard>
          <Styles.TitleCard>FILTRA USUÁRIOS ACATE</Styles.TitleCard>
          <Styles.NewUser
            onClick={modal}
            fullWidth={false}
            loading={isFetching}
            startIcon={<AddIcon style={{ margin: 0 }} />}>
            Cadastrar Novo Usuário
          </Styles.NewUser>
        </Styles.HeaderCard>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Input
            inputLabel="NOME DO USUÁRIO"
            name="name"
            placeholder="Usuário Exemplo"
          />
        </Grid>

        <Grid item xs={4}>
          <InputEmail
            inputLabel="E-MAIL"
            name="email"
            placeholder="user@acate.com.br"
          />
        </Grid>

        <Grid item xs={4}>
          <Select
            alternativeColors
            $maxHeight
            inputLabel="Status"
            defaultValue={-1}
            name="status"
            options={[
              { value: -1, label: "Todos" },
              { value: 0, label: "Acesso Ativo" },
              { value: 1, label: "Acesso Inativo" },
            ]}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        style={{ justifyContent: "flex-end" }}>
        <Grid item>
          <ButtonOutlined
            startIcon={<DeleteIcon />}
            type="clearDefault"
            fullWidth={false}
            loading={isFetching}>
            Limpar
          </ButtonOutlined>
        </Grid>
        <Grid item>
          <ButtonContained
            startIcon={<SearchIcon />}
            type="submit"
            fullWidth={false}
            loading={isFetching}>
            Pesquisar
          </ButtonContained>
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.users;
  return {
    isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(SearchUsers));
