import React from "react";
import { Texts } from "../../../config";
import { Input, InputAutoComplete } from "../../../components";
import { Filters, Validations } from "../../../lib";
import { Grid } from "@material-ui/core";
function RegistryUser({ cities, UFList, cityListRequest }) {
  const texts = Texts["pt-BR"];
  return (
    <>
      <Input
        variant="standard"
        name="username"
        nextInput="cpf"
        required={texts.login.userMessage}
        inputLabel={texts.login.stepTwo.name}
        mask={(data) => Filters.maskName(data)}
        validation={(data) => Validations.isFullName(data)}
        maskToSubmit={Filters.maskName}
      />

      <Input
        variant="standard"
        name="cpf"
        nextInput="cityId"
        required={texts.login.userMessage}
        inputLabel={texts.login.stepTwo.cpf}
        mask={Filters.cpfMask}
        validation={Validations.isCPF}
        maskToSubmit={Filters.cpfToRequest}
      />

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <InputAutoComplete
            defaultValue={null}
            inputLabel={texts.login.stepTwo.uf}
            name="uf"
            nextInput="cityId"
            autoComplete="off"
            options={UFList ? UFList : []}
            required={texts.login.userMessage}
            onChange={(value, index) => {
              UFList?.find((element) => {
                if (element.value === index) {
                  return cityListRequest(element.ufName);
                }
                return null;
              });
            }}
            validation={(value) => {
              if (value !== -1) {
                return { isValid: true, errorMessage: "" };
              } else {
                return { isValid: false, errorMessage: "*Campo obrigatório." };
              }
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <InputAutoComplete
            defaultValue={null}
            inputLabel={texts.login.stepTwo.city}
            name="cityId"
            nextInput="telephone"
            required={texts.login.userMessage}
            options={cities ? cities : []}
            validation={(value) => {
              if (value !== -1) {
                return { isValid: true, errorMessage: "" };
              } else {
                return { isValid: false, errorMessage: "*Campo obrigatório." };
              }
            }}
          />
        </Grid>
      </Grid>

      <Input
        variant="standard"
        name="telephone"
        nextInput="username"
        inputLabel={texts.login.stepTwo.phone}
        mask={Filters.phoneMask}
        maskToSubmit={Filters.phoneToRequest}
        validation={(data) => Validations.isPhone(data)}
      />
    </>
  );
}

export default React.memo(RegistryUser);
