import createReducers from "../../../store/helpers/createPageReducer";
import accessHistoryRequest from "./accessHistoryRequest";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getAccessHistory",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: accessHistoryRequest,
    },
    {
      name: "accessHistorySuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        accessHistory: data,
      }),
    },
    {
      name: "accessHistoryFailure",
      function: (state) => ({ ...state, isFetching: false, usersList: [] }),
    },
  ],
  {
    isFetching: false,
    accessHistory: [],
  }
);

export { Creators, reducers, sagas };
