export default function getIndicatorsYear(response) {
  const data = response.data.response.map((item) => {
    return {
      value: item.year,
      label: item.year,
      filled: item.filled,
    };
  });
  return data;
}
