import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* putApprovalPlan({ data, callback }) {
  try {
    if (data?.approve === true) {
      yield call(api.putApprovePlan, data.id);
    } else {
      yield call(api.putDisapprovePlan, data.id);
    }
    yield put(Creators.putApprovalPlanSuccess());
    Alerts.alertSuccess(
      "Plano foi " +
        (data?.approve === true ? "aprovado" : "desaprovado") +
        " com sucesso!"
    );
    const filter = SessionStorage.getItem("plansApprovalFilter");

    callback(filter);
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao " +
            (data?.approve === true ? "aprovar" : "desaprovar") +
            " plano."
    );
    yield put(Creators.putApprovalPlanFailure());
  }
}
