import createReducers from "../../../store/helpers/createPageReducer";
import userListRequest from "./userListRequest";
import newUserRequest from "./newUserRequest";
import updateUserRequest from "./updateUserRequest";
import patchStatusUser from "./patchStatusUser";
import newManagerRequest from "./newManagerRequest";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getUserList",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: userListRequest,
    },
    {
      name: "userListSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        usersList: data,
      }),
    },
    {
      name: "userListFailure",
      function: (state) => ({ ...state, isFetching: false, usersList: [] }),
    },

    {
      name: "newUserRequest",
      params: ["data", "update"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: newUserRequest,
    },
    {
      name: "newUserSuccess",
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "newUserFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "newManagerRequest",
      params: ["data", "update"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: newManagerRequest,
    },
    {
      name: "newManagerSuccess",
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "newManagerFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "updateUserRequest",
      params: ["data", "update"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: updateUserRequest,
    },
    {
      name: "updateUserSuccess",
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "updateUserFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "patchStatusUser",
      params: ["data", "update"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: patchStatusUser,
    },
    {
      name: "patchStatusUserSuccess",
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "patchStatusUserFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    usersList: [],
  }
);

export { Creators, reducers, sagas };
