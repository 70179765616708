import { Backdrop, Paper, Modal, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { MediaQueries } from "../../../config";
import Text from "../../../components/strings/Text";
import { FontStyles } from "../../../components";

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    input: {
      marginTop: 0,
      minHeight: spacing(10),
      maxHeight: "10vh",
      overflow: "overlay",
      scrollbarColor: "white",
      alignItems: "flex-start",
    },
  };
});

const styledBackDrop = styled(Backdrop)({
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  backgroundColor: "#00000020",
});

const StyledRegisterModal = styled(Modal)({
  outlineWidth: 0,
  borderWidth: 0,
  ":focus": {},
});

const Container = styled(Paper)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    position: "absolute",
    width: "100%",
    maxWidth: "60vw",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    outline: "none",
    backgroundColor: colors.whiteBackground,

    [MediaQueries.smUp]: {
      width: `calc(100% - ${spacing(2)}px)`,
    },
  };
});

const HeaderContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: spacing(2),
  };
});
const Close = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.white,
  };
});

const Title = styled(Text)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginBottom: spacing(2),
    ...FontStyles.bold18,
  };
});
const SubTitle = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.medium16,
  };
});
const TermsTitle = styled(Text)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginBottom: spacing(2),
    ...FontStyles.bold14,
  };
});

const TermsText = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    marginBottom: spacing(2),
    ...FontStyles.medium14,
    textIndent: spacing(4),
    color: colors.text.secondary,
  };
});

const TermsContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  const { palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    maxHeight: "50vh",
    overflow: "auto",
    backgroundColor: colors.background.default,
    width: "100%",
    padding: spacing(2),
    marginBottom: spacing(2),
    "::-webkit-scrollbar-thumb": {
      background: colors.background.secondary,
      borderRadius: "15px",
      height: "2px",
    },
  };
});

const Label = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    marginBlock: spacing(1),
    flexDirection: "row",
    ...FontStyles.roman14,
  };
});
const LabelWarning = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: 0,
    marginInline: spacing(2),
    flexDirection: "row",
    ...FontStyles.roman12,
  };
});
const RowContainer = styled.div(({ theme, $padding }) => {
  const { spacing } = theme;
  return {
    padding: $padding ? spacing(2) : null,
    paddingInline: spacing(2),

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  };
});

const Content = styled.div((props) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "90%",
  };
});

const Styles = {
  styledBackDrop,
  StyledRegisterModal,
  HeaderContainer,
  Container,
  Title,
  Label,
  SubTitle,
  Content,
  useStyles,
  LabelWarning,
  Close,
  TermsTitle,
  TermsText,
  TermsContainer,
  RowContainer,
};
export default Styles;
