export default function getEvaluatedReleases(response) {
  const data = response.data.response.elements.map((item) => {
    return {
      companyName: item.companyName,
      username: item.username,
      email: item.email,
      office: item.office,
      beginRequestDate: item.beginRequestDate,
      endRequestDate: item.endRequestDate,
      accessTypeRequested: item.accessTypeRequested,
      active: item.active,
      releasedDate: item.releasedDate,
      id: item.id,
      status: item.status,
      plan: item.plan,
      employeeNumber: item.employeeNumber,
      isTech: item.isTech,
      incubatorId: item.incubator?.name ? item.incubator?.name : "N/A",
      cnpj: item.cnpj,
      isActive: item.isActive,
    };
  });
  return { ...response, evaluatedReleases: data };
}
