import { createReducers } from "../../store/helpers";
import logoutFunction from "./logoutFunction";
import getGlobalStatus from "./getGlobalStatus";
import getUserInfos from "./getUserInfos";
import getCountries from "./getCountries";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "globalLogout",
      function: (state) => ({
        ...state,
        userInfos: {},
      }),
      sagaFunction: logoutFunction,
    },

    {
      name: "getGlobalStatus",
      function: (state) => ({
        ...state,
      }),
      sagaFunction: getGlobalStatus,
    },

    {
      name: "setIsLoggedIn",
      params: ["isLoggedIn"],
      function: (state, { isLoggedIn }) => ({
        ...state,
        isLoggedIn: isLoggedIn,
      }),
    },
    {
      name: "setAcateMode",
      params: ["isAcateMode"],
      function: (state, { isAcateMode }) => ({
        ...state,
        isAcateMode: isAcateMode,
      }),
    },
    {
      name: "getUserInfosRequest",
      function: (state) => ({
        ...state,
        isFetchingUserInfos: true,
      }),
      sagaFunction: getUserInfos,
    },
    {
      name: "getUserInfosSuccess",
      params: ["userInfos"],
      function: (state, { userInfos }) => ({
        ...state,
        userInfos,
        isFetchingUserInfos: false,
      }),
    },
    {
      name: "getUserInfosFailure",
      function: (state) => ({
        ...state,
      }),
    },
    {
      name: "getUserDataSuccess",
      params: ["userData"],
      function: (state, { userData }) => ({
        ...state,
        userData,
        isFetchingUserInfos: false,
      }),
    },
    {
      name: "clearUserData",
      function: (state) => ({
        ...state,
        userInfos: {},
        userData: null,
      }),
    },

    {
      name: "getCountries",
      function: (state) => ({
        ...state,
        isFetchingLists: true,
      }),
      sagaFunction: getCountries,
    },
    {
      name: "getCountriesSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        countriesList: data,
        isFetchingLists: false,
      }),
    },
    {
      name: "getCountriesFailure",
      function: (state) => ({
        ...state,
        isFetchingLists: false,
      }),
    },
  ],
  {
    isAcateMode: false,
    isLoggedIn: false,
    isFetchingUserInfos: true,
    userInfos: {},
    userData: null,
    countriesList: null,
    isFetchingLists: false,
  }
);

export { Creators, reducers, sagas };
