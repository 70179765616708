import {
  createBarScalesX,
  createFixedDatalabels,
  createLayoutWithDatalabels,
} from "../helpers/CommonChartConfigs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";

function getData(data, labels) {
  if (data === (null || undefined)) {
    return [];
  } else {
    const newData = [null];
    for (let index = 0; index < labels.length; index++) {
      if (data[labels[index]]) {
        newData.push(data[labels[index]]);
      } else {
        newData.push(null);
      }
    }
    newData.push(null);

    return newData;
  }
}
function getLabels(data) {
  if (data === (null || undefined)) {
    return [];
  } else {
    const newData = [""];
    const array = Object.keys(data);
    newData.push(...array);
    newData.push("");
    return newData;
  }
}
export default function getCapacityHistoricLine(response, noScales) {
  const iMax = 4;
  const labels = getLabels(
    Object.keys(response?.otherCompanies).length > 0
      ? response?.otherCompanies
      : response?.myCompany
  );

  const otherCompanies = getData(
    response?.otherCompanies,
    Object.keys(response?.otherCompanies).length > 0
      ? Object.keys(response?.otherCompanies)
      : Object.keys(response?.myCompany)
  );
  const myCompany = getData(
    response?.myCompany,
    Object.keys(response?.otherCompanies).length > 0
      ? Object.keys(response?.otherCompanies)
      : Object.keys(response?.myCompany)
  );

  const chartData = {
    type: "line",
    data: {
      labels,
      datasets: [
        {
          borderColor: "#CBE255",
          backgroundColor: "#E9F1C473",
          lineTension: 0,
          borderWidth: 1,
          pointRadius: 6,
          pointBorderWidth: 0.5,
          pointBackgroundColor: "#ACCA13",
          pointBorderColor: "#ACCA13",
          fill: true,
          data: myCompany,
          spanGaps: true,
        },
        {
          borderColor: "#64DAE8",
          backgroundColor: "#C8F9FF70",
          lineTension: 0,
          borderWidth: 1,
          pointRadius: 6,
          pointBorderWidth: 0.5,
          pointStyle: "rect",
          pointBackgroundColor: "#21B3C4",
          pointBorderColor: "#21B3C4",
          fill: true,
          data: otherCompanies,
        },
      ],
    },
    plugins: [ChartDataLabels],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      labels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        datalabels: createFixedDatalabels(
          "white",
          "#00000012",
          "#464646",
          (value, context) => {
            if (value === null) {
              return;
            }
            const valueData = Number(value).toFixed(1);
            return valueData.replace(".", ",") + "%";
          },
          iMax > 32
        ),
      },
      onHover: function (event, elements) {
        var chart = this;

        // Store hovered elements under a private property
        // named $_user_ to make sure it doesn't conflict.
        var expando = chart.$_user_ || (chart.$_user_ = {});

        // To prevent updating the chart for every mouse move,
        // let's first check that the hovered items have changed.
        if (!Chart.helpers.arrayEquals(expando.hovered, elements)) {
          expando.hovered = elements;
          chart.update();
        }
      },
      layout: createLayoutWithDatalabels(),

      scales: noScales
        ? {
            yAxes: [{ ticks: { display: false } }],
            xAxes: [
              { ticks: { display: false }, gridLines: { display: false } },
            ],
          }
        : createBarScalesX(
            "#6A6A6A40",
            "#6A6A6A",
            false,
            undefined,
            10,
            "percent"
          ),
    },
  };

  return {
    data: {
      chartData,
      type: "line",
    },
  };
}
