import styled from "styled-components";
import { FontStyles, Text } from "../../../../components";
const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    flexDirection: "column",

    display: "flex",
    height: `calc(100vh - ${spacing(9)}px)`,
    width: "100%",
    paddingInline: spacing(1),
    paddingBlock: spacing(3),
    overflow: "auto",
  };
});

const Background = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: `calc(100% - ${spacing(4)}px)`,
    margin: spacing(2),
    height: "fit-content",
    position: "relative",
    overflow: "scroll",
  };
});

const FilterBackground = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: `calc(100% - ${spacing(4)}px)`,
    margin: spacing(2),
    marginTop: 0,
    marginBottom: spacing(1),
    height: "fit-content",
    position: "relative",
  };
});

const Card = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    position: "relative",
    padding: spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: " column",
    justifyContent: "space-between",
  };
});

const ContainerCards = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    marginInline: spacing(2),
    overflowY: "scroll",
    overflowX: "hidden",
  };
});

const HeaderCard = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: spacing(0.5),
  };
});

const Line = styled.div(({ theme, $margin }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    height: spacing(0.1),
    marginBottom: spacing(1),
    marginTop: $margin && spacing(1),
    backgroundColor: colors.carbon,
    opacity: 0.5,
  };
});

const TitleCard = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    letterSpacing: 0,
  };
});

const NumberCard = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: spacing(2.5),
    borderLeft: `${spacing(1.5)}px solid ${colors.text.tertiary}`,
    backgroundColor: colors.background.input,

    minHeight: "7.25rem",
    height: "80%",
    maxHeight: "11.25rem",
    paddingBlock: spacing(1.5),
  };
});

const SmallCard = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: spacing(0.5),
    paddingInline: spacing(1.25),
    marginRight: spacing(1),
    borderLeft: `${spacing(0.625)}px solid ${colors.text.tertiary}`,
    backgroundColor: "#F1F6D8",
  };
});

const SmallIndicator = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold16,
    color: colors.text.secondary,
  };
});

const SmallTitle = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    color: colors.text.secondary,
    fontSize: spacing(1.25),
  };
});
const BigIndicator = styled(Text)(({ theme, $margin }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold22,
    color: colors.text.secondary,
    fontSize: spacing(3),
    marginRight: $margin && spacing(2),
  };
});
const SubIndicator = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold16,
    color: colors.text.quaternary,
    fontWeight: "800",
  };
});
const Diference = styled(Text)(({ theme, negative }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.bold16,
    color: negative ? "#E95F69" : colors.text.tertiary,
    fontWeight: "800",
    display: "flex",
    marginLeft: spacing(1),
  };
});
const IndicadorContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    flexDirection: "row",
    display: "flex",
    marginBottom: spacing(0.725),
    alignItems: "center",
  };
});
const LoadingContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    flexDirection: "row",
    display: "flex",
    width: "100%",

    padding: spacing(3),

    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.background.input,
    borderRadius: spacing(0.675),
  };
});

const GaugesCard = styled.div(({ theme, noBG }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: spacing(0.5),
    paddingInline: spacing(1.25),
    marginRight: spacing(1),
    borderLeft: `${spacing(0.625)}px solid ${colors.text.tertiary}`,
    backgroundColor: !noBG && "#F1F6D8",
  };
});
const GaugesSubValue = styled(Text)(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.semibold12,
    color: "#A1A1A1",
    fontSize: spacing(1.25),
    marginInline: spacing(1),
  };
});

const Styles = {
  Container,
  Background,
  Card,
  ContainerCards,
  HeaderCard,
  Line,
  TitleCard,
  NumberCard,
  BigIndicator,
  SubIndicator,
  SmallCard,
  SmallIndicator,
  SmallTitle,
  IndicadorContainer,
  Diference,
  LoadingContainer,
  FilterBackground,
  GaugesCard,
  GaugesSubValue,
};

export default Styles;
