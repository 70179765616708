import styled from "styled-components";
import { FontStyles, ButtonContained } from "../../../../components";
import { Chip } from "@material-ui/core";
const Container = styled.div(({ theme, widthMax, open }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    maxWidth: `${widthMax - 40}px`,
    height: open ? `calc(100vh - ${spacing(16)}px)` : "fit-content",
    backgroundColor: "white",
    borderRadius: 5,
    position: open ? "absolute" : "relative",
    padding: "1rem",
    zIndex: 3,
  };
});
const BlurContainer = styled.div(({ theme, widthMax, open }) => {
  return {
    width: "110vw",
    height: "100vh",
    top: 0,
    left: 0,
    backgroundColor: "#00000026",
    position: "fixed",
    display: open ? "flex" : "none",
    zIndex: 2,
  };
});

const FilterSelected = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    minHeight: spacing(6.75),
    backgroundColor: colors.background.input,
    padding: spacing(1.6),
    borderRadius: 5,
    display: "flex",
    flexWrap: "wrap",
    marginBottom: spacing(1.725),
    marginTop: spacing(1),
  };
});

const Chips = styled(Chip)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.medium12,
    backgroundColor: colors.carbon,
    color: colors.text.primary,
    marginRight: spacing(0.625),
    marginBottom: spacing(0.625),
  };
});
const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    height: spacing(0.1),

    marginBottom: spacing(1.9),
    backgroundColor: colors.carbon,
    opacity: 0.7,
  };
});

const FilterTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    color: colors.text.secondary,
    textTransform: "uppercase",
    letterSpacing: 0,
    margin: 0,
    padding: 0,
    display: "flex",
  };
});

const CompactButton = styled(ButtonContained)(
  ({ theme, $export, bottomPadding }) => {
    const { spacing, palette: colors } = theme;
    return {
      ...FontStyles.semibold12,
      fontSize: $export
        ? FontStyles.semibold12.size
        : FontStyles.semibold14.size,
      padding: spacing(1.1),
      paddingInline: spacing(1.725),
      marginBottom: bottomPadding && spacing(1),
      color: colors.text.primary,
      textTransform: "capitalize",
      letterSpacing: 0,
      borderColor: colors.text.tertiary,
      borderWidth: spacing(0.15),
      borderStyle: !$export && "solid",
      backgroundColor: $export ? colors.text.secondary : colors.text.tertiary,
    };
  }
);
const FilterContainer = styled.div(({ theme, $open, removeHeight }) => {
  const { spacing } = theme;
  return {
    marginTop: $open && spacing(2.5),
    overflowY: "auto",
    overflowX: "hidden",
    height: $open ? `calc(100vh - ${spacing(28.75) + removeHeight}px)` : "0px",
  };
});
const Styles = {
  Container,
  Line,
  FilterSelected,
  Chips,
  FilterTitle,
  CompactButton,
  BlurContainer,
  FilterContainer,
};

export default Styles;
