import React from "react";
import { connect } from "react-redux";
import { ButtonText } from "../../../components";
import { Grid } from "@material-ui/core";
import { Styles } from "../styles";
import { FormHolder } from "../../../FormConfig";
import { customModal } from "../../modals/utils";
import { Texts } from "../../../config";
function ModalNewManager({ isFetching, updateManager, userData }) {
  const texts = Texts["pt-BR"].Users.modalManager;
  return (
    <>
      <FormHolder onSubmit={updateManager}>
        <Styles.ModalInfo>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Styles.ModalUserTitle>{texts.name}</Styles.ModalUserTitle>
              <Styles.ModalUserInfo>{userData?.name}</Styles.ModalUserInfo>
            </Grid>
            <Grid item xs={6}>
              <Styles.ModalUserTitle>{texts.email}</Styles.ModalUserTitle>
              <Styles.ModalUserInfo>{userData?.email}</Styles.ModalUserInfo>
            </Grid>
            <Grid item xs={6}>
              <Styles.ModalUserTitle>{texts.role}</Styles.ModalUserTitle>
              <Styles.ModalUserInfo>
                {userData.role.includes("COLLABORATOR")
                  ? "Colaborador"
                  : userData.role.includes("MANAGER")
                  ? "Gestor"
                  : userData.role}
              </Styles.ModalUserInfo>
            </Grid>
          </Grid>
        </Styles.ModalInfo>{" "}
        <Styles.ModalPadding>
          <Grid
            style={{ marginBlock: "1em" }}
            container
            direction="row"
            justify="flex-end">
            <Grid item style={{ width: "fit-content" }}>
              <ButtonText
                onClick={() => customModal.close()}
                fullWidth={false}
                style={{ textTransform: "uppercase" }}>
                {texts.exit}
              </ButtonText>
            </Grid>

            <Grid item style={{ width: "fit-content" }}>
              <ButtonText
                $defaultColor
                type="submit"
                fullWidth={false}
                style={{ textTransform: "uppercase" }}>
                {texts.confirm}
              </ButtonText>
            </Grid>
          </Grid>
        </Styles.ModalPadding>
      </FormHolder>
    </>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.users;
  return {
    isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(ModalNewManager));
