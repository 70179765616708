import React from "react";
import { connect } from "react-redux";
import Styles from "../styles/ContactUsStyles";
import { Grid } from "@material-ui/core";
import SendMessages from "../components/SendMessages";

function ContactUs() {
  return (
    <Styles.Container>
      <Grid container direction="row" alignContent="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Styles.Content>
            <Grid container spacing={1}>
              <SendMessages />
            </Grid>
          </Styles.Content>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

export default connect()(React.memo(ContactUs));
