import React, { useState } from "react";
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
  Chip,
} from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { formConnector } from "../../FormConfig";
import { Autocomplete } from "@material-ui/lab";
import { FontStyles, Tolltip } from "../index";
import Text from "../strings/Text";
import FontStyle from "../styles/fontsStyles";
import { makeStyles } from "@material-ui/core";
const StyledInput = styled(TextField)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $smallBottom,
    $alternativeColors,
    $margin,
    $height,
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      padding: $small ? spacing(0, 3) : spacing(0.625),
      borderRadius: theme.spacing(0.625),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor: $alternativeColors
        ? colors.text.secondary
        : colors.background.input,
      height: $height === 2 ? spacing(6) : null,
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.text.tertiary}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.3 : 1,
      "& .MuiInput-input": {
        color: $alternativeColors ? colors.text.primary : colors.text.secondary,
        "&::before": { display: "none" },
        "&::after": { display: "none" },
        "&::placeholder": {
          opacity: 0.8,
        },
      },
      "&::before": { display: "none" },
      "&::after": { display: "none" },
      ...$inputStyle,
    };
  }
);
const Label = styled(Text)(({ theme, smallLabel, $withError }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    fontFamily: smallLabel
      ? FontStyles.semibold14.fontFamily
      : FontStyles.bold14.fontFamily,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withError ? colors.text.tertiary : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});
const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.tertiary,
  };
});

const PopperSelect = styled(Popper)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    "& .MuiAutocomplete-listbox": {
      "& li:hover": { backgroundColor: colors.text.tertiary + 20 },
      '& [aria-selected="true"]': {
        backgroundColor: colors.text.tertiary + 40,
        borderColor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background: colors.background.secondary,
        borderRadius: "15px",
        height: "2px",
      },
    },
  };
});
const ChipSelect = styled(Chip)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    backgroundColor: colors.background.secondary,
    color: colors.text.primary,
    ...FontStyle.medium12,
  };
});
const LabelView = styled.div(() => {
  return {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
  };
});
const useStyles = makeStyles((theme) => ({
  root: {},
  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      "&::before": { display: "none" },
      "&::after": { display: "none" },
    },
  },
}));

function InputTags(props) {
  const { value, error, onBlur, setConfigs, formHandler, usedProps } =
    formConnector.useStringValue(props);
  const {
    inputLabel,
    hideVisualError,
    small,
    smallBottom,
    alternativeColors,
    marginInput,
    heightInput,
    options,
    inputStyle,
    disabled,
    required,
    noOptionsText,
    smallLabel,
    tooltip,
    readOnly,
  } = usedProps;
  let focus = false;
  const classes = useStyles();
  /* eslint-disable */
  const [r, setR] = useState(false);
  const [open, setOpen] = useState(false);
  /* eslint-enable */
  const RemoveDuplicates = (list) => {
    var valueArr = list.map(function (item) {
      return item.value;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });

    if (isDuplicate) {
      var toRemove = list
        .map((e) => e["value"])
        .map((e, i, final) => final.indexOf(e) !== i && i)
        .filter((obj) => list[obj])
        .map((e) => list[e]["value"]);

      const newList = list.filter(function (el) {
        return el.value !== toRemove[0];
      });
      return newList;
    } else {
      return list;
    }
  };

  React.useEffect(() => {
    if (value?.length) {
      setR(true);
    }
  }, [value]);

  return (
    <FormControl fullWidth error={hideVisualError ? null : Boolean(error)}>
      <Autocomplete
        readOnly={readOnly}
        open={open}
        onOpen={() => !readOnly && setOpen(true)}
        onClose={() => !readOnly && setOpen(false)}
        noOptionsText={noOptionsText}
        classes={classes}
        defaultValue={value?.length > 0 ? value : []}
        actualValue={value?.length > 0 ? value : []}
        value={value?.length > 0 ? value : []}
        required={required}
        multiple
        forcePopupIcon={true}
        id="tags-filled"
        options={options}
        disabled={disabled}
        disableClearable
        PopperComponent={(props) => {
          return <PopperSelect {...props} />;
        }}
        getOptionLabel={(option) => option.label || ""}
        onChange={(event, value) => {
          if (value === null) {
            setConfigs(-1, event);
          } else {
            setConfigs(RemoveDuplicates(value), event);
          }
          setTimeout(() => {
            onBlur();
            formHandler.testInputError(props.name);
          }, 10);
        }}
        renderInput={(params) => (
          <>
            <LabelView>
              <Label
                smallLabel={smallLabel}
                $withValue={Boolean(value) || value === 0 || focus}
                $withError={Boolean(error)}
              >
                {inputLabel}
              </Label>
              {tooltip && (
                <Tolltip
                  title={tooltip?.title}
                  tip={tooltip?.content}
                  disableFocusListener
                />
              )}
            </LabelView>

            <StyledInput
              readOnly={readOnly}
              InputProps={{
                disableUnderline: true,
                underline: "none",
              }}
              onFocus={() => (focus = true)}
              required={required}
              $inputStyle={inputStyle}
              $alternativeColors={alternativeColors}
              $margin={marginInput}
              $height={heightInput}
              $small={small}
              $smallBottom={smallBottom}
              $error={Boolean(error)}
              error={Boolean(error)}
              {...params}
            />
          </>
        )}
        renderTags={(value, getTagProps) =>
          RemoveDuplicates(value).map((option, index) => (
            <ChipSelect
              clickable={!readOnly}
              deleteIcon={readOnly && <></>}
              tabindex={option.value}
              label={option.label}
              {...getTagProps({ index })}
            />
          ))
        }
      />

      <ErrorMessage error={Boolean(error)}>
        {Boolean(error) ? error : " "}
      </ErrorMessage>
    </FormControl>
  );
}

export default withTheme(InputTags);
