import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

export default function* getPlanTec() {
  try {
    const response = yield call(api.getPlanTec, null);

    yield put(Creators.getPlanSuccess(response.data));
  } catch (response) {
    yield put(Creators.getPlanFailure());
  }
}
