import React from "react";
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
} from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { FontStyles } from "../index";
import Text from "../strings/Text";
const StyledInput = styled(TextField)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $smallBottom,
    $alternativeColors,
    $margin,
    $height,
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      paddingBlock: spacing(1),
      paddingTop: $smallBottom === 2 ? spacing(1) : null,
      paddingInline: spacing(1),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor: $alternativeColors
        ? colors.text.secondary
        : colors.background.input,
      borderRadius: spacing(0.625),
      height: $height === 2 ? spacing(6) : null,
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.text.tertiary}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.6 : 1,
      "& .MuiInput-input": {
        color: $alternativeColors ? colors.text.primary : colors.text.secondary,
        marginLeft: spacing(1),
        "&::placeholder": {
          opacity: 0.8,
        },
      },

      ...$inputStyle,
    };
  }
);
const Label = styled(Text)(({ theme, smallLabel, $withError, disabled }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    fontFamily: smallLabel
      ? FontStyles.semibold14.fontFamily
      : FontStyles.bold14.fontFamily,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withError ? colors.text.tertiary : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
    opacity: disabled && 1,
  };
});
const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.tertiary,
  };
});

const PopperSelect = styled(Popper)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    "& .MuiAutocomplete-listbox": {
      "& li:hover": { backgroundColor: colors.text.tertiary + 20 },
      '& [aria-selected="true"]': {
        backgroundColor: colors.text.tertiary + 40,
        borderColor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background: colors.background.secondary,
        borderRadius: "15px",
        height: "2px",
      },
    },
  };
});
const useStyles = makeStyles((theme) => ({
  root: {},
  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      "&::before": { display: "none" },
      "&::after": { display: "none" },
    },
  },
}));
function InputComponent(props) {
  const { value, error, onBlur, setConfigs, formHandler, usedProps, ref } =
    formConnector.useStringValue(props);
  const {
    inputLabel,
    hideVisualError,
    small,
    smallBottom,
    alternativeColors,
    marginInput,
    heightInput,
    options,
    inputStyle,
    disabled,
    required,
    smallLabel,
    noOptionsText,
    disableClearable,
    disableError,
    placeholder,
    justNumbers,
  } = usedProps;
  const classes = useStyles();
  let focus = false;
  React.useEffect(() => {
    if (ref && value === "") {
      const ele = ref.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) {
        ele.click();
      }
    }
  }, [value, ref]);

  const filterOptions = (options, state) =>
    options.filter((item) =>
      item.label
        .replace(/[.,/\\-]/g, "")
        .includes(state?.inputValue?.replace(/[.,/\\-]/g, ""))
    );

  const filterOptionsDefault = createFilterOptions({
    stringify: (option) => option.label,
  });

  return (
    <FormControl fullWidth error={hideVisualError ? null : Boolean(error)}>
      <Autocomplete
        filterOptions={justNumbers ? filterOptions : filterOptionsDefault}
        disableClearable={disableClearable}
        ref={ref}
        key={inputLabel}
        noOptionsText={noOptionsText}
        classes={classes}
        defaultValue={value}
        componentsProps={{ disableUnderline: true }}
        options={options}
        PopperComponent={(props) => {
          return <PopperSelect {...props} />;
        }}
        placeholder={placeholder}
        clearOnBlur={false}
        clearOnEscape={false}
        disabled={disabled}
        disableUnderline={true}
        getOptionLabel={(option) => option.label || ""}
        onChange={(event, value) => {
          if (value === null) {
            setConfigs(-1, event);
          } else {
            setConfigs(value.value, event);
          }
          setTimeout(() => {
            onBlur();
            formHandler.testInputError(props.name);
          }, 10);
        }}
        renderInput={(params) => (
          <>
            <Label
              smallLabel={smallLabel}
              disabled={disabled}
              $withValue={Boolean(value) || value === 0 || focus}
              $withError={Boolean(error)}
            >
              {inputLabel}
            </Label>
            <StyledInput
              InputProps={{
                disableUnderline: true,
                underline: "none",
              }}
              placeholder={placeholder}
              autoFocus={false}
              value={value}
              onFocus={() => (focus = true)}
              disableUnderline={true}
              required={required}
              $inputStyle={inputStyle}
              $alternativeColors={alternativeColors}
              $margin={marginInput}
              $height={heightInput}
              $small={small}
              $smallBottom={smallBottom}
              $error={Boolean(error)}
              error={Boolean(error)}
              {...params}
            />
          </>
        )}
      />

      {!disableError && (
        <ErrorMessage error={Boolean(error)}>
          {Boolean(error) ? error : " "}
        </ErrorMessage>
      )}
    </FormControl>
  );
}

export default withTheme(InputComponent);
