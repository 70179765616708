import styled from "styled-components";
import { FontStyles, Text } from "../../../components";
import ErrorIcon from "@material-ui/icons/Error";
import { IconButton } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    height: `calc(100vh - ${spacing(9)}px)`,
    width: "100%",
    paddingInline: spacing(3),
    paddingBlock: spacing(2.5),

    alignItems: "flex-start",
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.carbon,
    width: "100%",
    height: 1,
    marginBottom: spacing(2),
    opacity: 0.2,
  };
});

const CardContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",
    height: "100%",
    padding: spacing(2),
    borderRadius: spacing(0.625),
  };
});
const HeaderContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",
    padding: spacing(2),
    borderRadius: spacing(2),
  };
});

const CardHeader = styled.div(({ theme, $info }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: $info ? "flex-start" : "space-between",
    alignItems: "center",
  };
});

const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    color: colors.text.secondary,
    textTransform: "uppercase",
  };
});
const ButtonContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
  };
});

const Dots = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.carbon,
  };
});

const Description = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.medium12,
    color: colors.carbon,
    opacity: 0.6,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: spacing(1),
  };
});
const WarningDescription = styled(ErrorIcon)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(1.5),
    height: "auto",
    color: colors.carbon,
    opacity: 0.6,
    marginRight: spacing(0.5),
  };
});

const InfosButton = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(2),
    height: "auto",
  };
});

const InfoIcon = styled(HelpIcon)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.text.tertiary,
  };
});
const Scroll = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: `calc(100vh - ${spacing(45.25)}px)`,
    paddingBlock: spacing(1),
    paddingInline: spacing(0.725),
    overflowY: "auto",
    overflowX: "hidden",
  };
});
const Styles = {
  Scroll,
  Container,
  Line,
  CardContainer,
  CardHeader,
  CardTitle,
  Dots,
  ButtonContainer,
  Description,
  WarningDescription,
  InfosButton,
  InfoIcon,
  HeaderContainer,
};

export default Styles;
