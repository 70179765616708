import { call, put } from "redux-saga/effects";
import { Creators } from ".";

import { api } from "../../../services";

export default function* resetNewPasswordRequest({ data, callback }) {
  try {
    const dataRequest = {
      email: data.email,
      password: data.password,
    };

    yield call(api.sendRecoveryPasswordNewPassword, dataRequest);
    if (callback !== undefined) {
      callback();
    }
    yield put(Creators.resetNewPasswordFinished());
    yield put(Creators.resetPasswordClearData());
  } catch (response) {
    yield put(Creators.resetNewPasswordFailure());
  }
}
