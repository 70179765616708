import styled from "styled-components";

const Container = styled.div(({ $Image }) => ({
  display: "flex",
  overflow: "hidden",
  height: "100vh",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: $Image,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

const Background = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    margin: spacing(2),
    padding: spacing(2),
  };
});
const BackgroundMark = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    maxWidth: spacing(117.476),
    marginInline: "25%",
  };
});
const Styles = {
  Container,
  BackgroundMark,
  Background,
};

export default Styles;
