import { customModal } from "../features/modals/utils";
import { Texts } from "../config";

function setCustomModal(title, texts, customAction = null) {
  customModal.setInfos(title, texts, {
    label: Texts["pt-BR"].modals.success.buttonLabel,
    onClick:
      customAction !== null
        ? () => {
            customAction();
            customModal.close();
          }
        : customModal.close,
  });
}

function alertNotImplemented() {
  setCustomModal("Aviso", ["Não implementado"]);
}

function alertWithoutServices() {
  setCustomModal("Aviso", [
    "Serviço não existente para a realização desta operação",
  ]);
}

function alertWithoutServicesContinue() {
  setCustomModal("Aviso", [
    "Serviço não existente para esta operação, a operação irá continuar para fins de demonstração",
  ]);
}

function alertError(msg) {
  const texts = typeof msg === "string" ? [msg] : msg;
  setCustomModal("Erro", texts);
}

function alertWarning(msg) {
  const texts = typeof msg === "string" ? [msg] : msg;
  setCustomModal("Alerta", texts);
}

function alertSuccess(msg) {
  const texts = typeof msg === "string" ? [msg] : msg;
  setCustomModal("Sucesso", texts);
}

const alerts = {
  alertWithoutServices,
  alertWithoutServicesContinue,
  alertNotImplemented,
  alertError,
  alertWarning,
  alertSuccess,
  setCustomModal,
};

export default alerts;
