import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
import GenericCard from "../components/GenericCard";
import { ButtonContained, SelectYears } from "../../../components";

import { Grid, CircularProgress } from "@material-ui/core";
import { FormHolder } from "../../../FormConfig";
import { Creators } from "../reduxSagas";
import { Texts } from "../../../config";
function ManagementData({
  isFetching,
  getManagementIndicatorsRequest,
  dataIndicators,
  getIndicatorsYear,
  years,
  sendManagementIndicatorsRequest,
  userInfos,
}) {
  const texts = Texts["pt-BR"].managementData;
  const isOdd = (num) => num % 2 === 1;
  const [r, setR] = React.useState(false);
  const [form, setForm] = React.useState(null);
  const [yearsList, setYearsList] = React.useState([]);
  const [editable, setEditable] = React.useState(true);
  const [selectedYear, setSelecterYear] = React.useState(
    new Date().getFullYear() - 1
  );

  const getIndicator = (year) => {
    //getManagementIndicatorsRequest(year);
    setR(true);
    years.map((item) => item.value === year && setEditable(item.filled));
    setSelecterYear(year);
  };

  React.useEffect(() => {
    getIndicatorsYear();
  }, [getIndicatorsYear]);

  React.useEffect(() => {
    const getIndicator = (year) => {
      getManagementIndicatorsRequest(year);
      setR(true);
      let currentYear = years.find((item) => item.value === year.toString());
      if (currentYear) {
        setEditable(currentYear?.filled);
      }
    };

    if (years?.length > 0) {
      getIndicator(selectedYear);

      setR(true);

      const newYears = years.map((item) => {
        if (
          Boolean(
            window.localStorage.getItem(
              "managementData" + userInfos?.email + item.value
            )
          )
        ) {
          return {
            value: item.value,
            label: item.label,
            filled: item.filled,
            cache: true,
          };
        } else {
          return item;
        }
      });
      setYearsList(newYears);
    }
  }, [years, userInfos, selectedYear, getManagementIndicatorsRequest]);

  React.useEffect(() => {
    if (form !== (null && undefined)) {
      const errors = form?.testErrorsAndReturnData(false);
      if (errors?.hasError) {
        form?.buttons?.button?.disableHandler(true);
      }
    }

    if (r === true && !isFetching) {
      if (form !== (null && undefined) && dataIndicators) {
        const a = window.localStorage.getItem(
          "managementData" + userInfos?.email + form.getFieldValue("year")
        );
        const inputsV = JSON.parse(a);
        for (var item in inputsV) {
          if (
            form.findInput(item) &&
            (form.getFieldValue(item) === null ||
              form.getFieldValue(item) === "") &&
            inputsV[item] !== null
          ) {
            form.setValue(
              item,
              typeof inputsV[item] === "string"
                ? inputsV[item]
                : parseFloat(inputsV[item]).toFixed(2).toString()
            );
          }
        }
        setR(false);
      }
    }
  }, [form, dataIndicators, r, isFetching, userInfos]);

  const saveAllInputs = () => {
    const values = form.getValues();
    if (Object.keys(values).length > 1) {
      window.localStorage.setItem(
        "managementData" + userInfos?.email + form.getFieldValue("year"),
        JSON.stringify(values)
      );
    }
  };

  const toSubmit = (data) => {
    let inputList = [];
    for (var property in data) {
      if (property !== "year") {
        inputList.push({
          indicatorId: parseInt(property),
          value: data[property]?.toString(),
        });
      }
    }

    sendManagementIndicatorsRequest(
      {
        year: data.year,
        indicatorTypeId: 2,
        values: inputList,
      },
      () => {
        getIndicatorsYear();
        getManagementIndicatorsRequest(data.year);
        setEditable(false);
      }
    );
  };

  return (
    <Styles.Container>
      <FormHolder onSubmit={toSubmit} formRef={setForm}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Styles.HeaderContainer>
              <Styles.CardHeader>
                <Styles.CardTitle>{texts.send}</Styles.CardTitle>
              </Styles.CardHeader>
              <Styles.Line />

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SelectYears
                    variant="standard"
                    name="year"
                    inputLabel={texts.year}
                    defaultValue={selectedYear}
                    options={yearsList ? yearsList : []}
                    onChange={(data) => getIndicator(data)}
                  />
                </Grid>
              </Grid>
              {!editable && (
                <>
                  <Styles.Description>
                    <Styles.WarningDescription />
                    {texts.info}
                  </Styles.Description>

                  <Styles.ButtonContainer>
                    <ButtonContained
                      loading={isFetching}
                      disabled={isFetching}
                      type="submit"
                      name="button"
                      fullWidth={false}
                    >
                      {texts.confirm}
                    </ButtonContained>
                  </Styles.ButtonContainer>
                </>
              )}
            </Styles.HeaderContainer>
          </Grid>
          <Styles.Scroll>
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  marginTop: "2rem",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />{" "}
              </div>
            ) : (
              <Grid container spacing={1}>
                {dataIndicators?.map((item, index) => {
                  return index + 1 === dataIndicators.length &&
                    isOdd(index + 1) ? (
                    <Grid item xs={12} key={item.position}>
                      <GenericCard
                        isEditable={editable}
                        saveOnLocal={saveAllInputs}
                        data={item}
                        key={item.position}
                        lastRow={true}
                        nextData={
                          dataIndicators[
                            dataIndicators.length === index + 1 ? 0 : index + 1
                          ]
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} key={item.position}>
                      <GenericCard
                        isEditable={editable}
                        saveOnLocal={saveAllInputs}
                        data={item}
                        key={item.position}
                        nextData={
                          dataIndicators[
                            dataIndicators.length === index + 1 ? 0 : index + 1
                          ]
                        }
                        lastRow={
                          dataIndicators.length -
                            (isOdd(dataIndicators.length) ? 1 : 2) <=
                          index
                            ? true
                            : false
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Styles.Scroll>
        </Grid>
      </FormHolder>
    </Styles.Container>
  );
}
function mapStateToProps(state) {
  const { isFetching, dataIndicators, years } = state.managementData;
  const { userInfos } = state.global;
  return {
    isFetching,
    dataIndicators,
    years,
    userInfos,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getManagementIndicatorsRequest,
    getIndicatorsYear,
    sendManagementIndicatorsRequest,
  } = Creators;
  return {
    getManagementIndicatorsRequest: function (data) {
      return dispatch(getManagementIndicatorsRequest(data));
    },
    getIndicatorsYear: function () {
      return dispatch(getIndicatorsYear());
    },
    sendManagementIndicatorsRequest: function (data) {
      return dispatch(sendManagementIndicatorsRequest(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ManagementData));
