import React from "react";

import { NavigationButtonsStyles as Styles } from "../styles";
import { SessionStorage } from "../../../lib";
function NavigationInfos({ pathname, userData }) {
  const isAcate = SessionStorage.getItem("isAcateMode");
  return (
    <Styles.Container>
      {userData?.pages?.map((item, index) => {
        return item?.pages?.map((page, index) => {
          return page?.path === pathname ? (
            <Styles.Content>
              {page?.icon ? (
                <Styles.ImageIcon alt="" src={page?.icon} $Acate={isAcate} />
              ) : null}
              {page?.title}
            </Styles.Content>
          ) : null;
        });
      })}
    </Styles.Container>
  );
}

export default NavigationInfos;
