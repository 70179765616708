import React, { useEffect } from "react";
import { connect } from "react-redux";
import { UserInfosStyles as Styles } from "../styles";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { SessionStorage } from "../../../lib";
import { navigateTo } from "../../../navigation/navigate";
function UserInfos({ getUserInfosRequest, isFetching }) {
  const [currentUserData, setCurrentUserData] = React.useState(null);

  useEffect(() => {
    const newData = SessionStorage.getItem("userData");
    setCurrentUserData(newData);
  }, [getUserInfosRequest]);

  return (
    <>
      <Styles.PersonInfo onClick={() => navigateTo.Profile()}>
        <Styles.InfoRow>
          <Styles.Name loading={isFetching} loadingSizes={[250, 24]}>
            {currentUserData?.name}
          </Styles.Name>
          <Styles.Email loading={isFetching} loadingSizes={[250, 24]}>
            {currentUserData?.email}
          </Styles.Email>
        </Styles.InfoRow>
      </Styles.PersonInfo>
    </>
  );
}

function mapStateToProps({ global }) {
  const { userInfos, isFetchingUserInfos: isFetching } = global;
  return { userInfos, isFetching };
}

function mapDispatchToProps(dispatch) {
  const { getUserInfosRequest } = GlobalCreators;
  return {
    getUserInfosRequest: function () {
      return dispatch(getUserInfosRequest());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(UserInfos));
