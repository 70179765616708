import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { Creators } from "../index";
import { Alerts } from "../../../../lib";

export default function* getBusinessModel() {
  try {
    const businessModel = yield call(api.getBusinessModel, null);

    yield put(Creators.getBusinessModelSuccess(businessModel));
  } catch (response) {
    Alerts.alertError("Não foi encontrar os modelos de negócios");
    yield put(Creators.getInputsFailure());
  }
}
