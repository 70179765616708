import { Texts } from "../config";
// Conversor Default

function formatCheckValue(value) {
  if (value === undefined || value === null) {
    throw new Error("first parameter (value) is required.");
  }
}

function formatCheckFormatter(format) {
  if (!format) {
    throw new Error(
      "second parameter (format) is required to specify how the string should return. Date example: 9999[-]99[-]99."
    );
  }
}

function formatCheckClearCallback(clearCallback) {
  if (clearCallback && typeof clearCallback !== "function") {
    throw new Error(
      "third parameter (clearCallback) is required to clear the string before mask treatment."
    );
  }
}

function formatCheckPrefix(prefix) {
  if (typeof prefix !== "string") {
    throw new Error(
      "fourth parameter (prefix) need to be a 'string' type, but it was passed " +
        typeof prefix
    );
  }
}

function formatCheckSufix(sufix) {
  if (typeof sufix !== "string") {
    throw new Error(
      "fifth parameter (sufix) need to be a 'string' type, but it was passed " +
        typeof sufix
    );
  }
}

function fixDateToLabel(date) {
  if (date === "") {
    return "Data não cadastrada";
  }
  let dArr = date.split("-");

  if (dArr.length === 2) {
    return dArr[0] + "/" + dArr[1];
  } else {
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0].substring(2);
  }
}

function formatCheck(value, format, clearCallback, prefix, sufix) {
  return (
    formatCheckValue(value) ||
    formatCheckFormatter(format) ||
    formatCheckClearCallback(clearCallback) ||
    formatCheckPrefix(prefix) ||
    formatCheckSufix(sufix)
  );
}

const formatCharCodesRange = {
  A: [65, 90, 192, 197, 199, 207, 209, 214, 217, 221, 159, 159],
  a: [97, 122, 224, 229, 231, 239, 241, 246, 249, 253, 255, 255],
  9: [48, 57],
};

function makeMask(
  value = "",
  format = "",
  clearCallback,
  prefix = "",
  sufix = ""
) {
  if (formatCheck(value, format, clearCallback, prefix, sufix)) {
    return value;
  }
  let clearedValue = value;
  if (prefix) {
    clearedValue = clearedValue.replace(prefix, "");
  }
  if (sufix) {
    clearedValue = clearedValue.replace(sufix, "");
  }

  if (clearCallback) {
    clearedValue = String(clearCallback(clearedValue));
  }

  let mask = "";
  const maxFormatIndex = format.length;
  if (clearedValue.length > maxFormatIndex) {
    clearedValue = clearedValue.substring(0, maxFormatIndex);
  }
  let formatIndex = maxFormatIndex - 1;
  let maxMaskIndex = clearedValue.length - 1;
  for (; formatIndex >= 0; formatIndex--) {
    const range = formatCharCodesRange[format[formatIndex]];
    if (range) {
      const charCode = clearedValue.charCodeAt(maxMaskIndex);
      const iMax = range.length;
      let i = 0;
      for (; i < iMax; i++) {
        if (charCode >= range[i] && charCode <= range[i + 1]) {
          mask = clearedValue[maxMaskIndex] + mask;
          break;
        }
        i++;
      }
      maxMaskIndex--;
      if (maxMaskIndex < 0) {
        break;
      }
    } else {
      mask = format[formatIndex] + mask;
    }
  }
  if (mask.length > maxFormatIndex) {
    mask = mask.substring(0, maxFormatIndex);
  }

  return prefix + mask + sufix;
}

// Máscara de porcentagem
function taxMask(value) {
  if (value) {
    let mask = `${value}`.replace(/\D/g, "");

    if (!value.includes("%") && mask.toString().length > 0) {
      const a = mask.toString();
      mask = a.substring(0, mask.toString().length - 1);
    }

    if (!mask || mask.toString().length === 0) {
      return null;
    }

    if (mask.toString().length > 4) {
      const a = mask.toString();
      mask = a.substring(0, mask.toString().length - 1);
    }

    if (mask.includes(" %")) {
      return mask;
    }
    const contador = (value.length - 5) / 3;

    mask = mask.replace(/^([.\d]+)(\d{2})$/, "$1,$2");

    for (let i = 0; i < contador; i += 1) {
      mask = mask.replace(/(\d+)(\d{3})([.,\d]+)?$/, "$1.$2$3");
    }
    mask = `${mask} %`;

    return mask;
  }

  return null;
}

// Máscara de valores monetários (apenas textos estáticos)
function convertMoneyTextMask(value) {
  if (value) {
    let stringOnlyNumbers = value.replace(",", ".").replace("R$ ", "");

    if (!stringOnlyNumbers) {
      return null;
    }

    const { length } = stringOnlyNumbers;
    if (length === 1) {
      return value >= 0
        ? `R$ 0,0${stringOnlyNumbers}`
        : `R$ -0,0${stringOnlyNumbers}`;
    }
    if (length === 2) {
      return value >= 0
        ? `R$ 0,${stringOnlyNumbers}`
        : `R$ -0,${stringOnlyNumbers}`;
    }
    let moneyMask = "";

    for (let i = length - 1; i >= 0; i -= 1) {
      if (i === length - 2) {
        moneyMask = `,${stringOnlyNumbers[i]}${moneyMask}`;
      } else if (i < length - 5 && (i - length - 3) % 3 === 0) {
        moneyMask = `${stringOnlyNumbers[i]}.${moneyMask}`;
      } else {
        moneyMask = `${stringOnlyNumbers[i]}${moneyMask}`;
      }
    }

    return value >= 0 ? `R$ ${moneyMask}` : `R$ -${moneyMask}`;
  }
  return null;
}

function convertNumberTextMask(value) {
  if (value) {
    let stringOnlyNumbers = parseFloat(
      value
        .replaceAll(".", "")
        .replace("R$ ", "")
        .replace(" %", "")
        .replace(",", ".")
    );

    if (!stringOnlyNumbers || isNaN(stringOnlyNumbers)) {
      return null;
    }

    return stringOnlyNumbers;
  }
  return null;
}

// Máscara de valores monetários (apenas inputs)
function formatSmallNumberValues(value, isMoney, floatNumber) {
  const mask = String(
    Number(value.replaceAll(".", "").replaceAll("R$ ", "").replaceAll(",", ""))
  );

  if (!mask) {
    return null;
  }

  if (mask.length === 1) {
    return isMoney ? "R$ 0,0" + mask : floatNumber ? `0,0${mask}` : mask;
  } else if (mask.length === 2) {
    return isMoney ? "R$ 0," + mask : floatNumber ? `0,${mask}` : mask;
  } else {
    return mask;
  }
}

function convertNumberInputMask(
  value,
  isMoney,
  floatNumber = true,
  max10,
  percent = false
) {
  if (value) {
    let mask = `${value}`.replace(/\D/g, "");

    if (!mask || mask === "") {
      return mask;
    }
    mask = formatSmallNumberValues(mask, isMoney, floatNumber);

    if (isMoney && mask.includes("R$ ")) {
      return mask;
    }
    const contador = floatNumber ? (value.length - 5) / 3 : value.length / 3;

    if (floatNumber) {
      mask = mask.replace(/^([.\d]+)(\d{2})$/, "$1,$2");
    }
    for (let i = 0; i < contador; i += 1) {
      mask = mask.replace(/(\d+)(\d{3})([.,\d]+)?$/, "$1.$2$3");
    }

    mask = isMoney ? `R$ ${mask}` : percent ? mask + "%" : mask;
    return mask;
  }

  return null;
}

function convertNumberToAbbreviation(value) {
  let mask = value;
  if (Math.abs(mask) >= 1000 && Math.abs(mask) < 1000000) {
    return (mask / 1000).toFixed(0) + "Mil";
  } else if (Math.abs(mask) >= 1000000) {
    return (mask / 1000000).toFixed(0) + "M";
  } else {
    return mask.toFixed(0);
  }
}
// Fim de funções para capitalização de textos inteligente.

function getStringMonth(monthValue, initials) {
  return initials
    ? Texts["pt-BR"].months[monthValue].initials
    : Texts["pt-BR"].months[monthValue].fullName;
}
function phoneMask(value) {
  if (!value) {
    return null;
  }

  let newValue = clearStringOnlyNumbers(value);
  if (newValue.length > 11) {
    newValue = newValue.substring(0, 11);
  }

  if (newValue.length === 11) {
    newValue = newValue.replace(/(\d{2})(\d{1})(\d)/, "($1) $2 $3");
    newValue = newValue.replace(/(\d{4})(\d{4})$/, "$1-$2");
  } else {
    newValue = newValue.replace(/(\d{2})(\d)/, "($1) $2");
    newValue = newValue.replace(/(\d{4})(\d{4})$/, "$1-$2");
  }

  return newValue;
}

function clearStringOnlyNumbers(value) {
  return value ? `${value}`.replace(/\D/g, "") : value;
}
function clearStringOnlyCharacters(value) {
  return value ? value.replace(/\d/g, "") : value;
}

function cnpjMask(value) {
  if (!value) {
    return null;
  }
  let newValue = clearStringOnlyNumbers(value);

  if (newValue.length > 14) {
    newValue = newValue.substring(0, 14);
  }

  newValue = newValue.replace(/(\d{2})(\d)/, "$1.$2");
  newValue = newValue.replace(/(\d{3})(\d)/, "$1.$2");
  newValue = newValue.replace(/(\d{3})(\d)/, "$1/$2");
  newValue = newValue.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  return newValue;
}
function maskName(value) {
  if (!value) {
    return null;
  }
  const newValue = clearStringOnlyCharacters(value);
  const arr = newValue.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const name = arr.join(" ");
  return name;
}

function phoneToRequest(value) {
  if (!value) {
    return value;
  }
  const phone = clearStringOnlyNumbers(value);
  let a;
  phone.length > 11 ? (a = phone.substring(0, phone.length - 1)) : (a = phone);
  return a;
}

function cnpjToRequest(value) {
  if (!value) {
    return value;
  }
  const phone = clearStringOnlyNumbers(value);
  let a;
  phone.length >= 15 ? (a = phone.substring(0, phone.length - 1)) : (a = phone);
  return a;
}
function cpfToRequest(value) {
  if (!value) {
    return value;
  }
  const phone = clearStringOnlyNumbers(value);
  let a;
  phone.length >= 8 ? (a = phone.substring(0, phone.length - 1)) : (a = phone);
  return a;
}
function cpfMask(value) {
  return makeMask(value, "999.999.999-99", clearStringOnlyNumbers);
}
function formatDate(input) {
  var datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];

  return day + "/" + month + "/" + year;
}

function formatDateTime(input) {
  if (input === null) {
    return "Sem Valor";
  }

  var datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2],
    hour = datePart[3],
    minute = datePart[4];

  return day + "/" + month + "/" + year + " - " + hour + ":" + minute;
}

function formatTENtoOne(value) {
  const convertedNumber = convertNumberInputMask(value, false, true);

  if (parseFloat(convertedNumber) > 10) {
    return "10";
  } else {
    return convertedNumber;
  }
}

function timeMask(value) {
  if (!value) {
    return null;
  }

  let newValue = clearStringOnlyNumbers(value); //Usa o filtro que so deixa os numeros.

  if (newValue.length === 4) {
    if (parseInt(newValue.substring(2, 0)) > 24) {
      return (newValue = "23:59");
    } else {
      if (parseInt(newValue.substring(2, 4)) > 60) {
        return newValue.substring(2, 0) + ":59";
      } else {
        return newValue.replace(/(\d{2})(\d{2})/, "$1:$2");
      }
    }
  } else if (newValue.length > 4) {
    return newValue.substring(4, 0).replace(/(\d{2})(\d{2})/, "$1:$2");
  } else {
    return newValue;
  }
}

const filters = {
  convertMoneyTextMask,
  convertNumberInputMask,
  taxMask,
  makeMask,
  getStringMonth,
  fixDateToLabel,
  phoneMask,
  cnpjMask,
  maskName,
  clearStringOnlyNumbers,
  phoneToRequest,
  cnpjToRequest,
  cpfToRequest,
  cpfMask,
  convertNumberTextMask,
  formatDate,
  convertNumberToAbbreviation,
  formatTENtoOne,
  timeMask,
  formatDateTime,
};

export default filters;
