export default function getCompanyRegister(response) {
  const requestData = {
    employeeNumber: response?.data?.response?.employeeNumber,
    id: response?.data?.response?.id,
    description: response?.data?.response?.description,
    cnpj: response?.data?.response?.cnpj,
    name: response?.data?.response?.name,
    sustainability: response?.data?.response?.sustainability,
    foundationYear: response?.data?.response?.foundationYear,
    responsableName: response?.data?.response?.responsableName,
    createdDate: response?.data?.response?.createdDate,
    releasedDate: response?.data?.response?.releasedDate,
    isReleased: response?.data?.response?.isReleased,
    responsableTelephone: response?.data?.response?.responsableTelephone,
    responsableEmail: response?.data?.response?.responsableEmail,
    responsableOffice: response?.data?.response?.responsableOffice
      ? {
          value: response?.data?.response?.responsableOffice?.id,
          label: response?.data?.response?.responsableOffice?.name,
        }
      : null,
    enable: response?.data?.response?.enable,
    city: response?.data?.response?.city?.id
      ? {
          value: response?.data?.response?.city?.id,
          label: response?.data?.response?.city?.name,
        }
      : null,
    uf: response?.data?.response?.uf?.id
      ? {
          value: response?.data?.response?.uf?.id,
          label: response?.data?.response?.uf?.initials,
          ufName: response?.data?.response?.uf.name,
        }
      : null,
    size: {
      value: response?.data?.response?.size?.id,
      label: response?.data?.response?.size?.name,
    },
    cnae: response?.data?.response?.cnae
      ? {
          value: response?.data?.response?.cnae?.id,
          label: response?.data?.response?.cnae?.cnae,
        }
      : null,
    plan: response?.data?.response?.plan?.id
      ? {
          value: response?.data?.response?.plan?.id,
          label: response?.data?.response?.plan?.name,
        }
      : null,
    typeOfSolutions:
      response?.data?.response?.typeOfSolutions.length > 0
        ? response?.data?.response?.typeOfSolutions?.map((item) => {
            return { value: item.id, label: item.name };
          })
        : null,
    verticals:
      response?.data?.response?.verticals.length > 0
        ? response?.data?.response?.verticals?.map((item) => {
            return { value: item.id, label: item.name };
          })
        : null,
    sectors:
      response?.data?.response?.sectors.length > 0
        ? response?.data?.response?.sectors?.map((item) => {
            return { value: item.id, label: item.name };
          })
        : null,
    businessModels:
      response?.data?.response?.businessModels.length > 0
        ? response?.data?.response?.businessModels.map((item) => {
            return { value: item.id, label: item.name };
          })
        : null,
    incubator: {
      value: response?.data?.response?.incubator?.id,
      label: response?.data?.response?.incubator?.name,
    },
  };

  return requestData;
}
