import Home from "./Home";
import Login from "./Login";
import UserRegister from "./UserRegister";
import Users from "./Users";
import FirstAccess from "./FirstAccess";
import GeneralIndicators from "./GeneralfinancialIndicators";
import ManagementIndicators from "./GeneralmanagementIndicators";
import Profile from "./Profile";
import FinancialData from "./FinancialData";
import ManagementData from "./ManagementData";
import ForgotPasswordPage from "./ForgotPassword";
import AccessHistory from "./AccessHistory";
import AccessRequests from "./AccessRequests";
import DataEntry from "./DataEntry";
import EvaluatedReleases from "./EvalutedReleases";
import ContactUs from "./ContactUsAcate";
import PlansApproval from "./PlansApproval";
import Premium from "./Premium";
import AccessHistoryClient from "./AccessHistoryClients";
const pages = {
  Home,
  Login,
  UserRegister,
  Users,
  FirstAccess,
  GeneralIndicators,
  ForgotPasswordPage,
  Profile,
  FinancialData,
  ManagementData,
  AccessHistory,
  DataEntry,
  AccessRequests,
  ManagementIndicators,
  EvaluatedReleases,
  Premium,
  PlansApproval,
  ContactUs,
  AccessHistoryClient,
};
export default pages;
