import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getFinantialList() {
  try {
    const response = yield call(api.getFinantialList, null);
    yield put(Creators.getFinantialListSuccess(response));
  } catch (response) {
    Alerts.alertError("Não foi possível encontrar os indicadores.");
    yield put(Creators.getFinantialListFailure());
  }
}
