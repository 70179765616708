import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../../lib";

export default function* getFinantialTooltips({ filter }) {
  try {
    const response = yield call(api.getFinantialTooltips, null);

    yield put(Creators.getFinantialTooltipsSuccess(response?.data?.response));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log ? response?.data?.log : "Erro ao filtrar indicadores."
    );
    yield put(Creators.getFinantialTooltipsFailure());
  }
}
