import React from "react";
import { connect } from "react-redux";
import {
  Input,
  ButtonOutlined,
  ButtonContained,
  InputDate,
} from "../../../components";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import { Styles } from "../styles";
import { FormHolder } from "../../../FormConfig";
import { Texts } from "../../../config";
import { Validations } from "../../../lib";
function SearchUsers({ isFetching, onSubmit }) {
  const texts = Texts["pt-BR"].accessHistory;
  return (
    <FormHolder onSubmit={onSubmit}>
      <Grid item xs={12}>
        <Styles.HeaderCard>
          <Styles.TitleCard>{texts.filterTitle}</Styles.TitleCard>
        </Styles.HeaderCard>
        <Styles.Line />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Input
            inputLabel={texts.table.username}
            name="username"
            placeholder="Usuário Exemplo"
            $small
          />
        </Grid>

        <Grid item xs={3}>
          <InputDate
            name="releasedDate"
            inputLabel={texts.table.release}
            maxDate={new Date()}
            validation={Validations.isDATE}
          />
        </Grid>

        <Grid item xs={3}>
          <InputDate
            name="lastAccess"
            inputLabel={texts.table.last}
            maxDate={new Date()}
            validation={Validations.isDATE}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        style={{ justifyContent: "flex-end" }}>
        <Grid item>
          <ButtonOutlined
            startIcon={<DeleteIcon />}
            type="clearDefault"
            fullWidth={false}
            loading={isFetching}>
            {texts.table.clean}
          </ButtonOutlined>
        </Grid>
        <Grid item>
          <ButtonContained
            startIcon={<SearchIcon />}
            type="submit"
            fullWidth={false}
            loading={isFetching}>
            {texts.table.search}
          </ButtonContained>
        </Grid>
      </Grid>
    </FormHolder>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.users;
  return {
    isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(SearchUsers));
