import createReducers from "../../../store/helpers/createPageReducer";
import companyAccessRequest from "./companyAccessRequest";
import getCNPJ from "./getCNPJ";
import patchApproveAccess from "./patchApproveAccess";
import patchDisapproveAccess from "./patchDisapproveAccess";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getCompanyAccess",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: companyAccessRequest,
    },
    {
      name: "companyAccessSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        companyAccess: data,
      }),
    },
    {
      name: "companyAccessFailure",
      function: (state) => ({ ...state, isFetching: false, companyAccess: [] }),
    },

    {
      name: "getCnpj",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getCNPJ,
    },
    {
      name: "getCnpjSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        listCnpj: data,
      }),
    },
    {
      name: "getCnpjFailure",
      function: (state) => ({ ...state, isFetching: false, listCnpj: [] }),
    },

    {
      name: "patchApproveAccess",
      params: ["data", "update"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: patchApproveAccess,
    },
    {
      name: "patchApproveAccessSuccess",
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "patchApproveAccessFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
    {
      name: "patchDisapproveAccess",
      params: ["data", "update"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: patchDisapproveAccess,
    },
    {
      name: "patchDisapproveAccessSuccess",
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "patchDisapproveAccessFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    companyAccess: null,
    listCnpj: [],
  }
);

export { Creators, reducers, sagas };
