import React from "react";
import { FirstAccessPage } from "../features";

function FirstAccess() {
  return (
    <>
      <FirstAccessPage />
    </>
  );
}

export default FirstAccess;
