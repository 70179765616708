import React from "react";
import { connect } from "react-redux";
import Styles from "../styles/Styles";
import { Creators } from "../reduxSagas";
import { Grid } from "@material-ui/core";
import { IntegratedComponentHolder } from "../../../components";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchReleases from "../components/SearchReleases";
import ReleasesList from "../components/ReleasesList";
import { SessionStorage } from "../../../lib";
import { customModal } from "../../modals/utils";
import { Texts } from "../../../config";
import { Creators as ProfileCreators } from "../../Profile/reduxSagas";
import { Creators as CompanyCreators } from "../../companyAccess/reduxSagas";

function EvaluatedReleases({
  isFetching,
  evaluatedReleases,
  getEvaluatedReleases,
  patchDisapproveRelease,
  getPlansList,
  planList,
  exportUserList,
  exportData,
  getOffice,
  office,
  getIncubators,
  incubators,
  listCnpj,
  getCnpj,
  patchApproveRelease,
}) {
  const [page, setPage] = React.useState(1);
  const texts = Texts["pt-BR"].evaluatedReleases;
  const MountCnpj = React.useCallback(() => {
    getCnpj();
  }, [getCnpj]);
  React.useEffect(() => {
    MountCnpj();
  }, [MountCnpj]);

  React.useEffect(() => {
    getEvaluatedReleases({
      page: 1,
      companyName: null,
      accessTypeRequested: null,
      username: null,
      email: null,
      beginRequestDate: null,
      endRequestDate: null,
      planId: null,
      isTech: null,
      officeId: null,
      incubatorId: null,
      cnpj: null,
      active: true,
    });
    exportUserList({
      page: 1,
      companyName: null,
      accessTypeRequested: null,
      username: null,
      email: null,
      beginRequestDate: null,
      endRequestDate: null,
      planId: null,
      isTech: null,
      incubatorId: null,
      officeId: null,
      active: true,
      cnpj: null,
    });
    getPlansList();
    getOffice();
    getIncubators();
  }, [
    getEvaluatedReleases,
    getPlansList,
    exportUserList,
    getOffice,
    getIncubators,
  ]);

  const getReleases = (data) => {
    const requestBody = {
      companyName: data.companyName ? data.companyName : null,
      accessTypeRequested:
        data.accessTypeRequested !== "-1" ? data.accessTypeRequested : null,
      username: data.username ? data.username : null,
      email: data.email ? data.email : null,
      beginRequestDate: data.beginRequestDate ? data.beginRequestDate : null,
      endRequestDate: data.endRequestDate ? data.endRequestDate : null,
      active:
        data.status !== "-1" ? (data.status === "true" ? true : false) : null,
      page: page,
      planId: data.planId !== "-1" ? data.planId : null,
      officeId: data.officeId !== "-1" ? parseInt(data.officeId) : null,
      isTech: data.tec !== "-1" ? (data.tec === "true" ? true : false) : null,
      incubatorId:
        data.incubatorId !== "-1" ? parseInt(data.incubatorId) : null,
      cnpj: data.cnpj !== "-1" ? data.cnpj : null,
    };
    getEvaluatedReleases(requestBody);
  };

  const setPageList = (value) => {
    let filter = SessionStorage.getItem("evaluatedReleasesFilter");

    filter = { ...filter, page: value };
    setPage(value);
    getEvaluatedReleases(filter);
  };

  const modalDisapproved = (id) => {
    customModal.setInfos(
      texts.modalDisapproved.title,
      [texts.modalDisapproved.subTitle],
      {
        label: texts.modalDisapproved.confirm,
        onClick: () => {
          patchDisapproveRelease(id, getEvaluatedReleases);
          customModal.close();
        },
      },
      {
        label: texts.modalDisapproved.exit,
        onClick: () => {
          customModal.close();
        },
      }
    );
  };

  const modalApproved = (id) => {
    customModal.setInfos(
      texts.modalApproved.title,
      [texts.modalApproved.subTitle],
      {
        label: texts.modalApproved.confirm,
        onClick: () => {
          patchApproveRelease(id, getEvaluatedReleases);
          customModal.close();
        },
      },
      {
        label: texts.modalApproved.exit,
        onClick: () => {
          customModal.close();
        },
      }
    );
  };

  return (
    <Styles.Container>
      <Grid container direction="row" alignContent="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Styles.Content>
            <Grid container spacing={1}>
              <SearchReleases
                listCnpj={listCnpj}
                onSubmit={getReleases}
                planList={planList}
                exportData={exportData}
                office={office ? office : []}
                incubators={
                  incubators
                    ? incubators.map((item) => {
                        return { label: item?.name, value: item?.id };
                      })
                    : []
                }
              />
            </Grid>
          </Styles.Content>
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={
              !isFetching && evaluatedReleases?.evaluatedReleases?.length < 1
            }
            reloadMessage={"Não encontrado"}
            showEmpty={
              !isFetching && evaluatedReleases?.evaluatedReleases?.length < 1
            }
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />{" "}
              </div>
            ) : (
              <ReleasesList
                evaluatedReleases={evaluatedReleases?.evaluatedReleases}
                page={page}
                setPage={setPageList}
                totalPages={evaluatedReleases?.data?.response?.pageTotal}
                disapproveRelease={modalDisapproved}
                approveRelease={modalApproved}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching, evaluatedReleases, planList, exportData } =
    state.evaluatedReleases;

  const { isFetching: isOfficeFetching, office, incubators } = state.profile;
  const { isFetching: isCompanyFetching, listCnpj } = state.companyAccess;
  return {
    isFetching: isOfficeFetching || isFetching || isCompanyFetching,
    evaluatedReleases,
    planList,
    exportData,
    office,
    incubators,
    listCnpj,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getEvaluatedReleases,
    patchDisapproveRelease,
    getPlansList,
    exportUserList,
    patchApproveRelease,
  } = Creators;

  const { getOffice, getIncubators } = ProfileCreators;

  const { getCnpj } = CompanyCreators;

  return {
    getEvaluatedReleases: function (data) {
      return dispatch(getEvaluatedReleases(data));
    },
    patchDisapproveRelease: function (data, update) {
      return dispatch(patchDisapproveRelease(data, update));
    },
    patchApproveRelease: function (data, update) {
      return dispatch(patchApproveRelease(data, update));
    },
    getPlansList: function () {
      return dispatch(getPlansList());
    },
    exportUserList: function (data) {
      return dispatch(exportUserList(data));
    },
    getOffice: function (data) {
      return dispatch(getOffice(data));
    },
    getIncubators: function () {
      return dispatch(getIncubators());
    },
    getCnpj: function () {
      return dispatch(getCnpj());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(EvaluatedReleases));
