import React from "react";
import Styles from "../styles/ContactUsModalStyles";
import { Grid } from "@material-ui/core";
import { Spacing, Texts } from "../../../config";
import { Filters } from "../../../lib";

function ContactUsConfirm({ data }) {
  const texts = Texts["pt-BR"].contactUs.modal;
  return (
    <Styles.ContactContent>
      <Grid container>
        <Grid container direction="row" xs={12} sm={12} md={7} lg={7}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Styles.TitleModal>{texts.email}</Styles.TitleModal>
            <Styles.InfoModal>{data.email}</Styles.InfoModal>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Styles.TitleModal>{texts.time}</Styles.TitleModal>
            <ul style={{paddingLeft: Spacing(3.0)}}>
              <Styles.GreenBullet><Styles.InfoModal>{data.weekTime}</Styles.InfoModal></Styles.GreenBullet>
              <Styles.GreenBullet><Styles.InfoModal>{data.weekendTime}</Styles.InfoModal></Styles.GreenBullet>
            </ul>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
            <Styles.TitleModal>{texts.phone}</Styles.TitleModal>
            <Styles.InfoModal>{Filters.phoneMask(data.phone)}</Styles.InfoModal>
        </Grid>
      </Grid>
    </Styles.ContactContent>
  );
}

export default React.memo(ContactUsConfirm);
