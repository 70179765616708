import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* companyAccessRequest({ data }) {
  try {
    SessionStorage.setItem("companyAccessFilter", data);
    const requestBody = {
      searchParameters: {
        companyName: data?.companyName ? data.companyName : null,
        accessTypeRequested: data?.accessTypeRequested
          ? data?.accessTypeRequested
          : null,
        username: data?.username ? data?.username : null,
        email: data?.email ? data?.email : null,
        beginRequestDate: data?.beginRequestDate
          ? data?.beginRequestDate
          : null,
        endRequestDate: data?.endRequestDate ? data?.endRequestDate : null,
        cnpj: data?.cnpj ? data?.cnpj : null,
        status: data.status ? data.status : null,
      },
      page: {
        number: data.page - 1,
        size: 6,
      },
    };
    const response = yield call(api.getCompanyAccess, requestBody);

    yield put(Creators.companyAccessSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao realizar pesquisa de acessos. ");
    yield put(Creators.companyAccessFailure());
  }
}
