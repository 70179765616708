import { /*call,*/ put } from "redux-saga/effects";
//import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getPlans() {
  try {
    //const response = yield call(api.getPlans, null);
    const response = [
      {
        title: "Bronze",
        price: "R$ 25,00 MENSAL",
        info: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sagittis, enim in rhoncus vestibulum, mauris elit tempor velit, ac porta eros velit non libero.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sagittis, enim in rhoncus vestibulum, mauris elit tempor velit, ac porta eros velit non libero.",
        ],
        type: "Contrato de 3 meses + R$ 67,80 de taxa de adesão",
        id: 2,
      },
      {
        title: "SILVER",
        price: "R$ 25,00 MENSAL",
        info: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sagittis, enim in rhoncus vestibulum, mauris elit tempor velit, ac porta eros velit non libero.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sagittis, enim in rhoncus vestibulum, mauris elit tempor velit, ac porta eros velit non libero.",
        ],
        type: "Contrato de 3 meses + R$ 67,80 de taxa de adesão",
        id: 3,
      },
      {
        title: "GOLDEN ",
        price: "R$ 25,00 MENSAL",
        info: [
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sagittis, enim in rhoncus vestibulum, mauris elit tempor velit, ac porta eros velit non libero.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sagittis, enim in rhoncus vestibulum, mauris elit tempor velit, ac porta eros velit non libero.",
        ],
        type: "Contrato de 3 meses + R$ 67,80 de taxa de adesão",
        id: 4,
      },
    ];
    yield put(Creators.getPlansSuccess(response));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi recuperar a lista de planos"
    );
    yield put(Creators.getPlansFailure());
  }
}
