import React from "react";
import { Texts } from "../../../config";
import { Switch, Input, InputAutoComplete } from "../../../components";
import { Filters, Validations } from "../../../lib";
import { Grid } from "@material-ui/core";
import Styles from "../styles/Styles";
function RegistryNotAssociated({
  cities,
  UFList,
  cityListRequest,
  cnaes,
  formRef,
}) {
  const [showCNAE, setShowCNAE] = React.useState(false);
  const texts = Texts["pt-BR"];
  return (
    <>
      <Input
        variant="standard"
        name="username"
        required={texts.login.userMessage}
        inputLabel={texts.login.stepTwo.name}
        mask={(data) => Filters.maskName(data)}
        validation={(data) => Validations.isFullName(data)}
      />
      <Input
        variant="standard"
        name="companyName"
        required={texts.login.userMessage}
        inputLabel={texts.login.stepTwo.companyName}
      />

      <>
        <Switch
          onChange={(item, value) => {
            setShowCNAE(value);
            if (value === false) {
              formRef?.setDefaultValue("cnaeId");
              formRef?.setFormValue("cnaeId", null);
              formRef?.setValue("cnaeId", null);
              formRef?.setDefaultValue("foundationYear");
              formRef?.setFormValue("foundationYear", null);
              formRef?.setValue("foundationYear", null);

              if (formRef?.formInputs?.cnaeId) {
                formRef.formInputs.cnaeId.required = undefined;
              }
              if (formRef?.formInputs?.foundationYear) {
                formRef.formInputs.foundationYear.required = undefined;
              }

              formRef?.testInputError("cnaeId", true);
              formRef?.testInputError("foundationYear", true);
            } else {
              if (formRef?.formInputs?.cnaeId) {
                formRef.formInputs.cnaeId.required = "*Campo obrigatório.";
              }
              if (formRef?.formInputs?.foundationYear) {
                formRef.formInputs.foundationYear.required =
                  "*Campo obrigatório.";
              }
            }
          }}
          disableError
          compact
          label="EMPRESA É DO RAMO DE TECNOLOGIA?"
        />
        <Styles.Description>
          <Styles.WarningDescription />
          Ativar campo se sua Empresa possuir atuação no setor de Tecnologia.
        </Styles.Description>
      </>

      <Input
        variant="standard"
        name="cnpj"
        required={showCNAE ? texts.login.userMessage : false}
        inputLabel={texts.login.stepTwo.cnpj}
        mask={(data) => Filters.cnpjMask(data)}
        validation={Validations.isCNPJ}
        maskToSubmit={Filters.cnpjToRequest}
      />
      {showCNAE ? (
        <>
          <InputAutoComplete
            defaultValue={null}
            inputLabel={texts.login.stepTwo.cnae2}
            disabled={!showCNAE}
            name="cnaeId"
            required={showCNAE ? texts.login.userMessage : false}
            options={cnaes ? cnaes : []}
          />
          <Input
            maxLength={4}
            name="foundationYear"
            required={texts.login.userMessage}
            inputLabel={"ANO DE FUNDAÇÃO DA EMPRESA"}
            validation={Validations.maxYear}
            mask={Filters.clearStringOnlyNumbers}
            maskToSubmit={Filters.clearStringOnlyNumbers}
          />
        </>
      ) : (
        <>
          <InputAutoComplete
            defaultValue={null}
            inputLabel={texts.login.stepTwo.cnae2 + "⠀"}
            disabled={true}
            name="cnaeId1"
            required={false}
            options={cnaes ? cnaes : []}
          />
          <Input
            maxLength={4}
            name="foundationYear1"
            required={false}
            defaultValue={null}
            disabled={true}
            inputLabel={"ANO DE FUNDAÇÃO DA EMPRESA⠀"}
            validation={Validations.maxYear}
            mask={Filters.clearStringOnlyNumbers}
            maskToSubmit={Filters.clearStringOnlyNumbers}
          />
        </>
      )}

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <InputAutoComplete
            defaultValue={null}
            inputLabel={texts.login.stepTwo.uf}
            name="uf"
            autoComplete="off"
            options={UFList ? UFList : []}
            required={texts.login.userMessage}
            onChange={(value, index) => {
              UFList?.find((element) => {
                if (element.value === index) {
                  return cityListRequest(element.ufName);
                }
                return null;
              });
            }}
            validation={(value) => {
              if (value !== -1) {
                return { isValid: true, errorMessage: "" };
              } else {
                return { isValid: false, errorMessage: "*Campo obrigatório." };
              }
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <InputAutoComplete
            defaultValue={null}
            inputLabel={texts.login.stepTwo.city}
            name="cityId"
            required={texts.login.userMessage}
            options={cities ? cities : []}
            validation={(value) => {
              if (value !== -1) {
                return { isValid: true, errorMessage: "" };
              } else {
                return { isValid: false, errorMessage: "*Campo obrigatório." };
              }
            }}
          />
        </Grid>
      </Grid>

      <Input
        variant="standard"
        name="telephone"
        inputLabel={texts.login.stepTwo.phone}
        mask={Filters.phoneMask}
        maskToSubmit={Filters.phoneToRequest}
        validation={(data) => Validations.isPhone(data)}
      />
    </>
  );
}

export default React.memo(RegistryNotAssociated);
