import {
  financialIndicators,
  reducers as FinancialReducers,
  sagas as FinancialSagas,
} from "./financialIndicators";
import {
  managementIndicators,
  reducers as ManagementReducers,
  sagas as ManagementSagas,
} from "./managementIndicators";
const General = {
  financialIndicators,
  managementIndicators,
};
const reducers = {
  financialGeral: FinancialReducers,
  managementGeral: ManagementReducers,
};

const sagas = [...FinancialSagas, ...ManagementSagas];

export { reducers, sagas, General };
