import {
  createBarScalesX,
  createFixedDatalabels,
  createLayoutWithDatalabels,
} from "../helpers/CommonChartConfigs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";
import { Filters } from "../../lib";

function getData(data, labels) {
  if (data === (null || undefined)) {
    return [];
  } else {
    const newData = [null];
    for (let index = 0; index < labels.length; index++) {
      if (data[labels[index]]) {
        newData.push(data[labels[index]]);
      } else {
        newData.push(null);
      }
    }
    newData.push(null);

    return newData;
  }
}

function getLabels(data) {
  if (data === (null || undefined)) {
    return [];
  } else {
    const newData = [""];
    const array = Object.keys(data);
    newData.push(...array);
    newData.push("");
    return newData;
  }
}

export default function getCapacityHistoricLine(response, noScales) {
  const iMax = 4;
  const labels = getLabels(
    Object.keys(response?.otherCompanies).length > 0
      ? response?.otherCompanies
      : response?.myCompany
  );

  const otherCompanies = getData(
    response?.otherCompanies,
    Object.keys(response?.otherCompanies).length > 0
      ? Object.keys(response?.otherCompanies)
      : Object.keys(response?.myCompany)
  );
  const myCompany = getData(
    response?.myCompany,
    Object.keys(response?.otherCompanies).length > 0
      ? Object.keys(response?.otherCompanies)
      : Object.keys(response?.myCompany)
  );

  const chartData = {
    type: "line",
    data: {
      labels,
      datasets: [
        {
          label: "Teste",
          borderColor: "#464646",
          backgroundColor: "#464646",
          lineTension: 0,
          borderDash: [6],
          borderWidth: 1,
          pointRadius: 8,

          pointBorderWidth: 0.5,
          pointBackgroundColor: "#464646",
          pointBorderColor: "#464646",
          fill: false,
          data: otherCompanies,
        },
        {
          label: "Teste2",
          type: "bar",
          barPercentage: 0.4,
          backgroundColor: "#ACCA13",
          fill: true,
          data: myCompany,
        },
      ],
    },
    plugins: [ChartDataLabels],
    options: {
      interaction: {
        mode: "dataset",
      },
      responsive: true,
      maintainAspectRatio: false,
      labels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        datalabels: createFixedDatalabels(
          "white",
          "#00000012",
          "#464646",
          (value, context) => {
            if (value === null) {
              return;
            }
            const valueData = Filters.convertNumberInputMask(
              Number(value).toFixed(2),
              true,
              true
            );
            return valueData;
          },
          iMax > 32
        ),
      },
      onHover: function (event, elements) {
        var chart = this;
        var expando = chart.$_user_ || (chart.$_user_ = {});

        if (!Chart.helpers.arrayEquals(expando.hovered, elements)) {
          expando.hovered = elements;
          chart.update();
        }
      },
      layout: createLayoutWithDatalabels(),

      scales: noScales
        ? {
            yAxes: [{ ticks: { display: false } }],
            xAxes: [
              { ticks: { display: false }, gridLines: { display: false } },
            ],
          }
        : createBarScalesX(
            "#6A6A6A40",
            "#6A6A6A",
            false,
            undefined,
            10,
            "money"
          ),
    },
  };

  return {
    data: {
      chartData,
      type: "bar",
    },
  };
}
