import Logo from "../assets/images/logo_acate.png";
import BackgroundImage from "../assets/images/bg_login.png";
import LogoLogin from "../assets/images/LogoBlack.png";
import WaterMarkAcate from "../assets/images/datatech-watermark.png";
import Finep from "../assets/images/finep-01-branca.png";
const images = {
  Logo,
  BackgroundImage,
  LogoLogin,
  WaterMarkAcate,
  Finep,
};

export default images;
