export default function getIndicatorOrder(response) {
  const data = response.data.response.groups.map((item) => {
    return {
      id: item.id,
      label: item.name,
      position: item.position,
      tip: item.tip,
      inputs: item.indicators.map((input) => {
        return {
          id: input.id,
          label: input.name,
          tip: input.tip,
          value:
            input.valueType === "INTEGER"
              ? input.value?.value
              : parseFloat(input.value?.value).toFixed(2),
          valueLabel: input.valueLabel,
          valueType: input.valueType,
          status: input.status,
          required: input.required,
          notApplicable: input.notApplicable,
        };
      }),
    };
  });
  const groupList = response.data.response.groups.map((item) => {
    return {
      value: item.id,
      label: item.name,
      filled: item.name,
    };
  });
  data.sort(function (a, b) {
    return a.position - b.position;
  });
  return { ...response, inputs: data, groupList: groupList };
}
