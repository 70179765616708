import createReducers from "../../../store/helpers/createPageReducer";
import updatePassword from "./updatePassword";
import getCompanyData from "./getCompanyData";

import getVerticals from "./getInputs/getVerticals";
import getBusinessModel from "./getInputs/getBusinessModel";
import getSectors from "./getInputs/getSectors";
import getSizes from "./getInputs/getSizes";
import getTypeOfSolution from "./getInputs/getTypeOfSolution";
import getSustainability from "./getInputs/getSustainability";
import getMesoRegions from "./getInputs/getMesoRegions";
import putCompleteRegister from "./putCompleteRegister";
import putBasicRegister from "./putBasicRegister";
import getPlanTec from "./getPlanTec";
import getPlanNotTec from "./getPlanNotTec";
import getOffice from "./getInputs/getOffice";
import getIncubators from "./getIncubators";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "updatePasswordRequest",
      params: ["data", "form"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: updatePassword,
    },
    {
      name: "updatePasswordSuccess",
      function: (state) => ({ ...state, isFetching: false }),
    },
    {
      name: "updatePasswordFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getCompanyData",
      params: ["data", "form"],
      function: (state) => ({
        ...state,
        isFetching: true,
        companyData: null,
      }),
      sagaFunction: getCompanyData,
    },
    {
      name: "getCompanyDataSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        companyData: data,
      }),
    },
    {
      name: "getCompanyDataFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getBusinessModel",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getBusinessModel,
    },
    {
      name: "getSectors",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getSectors,
    },
    {
      name: "getOffice",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getOffice,
    },
    {
      name: "getSizes",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getSizes,
    },
    {
      name: "getTypeOfSolution",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getTypeOfSolution,
    },
    {
      name: "getVerticals",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getVerticals,
    },
    {
      name: "getSustainability",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getSustainability,
    },
    {
      name: "getSustainabilitySuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        sustainability: data,
      }),
    },
    {
      name: "getOfficeSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        office: data,
      }),
    },
    {
      name: "getBusinessModelSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        businessModel: data,
      }),
    },
    {
      name: "getSectorsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        sectors: data,
      }),
    },
    {
      name: "getSizeSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        sizes: data,
      }),
    },
    {
      name: "getTypeOfSolutionSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        typeOfSolution: data,
      }),
    },
    {
      name: "getVerticalsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        verticals: data,
      }),
    },
    {
      name: "getInputsFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "putCompleteRegister",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: putCompleteRegister,
    },
    {
      name: "putCompleteRegisterSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        companyData: data,
      }),
    },
    {
      name: "putCompleteRegisterFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "putBasicRegister",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: putBasicRegister,
    },
    {
      name: "putBasicRegisterSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        companyData: data,
      }),
    },
    {
      name: "putBasicRegisterFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getMesoRegionsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        mesoRegions: data,
      }),
    },
    {
      name: "getMesoRegions",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getMesoRegions,
    },
    {
      name: "cleanMesoRegions",
      function: (state) => ({
        ...state,
        mesoRegions: null,
      }),
    },

    {
      name: "getPlanTec",
      function: (state) => ({
        ...state,
        isFetching: true,
        companyData: null,
      }),
      sagaFunction: getPlanTec,
    },
    {
      name: "getPlanNotTec",
      function: (state) => ({
        ...state,
        isFetching: true,
        companyData: null,
      }),
      sagaFunction: getPlanNotTec,
    },
    {
      name: "getPlanSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        plan: data,
      }),
    },
    {
      name: "getPlanFailure",
      function: (state) => ({ ...state, isFetching: false, plan: null }),
    },

    {
      name: "getIncubators",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getIncubators,
    },
    {
      name: "getIncubatorsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        incubators: data,
      }),
    },
    {
      name: "getIncubatorsFailure",
      function: (state) => ({ ...state, isFetching: false, plan: null }),
    },
  ],
  {
    isFetching: false,
    companyData: null,

    businessModel: null,
    sectors: null,
    sizes: null,
    typeOfSolution: null,
    verticals: null,
    sustainability: null,
    mesoRegions: null,
    plan: null,
    office: null,
    incubators: null,
  }
);

export { Creators, reducers, sagas };
