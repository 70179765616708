import styled from "styled-components";
import {
  FontStyles,
  Text,
  ButtonContained,
  InputDate,
} from "../../../components";
import { IconButton } from "@material-ui/core";

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
  };
});

const HeaderCard = styled.div(({ theme, $info }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: $info ? "flex-start" : "space-between",
    alignItems: "center",
    marginBottom: spacing(0.5),
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    height: spacing(0.1),
    marginBottom: spacing(1),
    backgroundColor: colors.carbon,
    opacity: 0.7,
  };
});

const ActionContainer = styled.div(({ theme }) => {
  const { MediaQueries } = theme;
  return {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    [MediaQueries.lgDown]: {
      flexWrap: "wrap",
    },
  };
});
const ActionContent = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "flex-end",
    marginBlock: spacing(0.25),
  };
});

const TitleCard = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.semibold14,
  };
});

const ActionButton = styled(ButtonContained)(({ theme, $secondColor }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    width: 100,
    backgroundColor: $secondColor ? colors.green : colors.carbon,
    padding: spacing(0.9, 1.5),
    marginLeft: spacing(1),
    ":hover": {
      backgroundColor: $secondColor ? colors.green : colors.carbon,
    },
  };
});

const Dots = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.carbon,
    margin: 0,
    padding: 0,
  };
});

const InputWithoutLabel = styled(InputDate)(({ theme }) => {
  const { spacing } = theme;
  return {
    top: spacing(2.5),
  };
});
const InputLabel = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

const BetweenDate = styled(Text)(({ theme }) => {
  const { palette: colors, spacing } = theme;

  return {
    ...FontStyles.roman14,
    color: colors.text.secondary,
    textAlign: "center",
    paddingTop: spacing(1.5),
  };
});
const Styles = {
  Content,
  HeaderCard,
  TitleCard,
  ActionButton,
  Line,
  Dots,
  InputWithoutLabel,
  BetweenDate,
  InputLabel,
  ActionContainer,
  ActionContent,
};

export default Styles;
