import models from ".";

export default function getFinancialData(response) {
  var data = {};

  if (response.data === null) {
    return { error: "OK" };
  }

  data["churnRate"] = !response.data.churnRate
    ? null
    : models.PercentBarChart(response.data?.churnRate);

  data["customerAcquisitionCost"] = !response.data.customerAcquisitionCost
    ? null
    : models.MoneyBarChart(response.data?.customerAcquisitionCost, true);

  data["investmentInRD"] = response.data.investmentInRD;

  data["invoicing"] = !response.data.invoicing
    ? null
    : models.MoneyLineChart(response.data.invoicing);

  data["ltv"] = !response.data.ltv
    ? null
    : models.MoneyBarChart(response.data.ltv, true);

  data["marginEbitida"] = !response.data.marginEbitida
    ? null
    : models.PercentLineChart(response.data.marginEbitida);

  data["mrr"] = !response.data.mrr
    ? null
    : models.MoneyBarChart(response.data.mrr);

  data["netMargin"] = !response.data.netMargin
    ? null
    : models.MoneyLineChart(response.data.netMargin);

  data["nps"] = !response.data.nps
    ? null
    : models.TermoChart(response.data.nps);

  data["numberOfActiveCustomers"] = response.data.numberOfActiveCustomers;

  data["revenueFromOverseasCustomers"] = !response.data
    .revenueFromOverseasCustomers
    ? null
    : models.PercentLineChart(response.data.revenueFromOverseasCustomers);

  data["revenuePerEmployee"] = !response.data.revenuePerEmployee
    ? null
    : models.MoneyLineChart(response.data.revenuePerEmployee);

  data["volumeOfInvestmentRaised"] = response.data.volumeOfInvestmentRaised;

  return data;
}
