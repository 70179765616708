import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import Fonts from "./fonts";
import Colors from "./colors";

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1600,
  xxl: 1920,
};

function createTheme({
  backgroundColor,
  secondaryBackgroundColor,
  paperBackgroundColor,
  paperSecondaryBackgroundColor,
  backgroundDisabled,
  textDisabledColor,
  textPrimaryColor,
  textSecondaryColor,
  textTertiaryColor,
  textQuaternary,
  buttonTextColor,
  buttonBackgroundColor,
  contrastColor,
}) {
  return createMuiTheme({
    MediaQueries: {
      smUp: `@media only screen and (min-width: ${breakpointValues.sm + 1}px)`,
      smDown: `@media only screen and (max-width: ${breakpointValues.sm}px)`,
      mdUp: `@media only screen and (min-width: ${breakpointValues.md + 1}px)`,
      mdDown: `@media only screen and (max-width: ${breakpointValues.md}px)`,
      lgUp: `@media only screen and (min-width: ${breakpointValues.lg + 1}px)`,
      lgDown: `@media only screen and (max-width: ${breakpointValues.lg}px)`,
      xlUp: `@media only screen and (min-width: ${breakpointValues.xl + 1}px)`,
      xlDown: `@media only screen and (max-width: ${breakpointValues.xl}px)`,
      xxlUp: `@media only screen and (min-width: ${
        breakpointValues.xxl + 1
      }px)`,
      xxlDown: `@media only screen and (max-width: ${breakpointValues.xxl}px)`,
    },
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1600,
        xxl: 1920,
      },
    },
    palette: {
      white: Colors.white,
      redError: Colors.redError,
      darkOpacity: Colors.darkOpacity,
      darkGray: Colors.darkGray,
      softGray: Colors.softGray,
      carbon: Colors.carbon,
      green: Colors.green,
      darkGreen: Colors.darkGreen,
      lightGray: Colors.lightGray,
      primary: {
        contrastText: buttonTextColor,
        main: buttonBackgroundColor,
      },
      secondary: {
        main: buttonTextColor,
        contrastText: buttonBackgroundColor,
      },
      error: {
        main: Colors.redError,
        contrastText: Colors.white,
      },
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
        tertiary: textTertiaryColor,
        quaternary: textQuaternary,
        disabled: textPrimaryColor + 99,
        hint: textPrimaryColor,
        divider: textPrimaryColor,
      },
      background: {
        paper: paperBackgroundColor,
        default: backgroundColor,
        defaultHover: contrastColor,
        secondary: secondaryBackgroundColor,
        input: paperSecondaryBackgroundColor,
      },
      action: {
        active: secondaryBackgroundColor,
        hover: textSecondaryColor + "60",
        hoverOpacity: 0.04,
        selected: textSecondaryColor,
        selectedOpacity: 0.08,
        disabled: textDisabledColor,
        disabledBackground: backgroundDisabled,
        disabledOpacity: 0.38,
        focus: "#6CFF00",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
      colors: {
        white: Colors.white,
        redError: Colors.redError,
        green: Colors.green,
        darkGreen: Colors.darkGreen,
      },
    },
    typography: {
      h1: {
        color: textTertiaryColor,
        fontFamily: Fonts.bold,
        fontSize: Fonts.sizes.medium,
      },

      h2: {
        color: textTertiaryColor,
        fontFamily: Fonts.roman,
        fontSize: Fonts.sizes.small,
        letterSpacing: "0.05em",
      },

      body1: {
        color: textSecondaryColor,
        fontFamily: Fonts.light,
        fontSize: "1rem",
        letterSpacing: "0.05em",
      },
      button: {
        fontFamily: Fonts.roman,
        fontSize: Fonts.sizes.small,
        color: textSecondaryColor,
        letterSpacing: "0em",
      },
    },
    shape: {
      borderRadius: 4,
    },
    overrides: {
      MuiPickersDay: {
        dayDisabled: {
          opacity: 0.3,
        },
      },
      MuiPickersMonth: {
        monthDisabled: {
          opacity: 0.3,
        },
      },
    },
  });
}

const Light = createTheme({
  backgroundColor: Colors.softGray,
  secondaryBackgroundColor: Colors.green,

  paperBackgroundColor: Colors.white,
  paperSecondaryBackgroundColor: Colors.gray,

  backgroundDisabled: Colors.grayDisabled,

  textDisabledColor: Colors.black,
  textPrimaryColor: Colors.white,
  textSecondaryColor: Colors.carbon,
  textTertiaryColor: Colors.green,
  textQuaternary: Colors.subGray,

  buttonTextColor: Colors.white,
  buttonBackgroundColor: Colors.green,
  contrastColor: Colors.darkGreen,
});

const Acate = createTheme({
  backgroundColor: Colors.softGray,
  secondaryBackgroundColor: Colors.carbon,

  paperBackgroundColor: Colors.white,
  paperSecondaryBackgroundColor: Colors.gray,

  backgroundDisabled: Colors.grayDisabled,

  textDisabledColor: Colors.black,
  textPrimaryColor: Colors.white,
  textSecondaryColor: Colors.carbon,
  textTertiaryColor: Colors.green,
  textQuaternary: Colors.subGray,

  buttonTextColor: Colors.white,
  buttonBackgroundColor: Colors.green,
  contrastColor: Colors.darkGreen,
});

const Themes = {
  Light: responsiveFontSizes(Light),
  Acate: responsiveFontSizes(Acate),
};

export default Themes;
