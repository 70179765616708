import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* postUpdateIndicator({ data, callback }) {
  try {
    yield call(api.postUpdateIndicator, data);
    yield put(Creators.postUpdateIndicatorSuccess());
    Alerts.alertSuccess("Indicador atualizado com sucesso!");
    callback();
  } catch (response) {
    Alerts.alertError("Não foi possível salvar os indicadores.");
    yield put(Creators.postUpdateIndicatorFailure());
  }
}
