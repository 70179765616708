import React from "react";
import { HeaderStyles as Styles } from "../styles";
import { NavigationInfos, UserInfos } from "../components";
import { connect } from "react-redux";

function Header({ pathname, userData }) {
  return (
    <Styles.Container>
      <Styles.FirstContentGroup>
        <Styles.Rectangle />
        <NavigationInfos pathname={pathname} userData={userData} />
      </Styles.FirstContentGroup>
      <Styles.UserContentGroup>
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "10px solid #FFB05B",
            position: "absolute",
            top: 0,
            right: -1,
          }}></div>
        <div
          style={{
            width: 0,
            height: 0,
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderRight: "10px solid #FFB05B",
            position: "absolute",
            top: -1,
            right: 0,
          }}></div>
        <UserInfos />
      </Styles.UserContentGroup>
    </Styles.Container>
  );
}

function mapStateToProps({ router, global }) {
  const { userData } = global;
  return {
    pathname: router.location.pathname,
    userData,
  };
}
export default connect(mapStateToProps)(React.memo(Header));
