import { Backdrop, Modal, Paper } from "@material-ui/core";
import styled from "styled-components";
import { MediaQueries } from "../../../config";
import CloseIcon from "@material-ui/icons/Close";

const StyledBackdrop = styled(Backdrop)({
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  backgroundColor: "#00000020",
});

const StyledModal = styled(Modal)({
  outlineWidth: 0,
  borderWidth: 0,
  ":focus": {},
});

const Container = styled(Paper)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    position: "absolute",
    width: 400,
    maxWidth: 620,
    maxHeight: "95vh",
    paddingBlock: spacing(2),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    outline: "none",
    backgroundColor: colors.whiteBackground,

    [MediaQueries.smUp]: {
      width: `calc(100% - ${spacing(2)}px)`,
    },
  };
});

const ContentContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    overflowY: "auto",
    maxHeight: `calc(95vh - ${spacing(2)}px)`,
  };
});

const HeaderContainer = styled.div(({ theme, noExit }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: spacing(3.5),
    marginTop: noExit ? spacing(1.5) : spacing(0.5),
  };
});

const Close = styled(CloseIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.carbon,
    width: spacing(3),
    height: spacing(3),
  };
});

const TextContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "black",
    padding: spacing(2, 0, 2, 0),
    paddingInline: spacing(3.5),
  };
});
const PaddingModal = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    marginTop: spacing(1),
    paddingInline: spacing(3.5),
    display: "flex",
    justifyContent: "flex-end",
  };
});
const Styles = {
  StyledBackdrop,
  StyledModal,
  Container,
  ContentContainer,
  HeaderContainer,
  Close,
  TextContainer,
  PaddingModal,
};

export default Styles;
