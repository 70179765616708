import createReducers from "../../../store/helpers/createPageReducer";
import getFinantialList from "./getFinantialList";
import getManagerialList from "./getManagerialList";
import sendIndicatorPositionRequest from "./sendIndicatorPositionRequest";
import postNewIndicator from "./postNewIndicator";
import postUpdateIndicator from "./postUpdateIndicator";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getFinantialListRequest",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getFinantialList,
    },
    {
      name: "getFinantialListSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        finantialList: data.inputs,
        finantialGroupList: data.groupList,
      }),
    },
    {
      name: "getFinantialListFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getManagerialListRequest",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getManagerialList,
    },
    {
      name: "getManagerialListSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        managerialList: data.inputs,
        manageriaGroupList: data.groupList,
      }),
    },
    {
      name: "getManagerialListFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "sendIndicatorPositionRequest",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: sendIndicatorPositionRequest,
    },
    {
      name: "sendIndicatorPositionSuccess",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "sendIndicatorPositionFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
    {
      name: "postNewIndicator",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: postNewIndicator,
    },
    {
      name: "postNewIndicatorSuccess",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "postNewIndicatorFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
    {
      name: "postUpdateIndicator",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: postUpdateIndicator,
    },
    {
      name: "postUpdateIndicatorSuccess",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "postUpdateIndicatorFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    finantialList: null,
    managerialList: null,
    finantialGroupList: null,
    manageriaGroupList: null,
  }
);

export { Creators, reducers, sagas };
