import GreenPin from "../../assets/icons/pin-green.svg";
import BluePin from "../../assets/icons/pin-blue.svg";

import Chart from "chart.js";
import { FontStyles } from "../../components";
import { SessionStorage } from "../../lib";
export default function TermoChart(response) {
  const img1 = new Image();
  const img2 = new Image();
  img1.src = GreenPin;
  img2.src = BluePin;
  const getGradient = (item, a) => {
    var background_1 = item.createLinearGradient(
      0,
      0,
      a?.right ? a.right : 500,
      0
    );
    background_1.addColorStop(0, "#C42631");
    background_1.addColorStop(0.5, "#E95F69");
    background_1.addColorStop(0.7, "#FF921D");

    background_1.addColorStop(0.8, "#FFE81D");
    background_1.addColorStop(1, "#55CA13");

    return background_1;
  };
  const isAcateMode = SessionStorage.getItem("isAcateMode");
  const idleValue = [10];

  const chartData = {
    type: "horizontalBar",
    data: {
      datasets: [
        {
          barPercentage: 1,
          categoryPercentage: 0.9,
          backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            return getGradient(ctx, chartArea);
          },
          fill: true,
          data: idleValue,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      labels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      layout: {
        padding: {
          top: 12,
        },
      },
      scales: {
        yAxes: [
          {
            display: false, // hides the horizontal scale
            stacked: true, // stacks the bars on the x axis
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawTicks: false,
              z: 1,
              color: "white",
            },
            ticks: {
              fontFamily: FontStyles.medium12.fontFamily,
              fontSize: 12,
              fontColor: "black",
              beginAtZero: true,
              maxTicksLimit: 11,
              padding: 4,
              max: 10,
            },
          },
        ],
      },
    },
    onHover: function (event, elements) {
      var chart = this;

      // Store hovered elements under a private property
      // named $_user_ to make sure it doesn't conflict.
      var expando = chart.$_user_ || (chart.$_user_ = {});

      // To prevent updating the chart for every mouse move,
      // let's first check that the hovered items have changed.
      if (!Chart.helpers.arrayEquals(expando.hovered, elements)) {
        expando.hovered = elements;
        chart.update();
      }
    },
    plugins: [
      {
        id: "barAvatar",
        afterDraw: function (chart, args, options) {
          const { ctx, chartArea } = chart;

          ctx.fontFamily = FontStyles.medium12.fontFamily;
          ctx.fontSize = 12;
          const border = Math.round((ctx.measureText("10").width + 4) / 2);

          var widthMulti = (chartArea.right - border) / 10;
          var average = response.allCompanies * widthMulti;
          var company = response.myCompany * widthMulti;
          if (!isAcateMode) {
            ctx.save();
            ctx.drawImage(img1, company - 4, chartArea.top - 12, 24, 28);
            ctx.save();
          }

          ctx.save();
          ctx.drawImage(img2, average - 4, chartArea.top - 12, 24, 28);
          ctx.save();
        },
      },
    ],
  };

  return {
    data: {
      chartData,
      type: "gauges",
      difference: response?.difference?.toFixed(1),
      average: response.allCompanies?.toFixed(1),
      company: response.myCompany?.toFixed(1),
    },
  };
}
