import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
import GenericCard from "../components/GenericCard";
import { ButtonContained, SelectYears } from "../../../components";

import { Grid, CircularProgress } from "@material-ui/core";
import { FormHolder } from "../../../FormConfig";
import { Creators } from "../reduxSagas";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { Texts } from "../../../config";
function FinancialData({
  isFetching,
  getFinancialIndicatorsRequest,
  dataIndicators,
  getIndicatorsYear,
  years,
  sendFinancialIndicatorsRequest,
  userInfos,
  getCountries,
  countriesList,
}) {
  const texts = Texts["pt-BR"].financialData;
  const [form, setForm] = React.useState(null);
  const [r, setR] = React.useState(false);
  const [editable, setEditable] = React.useState(true);
  const [selectedYear, setSelecterYear] = React.useState(
    new Date().getFullYear() - 1
  );
  const [yearsList, setYearsList] = React.useState([]);
  const isOdd = (num) => num % 2 === 1;

  const getIndicator = (year) => {
    //getFinancialIndicatorsRequest(year);
    setR(true);
    years.map((item) => item.value === year && setEditable(item.filled));
    setSelecterYear(year);
  };

  React.useEffect(() => {
    getIndicatorsYear();
    getCountries();
  }, [getIndicatorsYear, getCountries]);

  React.useEffect(() => {
    const getIndicator = (year) => {
      getFinancialIndicatorsRequest(year);
      setR(true);
      let currentYear = years.find((item) => item.value === year.toString());
      if (currentYear) {
        setEditable(currentYear?.filled);
      }
    };

    if (years?.length > 0) {
      getIndicator(selectedYear);
      setR(true);

      let newYears = years.map((item) => {
        if (
          Boolean(
            window.localStorage.getItem(
              "financialData" + userInfos?.email + item.value
            )
          )
        ) {
          return {
            value: item.value,
            label: item.label,
            filled: item.filled,
            cache: true,
          };
        } else {
          return item;
        }
      });

      setYearsList(newYears);
    }
  }, [years, userInfos, selectedYear, getFinancialIndicatorsRequest]);

  React.useEffect(() => {
    if (form !== (null && undefined)) {
      const errors = form?.testErrorsAndReturnData(false);
      if (errors?.hasError) {
        form?.buttons?.button?.disableHandler(true);
      }
    }

    if (r === true && !isFetching) {
      if (form !== (null && undefined) && dataIndicators) {
        const a = window.localStorage.getItem(
          "financialData" + userInfos?.email + form.getFieldValue("year")
        );

        const inputsV = JSON.parse(a);

        for (var item in inputsV) {
          if (form.findInput(item) && inputsV[item] !== null) {
            if (
              form.getFieldValue(item) === null ||
              form.getFieldValue(item) === ""
            ) {
              form.setValue(
                item,
                typeof inputsV[item] === "string"
                  ? inputsV[item]
                  : parseFloat(inputsV[item]).toFixed(2).toString()
              );
            } else if (Array.isArray(form.getFieldValue(item))) {
              form.setValue(item, inputsV[item]);
            }
          }
        }

        setR(false);
      }
    }
  }, [form, dataIndicators, r, isFetching, userInfos]);

  const saveAllInputs = () => {
    const values = form.getValues();

    if (Object.keys(values).length > 1) {
      window.localStorage.setItem(
        "financialData" + userInfos?.email + form.getFieldValue("year"),
        JSON.stringify(values)
      );
    }
  };

  const toSubmit = (data) => {
    let inputList = [];
    for (var property in data) {
      if (property !== "year") {
        inputList.push({
          indicatorId: parseInt(property),
          value: Array.isArray(data[property])
            ? data[property].map((obj) => obj.label).join(",") + ", "
            : data[property]?.toString(),
        });
      }
    }

    sendFinancialIndicatorsRequest(
      {
        year: data.year,
        indicatorTypeId: 1,
        values: inputList,
      },
      () => {
        getIndicatorsYear();
        getFinancialIndicatorsRequest(data.year);
        setEditable(false);
      }
    );
  };

  return (
    <Styles.Container>
      <FormHolder onSubmit={toSubmit} formRef={setForm}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ height: "min-content" }}>
            <Styles.CardContainer>
              <Styles.CardHeader>
                <Styles.CardTitle>{texts.send}</Styles.CardTitle>
              </Styles.CardHeader>
              <Styles.Line />

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SelectYears
                    variant="standard"
                    name="year"
                    inputLabel={texts.year}
                    defaultValue={selectedYear}
                    options={yearsList ? yearsList : []}
                    onChange={(data) => getIndicator(data)}
                  />
                </Grid>
              </Grid>
              {!editable && (
                <>
                  <Styles.Description>
                    <Styles.WarningDescription />
                    {texts.info}
                  </Styles.Description>

                  <Styles.ButtonContainer>
                    <ButtonContained
                      loading={isFetching}
                      disabled={isFetching}
                      type="submit"
                      name="button"
                      fullWidth={false}
                    >
                      {texts.confirm}
                    </ButtonContained>
                  </Styles.ButtonContainer>
                </>
              )}
            </Styles.CardContainer>
          </Grid>
          <Styles.Scroll>
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  marginTop: "2rem",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />{" "}
              </div>
            ) : (
              <Grid container spacing={1}>
                {dataIndicators?.map((item, index) => {
                  return index + 1 === dataIndicators.length &&
                    isOdd(index + 1) ? (
                    <Grid item xs={12} key={item.position}>
                      <GenericCard
                        countriesList={countriesList}
                        isEditable={editable}
                        saveOnLocal={saveAllInputs}
                        data={item}
                        nextData={
                          dataIndicators[
                            dataIndicators.length === index + 1 ? 0 : index + 1
                          ]
                        }
                        key={item.position}
                        lastRow={true}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} key={item.position}>
                      <GenericCard
                        countriesList={countriesList}
                        isEditable={editable}
                        saveOnLocal={saveAllInputs}
                        data={item}
                        key={item.position}
                        nextData={
                          dataIndicators[
                            dataIndicators.length === index + 1 ? 0 : index + 1
                          ]
                        }
                        lastRow={
                          dataIndicators.length -
                            (isOdd(dataIndicators.length) ? 1 : 2) <=
                          index
                            ? true
                            : false
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Styles.Scroll>
        </Grid>
      </FormHolder>
    </Styles.Container>
  );
}
function mapStateToProps(state) {
  const {
    isFetching: isFetchingData,
    dataIndicators,
    years,
  } = state.financialData;
  const { userInfos, countriesList, isFetchingLists } = state.global;
  return {
    isFetching: isFetchingLists || isFetchingData,
    dataIndicators,
    years,
    userInfos,
    countriesList,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getFinancialIndicatorsRequest,
    getFinancialIndicatorsYear,
    sendFinancialIndicatorsRequest,
  } = Creators;
  const { getCountries } = GlobalCreators;

  return {
    getFinancialIndicatorsRequest: function (data) {
      return dispatch(getFinancialIndicatorsRequest(data));
    },
    getIndicatorsYear: function () {
      return dispatch(getFinancialIndicatorsYear());
    },
    sendFinancialIndicatorsRequest: function (data, callback) {
      return dispatch(sendFinancialIndicatorsRequest(data, callback));
    },
    getCountries: function () {
      return dispatch(getCountries());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(FinancialData));
