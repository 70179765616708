export default function getUsersList(response) {
  const data = response.data.response.elements.map((item) => {
    return {
      email: item.email,
      name: item.username,
      releasedDate: item.releasedDate,
      lastAccess: item.lastAccess,
      status: item.enable,
      id: item.id,
    };
  });
  return { ...response, usersList: data };
}
