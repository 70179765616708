import React from "react";
import { Texts, Themes } from "../../../config";
import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { FormHelperText, Grid } from "@material-ui/core";
import Title from "../../../components/strings/Title";
import { ButtonOutlined, InputPassword } from "../../../components";
import { Validations } from "../../../lib";

function StepThree({ goBack, onSubmit, title, text }) {
  const texts = Texts["pt-BR"].forgotPassword.passwordConfirmatiom;
  const [formConfig, setFormConfig] = React.useState(null);
  const [error, setError] = React.useState(true);
  const sendNewPassword = (data) => {
    onSubmit(data);
  };
  const validatePassword = () => {
    if (
      formConfig &&
      formConfig?.getFieldValue("password") !== "" &&
      formConfig?.getFieldValue("newPassword") !== ""
    ) {
      if (
        formConfig?.getFieldValue("password") ===
        formConfig?.getFieldValue("newPassword")
      ) {
        setError(null);
      } else {
        setError(" As senhas não correspondem.");
      }
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Styles.Content>
        <Title>{title}</Title>
        <Styles.Subtitle>{text}</Styles.Subtitle>
        <FormHolder onSubmit={sendNewPassword} formRef={setFormConfig}>
          <InputPassword
            name="password"
            nextInput="newPassword"
            autoComplete={"new-password"}
            inputLabel={texts.newPassword}
            required={texts.inputPasswordRequiredMessage}
            validation={Validations.validPassword}
            onBlur={(a, b) => {
              validatePassword();
            }}
          />
          <InputPassword
            name="newPassword"
            nextInput="password"
            autoComplete={"new-password"}
            inputLabel={texts.confirmNewPassword}
            required={texts.inputPasswordRequiredMessage}
            validation={Validations.validPassword}
            onBlur={(a, b) => {
              validatePassword();
            }}
          />
          {error && (
            <FormHelperText style={{ color: Themes.Acate.palette.redError }}>
              {error}
            </FormHelperText>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Styles.ConfirmButton
              disabled={Boolean(error)}
              name="connect"
              type="submit"
            >
              {texts.confirm}
            </Styles.ConfirmButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ButtonOutlined onClick={goBack}>{texts.back}</ButtonOutlined>
          </Grid>
        </FormHolder>
      </Styles.Content>
    </>
  );
}

export default React.memo(StepThree);
