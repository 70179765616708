import React from "react";
import { connect } from "react-redux";
import Title from "../../../components/strings/Title";
import { Texts, Images } from "../../../config";
import { InputPassword, InputEmail, ButtonOutlined } from "../../../components";
import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { Creators } from "../reduxSagas";
import { Grid } from "@material-ui/core";
import { navigateTo } from "../../../navigation/navigate";
import { useVersion } from "../../../lib";
import useMediaQuery from "@material-ui/core/useMediaQuery";
function Login({ loginRequest, isFetching }) {
  const texts = Texts["pt-BR"].login;
  const version = useVersion();
  const matches = useMediaQuery("(max-width:599px)");

  const onSubmit = (data) => {
    let requestData = {
      username: data.username,
      password: data.password,
      keepConnected: data.keepConnected ? data.keepConnected : false,
    };
    loginRequest(requestData);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction={matches ? "column-reverse" : "unset"}
        wrap={matches ? "nowrap" : "unset"}
        style={{ overflow: matches ? "auto" : null, height: "100vh" }}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <Styles.Container>
            <Styles.Header>
              <Styles.AcateLogo src={Images.LogoLogin} />
            </Styles.Header>
            <Styles.Content>
              <Title>{texts.title}</Title>
              <Styles.LoginSubtitle>{texts.subtitle}</Styles.LoginSubtitle>
              <FormHolder onSubmit={onSubmit}>
                <InputEmail
                  variant="standard"
                  name="username"
                  nextInput="password"
                  required={texts.userMessage}
                  inputLabel={texts.user}
                  autoComplete={"username"}
                />
                <InputPassword
                  variant="standard"
                  name="password"
                  nextInput="username"
                  autoComplete={"current-password"}
                  required={texts.passwordMessage}
                  inputLabel={texts.password}
                />
                <Styles.HyperLink
                  underline="always"
                  color="textSecondary"
                  onClick={navigateTo.ForgotPassword}>
                  {texts.forgotPass}
                </Styles.HyperLink>

                <Styles.ConfirmButton
                  loading={isFetching}
                  type="submit"
                  name="button"
                  $upper>
                  {texts.enter}
                </Styles.ConfirmButton>
                <ButtonOutlined $upper onClick={() => navigateTo.FirstAccess()}>
                  {texts.firstAccess}
                </ButtonOutlined>
                {/* <ButtonText
                onClick={() => navigateTo.Home()} 
                style={{ padding: "16px", marginTop: "8px" }}>
                  {texts.acateMode}
                </ButtonText> */}
              </FormHolder>
              <Styles.Version>
                {Texts["pt-BR"].version}
                {version}
              </Styles.Version>
            </Styles.Content>
          </Styles.Container>
        </Grid>

        <Grid item xs={12} sm={6} md={7} lg={8}>
          <Styles.ImageBG $Image={`url(${Images.BackgroundImage})`}>
            <Styles.DivBG>
              <Styles.FLogo src={Images.Finep} />
              <div>
                <Styles.TextBG>
                  {texts.bgText.map((item) => {
                    return item;
                  })}
                </Styles.TextBG>
                <Styles.TextCopyright>
                  {texts.copyright} <Styles.Copyright />
                </Styles.TextCopyright>
              </div>
            </Styles.DivBG>
          </Styles.ImageBG>
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.login;
  return {
    isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  const { loginRequest } = Creators;
  return {
    loginRequest: function (data, captcha) {
      return dispatch(loginRequest(data, captcha));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Login));
