import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* newManagerRequest({ data, update }) {
  try {
    const response = yield call(api.newManagerRequest, data);

    yield put(Creators.newManagerSuccess());

    if (response.ok) {
      update(true);
      Alerts.alertSuccess("Troca de gestor realizada com sucesso!");
    }
  } catch (response) {
    //    Alerts.alertError("Erro ao trocar gestor. ");
    yield put(Creators.newManagerFailure());
  }
}
