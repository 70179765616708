export default function getExportIndicators(response) {
  function getLabels(data) {
    if (data === null || data === undefined) {
      return [];
    } else {
      const yearSet = new Set();
      data.forEach((item) => yearSet.add(item.year));
      // Converter o Set em Array e ordenar os elementos
      return Array.from(yearSet).sort((a, b) => a - b);
    }
  }

  let headers = [];

  const sortedResponse = response.data.response.sort((a, b) => {
    const indicatorA = a.indicatorGroup + " - " + a.indicator;
    const indicatorB = b.indicatorGroup + " - " + b.indicator;
    return indicatorA.localeCompare(indicatorB);
  });

  const data = sortedResponse.map((item) => {
    headers = [...new Set([...headers, ...getLabels(item?.annualValues)])].sort(
      (a, b) => a - b
    );

    return {
      indicator: item.indicatorGroup + " - " + item.indicator,
      data: item.annualValues.map((value) => {
        return value.value.toFixed(2);
      }),
    };
  });

  return { headers: headers, data: data };
}
