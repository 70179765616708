import React from "react";
import { connect } from "react-redux";
import { Creators as CreatorsInputs } from "../../../Profile/reduxSagas";
import { Creators as CreatorsRegistry } from "../../../firstAccess/reduxSagas";
import { Creators as CreatorsExport } from "../../financialIndicators/reduxSagas";

import {
  ButtonOutlined,
  InputAutoComplete,
  Select,
  ChipList,
  Switch,
  Tolltip,
} from "../../../../components";
import { Icons } from "../../../../config";
import { Delete, Tune } from "@material-ui/icons";
import { FormHolder } from "../../../../FormConfig";
import Styles from "../styles/FilterStyles";
//import { Texts } from "../../../../config";
import { Grid } from "@material-ui/core";
import { ToCSV } from "../../../../lib";
function Filter({
  widthMax,
  getVerticals,
  getBusinessModel,
  getSectors,
  getSizes,
  getTypeOfSolution,

  cityListRequest,
  getMesoRegions,
  cleanMesoRegions,
  ufListRequest,
  inputs,
  isFetching,
  onSubmit,
  getExport,
  exportData,
  managementData,
}) {
  const [open, setOpen] = React.useState(false);
  const [formRef, setRef] = React.useState(false);
  const ref = React.useRef(null);
  const ref2 = React.useRef(null);
  const [width, setWidth] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = React.useState({});

  React.useEffect(() => {
    getVerticals();
    getBusinessModel();
    getSectors();
    getSizes();
    getTypeOfSolution();

    ufListRequest();
    cityListRequest(null);
  }, [
    getVerticals,
    getBusinessModel,
    getSectors,
    getSizes,
    getTypeOfSolution,

    ufListRequest,
    cityListRequest,
  ]);

  React.useEffect(() => {
    if (ref !== null) {
      setWidth(ref?.current?.offsetHeight);
      getExport(null);
    }
  }, [getExport]);

  const renderFilters = () => {
    function getValueFromList(list, key) {
      return list.map((item) => {
        return inputs[key]?.find((o) => o.value.toString() === item.toString())
          .label;
      });
    }

    function getValueFromString(item, key) {
      return inputs[key]?.find((o) => o.value.toString() === item.toString())
        ?.label;
    }

    let ss = [];
    Object.keys(selectedFilters).forEach(function (key, index) {
      if (key === "ufList" || selectedFilters[key] === "-1") {
        return null;
      }
      if (typeof selectedFilters[key] === ("string" || "number")) {
        if (
          selectedFilters[key] !== "true" &&
          selectedFilters[key] !== "false"
        ) {
          let label = getValueFromString(selectedFilters[key], key);
          ss.push(label);
        } else {
          ss.push(
            selectedFilters[key] === "true"
              ? "Empresa Associada"
              : "Empresa Não Associada"
          );
        }
      } else {
        let label = getValueFromList(selectedFilters[key], key);
        ss.push(...label);
      }
    });

    return ss.map((item) => <Styles.Chips label={item} />);
  };

  const Submit = (data) => {
    setSelectedFilters(data);

    const requestBody = {
      cityId: data.cityList && data.cityList !== "-1" ? data.cityList : null,
      mesoRegionId:
        data.mesoRegions && data.mesoRegions !== "-1" ? data.mesoRegions : null,
      isAssociateCompany:
        data.associated && data.associated !== "false" ? data.associated : null,
      businessModelIds: data.businessModel ? data.businessModel : null,
      typeOfSolutionsIds: data.typeOfSolution ? data.typeOfSolution : null,
      sizeIds: data.sizes ? data.sizes : null,
      verticalIds: data.verticals ? data.verticals : null,
      sectorIds: null,
      allNull:
        (data.associated && data.associated !== "false") ||
        data.businessModel ||
        data.typeOfSolution ||
        data.sizes ||
        data.verticals
          ? false
          : true,
    };
    onSubmit(requestBody);
    setOpen(false);
  };

  const Export = (data) => {
    const names = data.data.map((item) => {
      return item.indicator;
    });
    const values = data.data.map((item) => {
      return item.data.map((value) => value.replace(".", ","));
    });

    ToCSV.csv(values, ["", ...data.headers], names, "Indicadores");
  };

  return (
    <FormHolder onSubmit={Submit} formRef={setRef}>
      {inputs !== null && (
        <div style={{ minHeight: `${width}px` }}>
          <Styles.Container ref={ref} widthMax={widthMax} open={open}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              style={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Styles.FilterTitle>
                  FILTROS DE INDICADORES GERENCIAIS
                  <Tolltip
                    title={"Dica"}
                    tip={
                      "Você deve clicar no botão 'Filtrar' e selecionar um ou mais campos para realizar a sua consulta. Em seguida, clique em 'Aplicar Filtros'."
                    }
                  />
                </Styles.FilterTitle>
              </Grid>
              {!open && (
                <Grid item>
                  <Styles.CompactButton
                    bottomPadding
                    disabled={
                      (!managementData || managementData === null) &&
                      (!exportData || exportData?.data?.length < 1) &&
                      !isFetching
                    }
                    startIcon={<Icons.Export />}
                    loading={isFetching}
                    onClick={() => Export(exportData)}
                    fullWidth={false}
                    $export
                  >
                    Exportar Base
                  </Styles.CompactButton>
                </Grid>
              )}
            </Grid>
            <Grid container item>
              <Grid item xs={12} sm={12} md={9}>
                <Styles.FilterSelected ref={ref2}>
                  {renderFilters()}
                </Styles.FilterSelected>
              </Grid>

              <Grid
                container
                item
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                style={{ justifyContent: "flex-end" }}
                sm={12}
                md={3}
              >
                <Grid item>
                  <ButtonOutlined
                    startIcon={<Delete />}
                    onClick={() => {
                      formRef.clearAllValues(false);

                      cityListRequest(null);
                      cleanMesoRegions();
                      setSelectedFilters([]);
                      getExport(null);
                      onSubmit(null);
                    }}
                    loading={isFetching}
                    disabled={isFetching}
                    fullWidth={false}
                  >
                    Limpar
                  </ButtonOutlined>
                </Grid>

                <Grid item>
                  {open ? (
                    <Styles.CompactButton
                      type="submit"
                      fullWidth={false}
                      disabled={isFetching}
                      loading={isFetching}
                    >
                      Aplicar Filtros
                    </Styles.CompactButton>
                  ) : (
                    <Styles.CompactButton
                      startIcon={<Tune />}
                      onClick={() => setOpen(!open)}
                      fullWidth={false}
                      disabled={isFetching}
                      loading={isFetching}
                    >
                      Filtrar
                    </Styles.CompactButton>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {
              <Styles.FilterContainer
                $open={open}
                removeHeight={ref2?.current?.offsetHeight}
              >
                <Grid
                  container
                  spacing={2}
                  style={{ display: open ? "flex" : "none" }}
                >
                  <Grid
                    item
                    xs={12}
                    smallLabel
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <InputAutoComplete
                          name="ufList"
                          smallLabel
                          inputLabel="UF"
                          options={inputs?.ufList ? inputs.ufList : []}
                          noOptionsText="UF nâo encontradas"
                          defaultValue={null}
                          onChange={(value, index) => {
                            if (!isFetching && index !== "") {
                              const a = inputs?.ufList?.find((element) => {
                                if (element.value === index) {
                                  return element;
                                } else {
                                  return null;
                                }
                              });

                              if (a !== (undefined && null)) {
                                cityListRequest(a.ufName);
                                getMesoRegions(a.ufName);
                              } else {
                                cityListRequest(null);
                                cleanMesoRegions();
                              }
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <Select
                          smallLabel
                          name="mesoRegions"
                          inputLabel="MESORREGIÃO"
                          noOptionsText="Selecione uma UF"
                          options={
                            inputs?.mesoRegions
                              ? inputs.mesoRegions
                              : [
                                  {
                                    value: -1,
                                    label: "Selecione uma UF",
                                    disabled: true,
                                  },
                                ]
                          }
                          onChange={(value, index) => {
                            if (!isFetching && index !== "") {
                              if (index !== -1) {
                                cityListRequest(-1, index);
                              } else {
                                cityListRequest(-1);
                              }
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <InputAutoComplete
                          name="cityList"
                          smallLabel
                          inputLabel="CIDADE"
                          defaultValue={null}
                          noOptionsText="Selecione uma UF"
                          options={inputs?.cityList ? inputs.cityList : []}
                        />
                      </Grid>
                      {/*
                         <Grid item xs={4}>
                        <Select
                          smallLabel
                          inputLabel="DADOS NACIONAIS"
                          name="nationalData"
                          options={[
                            {
                              value: "Santa Catarina",
                              label: "Santa Catarina",
                            },
                            {
                              value: "Todas as UFs",
                              label: "Todas as UFs",
                            },
                          ]}
                        />
                      </Grid>
                        */}
                    </Grid>
                  </Grid>
                  <Styles.Line />
                  <Grid
                    item
                    xs={12}
                    smallLabel
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    {" "}
                    <Switch
                      smallLabel
                      label="EMPRESA ASSOCIADA"
                      name="associated"
                      defaultValue={false}
                    />
                    <Styles.Line />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    smallLabel
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <ChipList
                      smallLabel
                      name="businessModel"
                      label="MODELOS DE NEGÓCIO"
                      description="Selecione um ou mais bullets"
                      options={inputs?.businessModel}
                    />
                    <Styles.Line />
                    <ChipList
                      smallLabel
                      name="sizes"
                      label="PORTE DA EMPRESA"
                      description="Selecione um ou mais bullets"
                      options={inputs?.sizes}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <ChipList
                      smallLabel
                      name="typeOfSolution"
                      label="TIPO DE SOLUÇÃO"
                      description="Selecione um ou mais bullets"
                      options={inputs?.typeOfSolution}
                    />
                    <Styles.Line />

                    <ChipList
                      smallLabel
                      name="verticals"
                      label="VERTICAL DE ATUAÇÃO NA ACATE"
                      description="Selecione um ou mais bullets"
                      options={inputs?.verticals}
                    />
                  </Grid>
                </Grid>
              </Styles.FilterContainer>
            }
          </Styles.Container>
        </div>
      )}
      <Styles.BlurContainer
        open={open}
        onClick={() => setOpen(false)}
      ></Styles.BlurContainer>
    </FormHolder>
  );
}

function mapStateToProps(state) {
  const {
    isFetching: isFetchingProfile,
    businessModel,
    sectors,
    sizes,
    typeOfSolution,
    verticals,

    mesoRegions,
  } = state.profile;
  const { isFetching: isFetchingRegistry, CityList, UFList } = state.registry;
  const {
    isFetching: isFetchingExport,
    exportData,
    managementData,
  } = state.financialGeral;
  const { isFetching: isFetchingGeral } = state.managementGeral;
  return {
    exportData: exportData,
    managementData,
    isFetching:
      isFetchingRegistry ||
      isFetchingProfile ||
      isFetchingExport ||
      isFetchingGeral,
    inputs: {
      businessModel: businessModel,
      sectors: sectors,
      sizes: sizes,
      typeOfSolution: typeOfSolution,
      verticals: verticals,
      cityList: CityList,
      ufList: UFList,
      mesoRegions: mesoRegions,
    },
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getVerticals,
    getBusinessModel,
    getSectors,
    getSizes,
    getTypeOfSolution,
    getMesoRegions,
    cleanMesoRegions,
  } = CreatorsInputs;

  const { cityListRequest, ufListRequest } = CreatorsRegistry;
  const { postExportIndicators } = CreatorsExport;
  return {
    cityListRequest: function (uf, meso) {
      return dispatch(cityListRequest(uf, meso));
    },
    getMesoRegions: function (uf) {
      return dispatch(getMesoRegions(uf));
    },
    ufListRequest: function () {
      return dispatch(ufListRequest());
    },
    getVerticals: function (data) {
      return dispatch(getVerticals(data));
    },
    getBusinessModel: function (data) {
      return dispatch(getBusinessModel(data));
    },
    getSectors: function (data) {
      return dispatch(getSectors(data));
    },
    getSizes: function (data) {
      return dispatch(getSizes(data));
    },
    getTypeOfSolution: function (data) {
      return dispatch(getTypeOfSolution(data));
    },
    getExport: function (data) {
      return dispatch(postExportIndicators(data));
    },
    cleanMesoRegions: function () {
      return dispatch(cleanMesoRegions());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Filter));
