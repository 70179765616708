import React from "react";
import { connect } from "react-redux";
import { Input, ButtonText, InputEmail } from "../../../components";
import { Grid } from "@material-ui/core";
import { Styles } from "../styles";
import { FormHolder } from "../../../FormConfig";
import { customModal } from "../../modals/utils";
import { Validations, Filters } from "../../../lib";
function ModalRegistry({
  isFetching,
  newUser,
  updateUser,
  isEditing,
  userData,
}) {
  return (
    <Styles.ModalPadding>
      <FormHolder onSubmit={isEditing ? updateUser : newUser}>
        <Input
          inputLabel="NOME DO USUÁRIO"
          name="name"
          placeholder="Usuário Exemplo"
          defaultValue={userData ? userData.name : null}
          required="Campo Obrigatorio*"
          validation={Validations.isFullName}
          maskToSubmit={Filters.maskName}
        />
        <InputEmail
          inputLabel="E-MAIL"
          name="email"
          required="Campo Obrigatorio*"
          defaultValue={userData ? userData.email : null}
          placeholder="user@acate.com.br"
          validation={Validations.isEMAIL}
          tooltip={{
            title: "",
            content:
              "Somente serão aceitos e-mails do mesmo domínio da empresa",
          }}
        />

        <Grid
          style={{ marginBlock: "1em" }}
          container
          direction="row"
          justify="flex-end">
          <Grid item style={{ width: "fit-content" }}>
            <ButtonText
              onClick={() => customModal.close()}
              fullWidth={false}
              style={{ textTransform: "uppercase" }}>
              Cancelar
            </ButtonText>
          </Grid>

          <Grid item style={{ width: "fit-content" }}>
            <ButtonText
              $defaultColor
              type="submit"
              fullWidth={false}
              style={{ textTransform: "uppercase" }}>
              Confirmar {isEditing ? "Edição" : "Cadastro"}
            </ButtonText>
          </Grid>
        </Grid>
      </FormHolder>
    </Styles.ModalPadding>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.users;
  return {
    isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(ModalRegistry));
