import React from "react";
import { errorModal } from "../utils";

import { Fade, IconButton } from "@material-ui/core";
import {
  ButtonContained,
  ButtonText,
  Title,
  ThemeProvider,
} from "../../../components";
import { Styles } from "../styles";
import { Themes } from "../../../config";
function ModalContainer() {
  const [infos, setInfos] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const { title, confirmButton, exitButton } = infos;

  const handleModal = React.useCallback(
    (config) => {
      if (config.open) {
        setInfos(config.infos);
      }
      setOpen(config.open);
    },
    [setInfos, setOpen]
  );

  errorModal.setCallback(handleModal);
  return (
    <ThemeProvider theme={Themes.Light}>
      <Styles.StyledModal
        open={open}
        onClose={() => setOpen(false)}
        BackdropComponent={Styles.StyledBackdrop}
        aria-labelledby="Título do modal"
        aria-describedby="Descrição do modal"
        disableBackdropClick
      >
        <Fade in={open}>
          <Styles.Container>
            <Styles.ContentContainer>
              <Styles.HeaderContainer>
                <Title>{title}</Title>
                <IconButton onClick={errorModal.close}>
                  <Styles.Close />
                </IconButton>
              </Styles.HeaderContainer>
            </Styles.ContentContainer>

            {confirmButton ? (
              <ButtonContained {...confirmButton}>
                {confirmButton.label}
              </ButtonContained>
            ) : null}
            {exitButton ? (
              <ButtonText {...exitButton}>{exitButton.label}</ButtonText>
            ) : null}
          </Styles.Container>
        </Fade>
      </Styles.StyledModal>
    </ThemeProvider>
  );
}

export default React.memo(ModalContainer);
