import { paths } from "../navigation/navigate";
import Icons from "../config/icons";
import texts from "./texts";

const Titles = texts["pt-BR"];

const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login",
    },
    {
      navigationId: 1,
      path: paths.forgotPassword,
      name: "ForgotPasswordPage",
    },
    {
      navigationId: 3,
      path: paths.firstAccess,
      name: "FirstAccess",
    },
  ],
  logged: [
    {
      dontShow: true,
      pages: [
        {
          navigationId: 0,
          path: paths.home,
          name: "Home",
          working: true,
        },
        {
          navigationId: 1,
          path: paths.changePassword,
          name: "ChangePassword",
          working: true,
        },
        {
          navigationId: 2,
          path: paths.userRegister,
          name: "UserRegister",
          working: true,
        },
        {
          navigationId: 0,
          path: paths.generalFinancialIndicators,
          name: "GeneralIndicators",
          working: true,
        },
        {
          navigationId: 0,
          path: paths.managementIndicators,
          name: "ManagementIndicators",
          working: true,
        },

        {
          navigationId: 0,
          path: paths.accessHistory,
          name: "AccessHistory",
          working: true,
        },

        {
          navigationId: 0,
          path: paths.accessHistoryClients,
          name: "AccessHistoryClient",
          working: true,
        },

        {
          navigationId: 0,
          path: paths.userList,
          name: "Users",
          working: true,
        },
        {
          navigationId: 0,
          path: paths.profile,
          name: "Profile",
          title: Titles.Profile.title,
          icon: Icons.AccountEdit,
          showOnHeader: true,
          working: true,
        },
        {
          navigationId: 0,
          path: paths.companyAccess,
          name: "AccessRequests",
          working: true,
        },
        {
          navigationId: 0,
          path: paths.evaluatedReleases,
          name: "EvaluatedReleases",
          working: true,
        },
        {
          navigationId: 0,
          path: paths.plansApproval,
          name: "PlansApproval",
          working: true,
        },
        {
          navigationId: 0,
          name: "Premium",
          path: paths.premium,
          working: true,
        },
        {
          navigationId: 2,
          path: paths.contactUs,
          name: "ContactUs",
          working: true,
        },
      ],
    },
    {
      groupTitle: Titles.Menu.general,
      dontShow: false,
      pages: [
        {
          navigationId: 0,
          path: paths.generalFinancialIndicators,
          icon: Icons.ChartBubble,
          title: Titles.financialIndicators.title,
          name: "GeneralIndicators",
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
        {
          navigationId: 1,
          path: paths.home,
          icon: Icons.ChartSankey,
          title: Titles.managementIndicators.title,
          working: true,
          dontShow: true,
          showOnHeader: true,
        },
        {
          navigationId: 2,
          path: paths.home,
          iconFill: Icons.Star,
          title: Titles.premium.title,
          working: false,
          dontShow: true,
          contained: true,
          showOnHeader: false,
        },
      ],
    },
    {
      groupTitle: Titles.Menu.panel,
      dontShow: false,
      pages: [
        {
          navigationId: 0,
          path: paths.users,
          name: "Users",
          icon: Icons.AccountPlus,
          title: Titles.Users.title,
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
      ],
    },
    {
      groupTitle: Titles.Menu.fillingIndicators,
      dontShow: false,
      pages: [
        {
          navigationId: 0,
          path: paths.financialData,
          icon: Icons.FileChart,
          name: "FinancialData",
          title: Titles.financialData.title,
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
        {
          navigationId: 1,
          path: paths.managementData,
          icon: Icons.FileChart,
          name: "ManagementData",
          title: Titles.managementData.title,
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
        {
          navigationId: 1,
          path: paths.dataEntry,
          icon: Icons.FileChart,
          name: "DataEntry",
          title: Titles.dataEntry.title,
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
      ],
    },
    {
      groupTitle: Titles.Menu.system,
      dontShow: false,
      pages: [
        {
          navigationId: 0,
          path: "",
          title: "Notificações",
          working: false,
          dontShow: false,
          showOnHeader: true,
        },
        {
          navigationId: 1,
          path: "",
          title: "Meu Perfil",
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
        {
          navigationId: 2,
          path: paths.contactUs,
          // title: "Fale Conosco",
          name: "ContactUs",
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
        {
          navigationId: 3,
          path: "",
          title: "Sair",
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
      ],
    },
  ],
};
export default pagesConfig;
