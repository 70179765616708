import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import FontStyles from "../styles/fontsStyles";

import GridItemTable from "./GridItemTable";

const Table = styled.table(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    borderSpacing: spacing(0, 0.7),
    backgroundColor: "white",
  };
});

const HeaderRow = styled.tr(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.bold14,
    backgroundColor: "white",
    textTransform: "uppercase",
    padding: spacing(2),
    textAlign: "left",
    position: "sticky",
    top: 0,
    zIndex: 10,
  };
});
const HeaderItem = styled.th(({ theme, $first, $last }) => {
  const { spacing, palette: colors } = theme;
  return {
    fontWeight: "normal",
    padding: $first ? spacing(0, 1, 0, 3) : spacing(1),
    color: colors.text.secondary,
    display: $last ? "flex" : null,
    justifyContent: $last ? "flex-end" : "flex-start",

    whiteSpace: "normal",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  };
});

function TableDragDrop({
  items,
  setItems,
  renderItemColumns,
  updatePosition,
  isFetching,
  header,
}) {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    updatePosition(removed, endIndex);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const newitems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newitems);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div style={{ maxWidth: "100vw" }} ref={provided.innerRef}>
            <Table>
              <thead>
                <HeaderRow>
                  {header?.map((item, index) => {
                    return (
                      <HeaderItem
                        $first={index === 0 ? true : false}
                        $last={index === header.length - 1 ? true : false}
                      >
                        {item}
                      </HeaderItem>
                    );
                  })}
                </HeaderRow>
              </thead>
              <tbody>
                {items?.map((item, index) => (
                  <GridItemTable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                    item={item}
                    provided={provided}
                    snapshot={snapshot}
                    renderItemColumns={renderItemColumns}
                    isFetching={isFetching}
                  />
                ))}
              </tbody>
            </Table>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default React.memo(TableDragDrop);
