const colors = {
  // TODO/HINT REMOVER Cores projeto anterior
  greenSuccess: "#6EBe44",
  redError: "#F0166D",
  darkOpacity: "#00000020",

  // TODO/HINT pegar cores totais e mesclar aqui
  //
  //
  darkGray: "#303036",
  //
  lightGray: "#C5C5C5",
  white: "#FFFFFF",
  gray: "#F6F6F6",
  softGray: "#EBEBEB",
  grayDisabled: "#ECECEC",
  carbon: "#464646",
  black: "#000000",
  green: "#ACCA13",
  darkGreen: "#627502",
  subGray: "#A7A7A7",
};

export default colors;
