import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getCompanyData() {
  try {
    const response = yield call(api.getCompleteRegister, null);

    yield put(Creators.getCompanyDataSuccess(response));
  } catch (response) {
    Alerts.alertError("Não foi recuperar os dados do usuário");
    yield put(Creators.getCompanyDataFailure());
  }
}
