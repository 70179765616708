import createReducers from "../../../store/helpers/createPageReducer";
import getFinancialIndicators from "./getFinancialIndicators";
import getIndicatorsYear from "./getIndicatorsYear";
import sendFinancialIndicatorsRequest from "./sendFinancialIndicatorsRequest";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getFinancialIndicatorsRequest",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getFinancialIndicators,
    },
    {
      name: "getFinancialIndicatorsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataIndicators: data,
      }),
    },
    {
      name: "getFinancialIndicatorsFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getFinancialIndicatorsYear",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getIndicatorsYear,
    },
    {
      name: "getFinancialIndicatorsYearSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        years: data,
      }),
    },
    {
      name: "getFinancialIndicatorsYearFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "sendFinancialIndicatorsRequest",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: sendFinancialIndicatorsRequest,
    },
    {
      name: "sendFinancialIndicatorsSuccess",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "sendFinancialIndicatorsFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    dataIndicators: null,
    years: null,
  }
);

export { Creators, reducers, sagas };
