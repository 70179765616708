export default function getContactUsDetailed(response) {
  const data = {
      id: response.data.response.id,
      email: response.data.response.email,
      telephone: response.data.response.telephone,
      mondayToFridayBegin: response.data.response.mondayToFridayBegin,
      mondayToFridayEnd: response.data.response.mondayToFridayEnd,
      saturdayToSundayBegin: response.data.response.saturdayToSundayBegin,
      saturdayToSundayEnd: response.data.response.saturdayToSundayEnd,
    };
    return data;
  }
  