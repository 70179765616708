import React from "react";
import { Texts } from "../../../config";
import { Input, InputAutoComplete } from "../../../components";
import { Filters, Validations } from "../../../lib";
import { Grid } from "@material-ui/core";

function RegistryAssociated({ cities, UFList, cityListRequest, cnaes }) {
  const texts = Texts["pt-BR"];
  return (
    <>
      <Input
        autoFocus={true}
        variant="standard"
        name="username"
        required={texts.login.userMessage}
        inputLabel={texts.login.stepTwo.name}
        mask={(data) => Filters.maskName(data)}
        validation={(data) => Validations.isFullName(data)}
      />

      <Input
        variant="standard"
        name="companyName"
        required={texts.login.userMessage}
        inputLabel={texts.login.stepTwo.companyName}
      />

      <Input
        maxLength={4}
        name="foundationYear"
        required={texts.login.userMessage}
        inputLabel={"ANO DE FUNDAÇÃO DA EMPRESA"}
        validation={Validations.maxYear}
        mask={Filters.clearStringOnlyNumbers}
        maskToSubmit={Filters.clearStringOnlyNumbers}
      />

      <Input
        name="employeeNumber"
        inputLabel="Número de colaboradores"
        mask={(data) => Filters.convertNumberInputMask(data, false, false)}
        maskToSubmit={Filters.convertNumberTextMask}
        validation={Validations.isNumber}
        required={texts.login.userMessage}
      />

      <Input
        variant="standard"
        name="cnpj"
        required={texts.login.userMessage}
        inputLabel={texts.login.stepTwo.cnpj}
        mask={(data) => Filters.cnpjMask(data)}
        validation={Validations.isCNPJ}
        maskToSubmit={Filters.cnpjToRequest}
      />
      <InputAutoComplete
        inputLabel={texts.login.stepTwo.cnae}
        name="cnaeId"
        defaultValue={null}
        options={cnaes ? cnaes : []}
      />
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <InputAutoComplete
            defaultValue={null}
            inputLabel={texts.login.stepTwo.uf}
            name="uf"
            options={UFList ? UFList : []}
            required={texts.login.userMessage}
            onChange={(value, index) => {
              UFList?.find((element) => {
                if (element.value === index) {
                  return cityListRequest(element.ufName);
                }
                return null;
              });
            }}
            validation={(value) => {
              if (value !== -1) {
                return { isValid: true, errorMessage: "" };
              } else {
                return { isValid: false, errorMessage: "*Campo obrigatório." };
              }
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <InputAutoComplete
            inputLabel={texts.login.stepTwo.city}
            name="cityId"
            defaultValue={null}
            required={texts.login.userMessage}
            options={cities ? cities : []}
            validation={(value) => {
              if (value !== -1) {
                return { isValid: true, errorMessage: "" };
              } else {
                return { isValid: false, errorMessage: "*Campo obrigatório." };
              }
            }}
          />
        </Grid>
      </Grid>

      <Input
        variant="standard"
        name="telephone"
        inputLabel={texts.login.stepTwo.phone}
        mask={Filters.phoneMask}
        maskToSubmit={Filters.phoneToRequest}
        validation={(data) => Validations.isPhone(data)}
      />
    </>
  );
}

export default React.memo(RegistryAssociated);
