import React from "react";
import { ChartDescription } from "../../../../components";
import { Grid } from "@material-ui/core";
import { ChartsColors } from "../../../../config";
import { connect } from "react-redux";

function FooterChart({ data, $alternative, userData }) {
  return (
    <>
      {userData && userData?.role.includes("ACATE") ? (
        <Grid container>
          <Grid item>
            <ChartDescription
              color={ChartsColors.Acate.secondary}
              box={!$alternative}
              dotLine={$alternative}
            >
              Média das Empresas
            </ChartDescription>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item>
            <ChartDescription color={ChartsColors.Acate.primary}>
              Minha Empresa
            </ChartDescription>
          </Grid>
          <Grid item>
            <ChartDescription
              color={ChartsColors.Acate.secondary}
              box={!$alternative}
              dotLine={$alternative}
            >
              Média das Empresas
            </ChartDescription>
          </Grid>
        </Grid>
      )}
    </>
  );
}
function mapStateToProps(state) {
  const { userData } = state.global;
  return {
    userData,
  };
}

export default connect(mapStateToProps)(React.memo(FooterChart));
