import React from "react";
import { connect } from "react-redux";
import {
  Input,
  ButtonOutlined,
  ButtonContained,
  InputDate,
  Select,
  InputAutoComplete,
} from "../../../components";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import Styles from "../styles/Styles";
import { FormHolder } from "../../../FormConfig";
import { Texts } from "../../../config";

import { Validations } from "../../../lib";

function SearchCompanies({ isFetching, onSubmit, listCnpj }) {
  const texts = Texts["pt-BR"].companyAccess;
  const [formRef, setRef] = React.useState(null);
  return (
    <FormHolder onSubmit={onSubmit} formRef={setRef}>
      <Grid item xs={12}>
        <Styles.HeaderCard>
          <Styles.TitleCard>{texts.filterTitle}</Styles.TitleCard>
        </Styles.HeaderCard>
        <Styles.Line />
      </Grid>

      <Grid alignItems="flex-end" container spacing={1}>
        <Grid item xs={3}>
          <InputAutoComplete
            justNumbers
            inputLabel={texts.table.cnpj}
            name="cnpj"
            options={listCnpj ? listCnpj : []}
            placeholder={"Todos"}
          />
        </Grid>

        <Grid item xs={3}>
          <Input
            inputLabel={texts.table.companyName}
            name="companyName"
            placeholder="Enterprise Tec."
          />
        </Grid>

        <Grid item xs={3}>
          <InputAutoComplete
            inputLabel={texts.table.status}
            name="status"
            defaultValue={{
              label: "Pendente",
              value: "Pendente",
            }}
            options={[
              {
                label: "Todos",
                value: null,
              },
              {
                label: "Pendente",
                value: "Pendente",
              },
              {
                label: "Reprovado",
                value: "Reprovado",
              },
            ]}
            placeholder={"Todos"}
          />
        </Grid>

        <Grid item xs={3}>
          <Select
            $maxHeight
            alternativeColors
            inputLabel={texts.table.accessTypeRequested}
            name="accessTypeRequested"
            required="Selecione"
            defaultValue={-1}
            options={[
              { value: -1, label: "Todos" },
              {
                value: "Empresa Associada",
                label: texts.typeRequested.associate,
              },
              {
                value: "Empresa Não Associada",
                label: texts.typeRequested.notAssociate,
              },
              {
                value: "Empresa Não Associada Não Tech",
                label: texts.typeRequested.notAssociateTec,
              },
              { value: "Pesquisador", label: texts.typeRequested.researcher },
              {
                value: "Pessoa Física",
                label: texts.typeRequested.physicalPerson,
              },
            ]}
          />
        </Grid>

        <Grid item xs={4}>
          <Input
            inputLabel={texts.table.username}
            name="username"
            placeholder="Nome de Usuário"
          />
        </Grid>

        <Grid item xs={4}>
          <Input
            inputLabel={texts.table.email}
            name="email"
            placeholder="user@acate.com.br"
          />
        </Grid>

        <Grid container item xs={4}>
          <Grid xs={12}>
            <Styles.InputLabel>{texts.table.requestDate}</Styles.InputLabel>
          </Grid>
          <Grid xs={5}>
            <InputDate
              validation={Validations.isDATE}
              name="beginRequestDate"
              maxDate={new Date()}
            />
          </Grid>
          <Grid xs={2}>
            <Styles.BetweenDate>até</Styles.BetweenDate>
          </Grid>
          <Grid xs={5}>
            <InputDate
              validation={Validations.isDATE}
              name="endRequestDate"
              maxDate={new Date()}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        style={{ justifyContent: "flex-end" }}
      >
        <Grid item>
          <ButtonOutlined
            startIcon={<DeleteIcon />}
            fullWidth={false}
            loading={isFetching}
            onClick={() => {
              formRef?.clearAllValues();
              formRef.setValue("accessTypeRequested", -1);
            }}
          >
            {texts.table.clean}
          </ButtonOutlined>
        </Grid>
        <Grid item>
          <ButtonContained
            startIcon={<SearchIcon />}
            type="submit"
            fullWidth={false}
            loading={isFetching}
          >
            {texts.table.search}
          </ButtonContained>
        </Grid>
      </Grid>
    </FormHolder>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.companyAccess;
  return {
    isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(SearchCompanies));
