import { create } from "apisauce";
import caller from "./helpers/caller";
import jwt_decode from "jwt-decode";

import { SessionStorage } from "../lib";
import Models from "./models";
import Constants from "../config/constants";

const apiUrl = Constants.HML
  ? "https://devapi.acatehub.ianclive.com"
  : "https://api.acatehml.ianclive.com/";

const api = create({
  baseURL: apiUrl,
  timeout: 500000,
});

api.addRequestTransform((request) => {
  const token = SessionStorage.getItem("token");
  if (token) {
    request.headers.authorization = "Bearer " + token;
  }
});

api.addMonitor((response) => {
  const token = response.headers.authorization;
  if (token) {
    SessionStorage.setItem("token", token);
  }
});

async function getToken(loginData) {
  return caller(api.post, "/auth/sign_in", Models.getToken, loginData);
}

async function getUserInfos() {
  const decodedToken = jwt_decode(SessionStorage.getItem("token"));
  return caller(
    api.get,
    `/user/get_by_id?id=${decodedToken.user_id}`,
    Models.getUserInfos
  );
}

async function sendRecoveryPasswordRequest(data) {
  //request password reset
  return caller(api.post, `/password_reset/request?email=${data.email}`);
}
async function sendRecoveryPasswordNewPassword(data) {
  //password reset
  return caller(api.post, "/password_reset/reset", null, data);
}
async function recoveryPasswordCode(data) {
  //reset password code
  return caller(api.post, "/password_reset/code", null, data);
}

async function changePassword(data) {
  return caller(api.post, "/user/change_password", null, data);
}
async function createAccess(data) {
  return caller(
    api.post,
    "/user/insert",
    null,
    Models.sendCreateAccessModel(data)
  );
}

async function getAcesso() {
  return caller(api.get, "/user/current", Models.getUserInfos);
}

async function updateUserAccess(data) {
  return caller(
    api.post,
    "/user/update",
    null,
    Models.sendUpdateUserAccessModel(data)
  );
}

async function postRegistration(data) {
  return caller(api.post, `/auth/request_access`, null, data);
}

async function updatePassword(data) {
  return caller(api.patch, "/user/update_password", null, data);
}

async function getUsersList(data) {
  return caller(api.post, `/user/search`, Models.getUsersList, data);
}
async function getAccessHistory(data) {
  return caller(
    api.post,
    `/history_access/search`,
    Models.getAccessHistory,
    data
  );
}
async function getAccessHistoryClients(data) {
  return caller(
    api.post,
    `/history_access/search_company`,
    Models.getAccessHistoryClients,
    data
  );
}

async function postNewUser(data) {
  return caller(api.post, `/user/insert_company_user`, null, data);
}

async function postUpdateUser(data) {
  return caller(api.put, `/user/update_company_user`, null, data);
}

async function patchDeactivateUser(data) {
  return caller(api.patch, `/user/deactivate?userId=${data}`, null);
}

async function patchActivateUser(data) {
  return caller(api.patch, `/user/activate?userId=${data}`, null);
}

async function getCurrentUser() {
  //get logged user
  return caller(api.get, "/user/current", Models.getCurrentUser, null);
}

async function putCompleteRegister(data) {
  return caller(
    api.put,
    `/company/complete_register`,
    Models.getCompanyRegister,
    Models.CompleteRegister(data)
  );
}
async function putBasicRegister(data) {
  return caller(
    api.put,
    `/company/complete_register_simple`,
    Models.getCompanyRegister,
    Models.BasicRegister(data)
  );
}

async function getCompleteRegister(data) {
  return caller(api.get, `/company/get_register`, Models.getCompanyRegister);
}

//Inputs
async function getUF() {
  return caller(api.get, `/uf/all`, Models.getUF);
}
async function getCities(uf, meso) {
  if (uf === null) {
    return caller(api.get, `/city/find_all`, Models.getCities);
  } else if (meso === undefined) {
    return caller(api.get, `/city/find_all_by_uf?uf=${uf}`, Models.getCities);
  } else {
    return caller(
      api.get,
      `./city/find_all_by_meso_region/${meso}`,
      Models.getCities
    );
  }
}

async function getAllCities(uf) {
  return caller(api.get, `/city/find_all`, Models.getCities);
}

async function getMesoRegions(uf) {
  return caller(
    api.get,
    `./meso_region/find_all_by_uf_name/${uf}`,
    Models.getCities
  );
}

async function getCNAE() {
  return caller(api.get, `/cnae/all`, Models.getCNAE);
}

async function getVertical() {
  return caller(api.get, `/vertical/all`, Models.getCities, null, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}

async function getTypeOfSolution() {
  return caller(api.get, `/type_of_solution/all`, Models.getCities, null, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}

async function getSize() {
  return caller(api.get, `/size/all`, Models.getCities, null, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}

async function getSector() {
  return caller(api.get, `/sector/all`, Models.getCities, null, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}
async function getBusinessModel() {
  return caller(api.get, `/business_model/all`, Models.getCities, null, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}
async function getSustainability() {
  return caller(api.get, `/sustainability/all`, Models.getCities, null, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}
//Indicadores

async function getManagerIndicators(year) {
  return caller(
    api.get,
    `/indicator_type/managerial?year=${year}`,
    Models.getManagerIndicator,
    null
  );
}
async function getFinancialIndicators(year) {
  return caller(
    api.get,
    `/indicator_type/finantial?year=${year}`,
    Models.getManagerIndicator,
    null
  );
}
async function getManagementIndicators(year) {
  return caller(
    api.get,
    `/indicator_type/managerial?year=${year}`,
    Models.getManagerIndicator,
    null
  );
}

async function getIndicatorsYear(id) {
  return caller(
    api.get,
    `/company_indicator/years/${id}`,
    Models.getIndicatorsYear,
    null
  );
}

async function sendFinancialIndicators(data) {
  return caller(api.post, `/company_indicator/answer`, null, data);
}

async function getCompanyAccess(data) {
  return caller(
    api.post,
    `/user/company_access`,
    Models.getCompanyAccess,
    data
  );
}

async function patchApproveAccess(userId) {
  return caller(api.patch, `/user/approve_access/${userId}`, null);
}

async function patchDisapproveAccess(userId) {
  return caller(api.patch, `/user/disapprove_access/${userId}`, null);
}

async function patchApproveRelease(userId) {
  return caller(
    api.patch,
    `/company/grant_manager_access?company_id=${userId}`,
    null
  );
}

async function patchRevokeAccess(userId) {
  return caller(
    api.patch,
    `/company/revoke_manager_access?company_id=${userId}`,
    null
  );
}

async function getFinantialList() {
  return caller(
    api.get,
    `/indicator_type/finantial_simple`,
    Models.getIndicatorOrder,
    null
  );
}

async function getManagerialList() {
  return caller(
    api.get,
    `/indicator_type/managerial_simple`,
    Models.getIndicatorOrder,
    null
  );
}
async function putIndicatorPosition(data) {
  return caller(api.put, `/indicator_group/change_position`, null, data);
}

async function postNewIndicator(data) {
  return caller(api.post, `/indicator_type/insert_indicator`, null, data);
}

async function postUpdateIndicator(data) {
  return caller(api.post, `/indicator_type/update_indicator`, null, data);
}

async function getEvaluatedReleases(data) {
  return caller(
    api.post,
    `/user/evaluated_releases`,
    Models.getEvaluatedReleases,
    data
  );
}

async function postManagerialFilters(data) {
  return caller(
    api.post,
    `/company_indicator/managerial`,
    Models.getManagerialData,
    data
  );
}

async function postFinantialFilters(data) {
  return caller(
    api.post,
    `/company_indicator/finantial`,
    Models.getFinancialData,
    data
  );
}
async function postExportIndicators(data) {
  return caller(
    api.post,
    `/company_indicator/export`,
    Models.getExportIndicators,
    data
  );
}

async function getFinantialTooltips() {
  return caller(api.get, `/indicator_group/finantial_tooltip`, null, null);
}
async function getManageriaTooltips() {
  return caller(api.get, `/indicator_group/managerial_tooltip`, null, null);
}
async function getApprovalList(data) {
  return caller(
    api.post,
    `/plan_request/pagination`,
    Models.getPlansApproval,
    data
  );
}
async function postNewPlan(planId) {
  return caller(api.post, `/plan_request/insert/${planId}`, null, null);
}

async function putApprovePlan(planId) {
  return caller(api.put, `/plan_request/approve/${planId}`, null, null);
}
async function putDisapprovePlan(planId) {
  return caller(api.put, `/plan_request/disapprove/${planId}`, null, null);
}
async function getPlanTec() {
  return caller(api.get, `/plan/not_associate`, null, null);
}

async function getIncubator() {
  return caller(api.get, `/incubator/find_all`, null, null);
}

async function getPlanNotTec() {
  return caller(api.get, `/plan/not_associate_technology`, null, null);
}
async function newManagerRequest(id) {
  return caller(api.put, `/user/change_manager/${id}`, null, null);
}

async function putContactUsUpdate(data) {
  return caller(api.put, `/contact_us/update`, null, data);
}

async function getContactUsDetailed() {
  return caller(
    api.get,
    `/contact_us/contact_detailed`,
    Models.getContactUsDetailed,
    null
  );
}

async function getContactUsInformation() {
  return caller(
    api.get,
    `/contact_us/contact_information`,
    Models.getContactUsInformation,
    null
  );
}

async function getPlansList() {
  return caller(api.get, `/plan/all`, Models.getCities, null);
}

async function exportUserList(filter) {
  return caller(
    api.post,
    `/user/evaluated_releases_download`,
    Models.exportUsers,
    filter
  );
}

async function getOffice() {
  return caller(api.get, `/office/find_all`, Models.getOffice, null, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}
async function getCountries() {
  return caller(api.get, "/country/find_all", Models.getOffice);
}
async function emailOnUse(data) {
  return caller(api.get, "/user/has_email_available", null, data);
}
async function getCNPJ() {
  return caller(api.get, "/company/find_all_cnpjs", Models.getCnpj, null, {
    headers: { "Content-Type": "application/json" },
  });
}

const apiServices = {
  getCNPJ,
  getToken,
  getUserInfos,
  sendRecoveryPasswordRequest,
  sendRecoveryPasswordNewPassword,
  recoveryPasswordCode,
  changePassword,
  createAccess,
  getAcesso,
  updateUserAccess,
  getUF,
  getCities,
  getAllCities,
  getCNAE,
  postRegistration,
  updatePassword,
  getUsersList,
  postNewUser,
  postUpdateUser,
  patchDeactivateUser,
  patchActivateUser,
  getCompleteRegister,
  putCompleteRegister,
  getVertical,
  getTypeOfSolution,
  getSize,
  getSector,
  getSustainability,
  getBusinessModel,
  putBasicRegister,
  getManagerIndicators,
  getFinancialIndicators,
  getIndicatorsYear,
  sendFinancialIndicators,
  getManagementIndicators,
  getCurrentUser,
  getAccessHistory,
  getFinantialList,
  getManagerialList,
  putIndicatorPosition,
  postNewIndicator,
  postUpdateIndicator,
  getCompanyAccess,
  patchApproveAccess,
  patchDisapproveAccess,
  getMesoRegions,
  postManagerialFilters,
  postFinantialFilters,
  getEvaluatedReleases,
  postExportIndicators,
  getFinantialTooltips,
  getManageriaTooltips,
  getApprovalList,
  postNewPlan,
  putApprovePlan,
  putDisapprovePlan,
  newManagerRequest,
  putContactUsUpdate,
  getContactUsDetailed,
  getContactUsInformation,
  getPlanTec,
  getPlanNotTec,
  getPlansList,
  exportUserList,
  getOffice,
  getIncubator,
  getCountries,
  getAccessHistoryClients,
  emailOnUse,
  patchApproveRelease,
  patchRevokeAccess,
};

export default apiServices;
