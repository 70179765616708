import React from "react";
import { connect } from "react-redux";
import { ButtonText, FontStyles } from "../../../components";
import { Grid } from "@material-ui/core";
import Styles from "../styles/Styles";

import { customModal } from "../../modals/utils";
import { navigateTo } from "../../../navigation/navigate";
import { Texts } from "../../../config";
function FirstAcessNotification({ email, profile }) {
  const texts = Texts["pt-BR"].notification;

  return (
    <Styles.ModalPadding>
      <Grid
        style={{ marginBlock: "1em" }}
        container
        direction="row"
        justify="flex-end">
        <Grid item style={{ width: "fit-content" }}>
          <ButtonText
            $defaultColor
            onClick={() => {
              customModal.close();
              window.localStorage.setItem(
                `${email}-firstNotificationDisable`,
                JSON.stringify("true")
              );
              profile ? navigateTo.Profile() : navigateTo.FinancialData();
            }}
            fullWidth={false}
            style={{ textTransform: "uppercase", ...FontStyles.medium16 }}>
            {texts.ok}
          </ButtonText>
        </Grid>
      </Grid>
    </Styles.ModalPadding>
  );
}

export default connect()(React.memo(FirstAcessNotification));
