export default function getUF(response) {
  const data = response.data.response.map((item) => {
    return {
      value: item.id,
      label: item.initials,
      ufName: item?.name,
    };
  });
  return data;
}
