import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* postNewIndicator({ data, callback }) {
  try {
    yield call(api.postNewIndicator, data);
    yield put(Creators.postNewIndicatorSuccess());
    Alerts.alertSuccess("Indicador cadastrado com sucesso!");
    callback();
  } catch (response) {
    Alerts.alertError("Não foi possível salvar os indicadores.");
    yield put(Creators.postNewIndicatorFailure());
  }
}
