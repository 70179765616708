import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";

import { Select, TableDragDrop, Tolltip, Table } from "../../../components";

import { Grid } from "@material-ui/core";
import { FormHolder } from "../../../FormConfig";
import { Creators } from "../reduxSagas";
import { Texts } from "../../../config";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CreateIcon from "@material-ui/icons/Create";
import AddIcon from "@material-ui/icons/Add";
import { customModal } from "../../modals/utils";
import ModalRegistry from "../components/ModalRegistry";
function DataEntry({
  isFetching,
  getManagerialListRequest,

  getFinantialListRequest,
  finantialList,
  managerialList,
  sendIndicatorPositionRequest,
  finantialGroupList,
  manageriaGroupList,
  postNewIndicator,
  postUpdateIndicator,
}) {
  const texts = Texts["pt-BR"].dataEntry;
  const [list, setList] = React.useState([]);
  const [groupId, setGroupId] = React.useState(1);
  const [change, setChange] = React.useState(null);
  const types = [
    { value: "PERCENTAGE", label: "Porcentagem (%)" },
    { value: "INTEGER", label: "Inteiro" },
    { value: "MONEY", label: "Moeda (R$)" },
  ];
  React.useEffect(() => {
    getManagerialListRequest();
    getFinantialListRequest();
  }, [getManagerialListRequest, getFinantialListRequest]);

  React.useEffect(() => {
    if (groupId === 1) {
      setList(finantialList);
    } else {
      setList(managerialList);
    }
  }, [finantialList, managerialList, groupId]);

  const ChangeList = (value) => {
    setChange(null);
    if (value === 1) {
      setList(managerialList);
      setGroupId(2);
    } else {
      setList(finantialList);
      setGroupId(1);
    }
  };

  const modalNewIndicator = () => {
    customModal.setInfos(
      `${texts.newTitle} ${groupId === 1 ? "FINANCEIRO" : "GERENCIAL"}`,
      texts.newSub,
      null,
      null,
      <ModalRegistry
        newIndicator={(data) => {
          const requestBody = {
            name: data.name,
            valueType: data.valueType,
            valueLabel:
              data.valueType === "PERCENTAGE"
                ? "%"
                : data.valueType === "MONEY"
                ? "R$"
                : "",
            required: data.required,
            notApplicable: data.notApplicable,
            indicatorGroupId:
              data.group !== "new" ? parseInt(data.group) : null,
            indicatorTypeId: groupId,
            indicatorGroup:
              data.group !== "new"
                ? null
                : { name: data.groupName, tip: data.tip },
          };
          postNewIndicator(
            requestBody,
            groupId === 1
              ? () => {
                  getFinantialListRequest();
                  setChange(null);
                }
              : () => {
                  getManagerialListRequest();
                  setChange(null);
                }
          );
          customModal.close();
        }}
        groups={groupId === 1 ? finantialGroupList : manageriaGroupList}
      />
    );
  };

  const modalUpdateIndicator = (input) => {
    customModal.setInfos(
      `${texts.editTitle} ${groupId === 1 ? "FINANCEIRO" : "GERENCIAL"}`,
      texts.editSub,
      null,
      null,
      <ModalRegistry
        isEditing
        inputData={input}
        newIndicator={(data) => {
          const requestBody = {
            id: input.id,
            name: data.name,
            valueType: data.valueType,
            valueLabel:
              data.valueType === "PERCENTAGE"
                ? "%"
                : data.valueType === "MONEY"
                ? "R$"
                : "",
            required: data.required,
            notApplicable: data.notApplicable,
            indicatorGroupId: data.group,
            indicatorTypeId: groupId,
            indicatorGroup: null,
          };
          postUpdateIndicator(
            requestBody,
            groupId === 1
              ? () => {
                  getFinantialListRequest();
                  setChange(null);
                }
              : () => {
                  getManagerialListRequest();
                  setChange(null);
                }
          );
          customModal.close();
        }}
        groups={groupId === 1 ? finantialGroupList : manageriaGroupList}
      />
    );
  };

  const updatePosition = (data, position) => {
    const requestBody = {
      indicatorTypeId: groupId,
      indicatorGroupId: data.id,
      newPosition: position + 1,
    };
    sendIndicatorPositionRequest(requestBody);
  };

  const getType = (item) => {
    const type = types.find((data) => data.value === item);
    return type?.label;
  };

  return (
    <Styles.Container>
      <FormHolder onSubmit={() => {}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Styles.CardContainer>
              <Styles.CardHeader>
                <Styles.CardTitle>{texts.send}</Styles.CardTitle>
              </Styles.CardHeader>
              <Styles.Line />

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Select
                    disableError
                    variant="standard"
                    name="year"
                    inputLabel={texts.category}
                    defaultValue={0}
                    onChange={ChangeList}
                    options={[
                      { value: 0, label: texts.indicatorTypes[0] },
                      { value: 1, label: texts.indicatorTypes[1] },
                    ]}
                  />
                </Grid>
              </Grid>
            </Styles.CardContainer>
          </Grid>
          {change !== null ? (
            <Grid item xs={12}>
              <Styles.CardContainer>
                <Styles.CardHeader>
                  <Styles.Back
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => setChange(null)}
                  >
                    {texts.back}
                  </Styles.Back>
                  <Styles.NewIndicator
                    onClick={modalNewIndicator}
                    fullWidth={false}
                    loading={isFetching}
                    startIcon={<AddIcon style={{ margin: 0 }} />}
                  >
                    {texts.newIndicator}
                  </Styles.NewIndicator>
                </Styles.CardHeader>
                <Styles.CardTitle>
                  {texts.indicatorDatails} - {change?.name}
                </Styles.CardTitle>
                <Styles.Line />
                <Table
                  headers={{
                    table: texts.indicatorTable,
                  }}
                  data={change.inputList}
                  renderItemColumns={(input) => [
                    input.label,
                    getType(input.valueType),

                    input.required ? "Sim" : "Não",
                    input.notApplicable ? "Sim" : "Não",

                    <Styles.DeleteUser
                      $secondColor
                      startIcon={<CreateIcon style={{ margin: 0 }} />}
                      onClick={() =>
                        modalUpdateIndicator({ ...input, group: change.group })
                      }
                      fullWidth={false}
                    />,
                  ]}
                />
              </Styles.CardContainer>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Styles.CardContainer>
                <Styles.CardHeader>
                  <Styles.CardTitle>{texts.list}</Styles.CardTitle>
                  <Styles.NewIndicator
                    onClick={modalNewIndicator}
                    fullWidth={false}
                    loading={isFetching}
                    disabled={!finantialGroupList || !manageriaGroupList}
                    startIcon={<AddIcon style={{ margin: 0 }} />}
                  >
                    {texts.newIndicator}
                  </Styles.NewIndicator>
                </Styles.CardHeader>
                <Styles.Line />
                {list !== null ? (
                  <TableDragDrop
                    header={texts.groupTable}
                    isFetching={isFetching}
                    items={list}
                    setItems={setList}
                    renderItemColumns={(item, index) => [
                      item.label,
                      <>
                        <Tolltip
                          title={item.label}
                          tip={item.tip}
                          Odd={false}
                          id={item.position}
                          lastRow={false}
                          center={true}
                        />
                      </>,

                      <Styles.DeleteUser
                        $secondColor
                        startIcon={<VisibilityIcon style={{ margin: 0 }} />}
                        onClick={() =>
                          setChange({
                            inputList: item.inputs,
                            group: item.id,
                            name: item.label,
                          })
                        }
                        fullWidth={false}
                      />,
                    ]}
                    updatePosition={updatePosition}
                  />
                ) : null}
              </Styles.CardContainer>
            </Grid>
          )}
        </Grid>
      </FormHolder>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isFetching}
        transitionDuration={{ enter: 0, exit: 1000 }}
        message={
          <>
            <CircularProgress size={14} /> {texts.saving}
          </>
        }
      />
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const {
    isFetching,
    finantialList,
    managerialList,
    finantialGroupList,
    manageriaGroupList,
  } = state.dataEntry;
  return {
    isFetching,
    finantialList,
    managerialList,
    finantialGroupList,
    manageriaGroupList,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getFinantialListRequest,
    getManagerialListRequest,
    sendIndicatorPositionRequest,
    postNewIndicator,
    postUpdateIndicator,
  } = Creators;
  return {
    getFinantialListRequest: function () {
      return dispatch(getFinantialListRequest());
    },
    getManagerialListRequest: function () {
      return dispatch(getManagerialListRequest());
    },
    sendIndicatorPositionRequest: function (data) {
      return dispatch(sendIndicatorPositionRequest(data));
    },
    postNewIndicator: function (data, callback) {
      return dispatch(postNewIndicator(data, callback));
    },
    postUpdateIndicator: function (data, callback) {
      return dispatch(postUpdateIndicator(data, callback));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DataEntry));
