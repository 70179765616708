import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { Creators } from "../index";
import { Alerts } from "../../../../lib";

export default function* getTypeOfSolution() {
  try {
    const typeOfSolution = yield call(api.getTypeOfSolution, null);

    yield put(Creators.getTypeOfSolutionSuccess(typeOfSolution));
  } catch (response) {
    Alerts.alertError("Não foi encontrar os tipos de soluções");
    yield put(Creators.getInputsFailure());
  }
}
