import React from "react";
import { connect } from "react-redux";
import { Select, Input, ButtonText, Switch } from "../../../components";
import { Grid } from "@material-ui/core";
import Styles from "../styles/Styles";
import { FormHolder } from "../../../FormConfig";
import { customModal } from "../../modals/utils";
import { Filters } from "../../../lib";
import { Texts } from "../../../config";
function ModalRegistry({ newIndicator, isEditing, inputData, groups }) {
  const [ref, setRef] = React.useState(null);
  const [newGroup, setNew] = React.useState(false);
  const texts = Texts["pt-BR"].dataEntry.modal;
  React.useEffect(() => {
    if (!newGroup && ref !== null) {
      ref.removeInput("groupName");
      ref.removeInput("tip");
    }
  }, [newGroup, ref]);

  return (
    <Styles.ModalPadding>
      <FormHolder onSubmit={newIndicator} formRef={setRef}>
        <Input
          inputLabel={texts.name}
          name="name"
          placeholder={"Insira um título"}
          defaultValue={inputData ? inputData.label : null}
          required={texts.inputReq[1]}
          maskToSubmit={Filters.maskName}
        />
        <Select
          alternativeColors
          maxHeight
          placeholder={"Selecione uma categoria"}
          inputLabel={texts.group}
          name="group"
          required={texts.inputReq[0]}
          defaultValue={inputData ? inputData.group : groups[0]?.value}
          options={[
            ...groups,
            { value: "new", label: "+ Criar Nova Categoria" },
          ]}
          onChange={(value) =>
            value === "new" ? setNew(true) : newGroup ? setNew(false) : {}
          }
        />
        {newGroup ? (
          <>
            <Input
              name="groupName"
              inputLabel={texts.groupName}
              placeholder={"Insira um nome"}
              required={texts.inputReq[1]}
            />

            <Input
              minHeight
              name="tip"
              placeholder={"Insira um descrição"}
              inputLabel={
                <>
                  {texts.tip}
                  <Styles.InfoIcon />
                </>
              }
              multiline={true}
              required={texts.inputReq[1]}
              inputProps={{
                maxlength: 100,
              }}
              count={100}
            />
          </>
        ) : null}
        <Select
          alternativeColors
          maxHeight
          placeholder={"Selecione uma categoria"}
          inputLabel={texts.valueType}
          name="valueType"
          required={texts.inputReq[0]}
          defaultValue={inputData ? inputData.valueType : "PERCENTAGE"}
          options={[
            { value: "PERCENTAGE", label: "Porcentagem (%)" },
            { value: "INTEGER", label: "Inteiro" },
            { value: "MONEY", label: "Moeda (R$)" },
          ]}
        />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6} style={{ alignItems: "flex-end", display: "flex" }}>
            <Switch
              label={texts.required}
              name="required"
              defaultValue={inputData ? inputData.required : false}
            />
          </Grid>
          <Grid item xs={6} style={{ alignItems: "flex-end", display: "flex" }}>
            <Switch
              label={texts.notApplicable}
              name="notApplicable"
              defaultValue={inputData ? inputData.notApplicable : false}
            />
          </Grid>
        </Grid>

        <Grid
          style={{ marginBlock: "1em" }}
          container
          direction="row"
          justify="flex-end"
        >
          <Grid item style={{ width: "fit-content" }}>
            <ButtonText
              onClick={() => customModal.close()}
              fullWidth={false}
              style={{ textTransform: "uppercase" }}
            >
              {texts.cancel}
            </ButtonText>
          </Grid>

          <Grid item style={{ width: "fit-content" }}>
            <ButtonText
              $defaultColor
              type="submit"
              fullWidth={false}
              style={{ textTransform: "uppercase" }}
            >
              {isEditing ? texts.confirmEdit : texts.confirmNew}
            </ButtonText>
          </Grid>
        </Grid>
      </FormHolder>
    </Styles.ModalPadding>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.users;
  return {
    isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(ModalRegistry));
