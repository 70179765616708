import React from "react";
import { connect } from "react-redux";
import Styles from "../styles/Styles";
import { Pagination, Table } from "../../../components";
import { Texts } from "../../../config";
import { Filters } from "../../../lib";
import { Cancel, Check } from "@material-ui/icons";

function ReleasesList({
  evaluatedReleases,
  page,
  setPage,
  totalPages = 3,
  disapproveRelease,
  approveRelease,
}) {
  const texts = Texts["pt-BR"].evaluatedReleases;

  const typeRequested = (type) => {
    if (type.includes("ROLE_ASSOCIATE")) {
      return texts.typeRequested.associate;
    } else if (type.includes("ROLE_NOT_ASSOCIATE")) {
      return texts.typeRequested.notAssociate;
    } else if (type.includes("ROLE_RESEARCHER")) {
      return texts.typeRequested.researcher;
    } else if (type.includes("ROLE_PHYSICAL_PERSON")) {
      return texts.typeRequested.physicalPerson;
    } else {
      return texts.typeRequested.others;
    }
  };

  const haveButton = (status, id) => {
    if (status) {
      return (
        <Styles.ActionButton
          startIcon={<Cancel style={{ width: 15 }} />}
          fullWidth={false}
          onClick={() => disapproveRelease(id)}
        >
          {texts.table.deny}
        </Styles.ActionButton>
      );
    } else {
      return (
        <Styles.ActionButton
          startIcon={<Check style={{ width: 15 }} />}
          fullWidth={false}
          onClick={() => approveRelease(id)}
        >
          {texts.table.approve}
        </Styles.ActionButton>
      );
    }
  };

  return (
    <>
      <Styles.Content>
        <Styles.HeaderCard>
          <Styles.TitleCard>{texts.table.title}</Styles.TitleCard>
        </Styles.HeaderCard>
        <Styles.Line />
        <Styles.ScrollContainer>
          <Table
            headers={{
              table: texts.table.headers,
            }}
            data={evaluatedReleases}
            renderItemColumns={(item) => [
              !item?.cnpj ? "-" : Filters.cnpjMask(item.cnpj),
              item.companyName === null ? (
                "-"
              ) : (
                <span style={{ textTransform: "capitalize" }}>
                  {item.companyName.toLowerCase()}
                </span>
              ),
              item.incubatorId !== null ? item.incubatorId : "N/A",
              typeRequested(item.accessTypeRequested),
              item.username,
              item?.office ? item?.office?.name : "-",
              item.email,
              item.releasedDate === null
                ? "-"
                : Filters.formatDate(item.releasedDate),

              item.isActive ? "Ativo" : "Revogado",
              item.plan,

              item?.employeeNumber ? item?.employeeNumber : "-",

              haveButton(item.isActive, item.id),
            ]}
          />
        </Styles.ScrollContainer>

        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Styles.Content>
    </>
  );
}

export default connect()(React.memo(ReleasesList));
