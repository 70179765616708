import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { Creators } from "../index";
import { Alerts } from "../../../../lib";

export default function* getVerticals() {
  try {
    const verticals = yield call(api.getVertical, null);
    yield put(Creators.getVerticalsSuccess(verticals));
  } catch (response) {
    Alerts.alertError("Não foi encontrar os valores das verticais");
    yield put(Creators.getInputsFailure());
  }
}
