import { reducers as globalReducers } from "./globalReduxSagas";
import { reducers as loginReducers } from "./login";
import { reducers as firstAccessReducers } from "./firstAccess";
import { reducers as recoveryPasswordReducers } from "./forgotPassword";
import { reducers as profileReducers } from "./Profile";
import { reducers as financialDataReducers } from "./financialData";
import { reducers as managementDataReducers } from "./managementData";
import { reducers as usersReducers } from "./users";
import { reducers as accessHistoryReducers } from "./accessHistory";
import { reducers as accessHistoryClientsReducers } from "./accessHistoryClients";

import { reducers as dataEntryReducers } from "./dataEntry";
import { reducers as companyAccessReducers } from "./companyAccess";
import { reducers as generalReducers } from "./General";
import { reducers as evaluatedReleasesReducers } from "./evaluatedReleases";
import { reducers as contactUsReducers } from "./contactUsAcate";
import { reducers as plansApprovalReducers } from "./plansApproval";
import { reducers as premiumReducers } from "./premium";
const reducers = {
  global: globalReducers,
  login: loginReducers,
  recoveryPassword: recoveryPasswordReducers,
  profile: profileReducers,
  registry: firstAccessReducers,
  users: usersReducers,
  financialData: financialDataReducers,
  managementData: managementDataReducers,
  accessHistory: accessHistoryReducers,
  companyAccess: companyAccessReducers,
  dataEntry: dataEntryReducers,
  evaluatedReleases: evaluatedReleasesReducers,
  plansApproval: plansApprovalReducers,
  premium: premiumReducers,
  ...generalReducers,
  contactUs: contactUsReducers,
  accessHistoryClients: accessHistoryClientsReducers,
};

export default reducers;
