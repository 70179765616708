import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* sendIndicatorPositionRequest({ data }) {
  try {
    yield call(api.putIndicatorPosition, data);
    yield put(Creators.sendIndicatorPositionSuccess());
  } catch (response) {
    Alerts.alertError("Não foi possível salvar os indicadores.");
    yield put(Creators.sendFinancialIndicatorsFailure());
  }
}
