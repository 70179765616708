import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* cityListRequest({ data, meso }) {
  try {
    let city = null;

    if (data !== null && data !== -1) {
      city = data;
    } else if (data === -1) {
      city = SessionStorage.getItem("lastUf");
    }
    SessionStorage.setItem("lastUf", city);
    const response = yield call(api.getCities, city, meso);
    yield put(Creators.cityListListSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao encontra cidades.");
    yield put(Creators.cityListFailure());
  }
}
