import React from "react";
import Styles from "../styles/Styles";
import { Grid } from "@material-ui/core";
import { Spacing, Texts } from "../../../config";
import { connect } from "react-redux";
import { Creators } from "../../contactUsAcate/reduxSagas";
import { Filters } from "../../../lib";

function ContactUs({
  getContactUsInformation,
  contactUsInformation,
  isFetching,
}) {
  const texts = Texts["pt-BR"].contactUs.modal;

  React.useEffect(() => {
    getContactUsInformation();
  }, [getContactUsInformation]);

  return (
    <Styles.ContactContent>
      {!isFetching && contactUsInformation ? (
        <Grid container>
          <Grid container direction="row" xs={12} sm={12} md={7} lg={7}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Styles.TitleModal>{texts.email}</Styles.TitleModal>
              <Styles.InfoModal>
                {contactUsInformation?.contactUsInformation?.email}
              </Styles.InfoModal>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Styles.TitleModal>{texts.time}</Styles.TitleModal>
              <ul style={{ paddingLeft: Spacing(3.0) }}>
                <Styles.GreenBullet>
                  <Styles.InfoModal>
                    {contactUsInformation?.contactUsInformation?.mondayToFriday}
                  </Styles.InfoModal>
                </Styles.GreenBullet>
                {!contactUsInformation?.contactUsInformation
                  ?.saturdayToSunday ? (
                  <></>
                ) : (
                  <Styles.GreenBullet>
                    <Styles.InfoModal>
                      {
                        contactUsInformation?.contactUsInformation
                          ?.saturdayToSunday
                      }
                    </Styles.InfoModal>
                  </Styles.GreenBullet>
                )}
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Styles.TitleModal>{texts.phone}</Styles.TitleModal>
            <Styles.InfoModal>
              {Filters.phoneMask(
                contactUsInformation?.contactUsInformation?.telephone
              )}
            </Styles.InfoModal>
          </Grid>
        </Grid>
      ) : null}
    </Styles.ContactContent>
  );
}

function mapStateToProps(state) {
  const { contactUsInformation, isFetching } = state.contactUs;
  return {
    contactUsInformation,
    isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  const { getContactUsInformation } = Creators;
  return {
    getContactUsInformation: function (data) {
      return dispatch(getContactUsInformation(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ContactUs));
