import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* sendFinancialIndicatorsRequest({ data }) {
  try {
    const response = yield call(api.sendFinancialIndicators, data);
    yield put(Creators.sendFinancialIndicatorsSuccess());
    Alerts.alertSuccess(response?.data?.message);
    yield put(Creators.getFinancialIndicatorsRequest(data.year));
    yield put(Creators.getFinancialIndicatorsYear());
  } catch (response) {
    Alerts.alertError("Não foi possível salvar os indicadores.");
    yield put(Creators.sendFinancialIndicatorsFailure());
  }
}
