import React from "react";
import { ButtonContained } from "../../../components";
import Styles from "../styles/Styles";
import Grid from "@material-ui/core/Grid";
import { FormHolder } from "../../../FormConfig";

import { Texts } from "../../../config";
import { Creators as CreatorsRegistry } from "../../firstAccess/reduxSagas";
import { Input, InputTags, Select } from "../../../components";
import { Filters, Validations } from "../../../lib";
import { connect } from "react-redux";

function CompleteData({
  isFetching,
  setForm,
  setCompleteData,
  onSubmit,
  inputs,
  companyData,
  formBasic,
}) {
  const texts = Texts["pt-BR"];
  return (
    <Styles.CardContainer>
      <Grid container>
        <Grid item xs={6}>
          <Styles.ButtonsNavigation
            Second
            onClick={() => setCompleteData(false)}
          >
            Dados Básicos
          </Styles.ButtonsNavigation>
        </Grid>
        <Grid item xs={6}>
          <Styles.ButtonsNavigation onClick={() => setCompleteData(true)}>
            Dados Completos
          </Styles.ButtonsNavigation>
        </Grid>
      </Grid>

      <Styles.CardHeader>
        <Styles.CardTitle>Completar Cadastro</Styles.CardTitle>
      </Styles.CardHeader>
      <Styles.Line />

      <FormHolder onSubmit={onSubmit} formRef={setForm}>
        <Grid container spacing={1}>
          <Grid item xs={Boolean(companyData?.employeeNumber) ? 6 : 12}>
            <Input
              variant="standard"
              name="responsibleName"
              nextInput="charge"
              required={texts.login.userMessage}
              inputLabel={"NOME DO RESPONSÁVEL"}
              defaultValue={companyData?.responsableName}
              validation={Validations.isFullName}
              maskToSubmit={Filters.maskName}
            />
          </Grid>

          {Boolean(companyData?.employeeNumber) && (
            <Grid item xs={6}>
              <Input
                variant="standard"
                name="employeeNumber"
                nextInput="charge"
                required={texts.login.userMessage}
                inputLabel={"Nº DE COLABORADORES"}
                defaultValue={companyData?.employeeNumber}
                mask={Filters.clearStringOnlyNumbers}
                maskToSubmit={Filters.clearStringOnlyNumbers}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <Select
              variant="standard"
              name="charge"
              nextInput="phone"
              defaultValue={companyData?.responsableOffice?.value}
              required={texts.login.userMessage}
              inputLabel={"CARGO DO RESPONSÁVEL"}
              options={inputs?.office ? inputs?.office : []}
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              variant="standard"
              name="phone"
              nextInput="foundationYear"
              required={texts.login.userMessage}
              inputLabel={"TELEFONE CELULAR"}
              mask={Filters.phoneMask}
              maskToSubmit={Filters.phoneToRequest}
              validation={(data) => Validations.isPhone(data)}
              defaultValue={companyData?.responsableTelephone}
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              maxLength={4}
              name="foundationYear"
              required={texts.login.userMessage}
              inputLabel={"ANO DE FUNDAÇÃO DA EMPRESA"}
              validation={Validations.maxYear}
              mask={Filters.clearStringOnlyNumbers}
              maskToSubmit={Filters.clearStringOnlyNumbers}
              defaultValue={companyData?.foundationYear}
            />
          </Grid>

          <Grid item xs={6}>
            <InputTags
              inputLabel={"MODELO DE NEGÓCIO"}
              name="businessModel"
              autoComplete="off"
              required={texts.login.userMessage}
              options={inputs?.businessModel ? inputs?.businessModel : []}
              defaultValue={companyData?.businessModels}
              tooltip={{
                title: "Dica!",
                content: "Selecione um ou mais de um valor.",
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <InputTags
              inputLabel={"TIPO DE SOLUÇÃO"}
              name="solutionType"
              autoComplete="off"
              required={texts.login.userMessage}
              options={inputs?.typeOfSolution ? inputs?.typeOfSolution : []}
              defaultValue={companyData?.typeOfSolutions}
              tooltip={{
                title: "Dica!",
                content: "Selecione um ou mais de um valor.",
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Select
              $maxHeight
              inputLabel={"PORTE DA EMPRESA"}
              name="companyPort"
              required={texts.login.userMessage}
              options={inputs?.sizes ? inputs?.sizes : []}
              defaultValue={companyData?.size.value}
            />
          </Grid>

          <Grid item xs={6}>
            <InputTags
              inputLabel={"VERTICAL QUE ATUA NA ACATE"}
              name="acateVertical"
              autoComplete="off"
              required={texts.login.userMessage}
              options={inputs?.verticals ? inputs?.verticals : []}
              defaultValue={companyData?.verticals}
              onChange={(event, values) => {
                const noValue = values.find(
                  (value) => value.label === "Nenhuma"
                );

                if (noValue && values.length > 1) {
                  formBasic?.setValue("acateVertical", [noValue]);
                }
              }}
              tooltip={{
                title: "Dica!",
                content: "Selecione um ou mais de um valor.",
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <InputTags
              inputLabel={"SETOR DE ATUAÇÃO"}
              name="sector"
              autoComplete="off"
              required={texts.login.userMessage}
              options={inputs?.sectors ? inputs?.sectors : []}
              defaultValue={companyData?.sectors}
              tooltip={{
                title: "Dica!",
                content: "Selecione um ou mais de um valor.",
              }}
            />
          </Grid>
        </Grid>

        <Styles.ButtonContainer>
          <ButtonContained
            loading={isFetching}
            disabled={isFetching}
            type="submit"
            name="button"
            fullWidth={false}
          >
            Confirmar Alterações
          </ButtonContained>
        </Styles.ButtonContainer>
      </FormHolder>
    </Styles.CardContainer>
  );
}

function mapStateToProps(state) {
  const { isFetching, CityList } = state.registry;
  return {
    isFetching,
    CityList,
  };
}

function mapDispatchToProps(dispatch) {
  const { cityListRequest, postRegistration } = CreatorsRegistry;

  return {
    cityListRequest: function () {
      return dispatch(cityListRequest());
    },
    postRegistration: function (data) {
      return dispatch(postRegistration(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CompleteData);
