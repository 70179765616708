import React from "react";
import { connect } from "react-redux";
import Styles from "../styles/Styles";
import { Pagination, Table } from "../../../components";
import { Texts } from "../../../config";
import { Filters } from "../../../lib";
import { CheckCircle, Cancel } from "@material-ui/icons";

function CompaniesList({
  companyAccess,
  page,
  setPage,
  totalPages = 3,
  disapprovedAccess,
  approvedAccess,
}) {
  const texts = Texts["pt-BR"].companyAccess;

  const typeRequested = (type) => {
    if (type === "ROLE_ASSOCIATE_MANAGER_WAITING_ACCESS") {
      return texts.typeRequested.associate;
    } else if (type.includes("NOT_ASSOCIATE_MANAGER")) {
      return texts.typeRequested.notAssociate;
    } else if (type.includes("NOT_ASSOCIATE_NOT_TECHNOLOGY")) {
      return texts.typeRequested.notAssociateTec;
    } else if (type === "ROLE_RESEARCHER_WAITING_ACCESS") {
      return texts.typeRequested.researcher;
    } else if (type === "ROLE_PHYSICAL_PERSON_WAITING_ACCESS") {
      return texts.typeRequested.physicalPerson;
    }
  };

  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <Styles.TitleCard>{texts.table.title}</Styles.TitleCard>
      </Styles.HeaderCard>
      <Styles.Line />
      <Table
        headers={{
          table: texts.table.headers,
        }}
        data={companyAccess}
        renderItemColumns={(item) => [
          item.cnpj === null ? "-" : Filters.cnpjMask(item.cnpj),
          item.companyName === null ? (
            "-"
          ) : (
            <span style={{ textTransform: "capitalize" }}>
              {item.companyName.toLowerCase()}
            </span>
          ),
          typeRequested(item.accessTypeRequested),
          item.username,
          item.email,

          item.requestPeriod === null
            ? "-"
            : Filters.formatDate(item.requestPeriod),
          item?.isReproved ? "Reprovado" : "Pendente",
          <Styles.ActionContainer>
            {!item?.isReproved ? (
              <Styles.ActionContent>
                <Styles.ActionButton
                  startIcon={<Cancel style={{ width: 15 }} />}
                  fullWidth={false}
                  onClick={() => disapprovedAccess(item.id)}
                >
                  {texts.table.disapprove}
                </Styles.ActionButton>
              </Styles.ActionContent>
            ) : (
              <></>
            )}

            <Styles.ActionContent>
              <Styles.ActionButton
                $secondColor
                startIcon={<CheckCircle style={{ width: 15 }} />}
                fullWidth={false}
                onClick={() => approvedAccess(item.id)}
              >
                {texts.table.approve}
              </Styles.ActionButton>
            </Styles.ActionContent>
          </Styles.ActionContainer>,
        ]}
      />
      <Pagination page={page} setPage={setPage} totalPages={totalPages} />
    </Styles.Content>
  );
}

export default connect()(React.memo(CompaniesList));
