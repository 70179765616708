export default function getCompanyAccess(response) {
  const data = response.data.response.elements.map((item) => {
    return {
      companyName: item.companyName,
      username: item.username,
      email: item.email,
      beginRequestDate: item.beginRequestDate,
      endRequestDate: item.endRequestDate,
      accessTypeRequested: item.accessTypeRequested,
      requestPeriod: item.requestPeriod,
      id: item.id,
      isReproved: item?.isReproved,
      cnpj: item?.cnpj,
    };
  });
  return { ...response, companyAccess: data };
}
