import React from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Input,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import { FontStyles, Tolltip } from "../index";
import Text from "../strings/Text";

import FontStyle from "../styles/fontsStyles";
const StyledInput = styled(Input)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $alternativeColors,
    ...rest
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      padding: $small ? spacing(0, 1) : spacing(1),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor: $alternativeColors
        ? colors.text.secondary
        : colors.text.primary,
      borderRadius: spacing(1),
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.error.main}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.3 : 1,
      "& .MuiInput-input": {
        backgroundColor: "red",
        color: $alternativeColors ? colors.text.primary : colors.text.primary,

        "&::placeholder": {
          opacity: 0.8,
        },
      },

      ...$inputStyle,
    };
  }
);
const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {};
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyle.medium14,
    backgroundColor: colors.background.paper,
    "&.MuiListItem-button:hover": {
      backgroundColor: colors.text.tertiary + 20,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      backgroundColor: colors.text.primary + 50,
    },

    "& .MuiPaper-root": {
      backgroundColor: "red",
    },
  };
});

const StyledSelect = styled(Select)(({ theme, $withError, $maxHeight }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyle.medium16,
    color: colors.text.secondary,
    padding: spacing(1),
    backgroundColor: colors.background.input,
    borderRadius: spacing(0.7),

    border: $withError
      ? `1px solid ${colors.background.secondary}`
      : "0px solid red",

    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: spacing(1),
    },
  };
});

const Label = styled(Text)(({ theme, smallLabel, $withError, $maxHeight }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    fontFamily: smallLabel
      ? FontStyles.semibold14.fontFamily
      : FontStyles.bold14.fontFamily,
    zIndex: $maxHeight ? 1000 : 10,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withError ? colors.text.tertiary : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

function SelectComponent(props) {
  const { value, error, setConfigs, onBlur, ref, formHandler, usedProps } =
    formConnector.useStringValue(props);
  const { onChange, name } = props;
  const onSelectValue = React.useCallback(
    (event) => {
      setConfigs(event.target.value, event);
      try {
        onChange(event.target.value);
      } catch (err) {}
      setTimeout(() => {
        onBlur();
        formHandler.testInputError(name);
      }, 10);
    },
    [setConfigs, onBlur, formHandler, name, onChange]
  );

  const {
    containerStyles,
    required,
    inputLabel,
    options,
    disableError,
    customInput,
    $maxHeight,
    alternativeColors = false,
    smallLabel,
    tooltip,
  } = usedProps;

  return (
    <StyledFormControl
      style={containerStyles}
      fullWidth
      error={Boolean(error)}
      required={Boolean(required)}
      $maxHeight={$maxHeight}
      onMouseUp={(event) => event.stopPropagation()}
    >
      <Label
        smallLabel={smallLabel}
        $withValue={Boolean(value) || value === 0}
        $withError={Boolean(error)}
        $maxHeight={$maxHeight}
      >
        {inputLabel}
        {tooltip && (
          <Tolltip
            title={tooltip?.title}
            tip={tooltip?.content}
            disableFocusListener
          />
        )}
      </Label>
      <StyledSelect
        $withError={Boolean(error)}
        $alternativeColors={alternativeColors}
        disableUnderline
        ref={ref}
        value={value}
        $maxHeight={$maxHeight}
        onChange={onSelectValue}
        onMouseUp={(event) => event.stopPropagation()}
        input={customInput ? <StyledInput $maxHeight={$maxHeight} /> : null}
      >
        {options?.map((option) => (
          <StyledMenuItem value={option.value} disabled={option.disabled}>
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </StyledFormControl>
  );
}

export default SelectComponent;
