import styled from "styled-components";
import { ButtonContained, FontStyles, Text } from "../../../components";

import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core/";
import FontStyle from "../../../components/styles/fontsStyles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    height: `calc(100vh - ${spacing(9)}px)`,
    width: "100%",
    paddingInline: spacing(3),
    paddingBlock: spacing(2.5),
    overflow: "auto",
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.carbon,
    width: "100%",
    height: 1,
    marginBottom: spacing(2),
  };
});

const CardContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",

    padding: spacing(2),
    borderRadius: spacing(2),
  };
});

const ActiveContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    padding: spacing(2),
  };
});

const CardHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };
});

const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    color: colors.text.secondary,
    textTransform: "uppercase",
    margin: 0,
  };
});
const ButtonContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
    margin: 0,
    padding: 0,
  };
});

const Dots = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.carbon,
  };
});

const ButtonsNavigation = styled(Button)(({ theme, Second }) => {
  const { palette: colors } = theme;
  return {
    width: "100%",
    backgroundColor: Second
      ? colors.action.disabledBackground
      : colors.primary.main,
    borderRadius: 0,
    borderBottom: Second
      ? `4px solid ${colors.carbon}`
      : `4px solid ${colors.background.defaultHover}`,
    color: Second ? colors.carbon : colors.text.primary,
    "&:hover": {
      backgroundColor: colors.background.defaultHover,
    },
  };
});

const PlanCard = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "#F9F9F9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: spacing(1),
    marginBlock: spacing(1.2),
  };
});

const PlanCardHeader = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    padding: spacing(1.1, 1.9),
    paddingTop: spacing(1.9),
  };
});

const PlanCardContent = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: spacing(1.9),
    backgroundColor: colors.background.secondary + "26",
  };
});

const PlanCardFooter = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    padding: spacing(1.1, 1.9),
    paddingBottom: spacing(1.9),
  };
});

const TitlePlan = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyle.semibold14,
    color: colors.text.secondary,
    textTransform: "uppercase",
  };
});

const ValuePlan = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyle.bold14,
    color: colors.text.tertiary,
    textTransform: "uppercase",
  };
});

const InfoPlan = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyle.medium14,
    color: colors.text.secondary,
    display: "flex",
    flexDirection: "row",
  };
});
const Dot = styled(FiberManualRecordIcon)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    height: spacing(1),
    marginTop: spacing(0.875),
    color: colors.text.tertiary,
  };
});

const TypePlan = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyle.italic14,
    fontSize: spacing(1.5),
    color: colors.text.secondary,
    paddingBlock: spacing(1),
  };
});
const SubmitPlan = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: spacing(1.3, 2.8),
    textTransform: "capitalize",
    ...FontStyle.medium14,
  };
});
const ActivePlan = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyle.roman16,
    color: colors.text.secondary,
    opacity: 0.88,
  };
});
const ActivePlanDate = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyle.bold14,
    color: colors.text.secondary,
    opacity: 0.44,
  };
});
const Styles = {
  Container,
  Line,
  CardContainer,
  CardHeader,
  CardTitle,
  Dots,
  ButtonContainer,
  ButtonsNavigation,
  PlanCard,
  PlanCardHeader,
  PlanCardContent,
  PlanCardFooter,
  TitlePlan,
  ValuePlan,
  InfoPlan,
  TypePlan,
  SubmitPlan,
  Dot,
  ActivePlan,
  ActiveContainer,
  ActivePlanDate,
};

export default Styles;
