import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* userListRequest({ data }) {
  try {
    SessionStorage.setItem("userListFilter", data);
    const requestBody = {
      searchParameters: {
        fullName: data.fullName,
        email: data.email,
        status: data.status,
        role: data.role,
      },
      page: {
        number: data.page - 1,
        size: 6,
      },
    };
    const response = yield call(api.getUsersList, requestBody);

    yield put(Creators.userListSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao realizar pesquisa de usuarios. ");
    yield put(Creators.userListFailure());
  }
}
