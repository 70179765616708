import { Filters } from "../../lib";

export default function getCnpj(response) {
  const data = response.data.cnpjs.map((item) => {
    return {
      value: item,
      label: Filters.cnpjMask(item),
    };
  });
  return { ...response, data: data };
}
