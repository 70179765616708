import React from "react";
import {
  FormControl,
  FormHelperText,
  Chip,
  withTheme,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import FontStyle from "../styles/fontsStyles";
import Text from "../strings/Text";

const ChipSelect = styled(Chip)(({ theme, selected, first }) => {
  const { palette: colors, spacing } = theme;
  return {
    marginRight: spacing(0.6),
    marginBottom: spacing(0.6),
    backgroundColor: selected ? "transparent" : colors.background.secondary,
    color: selected ? colors.text.secondary : colors.text.primary,
    border: selected
      ? `1px solid ${colors.text.secondary}`
      : `1px solid transparent`,
    ...FontStyle.medium12,
    ":focus": {
      backgroundColor: selected ? "transparent" : colors.background.secondary,
    },
    opacity: selected ? 0.66 : 1,
  };
});

const ListContainer = styled.div(({ theme, selected }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    marginTop: spacing(1.2),
  };
});

const Label = styled(Text)(({ theme, smallLabel, $withError }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyle.bold14,
    fontFamily: smallLabel
      ? FontStyle.semibold14.fontFamily
      : FontStyle.bold14.fontFamily,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withError ? colors.text.tertiary : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

const Description = styled(Text)(({ theme, $withValue, $withError }) => {
  const { palette: colors, spacing } = theme;

  return {
    ...FontStyle.regular14,
    marginBlock: spacing(0.6),
    color: colors.text.secondary,
    opacity: 0.55,
  };
});

function ChipList(props) {
  const { label, description, required, disableError, options, smallLabel } =
    props;

  const { value, error, setConfigs, ref } = formConnector.useStringValue(props);

  const handleSelect = (item) => {
    let newList = [...value];
    const index = newList.indexOf(item.value);
    if (index > -1) {
      newList.splice(index, 1);
    } else {
      newList.push(item.value);
    }
    setConfigs(newList);
  };

  return (
    <FormControl required={required} error={error}>
      <>
        <Label
          $withValue={Boolean(value) || value === 0}
          $withError={error}
          smallLabel={smallLabel}
        >
          {label}
        </Label>
        <Description> {description}</Description>
        <ListContainer>
          {options?.map((item, index) => {
            let propsInput;
            if ([...value].find((value) => value === item.value)) {
              propsInput = { selected: false };
            } else {
              propsInput = { selected: true };
            }

            return (
              <ChipSelect
                first={index === 0}
                inputRef={ref}
                label={item.label}
                onClick={() => handleSelect(item)}
                {...propsInput}
              />
            );
          })}
        </ListContainer>
      </>
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(ChipList);
