import styled from "styled-components";
import { FontStyles, Text, ButtonContained } from "../../../components";
const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    height: `calc(100vh - ${spacing(9)}px)`,
    width: "100%",
    paddingInline: spacing(3),
    paddingBlock: spacing(2.5),
    overflow: "auto",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
  };
});

const HeaderCard = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: spacing(0.5),
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    height: spacing(0.1),
    marginBottom: spacing(1),
    backgroundColor: colors.carbon,
    opacity: 0.2,
  };
});

const TitleCard = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.semibold14,
  };
});

const NewUser = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.semibold12,
    padding: spacing(0.9, 1.9),
  };
});

const ConfirmButton = styled(ButtonContained)(({ theme, $secondColor }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    minWidth: $secondColor ? 0 : null,
    backgroundColor: $secondColor ? colors.carbon : null,
    padding: spacing(0.9, 2),

    ":hover": {
      backgroundColor: $secondColor ? colors.carbon + "CC" : null,
    },
  };
});

const ModalPadding = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(2),
  };
});

const InputLabel = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

const BetweenDate = styled(Text)(({ theme }) => {
  const { palette: colors, spacing } = theme;

  return {
    ...FontStyles.roman14,
    color: colors.text.secondary,
    textAlign: "center",
    paddingTop: spacing(1.5),
  };
});

const Styles = {
  Container,
  Content,
  HeaderCard,
  TitleCard,
  NewUser,
  ConfirmButton,
  Line,
  ModalPadding,
  InputLabel,
  BetweenDate,
};

export default Styles;
