import React from "react";
import { Tolltip } from "../../../../components";
import Styles from "../styles/Styles";
import Chart from "chart.js";
import FooterChart from "./FooterChart";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

//import { Texts } from "../../../../config";
function CardIndicator({ data, isFetching, label, small, tip }) {
  const chartRef = React.useRef(null);
  const [, setChart] = React.useState();

  React.useEffect(() => {
    if (data?.chartData && !isFetching) {
      setChart((r) => {
        if (r != null) {
          r.destroy();
        }
        return new Chart(chartRef.current, data?.chartData);
      });
    }
  }, [isFetching, data, setChart]);

  return (
    <Styles.Card>
      <Styles.HeaderCard>
        <Styles.TitleCard>
          {label} <Tolltip title={label} tip={tip} />
        </Styles.TitleCard>
      </Styles.HeaderCard>
      <Styles.Line />

      {chartRef !== null && (
        <>
          {data?.type === "gauges" && (
            <Styles.GaugesCard noBG>
              <Styles.SmallIndicator>{data?.company}</Styles.SmallIndicator>{" "}
              <Styles.GaugesSubValue>
                Média Geral: {data?.average}
              </Styles.GaugesSubValue>
              {data?.difference && (
                <Styles.Diference
                  negative={data?.difference < 0 ? true : false}
                >
                  (
                  {data?.difference < 0 ? (
                    <ArrowDropDownIcon />
                  ) : data?.difference > 0 ? (
                    <ArrowDropUpIcon />
                  ) : (
                    "- "
                  )}
                  {data?.difference})
                </Styles.Diference>
              )}
            </Styles.GaugesCard>
          )}
          <canvas
            style={{
              maxWidth: "100%",
              maxHeight:
                data?.type === "gauges" ? "64px" : small ? "150px" : "250px",
            }}
            ref={chartRef}
          ></canvas>
          <Styles.Line $margin />
          <FooterChart $alternative={data?.type === "bar" ? true : false} />
        </>
      )}
    </Styles.Card>
  );
}

export default CardIndicator;
