import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* contactUsDetailedRequest({ data }) {
  try {
    SessionStorage.setItem("contactUsDetailedFilter", data);
    const requestBody = {
      searchParameters: {
        id: data?.id ? data.id : null,
        email: data?.email ? data?.email : null,
        telephone: data?.telephone ? data?.telephone : null,
        mondayToFridayBegin: data?.mondayToFridayBegin ? data?.mondayToFridayBegin : null,
        mondayToFridayEnd: data?.mondayToFridayEnd ? data?.mondayToFridayEnd : null,
        saturdayToSundayBegin: data?.saturdayToSundayBegin ? data?.saturdayToSundayBegin : null,
        saturdayToSundayEnd: data?.saturdayToSundayEnd ? data?.saturdayToSundayEnd : null,
      },
    };
    const response = yield call(api.getContactUsDetailed, requestBody);
    yield put(Creators.contactUsDetailedSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao carregar informações do Fale Conosco..");
    yield put(Creators.contactUsDetailedFailure());
  }
}
