import { put } from "redux-saga/effects";
import { SessionStorage } from "../../lib";
import { Creators } from ".";

export default function* getGlobalStatus() {
  const isLoggedIn = SessionStorage.getItem("isLoggedIn");
  const isAcateMode = SessionStorage.getItem("isAcateMode");
  yield put(Creators.setAcateMode(isAcateMode));

  if (isLoggedIn && isAcateMode !== undefined) {
    yield put(Creators.getUserInfosRequest());
    yield put(Creators.setIsLoggedIn(true));
  }
}
