import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";

import { Styles } from "../styles";
import EditIcon from "@material-ui/icons/Edit";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import { Pagination, Table } from "../../../components";
import { Texts } from "../../../config";
function UsersList({
  isFetching,
  updateUser,
  deleteUser,
  usersList,
  page,
  setPage,
  totalPages = 3,
  changeManager,
  userData,
}) {
  const texts = Texts["pt-BR"].Users;

  return (
    <>
      <Styles.Content>
        <Styles.HeaderCard>
          <Styles.TitleCard>{texts.tableTitle}</Styles.TitleCard>
        </Styles.HeaderCard>
        <Styles.Line />
        <Table
          headers={{ table: texts.table }}
          data={usersList}
          renderItemColumns={(item) => [
            item.name,
            item.email,
            item.role.includes("COLLABORATOR")
              ? "Colaborador"
              : item.role.includes("MANAGER")
              ? "Gestor"
              : item.role,
            item.status ? "Acesso Ativo" : "Acesso Inativo",
            <>
              <Grid container spacing={2} style={{ width: "fit-content" }}>
                <Grid
                  item
                  sm={12}
                  md={4}
                  lg={userData?.role?.includes("ACATE") ? 4 : 2}
                  xl={userData?.role?.includes("ACATE") ? 4 : 2}
                  style={
                    !userData?.role?.includes("ACATE")
                      ? {
                          display: "flex",
                          justifyContent: "flex-end",
                        }
                      : {}
                  }>
                  <Styles.DeleteUser
                    $secondColor
                    startIcon={<EditIcon style={{ margin: 0 }} />}
                    onClick={() => updateUser(item)}
                    loading={isFetching}
                  />
                </Grid>
                {userData?.role?.includes("ACATE") ? null : (
                  <Grid
                    item
                    sm={12}
                    md={8}
                    lg={6}
                    xl={7}
                    style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Styles.ManagerButton
                      onClick={() => changeManager(item)}
                      startIcon={<SwapHoriz />}
                      loading={isFetching}>
                      Tornar Gestor
                    </Styles.ManagerButton>
                  </Grid>
                )}

                <Grid
                  item
                  sm={12}
                  md={12}
                  lg={userData?.role?.includes("ACATE") ? 7 : 4}
                  xl={userData?.role?.includes("ACATE") ? 6 : 3}
                  style={
                    !userData?.role?.includes("ACATE")
                      ? {
                          display: "flex",
                          justifyContent: "flex-end",
                        }
                      : {}
                  }>
                  <Styles.DeleteUser
                    onClick={() => deleteUser(item)}
                    off={item.status}
                    loading={isFetching}>
                    {item.status ? texts.deactivate : texts.activate}
                  </Styles.DeleteUser>
                </Grid>
              </Grid>
            </>,
          ]}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Styles.Content>
    </>
  );
}

export default connect()(React.memo(UsersList));
