import React from "react";
import Styles from "../styles/Styles";
import Filter from "../components/Filter";
import { connect } from "react-redux";
import { Creators } from "../reduxSagas";

import DisplayIndicators from "../components/DisplayIndicators";
import { CircularProgress } from "@material-ui/core";

function Container({
  getFinantialData,
  isFetching,
  finantialData,
  exportData,
  postExportIndicators,
  getFinantialTooltips,
  tooltips,
}) {
  const ref = React.useRef(null);

  const [width, setWidth] = React.useState(null);

  React.useEffect(() => {
    if (ref !== null) {
      setWidth(ref?.current?.offsetWidth);
      getFinantialData(null);
      getFinantialTooltips();
    }
  }, [getFinantialData, getFinantialTooltips]);

  return (
    <Styles.Container ref={ref}>
      <Styles.FilterBackground>
        <Filter widthMax={width} onSubmit={(data) => getFinantialData(data)} />
      </Styles.FilterBackground>
      {finantialData !== null && !isFetching ? (
        <DisplayIndicators
          data={finantialData}
          isFetching={isFetching}
          tooltip={tooltips}
        />
      ) : isFetching ? (
        <Styles.Background>
          <Styles.LoadingContainer>
            <CircularProgress size={32} />
          </Styles.LoadingContainer>
        </Styles.Background>
      ) : null}
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching, finantialData, exportData, tooltips } =
    state.financialGeral;
  return {
    isFetching,
    finantialData,
    exportData,
    tooltips,
  };
}

function mapDispatchToProps(dispatch) {
  const { getFinantialData, postExportIndicators, getFinantialTooltips } =
    Creators;

  return {
    getFinantialData: function (filter) {
      return dispatch(getFinantialData(filter));
    },
    postExportIndicators: function (filter) {
      return dispatch(postExportIndicators(filter));
    },
    getFinantialTooltips: function () {
      return dispatch(getFinantialTooltips());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Container));
