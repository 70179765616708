import React from "react";
import { connect } from "react-redux";
import {
  Input,
  ButtonContained,
  Text,
  InputEmail,
  FontStyles,
} from "../../../components";
import { CircularProgress, Grid } from "@material-ui/core";
import Styles from "../styles/ContactUsStyles";
import { FormHolder } from "../../../FormConfig";
import { Spacing, Texts } from "../../../config";

import { Filters, Validations } from "../../../lib";
import { Creators } from "../reduxSagas";

function SendMessages({
  isFetching,
  getContactUsDetailed,
  contactUsDetailed,
  sendContactUsRequest,
}) {
  const texts = Texts["pt-BR"].contactUsAcate;

  const idContact = React.useRef(null);

  React.useEffect(() => {
    getContactUsDetailed();
  }, [getContactUsDetailed]);

  React.useEffect(() => {
    idContact.current = contactUsDetailed?.id;
  }, [contactUsDetailed]);

  const SendData = (data, id) => {
    const requestBody = {
      id: id,
      email: data.email ? data.email : null,
      telephone: data.telephone ? data.telephone : null,
      mondayToFridayBegin: data.mondayToFridayBegin
        ? data.mondayToFridayBegin
        : null,
      mondayToFridayEnd: data.mondayToFridayEnd ? data.mondayToFridayEnd : null,
      saturdayToSundayBegin: data.saturdayToSundayBegin
        ? data.saturdayToSundayBegin
        : null,
      saturdayToSundayEnd: data.saturdayToSundayEnd
        ? data.saturdayToSundayEnd
        : null,
    };
    sendContactUsRequest(requestBody);
  };

  return (
    <FormHolder onSubmit={(data) => SendData(data, idContact.current)}>
      {isFetching ? (
        <div
          style={{
            width: "100%",
            height: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {/* TITULO */}
          <Grid item xs={12}>
            <Styles.HeaderCard>
              <Styles.TitleCard>{texts.title}</Styles.TitleCard>
            </Styles.HeaderCard>
            <Styles.Line />
          </Grid>

          {/* EMAIL */}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputEmail
                inputLabel={texts.email}
                name="email"
                nextInput="telephone"
                defaultValue={contactUsDetailed?.email}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="telephone"
                inputLabel={texts.phone}
                mask={Filters.phoneMask}
                maskToSubmit={Filters.phoneToRequest}
                validation={(data) => Validations.isPhone(data)}
                nextInput="mondayToFridayBegin"
                defaultValue={contactUsDetailed?.telephone}
              />
            </Grid>
          </Grid>

          {/* SEMANA */}
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={6}>
              <Input
                readOnly
                inputLabel={texts.weekday}
                placeholder="Segunda à Sexta"
              />
            </Grid>
            <Grid container spacing={1} item xs={6} alignItems="flex-end">
              <Grid item xs={5}>
                <Input
                  inputLabel={texts.workingTime}
                  name="mondayToFridayBegin"
                  required={texts.userMessage}
                  nextInput="mondayToFridayEnd"
                  defaultValue={contactUsDetailed?.mondayToFridayBegin}
                  mask={Filters.timeMask}
                  maskToSubmit={Filters.timeMask}
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction="row"
                style={{ justifyContent: "center", alignSelf: "center" }}
              >
                <Text
                  style={{ ...FontStyles.italic14, paddingTop: Spacing(0.25) }}
                >
                  até
                </Text>
              </Grid>
              <Grid item xs={5}>
                <Input
                  inputLabel
                  name="mondayToFridayEnd"
                  required={texts.userMessage}
                  nextInput="saturdayToSundayBegin"
                  defaultValue={contactUsDetailed?.mondayToFridayEnd}
                  mask={Filters.timeMask}
                  maskToSubmit={Filters.timeMask}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* FINAL DE SEMANA */}
          <Grid container spacing={1} item alignItems="flex-end">
            <Grid item xs={6}>
              <Input readOnly placeholder="Sábado e Domingo" />
            </Grid>
            <Grid container spacing={1} item xs={6}>
              <Grid item xs={5}>
                <Input
                  name="saturdayToSundayBegin"
                  nextInput="saturdayToSundayEnd"
                  defaultValue={contactUsDetailed?.saturdayToSundayBegin}
                  mask={Filters.timeMask}
                  maskToSubmit={Filters.timeMask}
                />
              </Grid>
              <Grid
                container
                item
                xs={2}
                direction="row"
                style={{ justifyContent: "center", alignSelf: "flex-start" }}
              >
                <Text
                  style={{ ...FontStyles.italic14, paddingTop: Spacing(2) }}
                >
                  até
                </Text>
              </Grid>
              <Grid item xs={5}>
                <Input
                  name="saturdayToSundayEnd"
                  defaultValue={contactUsDetailed?.saturdayToSundayEnd}
                  mask={Filters.timeMask}
                  maskToSubmit={Filters.timeMask}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* BOTAO */}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            style={{ justifyContent: "flex-end" }}
          >
            <Grid item>
              <ButtonContained
                type="submit"
                fullWidth={false}
                loading={isFetching}
                style={{ width: 260 }}
              >
                {texts.confirm}
              </ButtonContained>
            </Grid>
          </Grid>
        </>
      )}
    </FormHolder>
  );
}

function mapStateToProps(state) {
  const { isFetching, contactUsDetailed } = state.contactUs;
  return {
    isFetching,
    contactUsDetailed,
  };
}

function mapDispatchToProps(dispatch) {
  const { getContactUsDetailed, sendContactUsRequest } = Creators;
  return {
    getContactUsDetailed: function (data) {
      return dispatch(getContactUsDetailed(data));
    },
    sendContactUsRequest: function (data) {
      return dispatch(sendContactUsRequest(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SendMessages));
