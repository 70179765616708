import React from "react";
import { connect } from "react-redux";

import { Styles } from "../styles";
import { Creators } from "../reduxSagas";
import { Creators as CreatorsPlan } from "../../evaluatedReleases/reduxSagas";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchList from "../components/SearchList";
import ApprovalList from "../components/ApprovalList";
import { IntegratedComponentHolder } from "../../../components";
import { SessionStorage } from "../../../lib";

function PlansApproval({
  isFetching,
  approvalList,
  getApprovalList,
  putApprovalPlan,
  planList,
  getPlansList,
}) {
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    getPlansList();
    const requestBody = {
      enterprise: null,
      plan: null,
      startDate: null,
      endDate: null,
      page: page,
    };

    getApprovalList(requestBody);
  }, [getApprovalList, page, getPlansList]);

  const getList = (data) => {
    const requestBody = {
      enterprise: data?.enterprise ? data.enterprise : null,
      plan: data?.plan ? data.plan : null,
      startDate: data?.startDate ? data.startDate : null,
      endDate: data?.endDate ? data.endDate : null,
      page: page,
    };

    getApprovalList(requestBody);
  };

  const setPageList = (value) => {
    let filter = SessionStorage.getItem("plansApprovalFilter");

    filter = { ...filter, page: value };
    setPage(value);
    getApprovalList(filter);
  };

  return (
    <Styles.Container>
      <Grid container direction="row" alignContent="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Styles.Content>
            <Grid container spacing={1}>
              <SearchList onSubmit={getList} planList={planList} />
            </Grid>
          </Styles.Content>
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={isFetching}
            reloadMessage={"Não encontrado"}
            showEmpty={!isFetching && approvalList?.ApprovalList?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}>
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <CircularProgress color="primary" />{" "}
              </div>
            ) : (
              <ApprovalList
                approvalList={approvalList}
                page={page}
                setPage={setPageList}
                totalPages={approvalList?.pageTotal}
                putApprovalPlan={(data) =>
                  putApprovalPlan(data, getApprovalList)
                }
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching, approvalList } = state.plansApproval;
  const { planList } = state.evaluatedReleases;
  return {
    isFetching,
    approvalList,
    planList,
  };
}

function mapDispatchToProps(dispatch) {
  const { getApprovalList, putApprovalPlan } = Creators;
  const { getPlansList } = CreatorsPlan;

  return {
    getApprovalList: function (data) {
      return dispatch(getApprovalList(data));
    },
    putApprovalPlan: function (data, callback) {
      return dispatch(putApprovalPlan(data, callback));
    },
    getPlansList: function () {
      return dispatch(getPlansList());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PlansApproval));
