import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";
import { customModal } from "../../../features/modals/utils";
import { Texts } from "../../../config";
import { navigateTo } from "../../../navigation/navigate";
export default function* postRegistration({ data }) {
  try {
    yield call(api.postRegistration, data);
    yield put(Creators.postRegistrationSuccess());
    customModal.setInfos(
      "Sucesso",
      [
        "Sua solicitação de acesso foi realizada com sucesso. Assim que o seu acesso for avaliado pela equipe Acate avisaremos a você por e-mail",
      ],
      {
        label: Texts["pt-BR"].modals.success.buttonLabel,
        onClick: () => {
          customModal.close();
          navigateTo.Landing();
        },
      }
    );
  } catch (response) {
    Alerts.alertError(
      response?.data[0]?.message
        ? response?.data[0]?.message
        : "Não foi possível realizar cadastro, entre em contato com nosso suporte."
    );
    yield put(Creators.postRegistrationFailure());
  }
}
