import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "../reduxSagas/index";
import { Alerts } from "../../../lib";
import ContactUsConfirm from "../components/ContactUsConfirm";
import { Texts } from "../../../config";
import { customModal } from "../../modals/utils";

export default function* sendContactUsRequest({ data }) {
  const texts = Texts["pt-BR"].contactUsAcate;
  try {
    const response = yield call(api.putContactUsUpdate, data);

    yield put(Creators.contactUsDetailedSuccess(response?.data?.response));
    customModal.setInfos(
      texts.modal.title,
      [texts.modal.text],
      {
        label: texts.modal.confirmation,
        onClick: () => {
          customModal.close();
        },
      },
      null,
      <ContactUsConfirm
        data={{
          email: data.email,
          phone: data.telephone,
          weekTime:
            "Segunda à Sexta: " +
            [data.mondayToFridayBegin] +
            " - " +
            [data.mondayToFridayEnd],
          weekendTime:
            data.saturdayToSundayBegin === null
              ? "Sábado e Domingo: Sem expediente"
              : "Sábado e Domingo: " +
                [data.saturdayToSundayBegin] +
                " - " +
                [data.saturdayToSundayEnd],
        }}
      />
    );
  } catch (response) {
    Alerts.alertError("Erro ao salvar informações do Fale Conosco.");
    yield put(Creators.sendContactUsFailure());
  }
}
