import React from "react";
import { FirstAccessPage } from "../features";

function UserRegister() {
  return (
    <>
      <p style={{ color: "black" }}>Teste 2</p>
      <FirstAccessPage />
    </>
  );
}

export default UserRegister;
