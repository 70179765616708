import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* putBasicRegister({ data }) {
  try {
    const response = yield call(api.putBasicRegister, data);

    yield put(Creators.putBasicRegisterSuccess(response));
  } catch (response) {
    Alerts.alertError("Não foi possível salvar os dados do usuário");
    yield put(Creators.putBasicRegisterFailure());
  }
}
