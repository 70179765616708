import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Styles } from "../styles";
import { Pagination, Table } from "../../../components";
import { Texts } from "../../../config";
import { CheckCircle, Cancel } from "@material-ui/icons";
import { Filters } from "../../../lib";
function ApprovalList({
  approvalList,
  page,
  setPage,
  isFetching,
  putApprovalPlan,
}) {
  const texts = Texts["pt-BR"].plansApproval;

  return (
    <>
      <Styles.Content>
        <Styles.HeaderCard>
          <Styles.TitleCard>{texts.table.title}</Styles.TitleCard>
        </Styles.HeaderCard>
        <Styles.Line />
        <Table
          headers={{
            table: texts.table.headers,
          }}
          data={approvalList?.ApprovalList}
          renderItemColumns={(item) => [
            item?.enterprise,
            item?.plan,
            item?.requestDate
              ? Filters.formatDate(item?.requestDate)
              : "Data não encontrada",
            <>
              <Grid
                container
                spacing={1}
                style={{ justifyContent: "flex-end" }}>
                <Grid item>
                  <Styles.ConfirmButton
                    $secondColor
                    startIcon={<Cancel />}
                    onClick={() =>
                      putApprovalPlan({ id: item?.id, approve: false })
                    }
                    fullWidth={false}
                    loading={isFetching}>
                    Reprovar
                  </Styles.ConfirmButton>
                </Grid>
                <Grid item>
                  <Styles.ConfirmButton
                    onClick={() =>
                      putApprovalPlan({ id: item?.id, approve: true })
                    }
                    startIcon={<CheckCircle />}
                    fullWidth={false}
                    loading={isFetching}>
                    Aprovar
                  </Styles.ConfirmButton>
                </Grid>
              </Grid>
            </>,
          ]}
        />
        <Pagination
          page={page}
          setPage={setPage}
          totalPages={approvalList?.totalPages}
        />
      </Styles.Content>
    </>
  );
}

export default connect()(React.memo(ApprovalList));
