import styled from "styled-components";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    height: `calc(100vh - ${spacing(11)}px)`,
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: spacing(2),
    overflow: "auto",
  };
});

const Styles = {
  Container,
  Content,
};

export default Styles;
