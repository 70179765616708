import models from ".";

export default function getManagerialData(response) {
  var data = {};

  if (response.data === null) {
    return { error: "OK" };
  }

  data["numberOfCollaborators"] = models.DecimalBarChart(
    response.data?.numberOfCollaborators
  );
  data["retainingTalent"] = models.DecimalBarChart(
    response.data?.retainingTalent
  );
  data["turnover"] = models.PercentBarChart(response.data.turnover);
  data["investimentInSocioEnvironmentActions"] = models.MoneyLineChart(
    response.data.investimentInSocioEnvironmentActions
  );

  data["gender"] = models.GenderBarChart(response.data.gender, null, true);
  data["enps"] = models.TermoChart(response.data.enps);

  data["womenInLeadershipPositions"] = response.data.womenInLeadershipPositions;

  return data;
}
