export default function getUsersList(response) {
  const data = response.data.response.elements.map((item) => {
    return {
      email: item.email,
      name: item.fullName,
      role: item.role,
      status: item.enable,
      id: item.id,
    };
  });
  return { ...response, usersList: data };
}
