import React from "react";
import { connect } from "react-redux";
import {
  Input,
  ButtonOutlined,
  ButtonContained,
  InputDate,
  Select,
} from "../../../components";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import { Styles } from "../styles";
import { FormHolder } from "../../../FormConfig";
import { Texts } from "../../../config";
import { Validations } from "../../../lib";
function SearchList({ isFetching, onSubmit, planList }) {
  const texts = Texts["pt-BR"].plansApproval;
  return (
    <FormHolder onSubmit={onSubmit}>
      <Grid item xs={12}>
        <Styles.HeaderCard>
          <Styles.TitleCard>{texts.filterTitle}</Styles.TitleCard>
        </Styles.HeaderCard>
        <Styles.Line />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Input
            inputLabel={texts.table.enterprise}
            name="enterprise"
            placeholder="Enterprise Tec."
            $small
          />
        </Grid>

        <Grid item xs={4}>
          <Select
            name="plan"
            inputLabel={texts.table.plan}
            defaultValue={-1}
            options={
              planList ? [{ value: -1, label: "Todos" }, ...planList] : []
            }
          />
        </Grid>

        <Grid container item xs={4}>
          <Grid item xs={12}>
            <Styles.InputLabel>{texts.table.date}</Styles.InputLabel>
          </Grid>

          <Grid item xs={5}>
            <InputDate
              validation={Validations.isDATE}
              name="startDate"
              inputLabel={""}
              maxDate={new Date()}
            />
          </Grid>
          <Grid item xs={2}>
            <Styles.BetweenDate>até</Styles.BetweenDate>
          </Grid>

          <Grid item xs={5}>
            <InputDate
              validation={Validations.isDATE}
              name="endDate"
              inputLabel={""}
              maxDate={new Date()}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        style={{ justifyContent: "flex-end" }}>
        <Grid item>
          <ButtonOutlined
            startIcon={<DeleteIcon />}
            type="clearDefault"
            fullWidth={false}
            loading={isFetching}>
            {texts.table.clean}
          </ButtonOutlined>
        </Grid>
        <Grid item>
          <ButtonContained
            startIcon={<SearchIcon />}
            type="submit"
            fullWidth={false}
            loading={isFetching}>
            {texts.table.search}
          </ButtonContained>
        </Grid>
      </Grid>
    </FormHolder>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.users;
  return {
    isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(SearchList));
