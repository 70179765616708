import React from "react";
import { connect } from "react-redux";
import { Texts, Images } from "../../../config";
import { Swiper } from "../../../components";
import { Styles } from "../styles";
import { Grid } from "@material-ui/core";
import { navigateTo } from "../../../navigation/navigate";
import { Creators } from "../reduxSagas";
import { StepOne, StepTwo, StepThree } from "../components";
import { customModal } from "../../modals/utils";
import { Alerts } from "../../../lib";

function ForgotPassword({
  resetPasswordRequest,
  resetPasswordCodeRequest,
  resetNewPasswordRequest,
  goBackCode,
  validCode,
  isFetching,
}) {
  const texts = Texts["pt-BR"].forgotPassword;
  const [formData, setFormData] = React.useState({});

  const FinishStepOne = (data, Callback) => {
    setFormData({ ...formData, ...data });
    resetPasswordRequest(data, Callback);
  };

  const FinishStepTwo = (data) => {
    resetPasswordCodeRequest({ ...formData, ...data });
    setFormData({ ...formData, ...data });
  };

  const FinishStepThree = (data, Callback) => {
    resetNewPasswordRequest({ ...formData, ...data }, Callback);
    setFormData({ ...formData, ...data });
  };

  function ConfirmModalPassword() {
    customModal.setInfos(
      texts.passwordConfirmatiom.modalTitle,
      [texts.passwordConfirmatiom.modalText],
      {
        label: texts.passwordConfirmatiom.buttomModal,
        onClick: () => {
          customModal.close();
          goBackCode();
          navigateTo.Landing();
        },
      },
      null,
      null,
      true
    );
  }

  return (
    <>
      <Grid container spacing={0} justify="space-between">
        <Grid item xs={6} sm={6} md={5} lg={4}>
          <Styles.Container>
            <Styles.LogoContainer>
              <Styles.AcateLogo src={Images.LogoLogin} />
              <Styles.SubLogo>{texts.subtitle}</Styles.SubLogo>
            </Styles.LogoContainer>
            <Styles.Content>
              {validCode === false ? (
                <Swiper
                  screens={[
                    ({ goNext }) => (
                      <StepOne
                        loading={isFetching}
                        title={texts.Title}
                        text={texts.emailConfirmatiom.text}
                        onSubmit={(data) => {
                          FinishStepOne(data, goNext);
                        }}
                        goBack={() => navigateTo.Landing()}
                      />
                    ),
                    ({ goNext, goPrevious }) => (
                      <StepTwo
                        loading={isFetching}
                        title={texts.Title}
                        text={texts.tokenConfirmatiom.textToken}
                        onSubmit={(data) => {
                          FinishStepTwo(data);
                        }}
                        resendToken={() => {
                          FinishStepOne(formData, () => {
                            Alerts.alertSuccess(
                              "Um novo token foi enviado ao seu email"
                            );
                          });
                        }}
                        goBack={() => {
                          goPrevious();
                        }}
                      />
                    ),
                  ]}
                />
              ) : (
                <StepThree
                  title={texts.Title}
                  text={texts.passwordConfirmatiom.textNewPassword}
                  onSubmit={(data) => {
                    FinishStepThree(data, () => ConfirmModalPassword());
                  }}
                  goBack={() => {
                    goBackCode();
                  }}
                />
              )}
            </Styles.Content>
          </Styles.Container>
        </Grid>

        <Grid item xs={6} sm={6} md={7} lg={8}>
          <Styles.ImageBG $Image={`url(${Images.BackgroundImage})`}>
            <Styles.ContainerTextBG>
              <Styles.FLogo src={Images.Finep} />
              <div>
                <Styles.TextBG>
                  {texts.bgText.map((item) => {
                    return item;
                  })}
                </Styles.TextBG>
                <Styles.TextCopyright>
                  {texts.copyright} <Styles.Copyright />
                </Styles.TextCopyright>
              </div>
            </Styles.ContainerTextBG>
          </Styles.ImageBG>
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { isFetching, validCode } = state.recoveryPassword;
  return {
    isFetching,
    validCode,
  };
}
function mapDispatchToProps(dispatch) {
  const {
    resetPasswordRequest,
    resetPasswordCodeRequest,
    resetNewPasswordRequest,
    goBackCode,
  } = Creators;
  return {
    resetPasswordRequest(email, callback) {
      return dispatch(resetPasswordRequest(email, callback));
    },
    resetPasswordCodeRequest(code) {
      return dispatch(resetPasswordCodeRequest(code));
    },
    resetNewPasswordRequest(data, callback) {
      return dispatch(resetNewPasswordRequest(data, callback));
    },
    goBackCode() {
      return dispatch(goBackCode());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
