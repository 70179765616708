import createReducers from "../../../store/helpers/createPageReducer";
import accessHistoryClientsRequest from "./accessHistoryClientsRequest";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getAccessHistoryClients",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: accessHistoryClientsRequest,
    },
    {
      name: "accessHistoryClientsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        accessHistoryClients: data,
      }),
    },
    {
      name: "accessHistoryClientsFailure",
      function: (state) => ({ ...state, isFetching: false, usersList: [] }),
    },
  ],
  {
    isFetching: false,
    accessHistoryClients: [],
  }
);

export { Creators, reducers, sagas };
