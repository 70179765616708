function tableToCSV(tableId, fileName) {
  //   Select rows from table_id
  var rows = document.querySelectorAll("table#" + tableId + " tr");
  // Construct csv
  var csv = [];
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td, th");
    for (var j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      var data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push(`="${data}"`);
    }
    csv.push(row.join(";"));
  }
  var csv_string = csv.join("\n");

  // Download it
  var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function forEachItem(list, callback) {
  let i = 0;
  const iMax = list.length;
  const result = [];
  for (; i < iMax; i++) {
    result.push(callback(list[i]));
  }
  return result;
}
function forEachBody(list, list2) {
  let i = 0;
  const iMax = list.length;
  const result = [];
  for (; i < iMax; i++) {
    result.push(list[i] + ";" + list2[i].join(";"));
  }
  return result;
}
function dataToCSV(data, headers, rows, fileName, title) {
  const header = forEachItem(headers, (item) => `"${item}"`).join(";");
  const body = forEachItem(data, (item) =>
    forEachItem(rows, (row) => `${item[row]}`).join(";")
  ).join("\n");

  const csv_string = `${title}\n${header}\n${body}`;

  var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // //   Select rows from table_id
  // var rows = document.querySelectorAll("table#" + tableId + " tr");
  // // Construct csv
  // var csv = [];
  // for (var i = 0; i < rows.length; i++) {
  //   var row = [],
  //     cols = rows[i].querySelectorAll("td, th");
  //   for (var j = 0; j < cols.length; j++) {
  //     // Clean innertext to remove multiple spaces and jumpline (break csv)
  //     var data = cols[j].innerText
  //       .replace(/(\r\n|\n|\r)/gm, "")
  //       .replace(/(\s\s)/gm, " ");
  //     // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
  //     data = data.replace(/"/g, '""');
  //     // Push escaped string
  //     row.push(`="${data}"`);
  //   }
  //   csv.push(row.join(";"));
  // }
  // var csv_string = csv.join("\n");
  // // Download it
  // var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  // var link = document.createElement("a");
  // link.style.display = "none";
  // link.setAttribute("target", "_blank");
  // link.setAttribute(
  //   "href",
  //   "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  // );
  // link.setAttribute("download", filename);
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
}

function dataToCSVAlternative(data, headers, rows, fileName) {
  const cdText = `Média dos Indicadores Financeiros e Gerenciais`;

  const header = forEachItem(headers, (item) => `"${item}"`).join(";");

  const body = forEachBody(rows, data).join("\n");

  const csv_string = `${cdText}\n${header}\n${body}`;

  var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // //   Select rows from table_id
  // var rows = document.querySelectorAll("table#" + tableId + " tr");
  // // Construct csv
  // var csv = [];
  // for (var i = 0; i < rows.length; i++) {
  //   var row = [],
  //     cols = rows[i].querySelectorAll("td, th");
  //   for (var j = 0; j < cols.length; j++) {
  //     // Clean innertext to remove multiple spaces and jumpline (break csv)
  //     var data = cols[j].innerText
  //       .replace(/(\r\n|\n|\r)/gm, "")
  //       .replace(/(\s\s)/gm, " ");
  //     // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
  //     data = data.replace(/"/g, '""');
  //     // Push escaped string
  //     row.push(`="${data}"`);
  //   }
  //   csv.push(row.join(";"));
  // }
  // var csv_string = csv.join("\n");
  // // Download it
  // var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  // var link = document.createElement("a");
  // link.style.display = "none";
  // link.setAttribute("target", "_blank");
  // link.setAttribute(
  //   "href",
  //   "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  // );
  // link.setAttribute("download", filename);
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
}
const toCSV = {
  data: dataToCSV,
  csv: dataToCSVAlternative,
  table: tableToCSV,
};

export default toCSV;
