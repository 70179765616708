import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* evaluatedReleasesRequest({ data }) {
  try {
    SessionStorage.setItem("evaluatedReleasesFilter", data);
    const requestBody = {
      searchParameters: {
        companyName: data.companyName,
        accessTypeRequested: data.accessTypeRequested,
        username: data.username,
        email: data.email,
        beginRequestDate: data.beginRequestDate,
        endRequestDate: data.endRequestDate,
        planId: data.planId,
        isTech: data.isTech,
        officeId: data.officeId,
        incubatorId: data.incubatorId,
        cnpj: data.cnpj !== "-1" ? data.cnpj : null,
      },
      page: {
        number: data.page - 1,
        size: 6,
      },
    };
    const response = yield call(api.getEvaluatedReleases, requestBody);

    yield put(Creators.evaluatedReleasesSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao realizar pesquisa de liberações. ");
    yield put(Creators.evaluatedReleasesFailure());
  }
}
