import React, { useCallback } from "react";
import { connect } from "react-redux";
import Styles from "../styles/CompanyStyles";
import { Creators } from "../reduxSagas";
import { Grid } from "@material-ui/core";
import { IntegratedComponentHolder } from "../../../components";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchCompanies from "../components/SearchCompanies";
import CompaniesList from "../components/CompaniesList";
import { SessionStorage } from "../../../lib";
import { customModal } from "../../modals/utils";
import { Texts } from "../../../config";

function CompanyAccess({
  isFetching,
  companyAccess,
  getCompanyAccess,
  patchApproveAccess,
  patchDisapproveAccess,
  getCnpj,
  listCnpj,
}) {
  const [page, setPage] = React.useState(1);

  const texts = Texts["pt-BR"].companyAccess;

  const MountCnpj = useCallback(() => {
    getCnpj();
    getCompanyAccess({
      page: 1,
      status: "Pendente",
    });
  }, [getCnpj, getCompanyAccess]);
  React.useEffect(() => {
    MountCnpj();
  }, [MountCnpj]);

  const getCompanies = (data) => {
    const requestBody = {
      companyName: data.companyName ? data.companyName : null,
      accessTypeRequested:
        data.accessTypeRequested !== "-1" && data.accessTypeRequested
          ? data.accessTypeRequested
          : null,
      username: data.username ? data.username : null,
      email: data.email ? data.email : null,
      beginRequestDate: data.beginRequestDate ? data.beginRequestDate : null,
      endRequestDate: data.endRequestDate ? data.endRequestDate : null,
      page: page,
      cnpj: data.cnpj !== "-1" && data.cnpj ? data.cnpj : null,
      status:
        data.status !== "-1"
          ? typeof data.status === "string"
            ? data.status
            : data.status?.value
          : null,
    };

    getCompanyAccess(requestBody);
  };

  const setPageList = (value) => {
    let filter = SessionStorage.getItem("companyAccessFilter");

    filter = { ...filter, page: value };
    setPage(value);
    getCompanyAccess(filter);
  };

  const modalDisapproved = (id) => {
    customModal.setInfos(
      texts.modalDisapproved.title,
      [texts.modalDisapproved.subTitle],
      {
        label: texts.modalDisapproved.confirm,
        onClick: () => {
          patchDisapproveAccess(id, getCompanyAccess);
          customModal.close();
        },
      },
      {
        label: texts.modalDisapproved.exit,
        onClick: () => {
          customModal.close();
        },
      }
    );
  };

  const modalApproved = (id) => {
    customModal.setInfos(
      texts.modalApproved.title,
      [texts.modalApproved.subTitle],
      {
        label: texts.modalApproved.confirm,
        onClick: () => {
          patchApproveAccess(id, getCompanyAccess);
          customModal.close();
        },
      },
      {
        label: texts.modalApproved.exit,
        onClick: () => {
          customModal.close();
        },
      }
    );
  };

  return (
    <Styles.Container>
      <Grid container direction="row" alignContent="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Styles.Content>
            <Grid container spacing={1}>
              <SearchCompanies onSubmit={getCompanies} listCnpj={listCnpj} />
            </Grid>
          </Styles.Content>
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && companyAccess?.companyAccess?.length < 1}
            reloadMessage={"Não encontrado"}
            showEmpty={!isFetching && companyAccess?.companyAccess?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />{" "}
              </div>
            ) : (
              <CompaniesList
                companyAccess={companyAccess?.companyAccess}
                page={page}
                setPage={setPageList}
                totalPages={companyAccess?.data?.response?.pageTotal}
                disapprovedAccess={modalDisapproved}
                approvedAccess={modalApproved}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching, companyAccess, listCnpj } = state.companyAccess;
  return {
    isFetching,
    companyAccess,
    listCnpj,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getCompanyAccess,
    patchApproveAccess,
    patchDisapproveAccess,
    getCnpj,
  } = Creators;
  return {
    getCompanyAccess: function (data) {
      return dispatch(getCompanyAccess(data));
    },
    patchApproveAccess: function (data, update) {
      return dispatch(patchApproveAccess(data, update));
    },
    patchDisapproveAccess: function (data, update) {
      return dispatch(patchDisapproveAccess(data, update));
    },
    getCnpj: function () {
      return dispatch(getCnpj());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CompanyAccess));
