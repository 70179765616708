import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* updateUserRequest({ data, update }) {
  try {
    yield call(api.postUpdateUser, data);
    yield put(Creators.updateUserSuccess());
    let filter = SessionStorage.getItem("userListFilter");
    Alerts.alertSuccess("Usuário editado com sucesso!");
    if (filter === null) {
      update({ fullName: null, email: null, status: null, role: null });
    } else {
      update(filter);
    }
  } catch (response) {
    Alerts.alertError(
      response?.data?.log ? response?.data?.log : "Erro ao editar usuário."
    );
    yield put(Creators.updateUserFailure());
  }
}
