import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../../lib";

export default function* getFinantialData({ filter }) {
  try {
    let data;
    if (filter === null) {
      data = {
        cityId: null,
        mesoRegionId: null,

        isAssociateCompany: null,
        businessModelIds: null,
        typeOfSolutionsIds: null,
        sizeIds: null,
        verticalIds: null,
        sectorIds: null,
        allNull: true,
      };
    } else {
      data = filter;
    }
    const response = yield call(api.postFinantialFilters, data);
    yield put(Creators.getFinantialDataSuccess(response));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log ? response?.data?.log : "Erro ao filtrar indicadores."
    );
    yield put(Creators.getFinantialDataFailure());
  }
}
