import React from "react";
import { Tolltip } from "../../../../components";
import Styles from "../styles/Styles";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { connect } from "react-redux";
import { Filters } from "../../../../lib";
//import { Texts } from "../../../../config";
function CardFlatIndicator({ data, isFetching, label, tip, type, userData }) {
  const convertMoney = (value) => {
    if (value === null) {
      return;
    }
    return Filters.convertNumberToAbbreviation(value);
  };
  return (
    <>
      {userData && userData?.role.includes("ACATE") ? (
        <Styles.Card>
          <Styles.HeaderCard>
            <Styles.TitleCard>
              {label} <Tolltip title={label} tip={tip} />
            </Styles.TitleCard>
          </Styles.HeaderCard>
          <Styles.Line />

          {data && !isFetching && (
            <Styles.NumberCard>
              <Styles.IndicadorContainer>
                <Styles.BigIndicator>
                  {type === "percent"
                    ? (data?.subData).toFixed(1) + "%"
                    : convertMoney(data?.subData)}
                </Styles.BigIndicator>
              </Styles.IndicadorContainer>
            </Styles.NumberCard>
          )}
        </Styles.Card>
      ) : (
        <Styles.Card>
          <Styles.HeaderCard>
            <Styles.TitleCard>
              {label} <Tolltip title={label} tip={tip} />
            </Styles.TitleCard>
          </Styles.HeaderCard>
          <Styles.Line />

          {data && !isFetching && (
            <Styles.NumberCard>
              {data?.extra !== undefined ? (
                <Styles.IndicadorContainer>
                  <Styles.BigIndicator
                    $margin={data?.focusData !== null ? true : false}
                  >
                    {type === "percent"
                      ? (data?.focusData).toFixed(1) + "%"
                      : convertMoney(data?.focusData)}
                  </Styles.BigIndicator>
                  {data?.extra?.map((item) => {
                    return (
                      <Styles.SmallCard>
                        <Styles.SmallTitle>{item.title}</Styles.SmallTitle>
                        <Styles.SmallIndicator>
                          {type === "percent"
                            ? (item?.value).toFixed(1) + "%"
                            : convertMoney(item.value)}
                        </Styles.SmallIndicator>
                      </Styles.SmallCard>
                    );
                  })}
                </Styles.IndicadorContainer>
              ) : (
                <Styles.IndicadorContainer>
                  <Styles.BigIndicator>
                    {type === "percent"
                      ? (data?.focusData).toFixed(1) + "%"
                      : convertMoney(data?.focusData)}
                  </Styles.BigIndicator>
                </Styles.IndicadorContainer>
              )}
              <Styles.IndicadorContainer>
                <Styles.SubIndicator>
                  {type === "percent"
                    ? (data?.subData).toFixed(1) + "%"
                    : convertMoney(data?.subData)}
                </Styles.SubIndicator>{" "}
                <Styles.Diference negative={data?.diference < 0 ? true : false}>
                  (
                  {data?.diference < 0 ? (
                    <ArrowDropDownIcon />
                  ) : data?.diference > 0 ? (
                    <ArrowDropUpIcon />
                  ) : (
                    "- "
                  )}
                  {type === "percent"
                    ? (data?.diference).toFixed(1) + "%"
                    : convertMoney(data?.diference)}
                  )
                </Styles.Diference>
              </Styles.IndicadorContainer>
            </Styles.NumberCard>
          )}
        </Styles.Card>
      )}
    </>
  );
}
function mapStateToProps(state) {
  const { userData } = state.global;
  return {
    userData,
  };
}

export default connect(mapStateToProps)(React.memo(CardFlatIndicator));
