import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* newUserRequest({ data, update }) {
  try {
    yield call(api.postNewUser, data);
    let filter = SessionStorage.getItem("userListFilter");

    yield put(Creators.newUserSuccess());
    Alerts.alertSuccess("Usuário cadastrado com sucesso!");
    if (filter === null) {
      update({ fullName: null, email: null, status: null, role: null });
    } else {
      update(filter);
    }
  } catch (response) {
    Alerts.alertError(
      response?.data?.log ? response?.data?.log : "Erro ao cadastrar usuário . "
    );
    yield put(Creators.newUserFailure());
  }
}
