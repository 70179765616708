import React from "react";

import Styles from "../styles/Styles";

import CardIndicator from "./CardIndicator";
import CardFlatIndicator from "./CardFlatIndicator";
import { Grid } from "@material-ui/core";
function DisplayIndicators({ data, isFetching, tooltips }) {
  return (
    <Styles.ContainerCards>
      <Grid container spacing={1}>
        <Grid item sm={12} md={6}>
          <CardFlatIndicator
            type="percent"
            tip={tooltips?.mulheres_EM_CARGO_DE_LIDERANCA}
            label={"MULHERES EM CARGO DE LIDERANÇA"}
            data={{
              focusData: data?.womenInLeadershipPositions?.myCompany,
              subData: data?.womenInLeadershipPositions?.allCompanies,
              diference: data?.womenInLeadershipPositions?.difference,
            }}
            isFetching={isFetching}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <CardIndicator
            tip={tooltips?.enps}
            data={data?.enps?.data}
            isFetching={isFetching}
            label={"E-NPS"}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <CardIndicator
            tip={tooltips?.numero_DE_COLABORADORES}
            data={data?.numberOfCollaborators?.data}
            isFetching={isFetching}
            label={"NÚMERO DE COLABORADORES"}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <CardIndicator
            tip={tooltips?.retencao_DE_TALENTOS}
            data={data?.retainingTalent?.data}
            isFetching={isFetching}
            label={"RETENÇÃO DE TALENTOS"}
          />
        </Grid>

        <Grid item sm={12} md={6}>
          <CardIndicator
            tip={tooltips?.turnover}
            data={data?.turnover?.data}
            isFetching={isFetching}
            label={"TURNOVER"}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <CardIndicator
            tip={tooltips?.investimento_EM_ACOES_SOCIOAMBIENTAIS}
            data={data?.investimentInSocioEnvironmentActions?.data}
            isFetching={isFetching}
            label={"INVESTIMENTO EM AÇÕES SOCIOAMBIENTAIS"}
          />
        </Grid>

        <Grid item sm={12} md={6}>
          <CardIndicator
            tip={tooltips?.genero}
            data={data?.gender?.data}
            isFetching={isFetching}
            label={"GÊNERO"}
          />
        </Grid>
      </Grid>
    </Styles.ContainerCards>
  );
}

export default DisplayIndicators;
