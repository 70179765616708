import createReducers from "../../../../store/helpers/createPageReducer";
import getManagementData from "./getManagementData";
import getManageriaTooltips from "./getManageriaTooltips";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getManagementData",
      params: ["filter"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getManagementData,
    },
    {
      name: "getManagementDataSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        managementData: data,
      }),
    },
    {
      name: "getManagementDataFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        managementData: null,
      }),
    },

    {
      name: "getManageriaTooltips",
      params: ["filter"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getManageriaTooltips,
    },
    {
      name: "getManageriaTooltipsSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        tooltips: data,
      }),
    },
    {
      name: "getManageriaTooltipsFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        tooltips: null,
      }),
    },
  ],
  {
    isFetching: false,
    managementData: null,
    tooltips: null,
  }
);

export { Creators, reducers, sagas };
