import React from "react";
import { Texts } from "../../../config";
import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { Grid } from "@material-ui/core";
import Title from "../../../components/strings/Title";
import { ButtonOutlined, InputEmail } from "../../../components";

function StepOne({ goBack, onSubmit, title, text, loading }) {
  const texts = Texts["pt-BR"].forgotPassword.emailConfirmatiom;

  const sendEmailToken = (data) => {
    onSubmit(data);
  };

  return (
    <>
      <Styles.Content>
        <Title>{title}</Title>
        <Styles.Subtitle>{text}</Styles.Subtitle>
        <FormHolder onSubmit={sendEmailToken}>
          <InputEmail
            name="email"
            autoComplete={"email"}
            required={texts.inputEmailRequiredMessage}
            inputLabel={texts.email}
            variant="standard"
          />
          <Grid item xs={12}>
            <Styles.ConfirmButton
              name="connect"
              type="submit"
              loading={loading}
              disable={loading}
            >
              {texts.confirm}
            </Styles.ConfirmButton>
          </Grid>
          <Grid item xs={12}>
            <ButtonOutlined
              loading={loading}
              disable={loading}
              onClick={goBack}
            >
              {texts.back}
            </ButtonOutlined>
          </Grid>
        </FormHolder>
      </Styles.Content>
    </>
  );
}

export default React.memo(StepOne);
