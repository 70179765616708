import { ReactComponent as AccountPlus } from "../assets/icons/account-plus.svg";
import { ReactComponent as Bell } from "../assets/icons/bell.svg";
import { ReactComponent as Bullhorn } from "../assets/icons/bullhorn.svg";
import { ReactComponent as CalendarClock } from "../assets/icons/calendar-clock.svg";
import { ReactComponent as ChartBubble } from "../assets/icons/chart-bubble.svg";
import { ReactComponent as ChartSankey } from "../assets/icons/chart-sankey-variant.svg";
import { ReactComponent as DoorOpen } from "../assets/icons/door-open.svg";
import { ReactComponent as FileChart } from "../assets/icons/file-chart.svg";
import { ReactComponent as Star } from "../assets/icons/star.svg";
import { ReactComponent as AccountEdit } from "../assets/icons/account-edit.svg";
import { ReactComponent as Export } from "../assets/icons/file-export.svg";
import { ReactComponent as Marker } from "../assets/icons/map-marker.svg";
import { ReactComponent as GreenPin } from "../assets/icons/pin-green.svg";
import { ReactComponent as BluePin } from "../assets/icons/pin-blue.svg";
const Icons = {
  AccountPlus,
  Bell,
  Bullhorn,
  CalendarClock,
  ChartBubble,
  ChartSankey,
  DoorOpen,
  FileChart,
  Star,
  AccountEdit,
  Export,
  Marker,
  BluePin,
  GreenPin,
};

export default Icons;
