import React from "react";

import Styles from "../styles/Styles";

import CardIndicator from "./CardIndicator";
import CardFlatIndicator from "./CardFlatIndicator";
import { Grid } from "@material-ui/core";

function DisplayIndicators({ data, isFetching, tooltip }) {
  return (
    <Styles.ContainerCards>
      <Grid container spacing={1}>
        <Grid item sm={6} md={4}>
          <CardFlatIndicator
            label={"INVESTIMENTO EM P&D"}
            tip={tooltip?.investimento_EM_PeD}
            type="percent"
            data={{
              diference: data?.investmentInRD?.difference,
              focusData: data?.investmentInRD?.myCompany,
              subData: data?.investmentInRD?.allCompanies,
            }}
            isFetching={isFetching}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <CardFlatIndicator
            label={"NÚMERO DE CLIENTES ATIVOS"}
            tip={tooltip?.numero_DE_CLIENTES_ATIVOS}
            data={{
              diference: data?.numberOfActiveCustomers?.difference,
              focusData: data?.numberOfActiveCustomers?.myCompanyAll,
              subData: data?.numberOfActiveCustomers?.allCompanies,
              extra: [
                {
                  title: "B2B",
                  value: data?.numberOfActiveCustomers?.myCompanyB2B,
                },
                {
                  title: "B2C",
                  value: data?.numberOfActiveCustomers?.myCompanyB2C,
                },
              ],
            }}
            isFetching={isFetching}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <CardFlatIndicator
            type="money"
            label={"VOLUME DE INVESTIMENTO CAPTADO"}
            tip={tooltip?.volume_DE_INVESTIMENTO_CAPTADO}
            data={{
              diference: data?.volumeOfInvestmentRaised?.difference,
              focusData: null,
              subData: data?.volumeOfInvestmentRaised?.allCompanies,
              extra: [
                {
                  title: "FOMENTO",
                  value: data?.volumeOfInvestmentRaised?.fomentation,
                },
                {
                  title: "PRIVADO",
                  value: data?.volumeOfInvestmentRaised?.privated,
                },
              ],
            }}
            isFetching={isFetching}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <CardIndicator
            tip={tooltip?.faturamento}
            data={data?.invoicing?.data}
            isFetching={isFetching}
            label={"FATURAMENTO"}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <CardIndicator
            tip={tooltip?.faturamento_POR_COLABORADOR}
            data={data?.revenuePerEmployee?.data}
            isFetching={isFetching}
            label={"FATURAMENTO POR COLABORADOR"}
          />
        </Grid>

        <Grid item sm={12} md={6}>
          <CardIndicator
            tip={tooltip?.margem_EBITDA}
            data={data?.marginEbitida?.data}
            isFetching={isFetching}
            label={"MARGEM EBITDA"}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <CardIndicator
            data={data?.mrr?.data}
            isFetching={isFetching}
            label={"MRR"}
            tip={tooltip?.mrr}
          />
        </Grid>

        <Grid item sm={12} md={6}>
          <CardIndicator
            data={data?.churnRate?.data}
            isFetching={isFetching}
            label={"CHURN RATE"}
            tip={tooltip?.churn_RATE}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <CardIndicator
                tip={tooltip?.custo_DE_AQUISICAO_DE_CLIENTES}
                data={data?.customerAcquisitionCost?.data}
                small
                isFetching={isFetching}
                label={"CUSTO DE AQUISIÇÃO DE CLIENTES"}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <CardIndicator
                small
                data={data?.ltv?.data}
                isFetching={isFetching}
                label={"LTV"}
                tip={tooltip?.ltv}
              />
            </Grid>
            <Grid item xs={12}>
              <CardIndicator
                data={data?.nps?.data}
                isFetching={isFetching}
                label={"NPS"}
                tip={tooltip?.nps}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <CardIndicator
            data={data?.revenueFromOverseasCustomers?.data}
            isFetching={isFetching}
            label={"% RECEITA DE CLIENTES NO EXTERIOR"}
            tip={tooltip?.porcentagem_DE_CLIENTES_NO_EXTERIOR}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <CardIndicator
            data={data?.netMargin?.data}
            isFetching={isFetching}
            label={"MARGEM LÍQUIDA"}
            tip={tooltip?.margem_LIQUIDA}
          />
        </Grid>
      </Grid>
    </Styles.ContainerCards>
  );
}

export default DisplayIndicators;
