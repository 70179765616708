import styled from "styled-components";
import { FontStyles, Text, ButtonContained, Input } from "../../../components";
import CopyrightIcon from "@material-ui/icons/Copyright";
const Container = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: colors.background.paper,
    flexDirection: "column",
    flexWrap: "wrap",
    padding: spacing(3, 4, 1, 4),
    justifyContent: "flex-start",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    width: "32rem",
    maxWidth: `calc(100% - ${spacing(2)}px)`,
    maxHeight: `calc(100% - ${spacing(0)}px)`,
    backgroundColor: colors.ba,
    borderRadius: 5,
    marginInline: "auto",
    overflowX: "hidden",
    overflowY: "hidden",
  };
});

const LoginSubtitle = styled(Text)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginBottom: spacing(5),
  };
});

const LogoContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "fit-content",
    marginBottom: spacing(7),
    display: "flex",
    flexDirection: "Column",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
  };
});

const LabelContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    marginTop: spacing(0),
    marginBottom: spacing(1),
    marginLeft: spacing(4),
    width: "100%",
    display: "flex",
  };
});
const InputCodeLabel = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.bold16,
    color: "black",
    fontSize: 12,
  };
});

const InputCode = styled(Input)(({ theme }) => {
  return {
    width: "100%",
    height: "80%",
    color: "#464646",
    "& .MuiInput-input": {
      fontSize: 30,
      marginLeft: 10,
      textTransform: "uppercase",
    },
  };
});

const AcateLogo = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(30.25),
    height: "auto",
  };
});
const SubLogo = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.bold16,
    marginInline: spacing(0),

    letterSpacing: 9,
    fontSize: "0.8rem",
    color: "#ACCA13",
    marginTop: spacing(-2),
  };
});

const Subtitle = styled(Text)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginBottom: spacing(5),
  };
});

const ImageBG = styled.div(({ theme, $Image }) => {
  return {
    width: "100%",
    height: "100%",
    backgroundImage: $Image,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    boxShadow: "inset 0 0 0 1000px #acca1359",
  };
});

const ContainerTextBG = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: spacing(9.5),
    flexDirection: "column",
    color: "white",
  };
});

const TextBG = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.bold22,
    fontSize: spacing(6.75),
    margin: 0,
    textAlign: "end",
  };
});

const ConfirmButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginBlock: spacing(5, 1),
  };
});
const Copyright = styled(CopyrightIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.text.primary,
    width: spacing(2),
    marginLeft: spacing(1.5),
  };
});

const TextCopyright = styled.p(({ theme }) => {
  return {
    ...FontStyles.bold16,
    margin: 0,
    textAlign: "end",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "white",
  };
});
const FLogo = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(16.125),
    height: "auto",
  };
});
const Styles = {
  Copyright,
  TextCopyright,
  Container,
  Content,
  LogoContainer,
  InputCodeLabel,
  Subtitle,
  ImageBG,
  TextBG,
  ContainerTextBG,
  AcateLogo,
  SubLogo,
  LoginSubtitle,
  ConfirmButton,
  InputCode,
  LabelContainer,
  FLogo,
};

export default Styles;
