import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { Creators } from "../index";
import { Alerts } from "../../../../lib";

export default function* getMesoRegions({ data }) {
  try {
    const mesoRegions = yield call(api.getMesoRegions, data);

    yield put(Creators.getMesoRegionsSuccess(mesoRegions));
  } catch (response) {
    Alerts.alertError("Não foi encontrar as Mesorregiões");
    yield put(Creators.getMesoRegionsFailure());
  }
}
