import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* patchDisapproveRelease({ data, update }) {
  try {
    yield call(api.patchRevokeAccess, data);
    let filter = SessionStorage.getItem("evaluatedReleasesFilter");
    yield put(Creators.patchDisapproveReleaseSuccess());
    Alerts.alertSuccess("Acesso da empresa foi revogada com sucesso!");
    update(filter);
  } catch (response) {
    Alerts.alertError("Erro ao revogar acesso da empresa");
    yield put(Creators.patchDisapproveReleaseFailure());
  }
}
