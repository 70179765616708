import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getCnpj() {
  try {
    const response = yield call(api.getCNPJ);
    if (response.ok) {
      yield put(Creators.getCnpjSuccess(response.data));
    }
  } catch (response) {
    Alerts.alertError("Erro ao carregar lista de CNPJ");
    yield put(Creators.getCnpjFailure());
  }
}
