import createReducers from "../../../store/helpers/createPageReducer";
import cnaListRequest from "./cnaListRequest";
import cityListRequest from "./cityListRequest";
import ufListRequest from "./ufListRequest";
import postRegistration from "./postRegistration";
import emailOnUse from "./emailOnUse";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "cnaListRequest",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: cnaListRequest,
    },
    {
      name: "cnaListRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        CNAEList: data,
      }),
    },
    {
      name: "cnaListRequestFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "emailOnUse",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: emailOnUse,
    },
    {
      name: "emailOnUseSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        isValid: data,
      }),
    },
    {
      name: "emailOnUseFailure",
      function: (state) => ({ ...state, isFetching: false, isValid: true }),
    },

    {
      name: "cityListRequest",
      params: ["data", "meso"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: cityListRequest,
    },
    {
      name: "cityListListSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        CityList: data,
      }),
    },
    {
      name: "cityListFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
    {
      name: "ufListRequest",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: ufListRequest,
    },
    {
      name: "ufListRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        UFList: data,
      }),
    },
    {
      name: "ufListRequestFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
    {
      name: "postRegistration",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: postRegistration,
    },
    {
      name: "postRegistrationSuccess",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "postRegistrationFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    CNAEList: null,
    UFList: null,
    CityList: null,
    isValid: true,
  }
);

export { Creators, reducers, sagas };
