export default function getUsersList(response) {
  const data = {
    email: response.data.response.email,
    name: response.data.response.firstName + " " + response.lastName,
    role: response.data.response.role.name,
    pages: response.data.response.role.pages,
    needToCompleteRegister: response.data.response.needToCompleteRegister,
    planName: response.data.response.planName,
    planBeginDate: response.data.response.planBeginDate,
    needToInputIndicators: response.data.response.needToInputIndicators,
  };
  return { ...response, userInfo: data };
}
