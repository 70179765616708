import React from "react";
import { General } from "../features";

function GeneralIndicators() {
  return (
    <>
      <General.financialIndicators.Container />
    </>
  );
}

export default GeneralIndicators;
