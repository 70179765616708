import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getIndicatorsYear() {
  try {
    const response = yield call(api.getIndicatorsYear, 1);
    yield put(Creators.getFinancialIndicatorsYearSuccess(response));
  } catch (response) {
    Alerts.alertError("Não foi possível encontrar os anos dos indicadores.");
    yield put(Creators.getFinancialIndicatorsFailure());
  }
}
