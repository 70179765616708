import React from "react";
import { Input, Tolltip } from "../../../components";
import Styles from "../styles/Styles";
import Grid from "@material-ui/core/Grid";
import { Filters, Validations } from "../../../lib";
import { Texts } from "../../../config";
function GenericCard({ data, nextData, saveOnLocal, isEditable }) {
  const isOdd = (num) => num % 2 === 1;
  const texts = Texts["pt-BR"].financialData;
  return (
    <Styles.CardContainer>
      <Styles.CardHeader $info>
        <Styles.CardTitle>{data.label}</Styles.CardTitle>
        <Tolltip title={data.label} tip={data.tip} disableFocusListener />
      </Styles.CardHeader>
      <Styles.Line />
      {nextData && data && (
        <Grid container spacing={1}>
          {data?.inputs?.map((item, i) => {
            return (
              <Grid
                style={{ alignItems: "flex-end", display: "flex" }}
                item
                xs={i === data.inputs.length - 1 && isOdd(i + 1) ? 12 : 6}
              >
                {data.label === "eNPS" ? (
                  <Input
                    readOnly={isEditable}
                    nextInput={
                      data?.inputs?.length === i + 1
                        ? nextData?.inputs[0]?.id
                        : data.inputs[i + 1]?.id
                    }
                    key={item.id}
                    variant="standard"
                    name={item.id}
                    inputLabel={
                      item.label +
                      (item?.required
                        ? " (Obrigatório)"
                        : item?.notApplicable
                        ? " (Não se aplica)"
                        : "")
                    }
                    defaultValue={item.value}
                    mask={(data) =>
                      Filters.convertNumberInputMask(data, false, true, true)
                    }
                    maskToSubmit={Filters.convertNumberTextMask}
                    validation={(data) => {
                      return Validations.maxAndMinNumber(data, 10, 0);
                    }}
                    onBlur={saveOnLocal}
                  />
                ) : item.valueType === "INTEGER" ? (
                  <Input
                    readOnly={isEditable}
                    nextInput={
                      data?.inputs?.length === i + 1
                        ? nextData?.inputs[0]?.id
                        : data.inputs[i + 1]?.id
                    }
                    key={item.id}
                    variant="standard"
                    name={item.id}
                    required={item?.required ? texts.required : false}
                    inputLabel={
                      item.label +
                      (item?.required
                        ? " (Obrigatório)"
                        : item?.notApplicable
                        ? " (Não se aplica)"
                        : "")
                    }
                    defaultValue={item.value}
                    mask={(data) =>
                      Filters.convertNumberInputMask(data, false, false)
                    }
                    maskToSubmit={Filters.clearStringOnlyNumbers}
                    validation={Validations.isNumber}
                    onBlur={saveOnLocal}
                  />
                ) : item.valueType === "MONEY" ? (
                  <Input
                    readOnly={isEditable}
                    nextInput={
                      data?.inputs?.length === i + 1
                        ? nextData?.inputs[0]?.id
                        : data.inputs[i + 1]?.id
                    }
                    onBlur={saveOnLocal}
                    key={item.id}
                    name={item.id}
                    required={item?.required ? texts.required : false}
                    inputLabel={
                      item.label +
                      (item?.required
                        ? " (Obrigatório)"
                        : item?.notApplicable
                        ? " (Não se aplica)"
                        : "")
                    }
                    defaultValue={item.value}
                    mask={(data) =>
                      Filters.convertNumberInputMask(data, true, true)
                    }
                    maskToSubmit={Filters.convertNumberTextMask}
                    validation={Validations.isNumber}
                  />
                ) : item.valueType === "PERCENTAGE" ? (
                  <Input
                    readOnly={isEditable}
                    nextInput={
                      data?.inputs?.length === i + 1
                        ? nextData?.inputs[0]?.id
                        : data.inputs[i + 1]?.id
                    }
                    percentage
                    onBlur={saveOnLocal}
                    key={item.id}
                    name={item.id}
                    required={item?.required ? texts.required : false}
                    inputLabel={
                      item.label +
                      (item?.required
                        ? " (Obrigatório)"
                        : item?.notApplicable
                        ? " (Não se aplica)"
                        : "")
                    }
                    defaultValue={item.value}
                    mask={(data) =>
                      Filters.convertNumberInputMask(data, false, true)
                    }
                    maskToSubmit={Filters.convertNumberTextMask}
                    validation={(data) => {
                      return Validations.maxAndMinNumber(data, 100, 0);
                    }}
                  />
                ) : item.valueType === "DECIMAL" ? (
                  <Input
                    readOnly={isEditable}
                    nextInput={
                      data?.inputs?.length === i + 1
                        ? nextData?.inputs[0]?.id
                        : data.inputs[i + 1]?.id
                    }
                    onBlur={saveOnLocal}
                    key={item.id}
                    name={item.id}
                    defaultValue={item.value}
                    required={item?.required ? texts.required : false}
                    inputLabel={
                      item.label +
                      (item?.required
                        ? " (Obrigatório)"
                        : item?.notApplicable
                        ? " (Não se aplica)"
                        : "")
                    }
                    mask={(data) =>
                      Filters.convertNumberInputMask(data, false, true)
                    }
                    maskToSubmit={Filters.convertNumberTextMask}
                    validation={Validations.isNumber}
                  />
                ) : (
                  <Input
                    readOnly={isEditable}
                    nextInput={
                      data?.inputs?.length === i + 1
                        ? nextData?.inputs[0]?.id
                        : data.inputs[i + 1]?.id
                    }
                    onBlur={saveOnLocal}
                    key={item.id}
                    defaultValue={item.value}
                    name={item.id}
                    required={item?.required ? texts.required : false}
                    inputLabel={
                      item.label +
                      (item?.required
                        ? " (Obrigatório)"
                        : item?.notApplicable
                        ? " (Não se aplica)"
                        : "")
                    }
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
    </Styles.CardContainer>
  );
}

export default React.memo(GenericCard);
