import createReducers from "../../../store/helpers/createPageReducer";
import getApprovalList from "./getApprovalList";
import putApprovalPlan from "./putApprovalPlan";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getApprovalList",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getApprovalList,
    },
    {
      name: "getApprovalListSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        approvalList: data,
      }),
    },
    {
      name: "getApprovalListFailure",
      function: (state) => ({ ...state, isFetching: false, usersList: null }),
    },

    {
      name: "putApprovalPlan",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: putApprovalPlan,
    },
    {
      name: "putApprovalPlanSuccess",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "putApprovalPlanFailure",
      function: (state) => ({ ...state, isFetching: false, usersList: null }),
    },
  ],
  {
    isFetching: false,
    approvalList: null,
  }
);

export { Creators, reducers, sagas };
