import React from "react";
import { connect } from "react-redux";

import { Styles } from "../styles";
import { Pagination, Table } from "../../../components";
import { Texts } from "../../../config";
import { Filters } from "../../../lib";
function UsersList({ usersList, page, setPage, totalPages = 3 }) {
  const texts = Texts["pt-BR"].accessHistory;

  return (
    <>
      <Styles.Content>
        <Styles.HeaderCard>
          <Styles.TitleCard>{texts.table.title}</Styles.TitleCard>
        </Styles.HeaderCard>
        <Styles.Line />
        <Table
          headers={{
            table: texts.table.headers,
          }}
          data={usersList}
          renderItemColumns={(item) => [
            item.name,
            item.releasedDate === null
              ? "Não encontrada"
              : Filters.formatDate(item.releasedDate),
            Filters.formatDateTime(item.lastAccess),
            item.status ? "Acesso Liberado" : "Acesso Revogado",
          ]}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Styles.Content>
    </>
  );
}

export default connect()(React.memo(UsersList));
