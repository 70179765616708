import createReducers from "../../../store/helpers/createPageReducer";
import evaluatedReleasesRequest from "./evaluatedReleasesRequest";
import patchDisapproveRelease from "./patchDisapproveRelease";
import getPlansList from "./getPlansList";
import exportUserList from "./exportUserList";
import patchApproveRelease from "./patchApproveRelease";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getEvaluatedReleases",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: evaluatedReleasesRequest,
    },
    {
      name: "evaluatedReleasesSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        evaluatedReleases: data,
      }),
    },
    {
      name: "evaluatedReleasesFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        evaluatedReleases: [],
      }),
    },

    {
      name: "getPlansList",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getPlansList,
    },
    {
      name: "getPlansListSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        planList: data,
      }),
    },
    {
      name: "getPlansListFailure",
      function: (state) => ({ ...state, isFetching: false, planList: null }),
    },

    {
      name: "patchDisapproveRelease",
      params: ["data", "update"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: patchDisapproveRelease,
    },
    {
      name: "patchDisapproveReleaseSuccess",
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "patchDisapproveReleaseFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "patchApproveRelease",
      params: ["data", "update"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: patchApproveRelease,
    },
    {
      name: "patchApproveReleaseSuccess",
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "patchApproveReleaseFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "exportUserList",
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: exportUserList,
    },
    {
      name: "exportUserListSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        exportData: data,
      }),
    },
    {
      name: "exportUserListFailure",
      function: (state) => ({ ...state, isFetching: false, exportData: "A" }),
    },
  ],
  {
    isFetching: false,
    evaluatedReleases: null,
    exportData: null,
    planList: null,
  }
);

export { Creators, reducers, sagas };
