import React from "react";
import {
  FormControlLabel,
  FormControl,
  FormHelperText,
  Switch,
  withTheme,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";

import { FontStyles } from "../index";
import styled from "styled-components";
import Text from "../strings/Text";

const Label = styled(Text)(({ theme, smallLabel, $withError, $maxHeight }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.bold14,
    fontFamily: smallLabel
      ? FontStyles.semibold14.fontFamily
      : FontStyles.bold14.fontFamily,
    zIndex: $maxHeight ? 1000 : 10,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withError ? colors.error.main : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});
const Container = styled.div(({ theme, compact }) => {
  const { palette: colors, spacing } = theme;
  if (compact) {
    return {
      padding: spacing(0.625),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    };
  } else {
    return {
      backgroundColor: colors.background.input,
      padding: spacing(0.625),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: spacing(0.6),
      width: "100%",
    };
  }
});
const ValueText = styled(Text)(({ theme }) => {
  const { palette: colors, spacing } = theme;

  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
    marginLeft: spacing(1),
  };
});
const AntSwitch = styled(Switch)(({ theme }) => {
  const { palette: colors, spacing } = theme;

  return {
    width: spacing(6),
    height: spacing(3.25),
    marginBlock: spacing(0.75),
    borderRadius: spacing(5),
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: spacing(4.25),
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: spacing(0.3),
      "&.Mui-checked": {
        transform: `translateX(${spacing(2.5)}px)`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: colors.text.tertiary,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: spacing(2.5),
      height: spacing(2.5),
      borderRadius: spacing(5),
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: spacing(5),
      opacity: 1,
      backgroundColor: colors.text.quaternary,
      boxSizing: "border-box",
    },
  };
});

function SwitchComponent(props) {
  const { label, required, disableError, smallLabel, compact, onChangeValue } =
    props;

  const { value, error, setConfigs, ref } =
    formConnector.useBooleanValue(props);

  return (
    <FormControl fullWidth required={required} error={error}>
      <Label
        smallLabel={smallLabel}
        $withValue={Boolean(value) || value === 0}
        $withError={Boolean(error)}
      >
        {label}
      </Label>
      {compact ? (
        <Container compact={compact}>
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <AntSwitch checked={value} inputRef={ref} color="primary" />
            }
            onChange={() => setConfigs(!value)}
          />
          <ValueText>{Boolean(value) ? "Sim" : "Não"}</ValueText>
        </Container>
      ) : (
        <Container>
          <ValueText>{Boolean(value) ? "Sim" : "Não"}</ValueText>
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <AntSwitch checked={value} inputRef={ref} color="primary" />
            }
            onChange={() => {
              setConfigs(!value);
              if (onChangeValue) {
                onChangeValue(!value);
              }
            }}
          />
        </Container>
      )}

      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(SwitchComponent);
