export default function CompleteRegister(response) {
  const requestData = {
    responsibleName: response.responsibleName.toLowerCase(),
    officeId: response.charge,
    telephone: response.phone,
    foundationYear: response.foundationYear,
    sizeId: response.companyPort,

    businessModelIds: response.businessModel.map((item) => {
      return item.value;
    }),
    typeOfSolutionIds: response.solutionType.map((item) => {
      return item.value;
    }),
    verticalIds: response.acateVertical.map((item) => {
      return item.value;
    }),
    sectorIds: response.sector.map((item) => {
      return item.value;
    }),
    employeeNumber: response.employeeNumber,
  };

  return requestData;
}
