const baseRequiredMessage = "*Campo obrigatório.";
// const baseRequiredFieldsDescription = "* Preenchimento obrigatório";

const ptBr = {
  notification: {
    back: "VOLTAR",
    complete: "COMPLETAR CADASTRO",
    title: "FINALIZE SEU CADASTRO!",
    firstTitle: "Boas-vindas à plataforma Data Tech Acate!",
    firstModalText: [
      "Você será redirecionado para completar o seu cadastro na plataforma e, em seguida, deverá preencher os dados dos indicadores de sua empresa. Estas ações são obrigatórias para que você possa utilizar a ferramenta.",
    ],
    ok: "COMPLETAR CADASTRO",
    modalText: [
      "Para poder utilizar o sistema e todo seu poder de análise precisamos que você finalize o seu cadastro com seus dados mais recentes(menu Sistema/Meu Perfil) e preencha os indicadores com os dados Financeiros e Gerenciais (menu Preenchimento Indicadores).",
    ],
    checkbox: "Não mostrar esta mensagem novamente",
  },
  months: [
    { initials: "Jan", fullName: "Janeiro" },
    { initials: "Fev", fullName: "Fevereiro" },
    { initials: "Mar", fullName: "Março" },
    { initials: "Abr", fullName: "Abril" },
    { initials: "Mai", fullName: "Maio" },
    { initials: "Jun", fullName: "Junho" },
    { initials: "Jul", fullName: "Julho" },
    { initials: "Ago", fullName: "Agosto" },
    { initials: "Set", fullName: "Setembro" },
    { initials: "Out", fullName: "Outubro" },
    { initials: "Nov", fullName: "Novembro" },
    { initials: "Dez", fullName: "Dezembro" },
  ],
  version: "AcateHub  Web ",
  modals: {
    success: {
      buttonLabel: "OK",
    },
    errors: {
      buttonLabel: "Ok",
    },
  },
  login: {
    title: "LOGIN",
    subtitle: "Por favor, preencha os seguintes campos para entrar:",
    remember: "Lembrar-me",
    forgotPass: "Esqueci a minha senha",
    keepData: "LEMBRAR DADOS",
    enter: "Entrar",
    continue: "Continuar",

    firstAccess: "Solicitar Acesso",
    acateMode: "Acesso visão ACATE",
    bgText: ["WE CREATE", <br />, "THE INNOVATION", <br />, "THE WORLD NEEDS."],
    copyright: " ASSOCIAÇÃO CATARINENSE DE TECNOLOGIA, 2022",
    password: "Senha",
    confirmPassword: "Confirmar Senha",
    selectCD: "Selecionar outra unidade",
    enterIn: (cd) => {
      return `Entrando na unidade: ${cd}`;
    },
    passwordMessage: baseRequiredMessage,
    user: "E-MAIL",
    userMessage: baseRequiredMessage,
    stepTwo: {
      cpf: "CPF",
      cnpj: "CNPJ",
      email: "Email",
      name: "Nome do usuário",
      companyName: "Nome da empresa",
      city: "Cidade",
      uf: "UF",
      phone: "Telefone (Opcional)",
      cnae: "CNAE (Opcional)",
      cnae2: "CNAE",
    },
    modal: {
      title: "Termos",
      subtitle: "Leia atentamente antes de continuar:",
      termTitle: "AO USUÁRIO DA ACATE INDICADORES",
      agree: "Li e Concordo com os ",
      terms: "Termos de Uso",
      confirm: "CONFIRMAR",
    },
  },
  registry: {
    title: "CADASTRO BÁSICO",
    subtitle: "Por favor, informe os seguintes campos para se cadastrar:",
    confirm: "Confirmar",
    back: "Voltar",
  },
  forgotPassword: {
    subtitle: "INDICADORES",
    bgText: ["WE CREATE", <br />, "THE INNOVATION", <br />, "THE WORLD NEEDS."],
    copyright: " ASSOCIAÇÃO CATARINENSE DE TECNOLOGIA, 2022",
    Title: "ESQUECI MINHA SENHA",
    emailConfirmatiom: {
      text: "Para continuar, insira seu email. Caso o email esteja correto, você receberá um código de confirmação para definir sua nova senha:",
      confirm: "Confirmar",
      back: "voltar",
      email: "E-MAIL",
      successResponse: "Senha alterada com sucesso.",
      inputEmailRequiredMessage: baseRequiredMessage,
    },

    tokenConfirmatiom: {
      textToken:
        "Para redefinir sua senha, confirme o Token de 5 dígitos enviado para o seu email:",
      instertToken: "INSIRA O TOKEN:",
      confirmToken: "CONFIRMAR",
      resendToken: "Reenviar Token",
      back: "voltar",
    },

    passwordConfirmatiom: {
      textNewPassword:
        "Digite abaixo uma nova senha que gostaria de usar para sua conta:",
      newPassword: "Nova Senha",
      confirmNewPassword: "Confirmação de Nova Senha",
      inputPasswordRequiredMessage: "*Campo Obrigatório",
      confirm: "Confirmar",
      back: "voltar",
      modalTitle: "SENHA REDEFINIDA",
      modalText: "A sua nova senha foi redefinida com sucesso!",
      buttomModal: "IR PARA LOGIN",
    },
  },
  error: {
    backHome: "VOLTAR PARA HOME",
    reload: "RECARREGAR",
    error404: "Erro 404.",
    message404: " Esta página apresenta estar fora do ar.",
  },
  header: {
    settings: "Configurações",
    system: "Sistema",
    settingsOptions: {
      integration: "Integrações",
      users: "Usuários",
      permissions: "Permissões",
      changePassword: "Alterar Senha",
    },
    exit: "Deseja encerra sua sessão?",
    exitConfirmationText:
      "Deseja realmente encerra sua sessão e sair do sistema?",
    exitConfirmation: "Sair",
    exitDeny: "Cancelar",
  },

  Menu: {
    system: "SISTEMA",
    general: "GERAL",
    panel: "PAINEL DE ACESSO EMPRESA",
    fillingIndicators: "PREENCHIMENTO INDICADORES",
  },

  leave: {
    title: "Sair",
    logoutModal: {
      exitTitle: "SAIR DO SISTEMA",
      exitConfirmationText:
        "Deseja realmente encerrar sua sessão e sair do sistema?",
      exitConfirmation: "Sim, sair",
      back: "Voltar",
    },
  },

  financialIndicators: {
    title: "INDICADORES FINANCEIROS",
  },

  managementIndicators: {
    title: "INDICADORES GERENCIAIS",
  },

  premium: {
    title: "TORNE-SE PREMIUM!",
  },

  Users: {
    title: "USUÁRIOS",

    table: ["Nome do Usuario", "Email", "Papel", "Status", "Ação"],
    tableTitle: "LISTA DE USUÁRIOS",
    activate: "Ativar",
    deactivate: "Desativar",
    modalNew: {
      title: "CADASTRAR NOVO USUÁRIO",
      subTitle:
        "Abaixo, informe as credenciais do usuário que deseja cadastrar:",
      confirm: "Confirmar cadastrar",
      exit: "Cancelar",
    },
    modalUpdate: {
      title: "EDITAR USUÁRIO",
      subTitle: "Abaixo, informe as credenciais do usuário que deseja editar:",
      confirm: "Confirmar edição",
      exit: "Cancelar",
    },
    modalDelete: {
      title: ["Desativar usuário ", "Ativar usuário "],
      subTitle: ["Deseja desativar o usuario ", "Deseja ativar o usuario "],
      confirm: "Confirmar",
      exit: "Cancelar",
    },
    modalManager: {
      title: "NOVO USUÁRIO GESTOR",
      subTitle: [
        "A troca de usuário gestor fará com que sua sessão seja encerrada e suas permissões redefinidas para Colaborador a partir do próximo acesso. Confirmar troca de gestor para o usuário selecionado?",
      ],
      name: "NOME DO USUÁRIO",
      email: "E-MAIL",
      role: "CARGO ATUAL",
      confirm: "CONFIRMAR TROCA",
      exit: "Cancelar",
    },
  },
  Profile: {
    title: "MEU PERFIL",
    confirm: "Confirmar Alterações",
    validationField: "*Campo Obrigatório",
    newPassword: {
      passwordMatch: "As senhas não correspondem.",
      passwordChange: "Alterar Senha",
      password: "Senha Atual",
      newPassword: "Nova Senha",
      confirmPassword: "Confirmar Nova Senha",
    },
  },
  financialData: {
    required: "*Campo Obrigatório",
    title: "DADOS FINANACEIROS",
    send: "SELEÇÃO DO ANO DE ENVIO",
    year: "Ano",
    info: "O botão de 'Enviar Dados' será habilitado uma vez que completar todos os campos. Uma vez enviado, você não poderá realizar um novo envio.",
    confirm: "Enviar Dados",
  },
  managementData: {
    title: "DADOS GERENCIAIS",
    send: "SELEÇÃO DO ANO DE ENVIO",
    year: "Ano",
    info: "O botão de 'Enviar Dados' será habilitado uma vez que completar todos os campos. Uma vez enviado, você não poderá realizar um novo envio.",
    confirm: "Enviar Dados",
  },
  dataEntry: {
    title: "Entrada de Dados",
    send: "Cadastro de Indicadores",
    list: "Lista de Indicadores",
    category: "Categoria",
    info: "O botão de 'Enviar Dados' será habilitado uma vez que completar todos os campos. Uma vez enviado, você não poderá realizar um novo envio.",
    confirm: "Enviar Dados",
    back: "Voltar",
    indicatorTypes: ["Indicadores Financeiros", "Indicadores Gerenciais"],
    newIndicator: "Cadastrar Novo Indicador",
    indicatorDatails: "Detalhes da Categoria",
    indicatorTable: [
      "TÍTULO DO INDICADOR",
      "TIPO DE DADO",
      "CAMPO OBRIGATÓRIO?",
      "NÃO SE APLICA?",
      "Ação",
    ],
    groupTable: ["Nº Ordenação", "Categoria", "ToolTip", "Ação"],
    saving: "Salvando!",
    editTitle: "EDITAR INDICADOR ",
    editSub: ["Abaixo, informe os dados do indicador a ser editado:"],
    newTitle: "CADASTRAR INDICADOR ",
    newSub: ["Abaixo, informe os dados do novo indicador a ser cadastrado:"],
    modal: {
      name: "TÍTULO DO INDICADOR",
      group: "Categoria",
      groupName: "Nome da Categoria",
      tip: "DESCRIÇÃO DE TOOLTIP",
      valueType: "TIPO DE DADO",
      required: "CAMPO OBRIGATÓRIO",
      notApplicable: "NÃO SE APLICA?",
      cancel: "Cancelar",
      confirmEdit: "Confirmar Edição",
      confirmNew: "Confirmar Cadastro",
      inputReq: ["Selecione", "Campo Obrigatorio*"],
    },
  },
  accessHistoryClients: {
    title: "HISTÓRICOS DE ACESSOS",
    filterTitle: "FILTRAR HISTÓRICO DE ACESSOS",
    table: {
      title: "LISTA DE USUÁRIOS",
      username: "NOME DO USUÁRIO",
      release: "DATA DA LIBERAÇÃO",
      last: "ÚLTIMO ACESSO",
      clean: "Limpar",
      search: "Pesquisar",
      headers: [
        "NOME DO USUÁRIO",
        "EMPRESA",
        "DATA DA LIBERAÇÃO",
        "ÚLTIMO ACESSO",
        "STATUS",
      ],
    },
  },
  accessHistory: {
    title: "HISTÓRICOS DE ACESSOS",
    filterTitle: "FILTRAR HISTÓRICO DE ACESSOS",
    table: {
      title: "LISTA DE USUÁRIOS",
      username: "NOME DO USUÁRIO",
      company: "EMPRESA",
      release: "DATA DA LIBERAÇÃO",
      last: "ÚLTIMO ACESSO",
      clean: "Limpar",
      search: "Pesquisar",
      headers: [
        "NOME DO USUÁRIO",
        "DATA DA LIBERAÇÃO",
        "ÚLTIMO ACESSO",
        "STATUS",
      ],
    },
  },
  plansApproval: {
    title: "APROVAÇÃO DE PLANOS",
    filterTitle: "FILTRAR SOLICITAÇÕES DE MUDANÇA DE PLANO",
    table: {
      title: "LIBERAÇÕES PENDENTES",
      enterprise: "SOLICITANTE",
      plan: "PLANO",
      date: "PERÍODO DA SOLICITAÇÃO",
      clean: "Limpar",
      search: "Pesquisar",
      headers: ["SOLICITANTE", "PLANO", "DATA DA SOLICITAÇÃO", "AÇÃO"],
    },
  },
  companyAccess: {
    title: "SOLICITAÇÕES DE ACESSO",
    filterTitle: "FILTRAR SOLICITAÇÕES DE ACESSO",
    table: {
      cnpj: "CNPJ",
      status: "STATUS",
      title: "LIBERAÇÕES PENDENTES",
      companyName: "NOME DA EMPRESA",
      accessTypeRequested: "TIPO DE ACESSO SOLICITADO",
      username: "NOME DO USUÁRIO",
      email: "E-MAIL",
      requestDate: "PERÍODO DA SOLICITAÇÃO",
      plan: "PLANO",
      clean: "Limpar",
      search: "Pesquisar",
      disapprove: "Reprovar",
      approve: "Aprovar",
      headers: [
        "CNPJ",
        "NOME DA EMPRESA",
        "TIPO DE ACESSO SOLICITADO",
        "NOME DO USUÁRIO",

        "E-MAIL",
        "DATA DA SOLICITAÇÃO",
        "Status Solicitações",
        "AÇÃO",
      ],
    },
    modalDisapproved: {
      title: "REPROVAR EMPRESA",
      subTitle: "Confirma reprovar a solicitação?",
      confirm: "Confirmar reprovação",
      exit: "Cancelar",
    },
    modalApproved: {
      title: "APROVAR EMPRESA",
      subTitle: "Confirma aprovar a solicitação?",
      confirm: "Confirmar aprovação",
      exit: "Cancelar",
    },
    typeRequested: {
      associate: "Empresa Associada",
      notAssociate: "Empresa Não Associada",
      notAssociateTec: "Empresa Não Associada Não Tech",
      researcher: "Pesquisador",
      physicalPerson: "Pessoa Física",
    },
  },

  evaluatedReleases: {
    title: "LIBERAÇÕES AVALIADAS",
    filterTitle: "PESQUISAR CLIENTES",
    exportHeaders: [
      "ID",
      "NOME DA EMPRESA",
      "NOME DO USUÁRIO",
      "E-MAIL",
      "DATA DA LIBERAÇÃO",
      "STATUS",
      "PLANO",
      "EMPRESA DE TECNOLOGIA",
      "Nº DE COLABORADORES",
      "CARGO ",
      "CNPJ ",
    ],
    table: {
      title: "CLIENTES COM ACESSOS AVALIADOS ",
      cnpj: "CNPJ",
      companyName: "NOME DA EMPRESA",
      accessTypeRequested: "TIPO DE ACESSO SOLICITADO",
      username: "NOME DO USUÁRIO",
      email: "E-MAIL",
      plan: "Plano",
      incubator: "INCUBADORA NO MIDITEC?",
      releasetDate: "PERÍODO DA LIBERAÇÃO",
      office: "CARGO",
      tec: "EMPRESA DE TECNOLOGIA?",
      clean: "Limpar",
      search: "Pesquisar",
      deny: "Remover Acesso",
      approve: "Liberar Acesso",
      headers: [
        "CNPJ",
        "NOME DA EMPRESA",
        "INCUBADORA NO MIDITEC",
        "TIPO DE ACESSO SOLICITADO",
        "GESTOR RESPONSÁVEL",
        "CARGO",
        "E-MAIL",
        "DATA DA LIBERAÇÃO",
        "STATUS",
        "PLANO",

        "Nº DE COLABORADORES",
        "AÇÃO",
      ],
    },
    modalDisapproved: {
      title: "REMOVER ACESSO",
      subTitle: "O acesso será removido de todos os usuários da empresa.",
      confirm: "Confirmar remoção",
      exit: "Cancelar",
    },
    modalApproved: {
      title: "LIBERAR ACESSO",
      subTitle: "O acesso será liberado para todos os usuários da empresa",
      confirm: "Confirmar liberação",
      exit: "Cancelar",
    },
    typeRequested: {
      associate: "Empresa Associada",
      notAssociate: "Empresa Não Associada",
      researcher: "Pesquisador",
      physicalPerson: "Pessoa Física",
      others: "Outros",
    },
    statusOp: {
      approved: "Aprovado",
      disapproved: "Revogado",
    },
  },

  Notification: {
    title: "Notificações",
  },

  contactUs: {
    title: "Fale Conosco",
    modal: {
      title: "FALE CONOSCO",
      text: "Informações para contato com nossa central de comunicações:",
      confirmation: "Confirmar",
      email: "E-MAIL",
      phone: "TELEFONE",
      time: "HORÁRIO DE FUNCIONAMENTO",
    },
  },

  contactUsAcate: {
    title:
      "CONFIRME OU ALTERE AS INFORMAÇÕES QUE SERÃO EXIBIDAS NO FALE CONOSCO",
    email: "E-MAIL",
    phone: "TELEFONE",
    weekday: "DIA DA SEMANA",
    workingTime: "HORÁRIO DE FUNCIONAMENTO",
    confirm: "Confirmar Alterações",
    modal: {
      title: "DADOS CONFIRMADOS",
      text: "As novas informações do Fale Conosco foram salvas com sucesso!",
      confirmation: "Ok",
    },
    userMessage: baseRequiredMessage,
  },
};

export default ptBr;
