import { call, put } from "redux-saga/effects";
import { api } from "../../../../services";
import { Creators } from "../index";
import { Alerts } from "../../../../lib";

export default function* getSizes() {
  try {
    const sizes = yield call(api.getSize, null);
    yield put(Creators.getSizeSuccess(sizes));
  } catch (response) {
    Alerts.alertError("Não foi encontrar os portes de empresas");
    yield put(Creators.getInputsFailure());
  }
}
