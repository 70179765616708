import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* accessHistoryRequest({ data }) {
  try {
    SessionStorage.setItem("accessHistoryFilter", data);
    const requestBody = {
      searchParameters: {
        username: data.username,
        lastAccess: data.lastAccess,
        releasedDate: data.releasedDate,
      },
      page: {
        number: data.page - 1,
        size: 6,
      },
    };
    const response = yield call(api.getAccessHistory, requestBody);

    yield put(Creators.accessHistorySuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao realizar pesquisa de acessos. ");
    yield put(Creators.accessHistoryFailure());
  }
}
