import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* patchStatusUser({ data, update }) {
  try {
    data.status
      ? yield call(api.patchDeactivateUser, data.id)
      : yield call(api.patchActivateUser, data.id);

    let filter = SessionStorage.getItem("userListFilter");
    yield put(Creators.patchStatusUserSuccess());
    Alerts.alertSuccess(
      "Usuário foi" +
        (data.status ? " desativado" : " ativado") +
        " com sucesso!"
    );
    if (filter === null) {
      update({ fullName: null, email: null, status: null, role: null });
    } else {
      update(filter);
    }
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao" + (data.status ? "desativar" : "ativar") + " usuário"
    );
    yield put(Creators.patchStatusUserFailure());
  }
}
