import styled from "styled-components";
import { FontStyles } from "../../../components";

const ContactContent = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    padding: spacing(3.5),
    backgroundColor: colors.background.input,
  };
});

const TitleModal = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: colors.text.secondary,
  };
});

const InfoModal = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
  };
});

const GreenBullet = styled.li(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.green,
    fontSize: 20,
  };
});

const Styles = {
  ContactContent,
  TitleModal,
  InfoModal,
  GreenBullet,
};

export default Styles;

