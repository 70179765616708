import {
  createBarScalesX,
  createLayoutWithDatalabels,
  createBarScalesDoubleY,
} from "../helpers/CommonChartConfigs";

import { Filters } from "../../lib";

function getData(data, labels) {
  if (data === (null || undefined)) {
    return [];
  } else {
    const newData = [null];
    for (let index = 0; index < labels.length; index++) {
      if (data[labels[index]]) {
        newData.push(data[labels[index]]);
      } else {
        newData.push(null);
      }
    }
    newData.push(null);

    return newData;
  }
}
function getLabels(data) {
  if (data === (null || undefined)) {
    return [];
  } else {
    const newData = [""];
    const array = Object.keys(data);
    newData.push(...array);
    newData.push("");
    return newData;
  }
}

export default function getCapacityHistoricLine(response, noScales, twoScales) {
  const labels =
    Object.keys(response?.allCompanies).length > 0
      ? getLabels(response?.allCompanies)
      : Object.keys(response?.female).length > 0
      ? getLabels(response?.female)
      : Object.keys(response?.male).length > 0
      ? getLabels(response?.male)
      : getLabels(response?.nonBinary);

  const allCompanies = getData(
    response?.allCompanies,
    Object.keys(response?.allCompanies).length > 0
      ? Object.keys(response?.allCompanies)
      : Object.keys(response?.female).length > 0
      ? Object.keys(response?.female)
      : Object.keys(response?.male).length > 0
      ? Object.keys(response?.male)
      : Object.keys(response?.nonBinary)
  );
  const female = getData(
    response?.female,
    Object.keys(response?.allCompanies).length > 0
      ? Object.keys(response?.allCompanies)
      : Object.keys(response?.female).length > 0
      ? Object.keys(response?.female)
      : Object.keys(response?.male).length > 0
      ? Object.keys(response?.male)
      : Object.keys(response?.nonBinary)
  );
  const male = getData(
    response?.male,
    Object.keys(response?.allCompanies).length > 0
      ? Object.keys(response?.allCompanies)
      : Object.keys(response?.female).length > 0
      ? Object.keys(response?.female)
      : Object.keys(response?.male).length > 0
      ? Object.keys(response?.male)
      : Object.keys(response?.nonBinary)
  );
  const nonBinary = getData(
    response?.nonBinary,
    Object.keys(response?.allCompanies).length > 0
      ? Object.keys(response?.allCompanies)
      : Object.keys(response?.female).length > 0
      ? Object.keys(response?.female)
      : Object.keys(response?.male).length > 0
      ? Object.keys(response?.male)
      : Object.keys(response?.nonBinary)
  );

  const chartData = {
    type: "line",
    data: {
      labels,
      datasets: [
        {
          yAxisID: "right-y-axis",
          label: "Teste",
          borderColor: "#F9959C",
          backgroundColor: "#E95F69",
          lineTension: 0,
          borderDash: [6],
          borderWidth: 1,
          pointRadius: 6,

          pointBorderWidth: 0.5,
          pointBackgroundColor: "#E95F69",
          pointBorderColor: "#E95F69",
          fill: false,
          data: male,
          spanGaps: true,
        },
        {
          yAxisID: "right-y-axis",
          label: "Teste",
          borderColor: "#60BEC9",
          backgroundColor: "#21B3C4",
          lineTension: 0,
          borderDash: [6],
          borderWidth: 1,
          pointRadius: 6,

          pointBorderWidth: 0.5,
          pointBackgroundColor: "#21B3C4",
          pointBorderColor: "#21B3C4",
          fill: false,
          data: female,
          spanGaps: true,
        },
        {
          yAxisID: "right-y-axis",
          label: "Teste",
          borderColor: "#DAACE9",
          backgroundColor: "#B96BD4",
          lineTension: 0,
          borderDash: [6],
          borderWidth: 1,
          pointRadius: 8,

          pointBorderWidth: 0.5,
          pointBackgroundColor: "#B96BD4",
          pointBorderColor: "#B96BD4",
          fill: false,
          data: nonBinary,
          spanGaps: true,
        },
        {
          yAxisID: "left-y-axis",
          label: "Teste2",
          type: "bar",
          barPercentage: 0.4,
          categoryPercentage: 0.4,
          backgroundColor: "#ACCA13",
          fill: true,
          data: allCompanies,
        },
      ],
    },

    options: {
      responsive: true,
      maintainAspectRatio: false,
      labels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        mode: "single",
        backgroundColor: "white", // Use a mesma cor de fundo que os datalabels
        borderColor: "#00000012", // Use a mesma cor da borda que os datalabels
        borderWidth: 2, // Use a mesma largura de borda que os datalabels
        titleFontFamily: "Montserrat", // Use a mesma família de fonte que os datalabels
        titleFontSize: 12, // Use o mesmo tamanho de fonte que os datalabels
        titleFontColor: "#464646", // Use a mesma cor de fonte que os datalabels
        bodyFontFamily: "Montserrat", // Use a mesma família de fonte que os datalabels
        bodyFontSize: 12, // Use o mesmo tamanho de fonte que os datalabels
        bodyFontColor: "#464646", // Use a mesma cor de fonte que os datalabels
        displayColors: false,
        callbacks: {
          title: function () {
            return ""; // Retorna uma string vazia para remover o título
          },
          displayColors: false,
          label: function (tooltipItem, data) {
            console.log(tooltipItem.datasetIndex);
            let valueData = "";
            if (tooltipItem.datasetIndex !== 3) {
              valueData = Filters.convertNumberInputMask(
                tooltipItem.yLabel.toFixed(2),
                false,
                true,
                null,
                tooltipItem.datasetIndex !== 3
              );
            } else {
              valueData = Filters.convertNumberInputMask(
                Number(tooltipItem.yLabel),
                false,
                false,
                null,
                tooltipItem.datasetIndex !== 3
              );
            }

            return valueData;
          },
        },
      },

      layout: createLayoutWithDatalabels(),

      scales: noScales
        ? {
            yAxes: [{ ticks: { display: false } }],
            xAxes: [
              { ticks: { display: false }, gridLines: { display: false } },
            ],
          }
        : twoScales
        ? createBarScalesDoubleY(
            "#6A6A6A40",
            "#6A6A6A",
            false,
            undefined,
            10,
            "decimal",
            "percent"
          )
        : createBarScalesX(
            "#6A6A6A40",
            "#6A6A6A",
            false,
            undefined,
            10,
            "decimal"
          ),
    },
  };

  return {
    data: {
      chartData,
      type: "gender",
    },
  };
}
