export default function getContactUsInformation(response) {
  const data = {
    id: response.data.response.id,
    email: response.data.response.email,
    telephone: response.data.response.telephone,
    mondayToFriday: response.data.response.mondayToFriday,
    saturdayToSunday: response.data.response.saturdayToSunday,
    modifyUsername: response.data.response.modifyUsername,
  };
  return { contactUsInformation: data, ...response };
}
  