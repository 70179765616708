import React from "react";
import { customModal } from "../utils";

import { Fade, IconButton } from "@material-ui/core";
import {
  ButtonText,
  FontStyles,
  Text,
  Title,
  ThemeProvider,
} from "../../../components";
import { Styles } from "../styles";
import { Themes } from "../../../config";
function ModalContainer() {
  const [infos, setInfos] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const {
    texts = [],
    title,
    confirmButton,
    exitButton,
    extraField,
    noExit,
  } = infos;

  const handleModal = React.useCallback(
    (config) => {
      if (config.open) {
        setInfos(config.infos);
      }
      setOpen(config.open);
    },
    [setInfos, setOpen]
  );

  customModal.setCallback(handleModal);
  return (
    <ThemeProvider theme={Themes.Light}>
      <Styles.StyledModal
        open={open}
        onClose={() => setOpen(false)}
        BackdropComponent={Styles.StyledBackdrop}
        aria-labelledby="Título do modal"
        aria-describedby="Descrição do modal"
        disableBackdropClick>
        <Fade in={open}>
          <Styles.Container>
            <Styles.ContentContainer>
              <Styles.HeaderContainer noExit={noExit}>
                <Title>{title}</Title>
                {noExit ? null : (
                  <IconButton onClick={customModal.close}>
                    <Styles.Close />
                  </IconButton>
                )}
              </Styles.HeaderContainer>
              {texts.length >= 1 ? (
                <Styles.TextContainer>
                  {texts.map((text) => (
                    <Text key={text}>{text}</Text>
                  ))}
                </Styles.TextContainer>
              ) : null}

              {extraField ? extraField : null}
            </Styles.ContentContainer>

            <Styles.PaddingModal>
              {exitButton ? (
                <ButtonText
                  {...exitButton}
                  fullWidth={false}
                  style={{
                    textTransform: "uppercase",
                    ...FontStyles.semibold14,
                  }}>
                  {exitButton.label}
                </ButtonText>
              ) : null}
              {confirmButton ? (
                <ButtonText
                  fullWidth={false}
                  $defaultColor
                  {...confirmButton}
                  style={{
                    textTransform: "uppercase",
                    ...FontStyles.semibold14,
                  }}>
                  {confirmButton.label}
                </ButtonText>
              ) : null}
            </Styles.PaddingModal>
          </Styles.Container>
        </Fade>
      </Styles.StyledModal>
    </ThemeProvider>
  );
}

export default React.memo(ModalContainer);
