import React from "react";
import { connect } from "react-redux";

import { Styles } from "../styles";
import { Creators } from "../reduxSagas";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchUsers from "../components/SearchUsers";
import UsersList from "../components/UsersList";
import { IntegratedComponentHolder } from "../../../components";
import { SessionStorage } from "../../../lib";

function AccessHistory({ isFetching, accessHistory, getAccessHistory }) {
  const [page, setPage] = React.useState(1);

  const Mount = React.useCallback(() => {
    const requestBody = {
      username: null,
      lastAccess: null,
      releasedDate: null,
      page: 1,
    };

    getAccessHistory(requestBody);
  }, [getAccessHistory]);

  React.useEffect(Mount, [Mount]);

  const getUsers = (data) => {
    setPage(1);
    const requestBody = {
      username: data.username ? data.username : null,
      lastAccess: data.lastAccess ? data.lastAccess : null,
      releasedDate: data.releasedDate ? data.releasedDate : null,
      page: 1,
    };

    getAccessHistory(requestBody);
  };

  const setPageList = (value) => {
    let filter = SessionStorage.getItem("accessHistoryFilter");

    filter = { ...filter, page: value };
    setPage(value);
    getAccessHistory(filter);
  };

  return (
    <Styles.Container>
      <Grid container direction="row" alignContent="flex-start" spacing={1}>
        <Grid item xs={12}>
          <Styles.Content>
            <Grid container spacing={1}>
              <SearchUsers onSubmit={getUsers} />
            </Grid>
          </Styles.Content>
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && !accessHistory}
            reloadMessage={"Não encontrado"}
            showEmpty={!isFetching && accessHistory?.usersList?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <UsersList
                usersList={accessHistory.usersList}
                page={page}
                setPage={setPageList}
                totalPages={accessHistory?.data?.response?.pageTotal}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching, accessHistory } = state.accessHistory;
  return {
    isFetching,
    accessHistory,
  };
}

function mapDispatchToProps(dispatch) {
  const { getAccessHistory } = Creators;
  return {
    getAccessHistory: function (data) {
      return dispatch(getAccessHistory(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AccessHistory));
