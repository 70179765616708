import {
  isAfter as isAfterFns,
  isBefore as isBeforeFns,
  set as setFns,
  format as fnsFormat,
} from "date-fns";
import { Filters } from ".";

function compareDates(selectedDate, testDate, isAfter, withDay) {
  const testFunction = isAfter ? isAfterFns : isBeforeFns;
  const secDate = setFns(selectedDate, {
    hours: 0,
    minutes: 0,
    milliseconds: 0,
    seconds: 0,
  });
  const tstDate = setFns(testDate, {
    hours: 0,
    minutes: 0,
    milliseconds: 0,
    seconds: 0,
  });

  if (testFunction(secDate, tstDate)) {
    return {
      isValid: false,
      errorMessage: `A data não pode ser ${
        isAfter ? "posterior" : "inferior"
      } a ${
        withDay
          ? fnsFormat(testDate, "dd/MM/yyyy")
          : fnsFormat(testDate, "MM/yyyy")
      }`,
    };
  } else {
    return { isValid: true };
  }
}

function validToken(newState) {
  return /[A-ZÇ]/g.test(newState);
}

const isDATE = (date, minDate, maxDate) => {
  if (!date) {
    return { isValid: false, errorMessage: "Insira uma data" };
  } else if (isNaN(date.getTime())) {
    return { isValid: false, errorMessage: "Insira uma data válida" };
  }

  if (minDate) {
    const { isValid, errorMessage } = compareDates(date, minDate, false, true);
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }

  if (maxDate) {
    const { isValid, errorMessage } = compareDates(date, maxDate, true, true);
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }
  return { isValid: true, errorMessage: " " };
};

const isEMAIL = (email) => {
  let errorMessage = "E-mail inválido";
  if (!email) {
    return { isValid: false, errorMessage: "Insira um email" };
  }
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*\.\w{2,}$/.test(email)) {
    return { isValid: true, errorMessage };
  }
  return { isValid: false, errorMessage };
};

function hasSpecialCharacters(password) {
  return /\W|_/g.test(password) ? 1 : 0;
}

function hasUppercase(password) {
  return /[A-ZÇ]/g.test(password) ? 1 : 0;
}

function hasNumber(password) {
  return /\d/g.test(password) ? 1 : 0;
}

function hasLowercase(password) {
  return /[a-zç]/g.test(password) ? 1 : 0;
}

function validPassword(password) {
  if (password.length < 6) {
    return {
      isValid: false,
      errorMessage: "Senha deve ter no mínimo 6 caracteres",
    };
  }
  if (password.length > 20) {
    return {
      isValid: false,
      errorMessage: "Senha deve ter no máximo 20 caracteres",
    };
  }
  if (
    hasSpecialCharacters(password) +
      hasLowercase(password) +
      hasNumber(password) +
      hasUppercase(password) <
    4
  ) {
    return {
      isValid: false,
      errorMessage:
        "Senha inválida. A senha deve conter: \n Letras maiúsculas, minúsculas, números e caracteres especiais",
    };
  }

  return { isValid: true, errorMessage: "Senha válida" };
}

function isSamePassword(value, value2) {
  if (validPassword(value)) {
    if (value === value2) {
      return {
        isValid: true,
        errorMessage: "",
      };
    } else {
      return {
        isValid: false,
        errorMessage: "Senhas não conferem.",
      };
    }
  } else {
    return {
      isValid: false,
      errorMessage:
        "Senha inválida. A senha deve conter: \n Letras maiúsculas, minúsculas, números e caracteres especiais",
    };
  }
}

function removeMask(value) {
  return value ? `${value}`.replace(/[^\d]/g, "") : value;
}
const isPhone = (phone) => {
  let errorMessage = "Número de telefone inválido";
  if (!phone) {
    return { isValid: false, errorMessage: "Insira um número de telefone" };
  }

  let a;

  phone.length >= 17 ? (a = phone.substring(0, phone.length - 1)) : (a = phone);

  const withoutMask = removeMask(a);

  if (withoutMask) {
    if (withoutMask.length === 10 || withoutMask.length === 11) {
      return { isValid: true, errorMessage };
    }
  }
  return { isValid: false, errorMessage };
};

const isFullName = (value) => {
  let errorMessage = "Nome inválido";
  const isValid = value
    .trim()
    .match(/^[A-zÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-Ÿ][A-zÀ-ÿ']+$/);

  return isValid
    ? { isValid: true, errorMessage }
    : {
        isValid: false,
        errorMessage:
          "O campo deve conter um nome completo valido. Com nome, sobrenome e não pode conter números.",
      };
};
const isCNPJ = (cnpj) => {
  let clearCnpj = "";
  let errorMessage = "CNPJ inválido";
  if (cnpj) {
    let a;
    a = cnpj.replace(/[^\d]/g, "");

    a.length >= 15
      ? (clearCnpj = a.substring(0, a.length - 1))
      : (clearCnpj = a);
  } else {
    return { isValid: false, errorMessage: "Insira um número de CNPJ" };
  }

  if (clearCnpj === "") {
    return { isValid: false, errorMessage: "Insira um número de CNPJ" };
  }

  if (clearCnpj.length !== 14) {
    return { isValid: false, errorMessage };
  }
  if (
    clearCnpj === "00000000000000" ||
    clearCnpj === "11111111111111" ||
    clearCnpj === "22222222222222" ||
    clearCnpj === "33333333333333" ||
    clearCnpj === "44444444444444" ||
    clearCnpj === "55555555555555" ||
    clearCnpj === "66666666666666" ||
    clearCnpj === "77777777777777" ||
    clearCnpj === "88888888888888" ||
    clearCnpj === "99999999999999"
  ) {
    return { isValid: false, errorMessage };
  }

  let size = clearCnpj.length - 2;
  let numbers = clearCnpj.substring(0, size);
  const digits = clearCnpj.substring(size);

  let sum = 0;
  let position = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * position--;
    if (position < 2) {
      position = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0), 10)) {
    return { isValid: false, errorMessage };
  }

  size += 1;
  numbers = clearCnpj.substring(0, size);
  sum = 0;
  position = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * position--;
    if (position < 2) {
      position = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1), 10)) {
    return { isValid: false, errorMessage };
  }

  return { isValid: true, errorMessage };
};

const isCPF = (cpf) => {
  if (!cpf) {
    return { isValid: false, errorMessage: "CPF Invalido" };
  }

  let clearCpf = "";
  let errorMessage = "CPF Invalido";
  if (cpf) {
    clearCpf = cpf.replace(/[^\d]/g, "");
  } else {
    return { isValid: false, errorMessage: "CPF Invalido" };
  }

  let sum = 0;
  let rest;
  if (
    clearCpf.length !== 11 ||
    clearCpf === "00000000000" ||
    clearCpf === "11111111111" ||
    clearCpf === "22222222222" ||
    clearCpf === "33333333333" ||
    clearCpf === "44444444444" ||
    clearCpf === "55555555555" ||
    clearCpf === "66666666666" ||
    clearCpf === "77777777777" ||
    clearCpf === "88888888888" ||
    clearCpf === "99999999999"
  ) {
    return { isValid: false, errorMessage };
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(clearCpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(clearCpf.substring(9, 10), 10)) {
    return { isValid: false, errorMessage };
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(clearCpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(clearCpf.substring(10, 11), 10)) {
    return { isValid: false, errorMessage };
  }

  return { isValid: true, errorMessage };
};

const isNumber = (value) => {
  let errorMessage = "Apenas valores numéricos";
  const isValid = parseFloat(
    value
      .replace(".", "")
      .replace("R$ ", "")
      .replace(" %", "")
      .replace(",", ".")
  );

  return isValid.toString().length > 0 && !isNaN(isValid)
    ? { isValid: true, errorMessage }
    : {
        isValid: false,
        errorMessage:
          "O campo deve conter um valor numérico ('0,00', 'R$ 00,00, '00,00%).",
      };
};

const maxAndMinNumber = (value, max, min) => {
  let errorMessage = "Apenas valores numéricos";
  const isValid = isNumber(value);
  if (!isValid?.isValid) {
    return isValid;
  }

  const number = parseFloat(
    Filters.convertNumberInputMask(value, false, true)
      .replace(".", "")
      .replace("R$ ", "")
      .replace(" %", "")
      .replace(",", ".")
  );

  if (number > max) {
    return { isValid: false, errorMessage: "Valor deve ser menor que " + max };
  }

  if (number < min) {
    return { isValid: false, errorMessage: "Valor deve ser maior que " + min };
  }

  return { isValid: true, errorMessage };
};

const maxYear = (value) => {
  let errorMessage = "Insira um ano igual ou menor ao atual";
  return parseInt(value) > new Date().getFullYear()
    ? { isValid: false, errorMessage }
    : { isValid: true, errorMessage };
};

const validations = {
  isEMAIL,
  validPassword,
  isDATE,
  isPhone,
  isFullName,
  isCNPJ,
  isCPF,
  validToken,
  hasUppercase,
  isNumber,
  maxYear,
  isSamePassword,
  maxAndMinNumber,
};

export default validations;
