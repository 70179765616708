export default function BasicRegister(response) {
  const requestData = {
    cityId:
      typeof response.cityId === typeof {}
        ? response.cityId.value
        : response.cityId,
    companyName: response.companyName.toLowerCase(),
    description: response.description,
    incubatorId:
      typeof response.incubatorId === typeof {}
        ? response.incubatorId.value
        : response.incubatorId !== "-1"
        ? response.incubatorId
        : null,
  };

  return requestData;
}
