import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* contactUsInfoRequest({ data }) {
  try {
    SessionStorage.setItem("contactUsInfoFilter", data);
    const requestBody = {
      searchParameters: {
        id: data?.id ? data.id : null,
        email: data?.email ? data?.email : null,
        telephone: data?.telephone ? data?.telephone : null,
        mondayToFriday: data?.mondayToFriday ? data?.mondayToFriday : null,
        saturdayToSunday: data?.saturdayToSunday ? data?.saturdayToSunday : null,
        modifyUsername: data?.modifyUsername ? data?.modifyUsername : null,
      },
    };
    const response = yield call(api.getContactUsInformation, requestBody);
    yield put(Creators.contactUsInfoSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao carregar informações do Fale Conosco.");
    yield put(Creators.contactUsInfoFailure());
  }
}
