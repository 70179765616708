import React from "react";
import Styles from "../styles/Styles";
import { Texts } from "../../../config";
import { InputPassword, InputEmail, ButtonOutlined } from "../../../components";
import { FormHelperText } from "@material-ui/core";
import { FormHolder } from "../../../FormConfig";
function StepOne({
  goBack,
  onSubmit,
  RenderSwiperDots,
  isFetching,
  checkEmail,
  isValidEmail,
}) {
  const texts = Texts["pt-BR"];
  const [error, setError] = React.useState(false);

  const validatePassword = (data, isValid) => {
    if (data.password === data.confirmPassword) {
      setError(false);
      checkEmail(data.email, () => {
        onSubmit(data);
      });
    } else {
      setError(true);
    }
  };

  return (
    <Styles.Content>
      <FormHolder onSubmit={(data) => validatePassword(data)}>
        <InputEmail
          variant="standard"
          name="email"
          required={texts.login.userMessage}
          inputLabel={texts.login.user}
        />
        <InputPassword
          variant="standard"
          name="password"
          autoComplete="new-password"
          required={texts.login.passwordMessage}
          inputLabel={texts.login.password}
        />
        <InputPassword
          variant="standard"
          name="confirmPassword"
          autoComplete="new-password"
          required={texts.login.passwordMessage}
          inputLabel={texts.login.confirmPassword}
        />
        <FormHelperText error={error || !isValidEmail?.response}>
          {Boolean(error)
            ? "As senhas não correspondem."
            : Boolean(isValidEmail)
            ? isValidEmail?.response === true
              ? " "
              : isValidEmail?.message
            : ""}
        </FormHelperText>
        {RenderSwiperDots()}
        <Styles.ConfirmButton
          type="submit"
          name="button"
          loading={isFetching}
          disabled={isFetching}
        >
          {texts.registry.confirm}
        </Styles.ConfirmButton>

        <ButtonOutlined
          $upper
          onClick={goBack}
          loading={isFetching}
          disabled={isFetching}
        >
          {texts.registry.back}
        </ButtonOutlined>
      </FormHolder>
    </Styles.Content>
  );
}

export default React.memo(StepOne);
