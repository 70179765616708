import { Base64 } from "../lib";
import { history } from "../store";
import Constants from "../config/constants";

export const prefix = "";

export const paths = {
  login: prefix + "/",
  home: prefix + "/",
  forgotPassword: prefix + "/forgot_password",
  changePassword: prefix + "/change_password",
  userRegister: prefix + "/user_register",
  userList: prefix + "/user_list",
  profile: prefix + "/my_perfil",
  generalFinancialIndicators: prefix + "/financial_indicator",
  firstAccess: prefix + "/first_access",
  users: prefix + "/users",
  premium: prefix + "/premium",
  managementIndicators: prefix + "/management_indicators",
  accessHistory: prefix + "/access_history",
  accessHistoryClients: prefix + "/access_history_company",

  financialData: prefix + "/financial_data",
  managementData: prefix + "/management_data",
  dataEntry: prefix + "/data_entry",
  companyAccess: prefix + "/access_requests",
  evaluatedReleases: prefix + "/release_log",
  plansApproval: prefix + "/plan_analysis",
  contactUs: prefix + "/contact_us",
};

export const navigateTo = {
  Home: () => navigate(paths.home),
  Landing: () => navigate(paths.login),
  ForgotPassword: () => navigate(paths.forgotPassword),
  ChangePassword: () => navigate(paths.changePassword),
  UserRegister: () => navigate(paths.userRegister),
  Profile: () => navigate(paths.profile),
  GeneralFinancialIndicators: () => navigate(paths.generalFinancialIndicators),
  FirstAccess: () => navigate(paths.firstAccess),
  Users: () => navigate(paths.users),
  AccessHistory: () => navigate(paths.accessHistory),
  AccessHistoryClients: () => navigate(paths.accessHistoryClients),
  FinancialData: () => navigate(paths.financialData),
  ManagementData: () => navigate(paths.managementData),
  CompanyAccess: () => navigate(paths.companyAccess),
  DataEntry: () => navigate(paths.dataEntry),
  EvaluatedReleases: () => navigate(paths.evaluatedReleases),
  PlansApproval: () => navigate(paths.plansApproval),
  Premium: () => navigate(paths.premium),
  ContactUs: () => navigate(paths.contactUs),

  exampleWithParams: (queryParam) =>
    navigate(`/example/${Base64.encode(queryParam)}`),
};

export const replaceTo = {
  Home: () => replace(paths.home),
  Landing: () => replace(paths.login),
  ForgotPassword: () => replace(paths.forgotPassword),
  ChangePassword: () => replace(paths.changePassword),
  UserRegister: () => replace(paths.userRegister),
  Profile: () => replace(paths.profile),
  GeneralFinancialIndicators: () => replace(paths.generalFinancialIndicators),
  FirstAccess: () => replace(paths.firstAccess),
  Users: () => replace(paths.users),
  ManagementData: () => replace(paths.managementData),
  FinancialData: () => replace(paths.financialData),
  DataEntry: () => replace(paths.dataEntry),
  AccessHistory: () => replace(paths.accessHistory),
  AccessHistoryClients: () => replace(paths.accessHistoryClients),
  CompanyAccess: () => replace(paths.companyAccess),
  EvaluatedReleases: () => replace(paths.evaluatedReleases),
  PlansApproval: () => replace(paths.plansApproval),
  Premium: () => replace(paths.premium),

  ContactUs: () => replace(paths.contactUs),
  exampleWithParams: (queryParam) =>
    replace(`/example/${Base64.encode(queryParam)}`),
};

export function navigate(pathname) {
  const element = document.getElementById(
    Constants.ELEMENTS.IDS.applicationDefaultScroll
  );
  if (element) {
    element.scrollTop = 0;
  }
  history.push({ pathname });
}

export function replace(pathname) {
  const element = document.getElementById(
    Constants.ELEMENTS.IDS.applicationDefaultScroll
  );
  if (element) {
    element.scrollTop = 0;
  }
  history.replace({ pathname });
}

export function goBack() {
  history.goBack();
}

export function goFoward() {
  history.goForward();
}
