import getToken from "./getToken";
import getUserInfos from "./getUserInfos";
import sendPasswordCode from "./sendRecoveryPasswordCode";
import sendNewPassword from "./sendRecoveryPasswordNewPassword";
import sendPasswordRequest from "./sendRecoveryPasswordRequest";
import sendCreateAccessModel from "./sendCreateAccess";
import getUF from "./getUF";
import getCNAE from "./getCNAE";
import getCities from "./getCities";
import getUsersList from "./getUsersList";
import getAccessHistory from "./getAccessHistory";
import getIndicatorOrder from "./getIndicatorOrder";

import getCurrentUser from "./getCurrentUser";
import getManagerIndicator from "./getManagerIndicator";
import getIndicatorsYear from "./getIndicatorsYear";

import getCompanyAccess from "./getCompanyAccess";
import getAccessHistoryClients from "./getAccessHistoryClients";

import getCompanyRegister from "./getCompanyRegister";
import getEvaluatedReleases from "./getEvaluatedReleases";
import MoneyLineChart from "./MoneyLineChart";
import MoneyBarChart from "./MoneyBarChart";
import PercentLineChart from "./PercentLineChart";
import PercentBarChart from "./PercentBarChart";
import DecimalLineChart from "./DecimalLineChart";
import DecimalBarChart from "./DecimalBarChart";
import TermoChart from "./TermoChart";
import getFinancialData from "./getFinancialData.js";
import getManagerialData from "./getManagerialData.js";
import GenderBarChart from "./GenderBarChart.js";
import getExportIndicators from "./getExportIndicators.js";
import BasicRegister from "./BasicRegister.js";
import CompleteRegister from "./CompleteRegister.js";
import getPlansApproval from "./getPlansApproval.js";

import getContactUsInformation from "./getContactUsInformation";
import getContactUsDetailed from "./getContactUsDetailed";
import exportUsers from "./exportUsers";
import getOffice from "./getOffice";
import getCnpj from "./getCnpj";

const models = {
  getCnpj,
  BasicRegister,
  CompleteRegister,
  getToken,
  getUserInfos,
  sendPasswordCode,
  sendNewPassword,
  sendPasswordRequest,
  sendCreateAccessModel,
  getUF,
  getCNAE,
  getCities,
  getUsersList,
  getCurrentUser,
  getManagerIndicator,
  getIndicatorsYear,
  getAccessHistory,
  getCompanyAccess,
  getIndicatorOrder,
  getCompanyRegister,
  MoneyLineChart,
  MoneyBarChart,
  PercentLineChart,
  PercentBarChart,
  DecimalLineChart,
  DecimalBarChart,
  TermoChart,
  getFinancialData,
  getManagerialData,
  GenderBarChart,
  getEvaluatedReleases,
  getExportIndicators,
  getPlansApproval,
  getContactUsInformation,
  getContactUsDetailed,
  exportUsers,
  getOffice,
  getAccessHistoryClients,
};

export default models;
