import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getPlansList({ data }) {
  try {
    const response = yield call(api.getPlansList, null);

    yield put(Creators.getPlansListSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao encontrar lista de planos.");
    yield put(Creators.getPlansListFailure());
  }
}
