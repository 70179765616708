import React from "react";
import { connect } from "react-redux";
import {
  Input,
  ButtonOutlined,
  ButtonContained,
  InputDate,
  Select,
  InputAutoComplete,
} from "../../../components";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import Styles from "../styles/Styles";
import { FormHolder } from "../../../FormConfig";
import { Texts } from "../../../config";
import { Icons } from "../../../config";
import { ToCSV, Validations } from "../../../lib";
function SearchReleases({
  isFetching,
  onSubmit,
  planList,
  exportData,
  office,
  incubators,
  listCnpj,
}) {
  const texts = Texts["pt-BR"].evaluatedReleases;
  const Export = (data) => {
    ToCSV.data(
      data,
      texts.exportHeaders,
      [
        "id",
        "companyName",
        "username",
        "email",
        "releasedDate",
        "status",
        "plan",
        "isTech",
        "employeeNumber",
        "office",
        "cnpj",
      ],
      "Lista de Clientes",
      "Listagem dos Clientes Acate Hub"
    );
  };
  return (
    <FormHolder onSubmit={onSubmit}>
      <Grid item xs={12}>
        <Styles.HeaderCard>
          <Styles.TitleCard>{texts.filterTitle}</Styles.TitleCard>
          <Styles.CompactButton
            startIcon={<Icons.Export />}
            disabled={!exportData}
            onClick={() => {
              Export(exportData);
            }}
            fullWidth={false}
            $export
          >
            Exportar Base
          </Styles.CompactButton>
        </Styles.HeaderCard>
        <Styles.Line />
      </Grid>

      <Grid
        alignItems="screech"
        style={{
          alignItems: "baseline",
          display: "flex",
        }}
        container
        spacing={1}
      >
        <Grid item xs={3}>
          <InputAutoComplete
            justNumbers
            disableError
            inputLabel={texts.table.cnpj}
            name="cnpj"
            options={listCnpj ? listCnpj : []}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            disableError
            inputLabel={texts.table.companyName}
            name="companyName"
            placeholder="Enterprise Tec."
          />
        </Grid>

        <Grid item xs={3}>
          <Select
            disableError
            $maxHeight
            alternativeColors
            inputLabel={texts.table.accessTypeRequested}
            name="accessTypeRequested"
            required="Selecione"
            defaultValue={-1}
            options={[
              { value: -1, label: "Todos" },
              {
                value: "Empresa Associada",
                label: texts.typeRequested.associate,
              },
              {
                value: "Empresa Não Associada",
                label: texts.typeRequested.notAssociate,
              },
              { value: "Pesquisador", label: texts.typeRequested.researcher },
              {
                value: "Pessoa Física",
                label: texts.typeRequested.physicalPerson,
              },
            ]}
          />
        </Grid>

        <Grid item xs={3}>
          <Input
            disableError
            inputLabel={texts.table.username}
            name="username"
            placeholder="Nome de Usuário"
          />
        </Grid>

        <Grid item xs={4}>
          <Input
            disableError
            inputLabel={texts.table.email}
            name="email"
            placeholder="user@acate.com.br"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            disableError
            name="planId"
            inputLabel={texts.table.plan}
            defaultValue={-1}
            options={
              planList ? [{ value: -1, label: "Todos" }, ...planList] : []
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            disableError
            $maxHeight
            alternativeColors
            inputLabel={texts.table.office}
            name="officeId"
            required="Selecione"
            defaultValue={-1}
            options={[{ value: -1, label: "Todos" }, ...office]}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            disableError
            $maxHeight
            alternativeColors
            inputLabel={texts.table.tec}
            name="tec"
            required="Selecione"
            defaultValue={-1}
            options={[
              { value: -1, label: "Todos" },
              { value: "true", label: "Sim" },
              { value: "false", label: "Não" },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            disableError
            name="incubatorId"
            inputLabel={texts.table.incubator}
            defaultValue={-1}
            options={
              incubators ? [{ value: -1, label: "Todos" }, ...incubators] : []
            }
          />
        </Grid>

        <Grid container item xs={4}>
          <Grid xs={12}>
            <Styles.InputLabel>{texts.table.releasetDate}</Styles.InputLabel>
          </Grid>
          <Grid xs={5}>
            <InputDate
              name="beginRequestDate"
              maxDate={new Date()}
              validation={Validations.isDATE}
            />
          </Grid>
          <Grid xs={2}>
            <Styles.BetweenDate>até</Styles.BetweenDate>
          </Grid>
          <Grid xs={5}>
            <InputDate
              validation={Validations.isDATE}
              name="endRequestDate"
              maxDate={new Date()}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        style={{ justifyContent: "flex-end", marginTop: "0.5rem" }}
      >
        <Grid item>
          <ButtonOutlined
            startIcon={<DeleteIcon />}
            type="clearDefault"
            fullWidth={false}
            loading={isFetching}
          >
            {texts.table.clean}
          </ButtonOutlined>
        </Grid>
        <Grid item>
          <ButtonContained
            startIcon={<SearchIcon />}
            type="submit"
            fullWidth={false}
            loading={isFetching}
          >
            {texts.table.search}
          </ButtonContained>
        </Grid>
      </Grid>
    </FormHolder>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.evaluatedReleases;

  return {
    isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(SearchReleases));
