import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, SessionStorage } from "../../../lib";

export default function* patchDisapproveAccess({ data, update }) {
  try {
    yield call(api.patchDisapproveAccess, data);
    let filter = SessionStorage.getItem("companyAccessFilter");
    yield put(Creators.patchDisapproveAccessSuccess());
    Alerts.alertSuccess(
      "Acesso da empresa foi reprovado com sucesso!"
    );
    update(filter);
  } catch (response) {
    Alerts.alertError(
      "Erro ao reprovar acesso da empresa"
    );
    yield put(Creators.patchDisapproveAccessFailure());
  }
}
