import React from "react";
import Styles from "../styles/Styles";
//import { Texts } from "../../../config";
import { Creators } from "../reduxSagas";
import { Creators as ProfileCreators } from "../../Profile/reduxSagas";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
function PlanList({
  isFetchingAll,
  getPlans,
  plansList,
  postNewPlan,
  getPlanTec,
  getPlanNotTec,
  plan,
  userData,
}) {
  //const texts = Texts["pt-BR"];
  React.useEffect(() => {
    if (userData?.role === "ROLE_NOT_ASSOCIATE_MANAGER") {
      getPlanNotTec();
    } else if (userData?.role === "ROLE_NOT_ASSOCIATE_TECHNOLOGY_MANAGER") {
      getPlanTec();
    }
  }, [getPlanTec, getPlanNotTec, userData]);

  return (
    <Styles.CardContainer>
      {isFetchingAll ? (
        <CircularProgress />
      ) : (
        <>
          <Styles.CardHeader>
            <Styles.CardTitle>TORNE-SE PREMIUM!</Styles.CardTitle>
          </Styles.CardHeader>
          <Styles.Line />
          {plan && (
            <Styles.PlanCard>
              <Styles.PlanCardHeader>
                <Styles.TitlePlan>{plan?.response?.name}</Styles.TitlePlan>
                <Styles.ValuePlan>
                  {plan?.response?.value
                    ? "R$ " +
                      plan?.response?.value
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    : "Valor não definido"}
                </Styles.ValuePlan>
              </Styles.PlanCardHeader>
              {plan?.response?.description !== "" && (
                <Styles.PlanCardContent>
                  <Styles.InfoPlan>
                    <Styles.Dot />
                    {plan?.response?.description}
                  </Styles.InfoPlan>
                </Styles.PlanCardContent>
              )}
              <Styles.PlanCardFooter>
                <Styles.TypePlan>
                  {"Contrato de 3 meses + R$ 67,80 de taxa de adesão"}
                </Styles.TypePlan>
                <Styles.SubmitPlan
                  fullWidth={false}
                  onClick={() => postNewPlan(plan?.response?.id)}>
                  Assinar
                </Styles.SubmitPlan>
              </Styles.PlanCardFooter>
            </Styles.PlanCard>
          )}
        </>
      )}
    </Styles.CardContainer>
  );
}

function mapStateToProps(state) {
  const { isFetching, plansList } = state.premium;
  const { isFetching: isFetchingProfile, plan } = state.profile;
  return {
    isFetchingAll: isFetching || isFetchingProfile,
    plansList,
    plan,
  };
}

function mapDispatchToProps(dispatch) {
  const { getPlans, postNewPlan } = Creators;
  const { getPlanTec, getPlanNotTec } = ProfileCreators;
  return {
    getPlans: function () {
      return dispatch(getPlans());
    },
    postNewPlan: function (id) {
      return dispatch(postNewPlan(id));
    },
    getPlanTec: function () {
      return dispatch(getPlanTec());
    },
    getPlanNotTec: function () {
      return dispatch(getPlanNotTec());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PlanList);
