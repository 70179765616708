import React from "react";
import { navigate } from "../../../navigation/navigate";
import Styles from "../styles/Styles";
import { customModal } from "../../modals/utils";
import ContactUs from "./ContactUs";
import { PagesConfig, Texts } from "../../../config";
import { connect } from "react-redux";
import CompleteRegister from "./CompleteRegister";

function MenuGroupWithPagesComponent({
  item,
  firstItem,
  headerName,
  globalLogout,
  clearUserData,
  pathname,
  user,
}) {
  const classes = Styles.useStyles();

  const texts = Texts["pt-BR"];

  function handleContactUs() {
    customModal.setInfos(
      texts.contactUs.modal.title,
      [texts.contactUs.modal.text],
      {
        label: texts.contactUs.modal.confirmation,
        onClick: () => {
          customModal.close();
        },
      },
      null,
      <ContactUs />
    );
  }

  function handleLogout() {
    customModal.setInfos(
      texts.leave.logoutModal.exitTitle,
      [texts.leave.logoutModal.exitConfirmationText],
      {
        label: texts.leave.logoutModal.exitConfirmation,
        onClick: () => {
          customModal.close();
          clearUserData();
          globalLogout();
        },
      },
      {
        label: texts.leave.logoutModal.back,
        onClick: () => {
          customModal.close();
        },
      }
    );
  }

  const modalNotification = () => {
    customModal.setInfos(
      `FINALIZE SEU CADASTRO!`,
      [
        "Para poder utilizar o sistema e todo seu poder de análise precisamos que você finalize o seu cadastro com seus dados mais recentes(menu Sistema/Meu Perfil) e preencha os indicadores com os dados Financeiros e Gerenciais (menu Preenchimento Indicadores).",
      ],
      null,
      null,
      <CompleteRegister
        email={user?.email}
        profile={user?.needToCompleteRegister}
      />
    );
  };

  const goto = (path, title) => {
    let link;
    if (!isDisabled(path) === false) {
      if (title === "Fale Conosco") {
        user?.role.includes("ACATE") ? navigate(path) : handleContactUs();
      } else if (title === "Sair") {
        handleLogout();
      } else if (title === "Notificações") {
        modalNotification();
      } else {
        navigate(path);
      }
    } else {
      link = null;
    }
    return link;
  };

  function isDisabled(path) {
    let a = false;
    PagesConfig.logged.map((item) => {
      return item.pages.map((element) => {
        if (path === element.path) {
          if (element.working !== (undefined || null)) {
            a = element.working;
            return element.working;
          } else {
            a = false;
            return false;
          }
        }
        return false;
      });
    });
    return a;
  }

  return (
    <Styles.Group $firstOne={firstItem}>
      <Styles.GroupTitleContainer>
        <Styles.GroupNameContainer>
          <Styles.GroupTitle>{headerName}</Styles.GroupTitle>
        </Styles.GroupNameContainer>
      </Styles.GroupTitleContainer>
      <Styles.SubGroup>
        {item.map((page, pageIndex) => {
          if (page.title === "Notificações") {
            return (
              <Styles.Page
                $currentPage={page.path === pathname}
                $disabled={
                  (!user?.needToCompleteRegister &&
                    !user?.needToInputIndicators) ||
                  !user?.role?.includes("ROLE_ASSOCIATE_MANAGER")
                }
                $buttonContained={page.contained}
                key={page.name + pageIndex}
                onClick={() => {
                  (user?.needToCompleteRegister ||
                    user?.needToInputIndicators) &&
                    goto(page.path, page.title);
                }}>
                <Styles.PageContent>
                  {page.icon ? (
                    <img alt="" src={page.icon} className={classes.icon} />
                  ) : null}
                  {page.title}
                </Styles.PageContent>
                {(user.needToCompleteRegister || user?.needToInputIndicators) &&
                  user?.role === "ROLE_ASSOCIATE_MANAGER" && (
                    <Styles.Notification>{"1 NOVA"}</Styles.Notification>
                  )}
              </Styles.Page>
            );
          } else {
            return (
              <Styles.Page
                $currentPage={page.path === pathname}
                $disabled={!isDisabled(page.path)}
                $buttonContained={page.contained}
                key={page.name + pageIndex}
                onClick={() => goto(page.path, page.title)}>
                <Styles.PageContent>
                  {page.icon ? (
                    <img alt="" src={page.icon} className={classes.icon} />
                  ) : null}
                  {page.name === "Premium" ? (
                    <Styles.Premium>{page.title}</Styles.Premium>
                  ) : (
                    page.title
                  )}
                </Styles.PageContent>
              </Styles.Page>
            );
          }
        })}
      </Styles.SubGroup>
    </Styles.Group>
  );
}

export default connect()(React.memo(MenuGroupWithPagesComponent));
