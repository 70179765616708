import React from "react";
import { Texts } from "../../../config";
import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { Grid } from "@material-ui/core";
import { ButtonOutlined } from "../../../components";
import Title from "../../../components/strings/Title";
import validations from "../../../lib/Validations";

function StepTwo({ goBack, onSubmit, loading, resendToken, title, text }) {
  const texts = Texts["pt-BR"].forgotPassword.tokenConfirmatiom;
  const [ref, setRef] = React.useState(null);
  const sendTokenData = (data) => {
    const dataRequest = {
      code: data.code0 + data.code1 + data.code2 + data.code3 + data.code4,
    };

    onSubmit(dataRequest);
  };

  const CtrlV = (a) => {
    ref.setValue("code0", a[0]);
    ref.setValue("code1", a[1]);
    ref.setValue("code2", a[2]);
    ref.setValue("code3", a[3]);
    ref.setValue("code4", a[4]);
  };

  return (
    <Styles.Content>
      <Title>{title}</Title>
      <Styles.Subtitle>{text}</Styles.Subtitle>
      <FormHolder onSubmit={sendTokenData} formRef={setRef} clearOnSubmit>
        <Grid container spacing={0}>
          <Styles.LabelContainer>
            <Styles.InputCodeLabel>{texts.instertToken}</Styles.InputCodeLabel>
          </Styles.LabelContainer>
          <Grid container spacing={1} justify="center">
            <Grid item xs={4} sm={2} md={2} lg={2}>
              <Styles.InputCode
                name="code0"
                maxLength={1}
                nextInput="code1"
                validations={validations.validToken}
                onChange={(text, a) => {
                  if (a.length <= 1) {
                    if (ref !== undefined) {
                      const nextRef = ref.getInputRef("code1");
                      nextRef.focus();
                    }
                  } else {
                    CtrlV(a);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Styles.InputCode
                name="code1"
                maxLength={1}
                nextInput="code2"
                validations={validations.validToken}
                onChange={(text, a) => {
                  if (a.length <= 1) {
                    if (ref !== undefined) {
                      const nextRef = ref.getInputRef("code2");
                      nextRef.focus();
                    }
                  } else {
                    CtrlV(a);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Styles.InputCode
                name="code2"
                maxLength={1}
                nextInput="code3"
                validations={validations.validToken}
                onChange={(text, a) => {
                  if (a.length <= 1) {
                    if (ref !== undefined) {
                      const nextRef = ref.getInputRef("code3");
                      nextRef.focus();
                    }
                  } else {
                    CtrlV(a);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Styles.InputCode
                name="code3"
                maxLength={1}
                nextInput="code4"
                validations={validations.validToken}
                onChange={(text, a) => {
                  if (a.length <= 1) {
                    if (ref !== undefined) {
                      const nextRef = ref.getInputRef("code4");
                      nextRef.focus();
                    }
                  } else {
                    CtrlV(a);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Styles.InputCode
                name="code4"
                maxLength={1}
                nextInput="code0"
                validations={validations.validToken}
                onChange={(text, a) => {
                  if (a.length > 1) {
                    CtrlV(a);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Styles.ConfirmButton
            name="button"
            type="submit"
            loading={loading}
            disable={loading}
          >
            {texts.confirmToken}
          </Styles.ConfirmButton>
        </Grid>
        <Grid container spacing={1} justify="center">
          <Grid item xs={12}>
            <ButtonOutlined
              name="button"
              onClick={resendToken}
              loading={loading}
              disable={loading}
            >
              {texts.resendToken}
            </ButtonOutlined>
          </Grid>
          <Grid item xs={12}>
            <ButtonOutlined
              loading={loading}
              disable={loading}
              onClick={goBack}
            >
              {texts.back}
            </ButtonOutlined>
          </Grid>
        </Grid>
      </FormHolder>
    </Styles.Content>
  );
}

export default React.memo(StepTwo);
