import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

export default function* getIncubators() {
  try {
    const response = yield call(api.getIncubator, null);

    yield put(Creators.getIncubatorsSuccess(response.data?.response));
  } catch (response) {
    yield put(Creators.getIncubatorsFailure());
  }
}
