import React from "react";
import { AccessHistory } from "../features";

function AccessHistoryPage() {
  return (
    <>
      <AccessHistory />
    </>
  );
}

export default AccessHistoryPage;
