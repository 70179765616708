import { sagas as globalSagas } from "./globalReduxSagas";
import { sagas as loginSagas } from "./login";
import { sagas as firstAccessSagas } from "./firstAccess";
import { sagas as profileSagas } from "./Profile";
import { sagas as financialDataSagas } from "./financialData";
import { sagas as managementDataSagas } from "./managementData";
import { sagas as recoveryPasswordSagas } from "./forgotPassword";
import { sagas as usersSagas } from "./users";
import { sagas as dataEntrySagas } from "./dataEntry";
import { sagas as accessHistorySagas } from "./accessHistory";
import { sagas as accessHistoryClientsSagas } from "./accessHistoryClients";

import { sagas as companyAccessSagas } from "./companyAccess";
import { sagas as generalSagas } from "./General";
import { sagas as evaluatedReleasesSagas } from "./evaluatedReleases";
import { sagas as premiumSagas } from "./premium";
import { sagas as plansApprovalSagas } from "./plansApproval";
import { sagas as contactUsSagas } from "./contactUsAcate";

const sagas = [
  ...globalSagas,
  ...loginSagas,
  ...profileSagas,
  ...financialDataSagas,
  ...managementDataSagas,
  ...firstAccessSagas,
  ...recoveryPasswordSagas,
  ...usersSagas,
  ...accessHistorySagas,
  ...dataEntrySagas,
  ...companyAccessSagas,
  ...generalSagas,
  ...evaluatedReleasesSagas,
  ...plansApprovalSagas,
  ...premiumSagas,
  ...contactUsSagas,
  ...accessHistoryClientsSagas,
];

export default sagas;
